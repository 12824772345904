<template>
	<div
		id="app"
		data-server-rendered="true"
		:class="[
			$route.meta.organizationLayout && '__active',
		]"
	>
		<div
			id="main-wrapper"
			:class="[
				!$route.meta.organizationLayout && 'wrapper',
				$route.meta.organizationLayout && 'organization-wrapper'
			]"
		>
			<div v-if="isRouteLoading" class="loading-wrapper">
				<i class="fa fa-spinner spinner"></i>
			</div>

			<OrganizationSidebar v-if="$route.meta.organizationLayout && ['md', 'lg'].includes($mq)" :open="isOpenBurger"/>
			
			<MobileSidebar v-else-if="$route.meta.organizationLayout"/>
			
			<Header v-if="!isHideHeader && !$route.meta.organizationLayout" :strictShowType="userRole" />
			
			<template v-if="$route.meta.organizationLayout">
				<div class="organization-admin" :class="{ '_open': sidebarOpen }">
					<transition name="router-fade">
						<router-view></router-view>
					</transition>
				</div>
			</template>

			<template v-else>
				<transition name="router-fade">
					<router-view></router-view>
				</transition>
			</template>
		</div>

		<vkLoginModal v-if="isVkLogin" />

		<TariffAccessDeniedModal />

		<RequisitesAccessDeniedModal />

		<Lightbox />

		<ModalGlobal></ModalGlobal>

		<Modal modalId="errorModal" :styleString="'z-index: 10000;'">
			<template slot="header">
				Ошибка
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</template>

			<template slot="body">
				{{ errorText }}
			</template>
			
			<template slot="footer">
				<button class="btn btn-light btn-offline" data-dismiss="modal">
					Закрыть
				</button>
			</template>
		</Modal>

		<transition name="fade">
			<GeolocationModal v-if="showGeolocationModal" @close="onCloseGeolocation"
				@resolve="doResolvePromise"
				@reject="doRejectPromise"
			/>
		</transition>
		
		<span style="display: none;">app updated at {{ updatedAt }}</span>
	</div>
</template>

<script>
	import authorizationModalMixin from '@/mixins/authorizationModal.js';
	import Header from './components/baseComponents/header/HeaderMain.vue';
	import router from './router/index.js';
	import TariffAccessDeniedModal from '@/components/organization/tariffAccessDeniedModal.vue';
	import RequisitesAccessDeniedModal from '@/components/organization/RequisitesAccessDeniedModal.vue';
	import vkLoginModal from '@/components/landing/vkLoginModal.vue';
	import moderationRequestEvent from '@/mixins/moderationRequestEvent.js';
	import requisites from '@/mixins/requisites.js';
	import managingModalWindows from '@/mixins/managingModalWindows.js';
	import Modal from '@/components/modal.vue';
	import { mapGetters, mapActions } from "vuex";
	import documentResolution from "@/mixins/documentResolution";
	import ModalGlobal from "@/components/modal/ModalGlobal";
	import OrganizationSidebar from '@/components/baseComponents/sidebar/Sidebar.vue';
	import MobileSidebar from '@/components/baseComponents/sidebar/MobileSidebar.vue';
	import Lightbox from "@/components/Lightbox";
	import RouterHelper from "@/router/routerHelper";
	import Vue from 'vue';
	import lazyImages from '@/utils/lazyImages';
	import initAppMixin from '@/mixins/initAppMixin';
	import GeolocationModal from '@/components/baseComponents/GeolocationModal.vue';
	import bridge from '@vkontakte/vk-bridge';

	Vue.prototype.$routerHelper = new RouterHelper(router);

	const updatedAt = process.env.APP_UPDATED_AT

	export default {
		name: 'app',

		router,
		
		data() {
			return {
				updatedAt,
				isHideHeader: false,
				isOpenBurger: false,

				showGeolocationModal: false,
				timeOut: null,
			}
		},

		components: {
			Lightbox,
			Modal,
			Header,
			TariffAccessDeniedModal,
			RequisitesAccessDeniedModal,
			vkLoginModal,
			ModalGlobal,
			OrganizationSidebar,
			MobileSidebar,
			GeolocationModal,
		},

		mixins: [
			authorizationModalMixin,
			documentResolution,
			moderationRequestEvent,
			requisites,
			managingModalWindows,
			initAppMixin,
		],

		computed: {
			...mapGetters({
				user: 'user',
				sidebarOpen: 'sidebarOpen',
				enterFrom: 'enterFrom',
				currentUser: 'userProfile',
				isVKMiniApp: 'isVKMiniApp',
			}),

			logo() {
				return lazyImages.loader_logo;
			},

			userRole: function () {
				return this.$store.getters.userRole
			},

			isModerator() {
				return this.$store.getters.isModerator
			},

			isRouteLoading() {
				return this.$store.getters.isRouteLoading
			},

			errorText() {
				return this.$store.getters.errorText
			},

			isVkLogin() {
				return this.$store.getters.vkLoginModalSettings.show
			},
		},

		watch: {
			errorText() {
				if (this.errorText) {
					$('#errorModal').modal('show');
				}
			},

			activeTariff(newVal) {
				if (newVal.paid_at) {
					this.setPassedStep({ companyId: this.currentCompanyId, step: '/organization-admin/tariff', context: this });
				}
			},

			'$route.query.pdc_id': {
				immediate: true,
				handler() {
					if (!this.$route.query.pdc_id) return
					this.$store.dispatch('pdcCode', this.$route.query.pdc_id);
				}
			},

			'$route.path': {
				immediate: true,
				handler(newVal) {
					if (this.$route.meta.isHideHeader) {
						this.isHideHeader = true;
					}
				}
			},
		},

		metaInfo() {
			return {
				title: 'BenefittY - скидки, купоны, скидочные карты, программы лояльности',
				meta: [
					{
						vmid: 'description',
						name: 'description',
						content: 'BENEFITTY - СЕРВИС ВЫГОДНЫХ ПОКУПОК. Пользуйся картами лояльности и скидками всей семьей и с друзьями, получай персональные предложения, знай все свои выгоды перед покупкой!'
					}
				],
			}
		},

		mounted() {
			if (this.$route.meta.isHideHeader) {
				$('#errorModal').on('hide.bs.modal', () => {
					this.$store.dispatch('setErrorText', '');
				});

				this.$store.dispatch('fetchUserDiscountCards', this)
			}

			bridge.send('VKWebAppStorageGet', {
				keys: [
					'show_geo_modal',
				]
			})
			.then((data) => { 
				if (data.keys) {
					if (!data.keys[0].value) {
						this.timeOut = setTimeout(() => {
							this.showGeolocationModal = true;
						}, 2000);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
		},

		async created() {
			if (this.activeTariff && this.activeTariff.paid_at) {
				this.setPassedStep({ companyId: this.currentCompanyId, step: '/organization-admin/tariff', context: this });
			}
			if (window.innerWidth < 1200) {
				this.setSidebarOpen(false);
			} else {
				this.setSidebarOpen(true);
			}
		},

		methods: {
			...mapActions({
				setSidebarOpen: 'setSidebarOpen',
				setPassedStep: 'guide/setPassedStep',
			}),

			onCloseGeolocation() {
				this.showGeolocationModal = false;

				bridge.send('VKWebAppStorageSet', {
					key: 'show_geo_modal',
					value: 'true',
				})
				.catch((error) => {
					console.error(error);
				});
			},

			backToQrsLk() {
				window.close();
			},

			getCookie(name) {
				const matches = document.cookie.match(new RegExp(
					"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
				))
				return matches ? decodeURIComponent(matches[1]) : undefined;
			},

			doResolvePromise() {
				this.resolvePromise(true);

				this.$messageModal.close();
			},

			doRejectPromise() {
				this.rejectPromise('Cancel');

				this.$messageModal.close();
			}
		}
	};
</script>

<style lang="scss">
.float-btn {
	position: absolute;
	left: 0;
	bottom: 0;
}

body,
html {
	background: #F0F6F7;
	height: 100%;
}

.main-wrapper {
	overflow: hidden;
}

.organization-wrapper {
	min-height: 100%;
	background: #f5f5f5;
}

#app {
	height: 100%;
	height: -webkit-fill-available;
	min-height: 100vh;
    min-height: -webkit-fill-available;

	@include respond-to(lTablet) {
		overflow-x: hidden;
	}

	&.__active {
		background: #F0F6F7;
	}
}

.organization-admin {
	padding: 30px 24px!important;
	transition: $default-transition;

	&._open {
		padding-left: 298px!important;

		@include respond-to(lTablet) {
			padding: 30px 20px!important;
		}
	}

	@include respond-to(lTablet) {
		padding: 30px 20px!important;
	}
}

.loading-wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}
</style>