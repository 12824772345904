import Vue from 'vue';
import store from '@/store/index.js';
import router from '@/router/index.js';
import bridge from '@vkontakte/vk-bridge';
import authProvider from '@/utils/providers/authProvider.js';
import consts from '@/utils/consts.js';
import userProvider from '@/utils/providers/userProvider.js';
import VueMain from '@/main.js';
import { ROUTER_HELPER_ROUTES, routes } from '@/router/routerHelperRoutes';
import { optional } from '@/utils/helpers/common';

const webViewFlag = '/web-view/';

class RouterHelper {
    constructor() {
        this.router = router;
    }

    checkIsCurrentRouteByName(name) {
        if (name == this.router.app.$route.name || 'Moderator' + name == this.router.app.$route.name) {
            return true;
        } else {
            return false;
        }
    }

    checkIsCurrentRouteByNames(names) {
        if (!names) {
            return;
        }

        for (let i = 0; i < names.length; i++) {
            if (names[i] == this.router.app.$route.name || 'Moderator' + names[i] == this.router.app.$route.name) {
                return true;
            }
        }

        return false;
    }

    async asyncSetRouterQueryParameters(options) {
        const query = {
            ...this.router.currentRoute.query,
            ...options,
        };
        await this.router.replace({ query });
    }

    async asyncSetRouterQueryByName(name, value, options = null) {
        const method = options && options.replace ? 'replace' : 'push';
        if (!Object.keys(this.router.app.$route.query).length) {
            await this.router[method]({ query: { [name]: value } });
            return;
        }

        const query = {
            ...this.router.currentRoute.query,
            [name]: value,
        };

        await this.asyncSetRouterQueryParameters(query);
    }

    setRouterQueryByName(name, value, options = null) {
        const method = options && options.replace ? 'replace' : 'push';
        if (Object.keys(this.router.app.$route.query).length == 0) {
            this.router[method]({ query: { [name]: value } });
            return;
        }

        for (const r in this.router.app.$route.query) {
            if (this.router.app.$route.query[r]) {
                this.router.replace({ query: { ...this.router.app.$route.query, [name]: value } });
            } else {
                this.router[method]({ query: { ...this.router.app.$route.query, [name]: value } });
            }
        }
    }

    beforeUserEnter(to, from, next) {
        if (store.getters.user.authenticated) {
            next();
            return;
        }

        store.dispatch('setAuthorizationModalSettings', { isLoginForm: true, isRegistrationForm: false, role: 'person', routeTo: to.fullPath });
    }

    beforeResetEnter(to, from, next) {
        if (!store.getters.user.authenticated) {
            next();
            return;
        }

        next({ path: '/' });
    }

    beforePersonEnter(to, from, next) {
        const authVK = async () => {
            try {
                const data = await bridge.send('VKWebAppGetUserInfo', {});
                const url = to.fullPath;
                const launchParams = url.slice(url.indexOf('?') + 1);
                const launchParamsParsed = launchParams.split('&').reduce((acc, el) => ({ [el.split('=')[0]]: el.split('=')[1], ...acc }), {});
                const query = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    sex: data.sex,
                    bdate: data.bdate,
                    photo_max_orig: data.photo_max_orig,
                    launch_params: launchParams,
                };
                store.dispatch('setVKUserInfo', data);
                await authProvider.silentLogOut(VueMain);
                const { exists } = await userProvider.checkIfVkUserExists(VueMain, Number(launchParamsParsed.vk_user_id));
                console.log(exists);
                if (exists) {
                    await authProvider.loginVkMiniapp(VueMain, query);
                    await userProvider.getCurrentUser(VueMain);
                    userProvider.setUserRole(VueMain, consts.userRole.person);
                    store.dispatch('setIsRouteLoading', false);
                    next('person/home');
                } else {
                    store.dispatch('setVkLoginModalSettings', {
                        show: true,
                        callback: async () => {
                            let email;
                            let phone_number;
                            let group_messages_confirm = false;
                            try {
                                email = (await bridge.send('VKWebAppGetEmail', {})).email;
                            } catch (e) { }
                            try {
                                phone_number = (await bridge.send('VKWebAppGetPhoneNumber', {})).phone_number;
                            } catch (e) { }
                            try {
                                bridge.send('VKWebAppAllowMessagesFromGroup', { group_id: 180782852 });
                                group_messages_confirm = true;
                            } catch (e) { }
                            store.dispatch('setVkLoginModalSettings', {
                                show: false,
                                data: {},
                                callback: null,
                            });
                            await authProvider.loginVkMiniapp(VueMain, {
                                email,
                                phone: phone_number,
                                group_messages_confirm,
                                ...query,
                            });
                            await userProvider.getCurrentUser(VueMain);
                            userProvider.setUserRole(VueMain, consts.userRole.person);
                            store.dispatch('setIsRouteLoading', false);
                            next('person/home');
                        },
                    });
                }
            } catch (e) {
                console.error('I Am An Error!', e);
                // setTimeout(authVK, 2000)
            }
        };
        if (!to.query.vk_app_id && store.getters.user.authenticated && (store.getters.user.email || Vue.ls.get('name')) && store.getters.userRole == 'person') {
            next();
        } else if (to.query.vk_app_id) {
            store.dispatch('setIsRouteLoading', true);
            if (!store.getters.isVKMiniApp) {
                authVK();
                store.dispatch('setIsVKMiniApp', true);
            } else if (to.path === '/') {
                next('/person/home');
            }
        } else if (!consts.notGuestRoutes.includes(to.name) && !consts.notGuestRoutes.includes(to.fullPath) && !store.getters.isVKMiniApp) {
            next();
        } else {
            store.dispatch('setAuthorizationModalSettings', { isLoginForm: true, isRegistrationForm: false, role: 'person', routeTo: to.fullPath });
        }
    }

    beforePersonEnterRegistration(to, from, next) {
        if (store.getters.user.authenticated
			&& (store.getters.user.email || Vue.ls.get('name'))
			&& store.getters.userRole == 'person') {
            next();
            return;
        }

        store.dispatch('setAuthorizationModalSettings', { isLoginForm: false, isRegistrationForm: true, role: 'person', routeTo: to.fullPath });
    }

    beforeOrganizationEnter(to, from, next) {
        const isPayment = window.location.href.indexOf('person/payment') > -1;
        // let enterFrom = localStorage.getItem('enterFrom')

        // if (to.matched.some(record => record.meta.sber_business) && enterFrom === 'sber_business') {
        // 	next();
        // } else if (to.name !== 'organizationAdmin') {
        // 	next({ name: 'organizationAdmin' });
        // } else {
        // 	next();
        // }

        if (store.getters.user.authenticated) {
            if (store.getters.userRole === 'organization') {
                next();
            } else {
                store.dispatch('setAuthorizationModalSettings', { isLoginForm: true, isRoleChoose: true, isRegistrationForm: false, role: 'organization', routeTo: to.fullPath, isPayment: isPayment });
            }
            return;
        }

        store.dispatch('setAuthorizationModalSettings', { isLoginForm: true, isRegistrationForm: false, role: 'organization', routeTo: to.fullPath, isPayment: isPayment });
    }

    beforeBrandEnter(to, from, next) {
        if (store.getters.user.authenticated && (store.getters.user.email || Vue.ls.get('name'))) {
            if (store.getters.userRole == 'brand') {
                next();
            } else {
                store.dispatch('setAuthorizationModalSettings', { isLoginForm: true, isRoleChoose: true, isRegistrationForm: false, role: 'brand', routeTo: to.fullPath });
            }
            return;
        }
        store.dispatch('setAuthorizationModalSettings', { isLoginForm: true, isRegistrationForm: false, role: 'brand', routeTo: to.fullPath });
    }

    beforeBankEnter(to, from, next) {
        if (store.getters.user.authenticated
			&& (store.getters.user.email || Vue.ls.get('name'))
			&& store.getters.userRole == 'bank') {
            next();
            return;
        }

        store.dispatch('setAuthorizationModalSettings', { isLoginForm: true, isRegistrationForm: false, role: 'bank', routeTo: to.fullPath });
    }


    beforeModeratorEnter(to, from, next) {
        if (store.getters.user.authenticated
			&& (store.getters.user.email || Vue.ls.get('name'))
			&& store.getters.isModerator) {
            next();
            return;
        }

        next('/moderator/login');
    }

    /**
	 * @param { RedirectHelperOptions|string } options
	 * @returns { RawLocation }
	 */
    resolveRoute(options) {
        const routeKey = options.routeKey || options;
        const isWebView = window.location.href.includes(webViewFlag);
        const routeType = isWebView ? 'webview' : 'basic';
        const routeName = optional(routes, `${routeKey}.${routeType}`);
        const route = {
            name: routeName,
            query: {},
            params: {},
        };

        if (options.params) {
            route.params = options.params;
        }

        if (isWebView) {
            route.query = router.currentRoute.query;
        }

        if (options.query) {
            route.query = {
                ...route.query,
                ...options.query,
            };
        }

        return route;
    }

    /**
	 *
	 * @param { RedirectHelperOptions|string } options
	 * @returns {null|void}
	 */
    redirect(options) {
        try {
            const route = this.resolveRoute(options);
            console.log({ route });
            return router.push(route);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    isCurrentRouteEquals(routeKey) {
        const route = routes[routeKey];
        console.log({ routeKey, route });
        if (route) {
            const names = Object.values(route);
            console.log({ names });
            return names.includes(router.currentRoute.name);
        } else {
            return false;
        }
    }
}

export default RouterHelper;
