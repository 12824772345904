<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		:fill="fill"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.0049 19.3508C19.6723 17.0469 17.6187 15.3949 15.222 14.6118C16.4075 13.9061 17.3286 12.8307 17.8437 11.5509C18.3589 10.271 18.4397 8.85747 18.0737 7.52726C17.7077 6.19704 16.9152 5.02374 15.8179 4.18753C14.7205 3.35132 13.379 2.89844 11.9994 2.89844C10.6198 2.89844 9.27827 3.35132 8.18093 4.18753C7.08359 5.02374 6.29108 6.19704 5.9251 7.52726C5.55913 8.85747 5.63993 10.271 6.1551 11.5509C6.67026 12.8307 7.5913 13.9061 8.77678 14.6118C6.38016 15.3941 4.32653 17.0461 2.99391 19.3508C2.94504 19.4305 2.91262 19.5192 2.89857 19.6116C2.88453 19.704 2.88913 19.7983 2.91212 19.8889C2.9351 19.9795 2.976 20.0646 3.0324 20.1391C3.0888 20.2137 3.15956 20.2762 3.2405 20.3229C3.32145 20.3697 3.41094 20.3997 3.50369 20.4114C3.59644 20.423 3.69058 20.4159 3.78055 20.3905C3.87052 20.3652 3.9545 20.322 4.02753 20.2637C4.10057 20.2053 4.16117 20.133 4.20578 20.0508C5.85428 17.2018 8.76803 15.5008 11.9994 15.5008C15.2308 15.5008 18.1445 17.2018 19.793 20.0508C19.8376 20.133 19.8982 20.2053 19.9713 20.2637C20.0443 20.322 20.1283 20.3652 20.2183 20.3905C20.3082 20.4159 20.4024 20.423 20.4951 20.4114C20.5879 20.3997 20.6774 20.3697 20.7583 20.3229C20.8393 20.2762 20.91 20.2137 20.9664 20.1391C21.0228 20.0646 21.0637 19.9795 21.0867 19.8889C21.1097 19.7983 21.1143 19.704 21.1002 19.6116C21.0862 19.5192 21.0538 19.4305 21.0049 19.3508ZM7.09941 9.20082C7.09941 8.23169 7.38679 7.28432 7.92521 6.47852C8.46362 5.67272 9.2289 5.04468 10.1243 4.67381C11.0196 4.30294 12.0048 4.2059 12.9553 4.39497C13.9059 4.58404 14.779 5.05072 15.4642 5.73599C16.1495 6.42127 16.6162 7.29437 16.8053 8.24488C16.9943 9.19538 16.8973 10.1806 16.5264 11.076C16.1555 11.9713 15.5275 12.7366 14.7217 13.275C13.9159 13.8134 12.9685 14.1008 11.9994 14.1008C10.7003 14.0994 9.45475 13.5827 8.53612 12.6641C7.61749 11.7455 7.1008 10.5 7.09941 9.20082Z"
			:fill="fill"
		/>
	</svg>
</template>

<script>
import BaseIcon from "@/components/baseComponents/icons/BaseIcon";

export default {
	extends: BaseIcon,
};
</script>
