import Vue from 'vue'
import InputMask from 'inputmask'

require('inputmask/dist/inputmask/phone-codes/phone.js');

import { loading } from '@/utils/directives/Loading'
import { fixed } from "@/utils/directives/Fixed"
import {observeLast} from "@/utils/directives/ObserveLast";

export const directives = {
	observeLast,
	loading,
	fixed,
	mask: {
		bind: function (el, binding) {
			InputMask(binding.value.mask, binding.value.options).mask(el);
		}
	},
	scrollEnd: {
		bind: function (el, binding) {
			const id = Math.floor( Math.random() * 1000 ) + 9999
			el.id = id
			el.addEventListener('scroll', function (e) {
				const scrollId =  `scrollTimeout[${id}]`
				window[scrollId] = null
				clearTimeout(window[scrollId])
				const bottomScrolled = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
				if (bottomScrolled && binding.value) {
					window[scrollId] = setTimeout(() => {
						binding.value()
					}, binding.arg || 300)
				}
			})
		},
		unbind: function (el) {
			const scrollId =  `scrollTimeout[${el.id}]`
			clearTimeout(window[scrollId])
		}
	},
	inputNumber: {
		bind: function (el, binding) {
			el.type = "number"
			el.addEventListener('keydown', function (event) {
				// Код клавиши - это код именно клавиши числа (48 - 0, 57 - 9)
				if (['+', '-', 'e'].includes(event.key))
					event.preventDefault()
			})
			el.addEventListener('wheel', function (event) {
				el.blur()
			})
		}
	}
}


/**
 *  v-input-number
 * 	- директива, нужная, чтобы не дублировать код кастомизации поведения <input type="number">
 *
 */