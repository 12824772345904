<template>
    <aside
        class="sidebar"
        :class="{'_open': sidebarOpen}"
    >
        <Transition name="fade">
            <SidebarLogo v-if="sidebarOpen"/>
        </Transition>

        <Transition name="fade">
            <div
                class="sidebar-content"
                v-if="sidebarOpen"
            >
                <router-link :to="{ path: '/organization-admin/places' }">
                    <div class="sidebar-content__item dropdown _single">
                        <div class="dropdown-header places">
                            <span class="dropdown-header__title">Мои торговые точки</span>
                        </div>
                    </div>
                </router-link>

                <div class="sidebar-content__item dropdown _single">
                    <div class="dropdown-header" @click="showPromotionalSublist = !showPromotionalSublist">
                        <span class="dropdown-header__title">Инфо-материалы</span>

                        <DropdownIcon :active="showPromotionalSublist"/>
                    </div>

                    <Transition name="sublist">
                        <ul
                            v-if="showPromotionalSublist"
                            class="dropdown-content __with-pb"
                        >
                            <SidebarItem
                                v-for="item in promotionalSublist"
                                :title="item.title"
                                :link="item.link"
                                :icon="item.icon"
                            />
                        </ul>
                    </Transition>
                </div>

                <SingleItem
                    v-for="item in singleItems"
                    :link="item.link"
                    :title="item.title"
                />

                <div class="sidebar-content__item dropdown _single">
                    <div
                        class="dropdown-header"
                        @click="showLoyaltySublist = !showLoyaltySublist"
                    >
                        <span class="dropdown-header__title">Лояльность</span>

                        <DropdownIcon :active="showLoyaltySublist"/>
                    </div>

                    <Transition name="sublist">
                        <ul
                            v-if="showLoyaltySublist"
                            class="dropdown-content __with-pb"
                        >
                            <SidebarItem
                                v-for="item in loyaltySublist"
                                :title="item.title"
                                :link="item.link"
                                :icon="item.icon"
                            />
                        </ul>
                    </Transition>
                </div>
    
                <div class="sidebar-content__item dropdown _single">
    
                    <div @click="showAccountSettings = !showAccountSettings" class="dropdown-header">
                        <span class="dropdown-header__title">Настройки аккаунта</span>
    
                        <DropdownIcon :active="showAccountSettings"/>
                    </div>
    
                    <Transition name="sublist">
                        <ul
                            v-if="showAccountSettings"
                            class="dropdown-content __with-pb"
                        >
                            <SidebarItem
                                v-for="item in filteredSettingsItems"
                                :title="item.title"
                                :link="item.link"
                                :icon="item.icon"
                            />
                        </ul>
                    </Transition>
                </div>

                <div class="sidebar-content__item dropdown _single">
                    <div @click="showBaseInfo = !showBaseInfo" class="dropdown-header">
                        <span class="dropdown-header__title">Сервисы BenefittY</span>
    
                        <DropdownIcon :active="showBaseInfo"/>
                    </div>
    
                    <Transition name="sublist">
                        <ul
                            v-if="showBaseInfo"
                            class="dropdown-content"
                        >
                            <SidebarItem
                                title="Общие Скидки/акции"
                                icon="DiscountIcon"
                                link="/organization-admin/promotions-and-discounts"
                            />
    
                            <li class="dropdown">
                                <div @click="showReviews = !showReviews" class="dropdown-header __sub">
                                    <div class="dropdown-header__heading">
                                        <ReviewsIcon/>
                                        <span class="heading-text"
                                        :class="{
                                            '_current': [
                                                '/organization-admin/reviews-list',
                                                '/organization-admin/discount/reviews-list'
                                            ].includes($route.path)
                                        }">Общие Отзывы</span>
                                    </div>
    
                                    <DropdownIcon :active="showReviews"/>
                                </div>
    
                                <Transition name="sublist">
                                    <ul
                                        v-if="showReviews"
                                        class="dropdown-content"
                                    >
                                        <SidebarItem title="Отзывы о торговых<br>точках" link="/organization-admin/reviews-list" />
    
                                        <SidebarItem title="Отзывы об акциях" link="/organization-admin/discount/reviews-list" />
                                    </ul>
                                </Transition>
                            </li>
    
                            <SidebarItem
                                icon="NoticeIcon"
                                title="Общие Пуш-уведомления"
                                link="/organization-admin/communicate-customers"
                            />
                        
                            <SidebarItem
                                title="Общие Фото и видео"
                                icon="PhotoIcon" 
                                link="/organization-admin/organizations-media"
                            />
                        </ul>
                    </Transition>
                </div>

                <SingleItem
                    link="/organization-admin/support"
                    title="Поддержка"
                />
            </div>
        </Transition>
        
        <Transition>
            <div class="sidebar-bottom" v-if="false">
                <div class="button">Личный&nbsp;кабинет&nbsp;{{ enterFrom }}</div>
            </div>
        </Transition>
    </aside>
</template>

<script>
    import lazyImages from '@/utils/lazyImages.js';
    import organizationProvider from '@/utils/providers/organizationProvider.js';
    import SidebarLogo from './components/SidebarLogo.vue';
    import DropdownIcon from './components/icons/DropdownIcon.vue';
    import SidebarItem from './components/SidebarItem.vue';
    import ReviewsIcon from './components/icons/ReviewsIcon.vue';
    import HideIcon from './components/icons/HideIcon.vue';
    import SingleItem from './components/SingleItem.vue';
    import { mapGetters } from 'vuex';
    import { userVisibleInfo } from '@/utils/users/userVisible';

    export default {
        name: "OrganizationSidebar",

        components: {
            SidebarLogo,
            DropdownIcon,
            SidebarItem,
            ReviewsIcon,
            HideIcon,
            SingleItem,
        },

        data() {
            return {
                lazyImages: lazyImages,
                companyCategories: [],
                showBaseInfo: true,
                showMyPlaces: false,
                showAccountSettings: true,
                showLoyaltySublist: true,
                showPromotionalSublist: true,
                showMore: false,
                showReviews: false,

                settingsItems: [
                    { title: 'Общие настройки', link: '/organization-admin/settings', icon: 'SettingsIcon' },
                    { title: 'Администраторы', link: '/organization-admin/administrators', icon: 'AdminsIcon' },
                    { title: 'Кассиры', link: '/organization-admin/cashier', icon: 'CashierIcon', qrs: false, },
                    { title: 'Кассовые аппараты', link: '/organization-admin/cash-machines/variants', icon: 'MachinesIcon', qrs: false, },
                    { title: 'Платежный кабинет', link: '/organization-admin/payment-account/balance', icon: 'CabinetIcon' },
                    { title: 'Реквизиты компании', link: '/organization-admin/settings/requisites', icon: 'RequisitesIcon' },
                ],
                
                loyaltySublist: [
                    {
                        title: 'Показатели лояльности',
                        link: '/organization-admin/loyalty-program',
                        icon: 'StatsIcon'
                    },
                    {
                        title: 'Настройки лояльности',
                        link: '/organization-admin/loyalty-program/settings',
                        icon: 'LoyaltySettingsIcon'
                    },
                ],

                promotionalSublist: [
                    {
                        title: 'Обучите сотрудников',
                        link: '/organization-admin/promote-loyalty/teach',
                        icon: 'TeachIcon'
                    },
                    {
                        title: 'Материалы&nbsp;в&nbsp;торговую<br>точку',
                        link: '/organization-admin/promote-loyalty/materials',
                        icon: 'MaterialsIcon'
                    },
                ],

                singleItems: [
                    { title: 'Тарифы', link: '/organization-admin/tariff' },
                ],
            };
        },

        computed: {
            ...mapGetters({
                sidebarOpen: 'sidebarOpen',
                enterFrom: 'enterFrom',
            }),
            filteredSettingsItems() {
                return userVisibleInfo(this.settingsItems, this.enterFrom);
            },

            userRole() {
                this.$store.getters.userRole;
            },

            currentCompanyId() {
                return this.$store.getters.currentCompanyId;
            },

            isModerator() {
                return this.$store.getters.isModerator;
            },

            allCategoriesCount() {
                return this.companyCategories.reduce((sum, category) => sum + category.count, 0)
            },
        },

        async created() {
            this.companyCategories = await this.getCompanyCategories();
        },

        methods: {
            getCompanyCategories() {
                const options = {
                    for_current_user: !this.isModerator,
                    companyId: this.currentCompanyId,
                };

                return organizationProvider.getCompanyCategories(this, options);
            },

            getPlaceLink(id) {
                return `/organization-admin/places?currentCategory=${id}`
            },

            openPlaces(id) {
                this.$router.push({
                    path: `/organization-admin/places`,
                    query: {
                        currentCategory: id,
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>

.heading-text {
    &._current {
        color: #007bff;
    }
}

.sidebar {
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 0;
    opacity: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    transition: $default-transition;
    z-index: 1000;
    transform: translateX(0);

    &._open {
        opacity: 1;
        width: 266px;
    }

    .sidebar-content {
        overflow-y: auto;
        max-height: calc(100vh - 100px);

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0);
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        }

        &:hover {

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            }
        }

        .sidebar-content__item {
            display: block;
            border-bottom: 1px solid #d9e5f6;
            padding: 30px 24px;

            &._single {
                padding: 20px;
                padding-right: 16px;
            }

            .item-header {
                display: flex;
                align-items: center;
                gap: 13px;
                cursor: pointer;

                .item-header__title {
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 500;
                    color: $dark;
                }
            }
        }
    }

    .sidebar-bottom {
        padding: 0 20px;
        margin: 10px 0;
        font-size: 15px;

        .button {
            width: 100%;
            padding: 10px 20px;
            background: #007BFF;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            cursor: pointer;
            transition: 0.3s all;

            &:hover {
                background: #086cd7;
            }
        }
    }
}

.dropdown {
    .item-icon {
        width: 20px;
        height: 20px;
    }

    .dropdown-content {
        margin-bottom: 0;

        &.__with-pb {
            padding-bottom: 1rem;
        }
    }

    .dropdown-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0;
        cursor: pointer;
        // margin-bottom: 8px;

        &.places {
            margin: 0;
        }

        &.__sub {
            gap: 0;
            justify-content: space-between;
            margin-bottom: 0;
            margin-top: 16px;

            .dropdown-header__heading {
                display: flex;
                gap: 9px;
                align-items: center;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #475569;
            }
        }

        .dropdown-header__title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            color: $dark;
            cursor: pointer;
        }

        .dropdown-header__icon {
            color: #475569;
            transform: rotate(0deg);
            transition: 0.3s all;
            font-size: 24px;

            &.__active {
                transform: rotate(180deg);
            }

            &.__active-left {
                transform: rotate(90deg);
            }
        }
    }

    .dropdown-content {
        overflow: hidden;

        .dropdown-content__item {
            display: flex;
            align-items: center;
            margin-top: 16px;
            cursor: pointer;
            justify-content: space-between;

            .item-content__wrapper {
                display: flex;
                align-items: center;
                gap: 9px;
            }


            .item-text {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #475569;

                &.__active {
                    color: #007BFF;
                }
            }

            .item-sum {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                width: 23px;
                height: 18px;
                background: #007BFF;
                border-radius: 4px;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}

@media (max-width: 1528px) {
    .sidebar {
        padding-bottom: 0;
    }
}

@media (max-width: 1030px) {
    .sidebar {
        transform: translateX(-400px);

        &.__active {
            transform: translateX(0);
        }
    }
}</style>