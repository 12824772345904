export default {
	methods: {
		clickOutsideElement: function (elementId, animation = 'none', duration = 0) {
			document.onmouseup = null
			if (!elementId) {
				return;
			}

			if (animation == 'appearFromLeft') {
				this.appearFromLeft(elementId);
				return;
			}
			if (animation == 'none' || animation == 'duration') {
				this.noneAnimation(elementId, duration)
			}
		},
		noneAnimation: function (elementId, duration = 0) {
			let elem = $('#' + elementId);
			if (elem.is(':visible')) {
				elem.hide(duration);
				return;
			} else {
				elem.show(duration);
			}

			document.onmouseup = function (e) {
				elem.hide(duration);
			}
		},
		appearFromLeft: function (elementId) {
			let elem = document.getElementById(elementId);
			if (elem.offsetWidth != 0) {
				elem.style.width = '0px';
				return;
			} else {
				elem.style.width = '70%';
			}

			document.onmouseup = function (e) {
				elem.style.width = '0px';
			}
		}
	}
}