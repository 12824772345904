import consts from '@/utils/consts';
import store from '@/store';
import VueMain from '@/main';
import bridge from '@vkontakte/vk-bridge';

export default class Geolocation {
    constructor(isVKMiniApp, mapRef) {
        this.geolocationInit = false;
        this.geolocationIsDefault = false;
        this.currentGeolocation = {
            lat: consts.defaultGeolocation[1],
            lng: consts.defaultGeolocation[0],
        };
        this.mapRef = mapRef;
        this.isVKMiniApp = isVKMiniApp;

        this.setPermissions();

        if (isVKMiniApp) {
            this.getVkGeolocation();
        } else {
            navigator.geolocation.watchPosition(position => this.onSuccess.call(this, position), error => this.onError.call(this, error));
        }
    }

    get hasPermissions() {
        return store.getters.isGeopositionAllowed;
    }

    set hasPermissions(access) {
        store.commit('setIsGeopositionAllowed', Boolean(access));
    }

    /**
	 * @returns { [ number, number ] } [ lat, lng ]
	 */
    get currentGeolocationArray() {
        return [this.currentGeolocation.lat, this.currentGeolocation.lng];
    }

    onSuccess(location) {
        this.geolocationInit = true;
        return Promise.resolve(this.getGeolocation(location));
    }

    getVkGeolocation() {
        return bridge.send('VKWebAppGetGeodata')
            .then(data => {
                if (data.available) {
                    const location = {
                        coords: {
                            latitude: data.lat,
                            longitude: data.long,
                        },
                    };

                    this.onSuccess.call(this, location);
                    this.setGeopositionAccess(true);
                } else {
                    this.setGeopositionAccess(false);
                }
            })
            .catch(error => {
                this.onError.call(this, error);
				
                this.geolocationIsDefault = true;
            });
    }

    setGeopositionAccess(value) {
        bridge.send('VKWebAppStorageSet', {
            key: 'geolocation_access',
            value: `${value}`,
        })
            .then(data => { 
                if (data.result) {
                    // Значение переменной задано
                }
            })
            .catch(error => {
                // Ошибка
                console.log(error);
            });
    }

    async onError() {
        return this.setPermissions(false);
    }

    getCurrentPosition(onSuccess = () => {}, onError = () => {}) {
        return new Promise((resolve, reject) => {
            try {
                if (this.isVKMiniApp) {
                    this.getVkGeolocation();
                } else {
                    navigator.geolocation.getCurrentPosition(async location => {
                        try {
                            this.geolocationInit = true;
                            const position = await this.onSuccess(location);
                            resolve(onSuccess(position));
                        } catch (error) {
                            reject(error);
                        }
                    }, error => {
                        if (error.code === 3) { // If time out returns default location
                            this.geolocationInit = true;
								
                            resolve(onSuccess(this.currentGeolocation));
                        } else {
                            resolve(onError(this.currentGeolocation));
                        }
                    }, {
                        timeout: 5000,
                    });
                }
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    getGeolocation(location) {
        return new Promise(async (resolve, reject) => {
            try {
                if (location) {
                    if (location.coords.latitude !== this.currentGeolocation.lat || location.coords.longitude !== this.currentGeolocation.lng) {
                        VueMain.$root.$emit('changed:geolocation', this.currentGeolocationArray);
                    }
                    this.currentGeolocation = {
                        lat: location.coords.latitude,
                        lng: location.coords.longitude,
                    };
                }

                if (this.mapRef) {
                    this.mapRef.setDefaults(this.currentGeolocation);
                }
                resolve(this.currentGeolocation);
                this.hasPermissions = true;
            } catch (error) {
                console.error(error);
                this.hasPermissions = false;
                reject(error);
            }
        });
    }

    async setPermissions(access) {
        if (access === undefined) {
            if (navigator.permissions && navigator.permissions.query) {
                const permissions = await navigator.permissions.query({ name: 'geolocation' });
                this.hasPermissions = permissions.state !== 'denied';
            } else {
                this.hasPermissions = true;
            }
        } else {
            this.hasPermissions = access;
        }
    }
}
