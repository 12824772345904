<template>
    <svg class="dropdown-icon" :class="{'_active': active}" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4998 12.4001C17.4998 12.2465 17.4235 12.0929 17.2719 11.9759L11.0496 7.17571C10.7455 6.9411 10.254 6.9411 9.94984 7.17571L3.7276 11.9759C3.42348 12.2105 3.42348 12.5897 3.7276 12.8243C4.03171 13.0589 4.52327 13.0589 4.82738 12.8243L10.4997 8.44835L16.1721 12.8243C16.4762 13.0589 16.9678 13.0589 17.2719 12.8243C17.4235 12.7073 17.4998 12.5537 17.4998 12.4001Z" fill="#475569"/>
    </svg>        
</template>

<script>
    export default {
        name: 'DropdownIcon',

        props: {
            active: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .dropdown-icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        color: #475569;
        transform: rotate(180deg);
        transition: all .3s ease;

        &._active {
            transform: rotate(0);
        }
    }
</style>