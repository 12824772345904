<template>
	<router-link
		@click.stop
		:to="to"
		v-if="shouldRedirect">
		<slot></slot>
	</router-link>
	<div v-else>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		to: {
			required: false,
			type: [ Object, String ]
		},
		shouldRedirect: {
			required: false,
			type: Boolean,
			default: false
		}
	}
}
</script>