import {createId} from "@/utils/helpers/common";

const observers = {

}

function getObserverId (el) {
	return el.getAttribute('data-observe') || `list_${createId()}`
}

function getObserver (el) {
	const id = getObserverId(el)
	if (!observers[id]) {
		observers[id] = {
			attempts: 0,
			instance: null
		}
	}
	return observers[id]
}

function removeObserver (el) {
	const id = getObserverId(el)
	if (observers[id] && observers[id] instanceof IntersectionObserver) {
		observers[id].instance.disconnect()
		delete observers[id]
	}
}

/**
 * @type {IntersectionObserver}
 */
/**
 * @param { HTMLElement } el
 * @param binding
 */
function observe (el, binding) {
	removeObserver(el)

	const observer = getObserver(el)
	const { onIntersected, active } = binding.value
	if (active) {
		observer.attempts = 0
		const lastElement = el.lastElementChild
		if (lastElement) {
			const options = {
				root: null,
				threshold: 1.0
			}
			const intersectionCallback = (entries, observer) => {
				if (entries[0].isIntersecting) {
					onIntersected()
					observer.unobserve(lastElement)
				}
			}

			observer.instance = new IntersectionObserver(intersectionCallback, options)
			observer.instance.observe(lastElement)
		}
	}
}

function unbind (el) {
	removeObserver(el)
}

export const observeLast = {
	bind: observe,
	componentUpdated: observe,
	unbind
}

/**
 * Лучше устанавливать директиву на html-элементы, а не на кастомные теги и vue-компоненты
 */