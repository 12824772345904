const defaultValue = {
	height: 240
}

function resetStyle (el) {
	const parent = el.parentElement

	if (parent) {
		parent.style.height = 'auto'
	}
	el.style.position = 'static'
	el.style.width = 'auto'
}

function setElementStyle (el, binding) {
	if (!el) {
		return
	}
	const parent = el.parentElement
	if (parent) {
		parent.style.height = (binding.value.height || defaultValue.height) + 'px'
		el.style.width = parent.clientWidth + 'px'
	}

	if (binding.value.active) {
		const height = (binding.value.height || defaultValue.height)
		el.style.position = 'fixed'
		el.style.height = height + 'px'
		el.style.minHeight = height + 'px'
		Array.from(el.children).forEach(child => {
			child.style.height = height + 'px'
		})
	} else {
		resetStyle(el)
	}
}

/**
 * @param { HTMLElement } el
 * @param binding
 */
function inserted (el, binding) {
	window._fixedInit = () => setElementStyle(el, binding)
	document.addEventListener('resize', window._fixedInit)
	setElementStyle(el, binding)
}

function componentUpdated (el, binding) {
	setElementStyle(el, binding)
}

function unbind (el) {
	resetStyle(el)

	document.removeEventListener('resize', window._fixedInit)
	delete window._fixedInit
}

export const fixed = {
	bind: inserted,
	inserted,
	unbind,
	update: componentUpdated,
	componentUpdated: componentUpdated
}