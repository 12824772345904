<template>
  <transition name="modal-authorization">
    <div
      class="modal-mask"
      id="vkLoginModal"
      :style="`background-color: rgba( 0, 0, 0, 0.8)`"
    >
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="person-header">
              BenefittY позволяет хранить свои карты скидок и иметь онлайн
              доступ к картам друзей
            </div>
          </div>
          <div class="modal-body p-0">
            Если Вы ранее пользовались мобильным приложением BenefittY, мы сразу
            покажем все Ваши карты.
            <br />
            <br />
            Если Вы впервые используете BenefittY, приложению понадобятся Ваш
            адрес электронной почты и телефон. Эти данные необходимы для Вашего
            доступа в BenefittY с любого устройства.
            <form @submit.prevent="" novalidate>
              <div class="login-block mb-3">
                <button
                  @click="validateBeforeSubmit"
                  class="btn btn-block btn-rounded vk-login-modal__submit"
                >
                  <span>Войти</span>
                </button>
              </div>
              <div class="col-12">
                <span
                  class="text-danger small"
                  v-show="isRulesAcceptedErrorText"
                  >Необходимо подтвердить согласие с условиями</span
                >
              </div>
              <div class="col-12">
                <div class="custom-control custom-checkbox">
                  <input
                    v-model="isRulesAccepted"
                    id="isRulesAccepted"
                    v-validate="'required'"
                    class="custom-control-input"
                    name="rulesAcception"
                    type="checkbox"
                  />

                  <label
                    for="isRulesAccepted"
                    class="custom-control-label rules-label"
                    :class="{ 'has-error': errors.has('rulesAcception') }"
                  >
                    Я ознакомлен с
                    <router-link :to="'/docs/terms-of-use'" target="_blank"
                      >Пользовательским соглашением</router-link
                    >
                    и
                    <router-link :to="'/docs/privacy-policy'" target="_blank"
                      >Политикой конфиденциальности</router-link
                    >
                    и принимаю их условия
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
	import lazyImages from "@/utils/lazyImages.js"

	export default {
  data() {
    return {
      isRulesAccepted: false,
      isRulesAcceptedErrorText: false,
      lazyImages,
    }
  },
  methods: {
    submit() {
      let callback = this.$store.getters.vkLoginModalSettings.callback
      if (callback) callback(this.$store.getters.vkLoginModalSettings.data)
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (this.isRulesAccepted) {
          this.isRulesAcceptedErrorText = false
        } else {
          this.isRulesAcceptedErrorText = true
        }

        if (result) {
          this.submit()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-authorization-enter {
  opacity: 0;
}

.modal-authorization-leave-active {
  opacity: 0;
}

.modal-authorization-enter .modal-container,
.modal-authorization-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-mask {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  text-align: center;
  overflow: auto;
  transition: opacity 0.2s ease-in;
}

.modal-wrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.modal-container {
  width: 432px;
  margin: 0px auto;
  padding: 20px 10px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.2s linear;
  @media (max-width: 432px) {
    width: 100%;
  }
}

.modal-header {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  justify-content: center;
  border-bottom: none;
  font-family: "SFCompactDisplay", "Roboto" !important;
  font-weight: 600;
  letter-spacing: 0.07px;
}
.modal-header > .person-header {
  padding-top: 2px;
  padding-bottom: 25px;
  font-size: 24.7px;
}

.modal-header > .organization-header {
  padding-bottom: 25px;
  padding-top: 2px;
  padding-left: 16.2px;
  font-size: 16px;
}

.modal-header > .organization-header > .small {
  font-size: 10px;
}
.modal-header > .remind-password-header {
  font-size: 24.7px;
}
.modal-header > .approve-password-header {
  font-size: 24.7px;
}
.modal-header > .new-password-header {
  font-size: 24.7px;
}
.modal-header .text-info {
  font-size: 10px;
  line-height: 1 !important;
}

.rules-label {
  font-size: 12px;
  font-weight: normal;
}

.login-social-text {
  padding-left: 10px;
  letter-spacing: 0.5px;
  font-family: "SFCompactDisplay", "Roboto" !important;
  font-size: 24px;
}

.close {
  opacity: 1 !important;
  padding-left: -5px !important;
  padding-bottom: -5px !important;
  float: right;
}

.modal-footer {
  padding-top: 10px;
  text-align: center;
  border-top: none;
}

.input-group-text {
  background-color: white !important;
}

.right-border-rounded {
  border-bottom-right-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.left-border-rounded {
  border-bottom-left-radius: 7px !important;
  border-top-left-radius: 7px !important;
}

.left-border-none {
  border-left: none;
}

.btn-remind-password {
  color: #b1b1b1;
  font-size: 12.7px;
  margin-top: 0px;
}
.form-control {
  height: 31px !important;
  padding-left: 3px !important;
}
.form-control::placeholder {
  font-size: 15px;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.input-group-prepend {
  height: 31px !important;
}
.login-block {
  display: block;
  margin-top: 20px;
}
.lack-of-account {
  margin-top: 26px;
  font-size: 12px;
  letter-spacing: 0.28px;
}
.lack-of-account .question {
  color: #b1b1b1;
}

.custom-checkbox .custom-control-label.has-error::before {
  border: 2px solid #ff3860 !important;
}

 .vk-login-modal {
   &__submit {
     background-color:rgb(81, 129, 184);
     color: white;
   }
 }
</style>