import store from '../../store/index.js';
import consts from '../consts.js';
import ProviderHelper from './providerHelper.js';
import Vue from 'vue';
import Api from '@/utils/providers/provider';
const api = new Api();

const providerHelper = new ProviderHelper();
export default {
    createUserComplain({ userId, message }) {
        return api.post(`users/${userId}/complaints`, { message });
    },
    setSBPbankSettings(context, body) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/sbp/companies`, body)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    closeAllPlaces(context, companyId, body) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/companies/${companyId}/places/close-all`, body)
            .then(data => resolve(data))
            .catch(err => reject(err)));
    },
    openAllPlaces(context, companyId, body) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/companies/${companyId}/places/open-all`, body)
            .then(data => resolve(data))
            .catch(err => reject(err)));
    },
    getSBPbankSettings(context, company_id) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/sbp/companies/${company_id}`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getSBPbanks(context) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/sbp/banks`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getDiscountCount(context, params) {
        return new Promise((resolve, reject) => context.$http.get('discounts/count', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPointExchangeCount(context, params) {
        return new Promise((resolve, reject) => context.$http.get('bonus-exchange/count', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCurrentPromocodes(context, id) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/loyality-programs/${id}/promo-codes`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    generatePromocodesForLoyaltyProgram(context, body) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/loyality-programs/promocode`, body)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    giveGrantToCompany(context, companyId, body) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/companies/${companyId}/apply-instant-grant`, body)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getGrants(context, params) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/grants`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getLastGrantOfCompany(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${companyId}/get-confirmed-grants-sum`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    searchCompanies(context, params) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/search`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getAllPlaces(context, options = {}) {
        let params = {
            bank_card_id: options.bankCardId,
            limit: options.limit || 10,
            offset: options.offset,
            search: options.search,
            bank_partners: options.bankPartners,
            bank_id: options.bankId,
            city_id: options.cityId,
            friends_recommended: options.friendsRecommended,
            checkined: options.isCheckined,
            new: options.isNew,
            bookmarked: options.bookmarked,
            work_now: options.workNow,
            loyality_program_discount: options.loyalityProgramDiscount,
            bank_discount: options.bankDiscount,
            payment_system_discount: options.paymentSystemDiscount,
            discount: options.isDiscount,
            accrues_cashback_by_link: options.accruesCashbackByLink,
            accrues_cashback: options.accruesCashback,
            accrues_rewards: options.accruesRewards,
            sort_by: options.sortBy,
            tag: options.tag,
            properties: options.properties,
            category_id: options.category_id,
            scope: options.scope,
            price_level: options.priceLevel,
            min_rating: options.minRating,
            payment_system: options.paymentSystem,
            bank: options.bank,
            distance: options.distance,
            banks_ids_discount: options.bankIdsDiscount,
            company_id: options.companyId,
            online: options.online,
        };
        console.log(options.position);
        if (Array.isArray(options.position)) {
            params.lat = options.position[0].toFixed(2);
            params.lng = options.position[1].toFixed(2);
        }
        if (options.paginate) {
            params.paginate = options.paginate;
            params.page = options.page;
        } else {
            params.offset = options.offset || 0;
        }
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get('places', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlacesOfDiscount(context, discountId, options = {}) {
        const params = {
            latitude: options.latitude || 55.756030,
            longitude: options.longitude || 37.614403,
            limit: options.limit || 10,
            offset: options.offset || 0,
        };
        return new Promise((resolve, reject) => context.$http.get(`discounts/${discountId}/places`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlacesOfBrand(context, brandId, options = {}) {
        const params = {
            lat: options.lat || 55.756030,
            lng: options.lng || 37.614403,
            limit: options.limit || 10,
            offset: options.offset || 0,
        };

        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/brands/${brandId}/places`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getAllPlacesForAdmin(context, options = {}) {
        options.search = options.search || '';
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        options.bankPartners = options.bankPartners || 0;
        options.bankId = options.bankId || '';
        options.city_id = options.city_id || '';
        options.position = options.position || [55.756030, 37.614403];

        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/places-admin', {
            params: {
                search: options.search,
                limit: options.limit,
                offset: options.offset,
                bank_partners: options.bankPartners,
                bank_id: options.bankId,
                city_id: options.city_id,
                lat: options.position[0] != null ? options.position[0] : '',
                lng: options.position[1] != null ? options.position[1] : '',
                friends_recommended: options.friendsRecommended,
                checkined: options.isCheckined,
                new: options.isNew,
                bookmarked: options.isBookmarked,
                work_now: options.workNow,
                loyality_program_discount: options.isLoyalityProgramDiscount,
                bank_discount: options.isBankDiscount,
                discount: options.isDiscount,
                accrues_cashback_by_link: options.accruesCashbackByLink,
                accrues_cashback: options.accruesCashback,
                accrues_rewards: options.accruesRewards,
                sort_by: options.sortBy,
            },
            before(request) {
                if (this.previousRequest) {
                    this.previousRequest.abort();
                }

                this.previousRequest = request;
            },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlacesCompanies(context, options = {}) {
        options.query = options.query || '';
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        options.position = options.position || [55.5807481, 36.8251304];
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/search/places-companies', {
            before(request) {
                if (this.previousRequest) {
                    this.previousRequest.abort();
                }

                this.previousRequest = request;
            },
            params: {
                lat: options.position[0] != null ? options.position[0] : '',
                lng: options.position[1] != null ? options.position[1] : '',
                query: options.query,
                limit: options.limit,
                offset: options.offset,
            },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getOrganizationPlaces(context, options = {}) {
        const currentCompanyId = options.companyId ? options.companyId : store.getters.currentCompanyId;
        let params = {
            search: options.search,
            address: options.address,
            limit: options.limit || 100,
            category_id: options.category_id,
            page: options.page,
            sort: options.sort || 'address-asc',
            lat: options.lat,
            lng: options.lng,
            distance: options.distance,
        };
        if (options.paginate) {
            params.paginate = options.paginate;
            params.page = options.page || 1;
        } else {
            params.offset = options.offset;
        }
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${currentCompanyId}/places`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getOrganizationBrands(context, options = {}) {
        const currentCompanyId = options.companyId ? options.companyId : store.getters.currentCompanyId;
        let params = {
            search: options.search,
            limit: options.limit || 100,
            sort_direction: options.desc ? 'desk' : 'asc',
            sort_by: options.sortBy,
            offset: options.offset,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${currentCompanyId}/brands`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getOrganizationPlacesCount(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${companyId}/places/count`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getAdministratorPlaces(context, options = {}) {
        options.search = options.search || '';
        options.limit = options.limit || 100;
        options.offset = options.offset || 0;
        options.category_id = options.category_id || '';
        options.paginate = options.paginate || '';
        options.page = options.page || '';
        options.sort = options.sort || '';
        options = providerHelper.getObjectWithFields(options);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/administrators/current/places', {
            before(request) {
                if (this.previousRequest) {
                    this.previousRequest.abort();
                }

                this.previousRequest = request;
            },
            params: options,
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    replicatePlace(context, id) {
        return context.$http.post(consts.appUri + '/places/' + id + '/replicate', {})
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            });
    },
    deletePlace(context, id) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/places/' + id, {})
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    echo(context, place) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/echo', JSON.stringify({
            general: {
                name: place.name,
                latin_name: place.latinName,
                description: place.description,
                latitude: place.latitude,
                longitude: place.longitude,
            },
            address: place.address,
            category: place.category,
            company: place.company,
            phones: place.phones,
            websites: place.websites,
            emails: place.emails,
            social_accounts: place.socialAccounts,
            additional_info: place.additionalInfo,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createPlace(context, options) {
        const formData = new FormData();
        const { image, owner, latitude, longitude, address, segment } = options.place;
        const coordinate = [latitude, longitude];

        if (image) {
            formData.append('image', image);
        }

        const contents = {
            company_id: options.companyId || null,
            owner: owner,
            name: options.place.name,
            latin_name: options.place.latinName,
            description: options.place.description,
            location: latitude && longitude ? coordinate : address?.coordinate,
            price_level: options.place.priceLevel,
            google_id: options.place.googleId,
            double_gis_id: options.place.doubleGisId,
            trip_adviser_id: options.place.tripAdviserId,
            foursquare_id: options.place.foursquareId,
            yelp_id: options.place.yelpId,
            yandex_maps_url: options.place.yandexId,
            business_segment_id: segment || '',
            address: options.place.address
                ? {
                    post_index: options.place.address.postIndex,
                    street: options.place.address.street,
                    house: options.place.address.house,
                    additional_info: options.place.address.additionalInfo,
                }
                : null,
            categories: options.categories,
            phones: options.phones,
            websites: options.websites,
            emails: options.emails,
            social_accounts: options.socialAccounts,
            properties: options.properties || [],
            working_hours: options.workingTime,
            avg_check: {
                from: options.place.avgCheck.from,
                to: options.place.avgCheck.to,
            },
            tags: options.place.tags.split(',').filter(tag => tag.trim())
                .map(tag => tag.trim()),
            user: options.user
                ? {
                    first_name: options.user.name,
                    last_name: options.user.lastName,
                    email: options.user.workEmail,
                    phone: options.user.workPhone,
                    position: options.user.position,
                    role: options.user.role,
                }
                : null,
            offer_accepted: options.offerAccepted,
        };

        if (!options.companyId) {
            delete contents.company_id;
        }

        if (!options.user) {
            delete contents.user;
        }

        if (options.place.isAddressDoesNotExist) {
            delete contents.address;
            delete contents.location;
        }

        formData.append('contents', JSON.stringify(contents));
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updatePlace(context, options) {
        const formData = new FormData();
        if (options.image) {
            formData.append('image', options.image);
        }

        const content = {
            name: options.place.name,
            latin_name: options.place.latinName,
            description: options.place.description || null,
            location: options.place.isAddressDoesNotExist ? [] : [options.place.latitude, options.place.longitude],
            price_level: options.place.priceLevel,
            google_id: options.place.googleId || null,
            double_gis_id: options.place.doubleGisId || null,
            trip_adviser_id: options.place.tripAdviserId || null,
            foursquare_id: options.place.foursquareId || null,
            yelp_id: options.place.yelpId || null,
            yandex_maps_url: options.place.yandexId || null,
            address: options.place.isAddressDoesNotExist
                ? null
                : {
                    city: options.place.address.city.name,
                    country: options.place.address.country.name,
                    post_index: options.place.address.postIndex,
                    street: options.place.address.street,
                    house: options.place.address.house,
                    additional_info: options.place.address.additionalInfo || null,
                    region: options.place.address.region
                        ? {
                            name: options.place.address.region.name || null,
                        }
                        : null,
                    raw: options.addressRaw,
                    suggestion: options.addressSuggestion,
                },
            categories: options.categories,
            business_segment_id: options.segment,
            phones: options.phones,
            websites: options.websites,
            emails: options.emails,
            social_accounts: options.socialAccounts,
            working_hours: options.workingTime,
            avg_check: {
                from: options.place.avgCheck.from,
                to: options.place.avgCheck.to,
            },
            tags: options.place.tags.split(','),
        };

        // if (options.place.isAddressDoesNotExist) {
        // 	content.address = null;
        // 	delete content.location;
        // }

        if (options.properties) {
            content.properties = options.properties;
        }

        if (options.image) {
            formData.append('image', options.image);
        }

        formData.append('_method', 'Put');
        formData.append('contents', JSON.stringify(content));
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + options.place.id, formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    openPlace(context, id) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + id + '/open')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    closePlace(context, id) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + id + '/close')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    setPlaceMain(context, id) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + id + '/set-main')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    sendPushNowNotificationPlace(context, pushNotice) {
        const placeId = context.id;
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + placeId + '/push-now', JSON.stringify({
            message: pushNotice.message,
            user_type_id: pushNotice.userGroupId,
            region_id: pushNotice.selectRegion,
            gender_id: pushNotice.gender,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    sendPushNearNotificationPlace(context, pushNotice) {
        const dateStart = pushNotice.dateStart.format('YYYY-MM-DD');
        const dateEnd = pushNotice.dateEnd.format('YYYY-MM-DD');
        const placeId = context.id;
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + placeId + '/push-near', JSON.stringify({
            message: pushNotice.message,
            date_start: dateStart,
            date_end: dateEnd,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    sendPushNowNotificationCommon(context, companyId, pushNotice) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/push-now', JSON.stringify({
            message: pushNotice.message,
            user_type_id: pushNotice.userGroupId,
            region_id: pushNotice.selectRegion,
            gender_id: pushNotice.gender,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    sendNearPushNotificationCommon(context, companyId, pushNotice) {
        const dateStart = pushNotice.dateStart.format('YYYY-MM-DD');
        const dateEnd = pushNotice.dateEnd.format('YYYY-MM-DD');
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/push-near', JSON.stringify({
            message: pushNotice.message,
            date_start: dateStart,
            date_end: dateEnd,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    changeNearPushNotification(context, pushNotice) {
        const dateStart = pushNotice.dateStart.format('YYYY-MM-DD');
        const dateEnd = pushNotice.dateEnd.format('YYYY-MM-DD');
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/push/' + pushNotice.id, JSON.stringify({
            message: pushNotice.message,
            date_start: dateStart,
            date_end: dateEnd,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    enablePushNearNotification(context, pushNoticeId) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/push/' + pushNoticeId + '/enable')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    disableCommonNearPushNotification(context, pushNoticeId) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/push/' + pushNoticeId + '/disable')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCommonPushNearEnabled(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/push-near-enabled/')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlacePushNearEnabled(context, placeId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/places/' + placeId + '/push-near-enabled/')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCommonPushNowNotifications(context, companyId, options = {}) {
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/push-now' +
				'?limit=' + options.limit +
				'&offset=' + options.offset)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCommonPushNearNotifications(context, companyId, options = {}) {
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/push-near' +
				'?limit=' + options.limit +
				'&offset=' + options.offset)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlacePushNowNotifications(context, options = {}) {
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/places/' + options.placeId + '/push-now' +
				'?limit=' + options.limit +
				'&offset=' + options.offset)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlacePushNearNotifications(context, options = {}) {
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/places/' + options.placeId + '/push-near' +
				'?limit=' + options.limit +
				'&offset=' + options.offset)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyOwner(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/owner', {})
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getUserRepresentative(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/users/current', {})
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateUserRepresentative(context, companyId, user) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/users/current', {
            first_name: user.first_name,
            last_name: user.lastName,
            email: user.email,
            phone: user.workPhone,
            position: user.position,
            role: user.role,
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateCompanyName(context, companyId, company) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/companies/' + companyId, JSON.stringify({
            name: company.name,
            latin_name: company.latinName,
            about: company.about,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateCompanyInfo(context, companyId, company) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/companies/' + companyId, JSON.stringify({
            name: company.name,
            latin_name: company.latinName,
            about: company.about,
            house: company.address.house,
            post_index: company.address.postalCode,
            street: company.address.street,
            city_id: company.address.city.id,
            country_id: company.address.countryId,
            website: company.webSite ? company.webSite : '',
            phone: company.phone,
            license_info: company.license_info ? company.license_info : '',
            license_date: company.license_date ? company.license_date : '',
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteCompanyUser(context, companyId, reason) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/companies/' + companyId + '/users/current', {
            body: {
                reason: reason,
            },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadCompanyLogo(context, companyId, companyLogo) {
        const formData = new FormData();
        formData.append('image', companyLogo);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/images/profile', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    sendOfficialResponse(context, reviewId, message) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/reviews/' + reviewId + '/official-responses', JSON.stringify({
            message: message,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    changeOfficialResponse(context, officialResponseId, message) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/official-responses/' + officialResponseId, JSON.stringify({
            message: message,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteOfficialResponse(context, officialResponseId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/official-responses/' + officialResponseId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    /**
	 * @param { Vue } context
	 * @param { OptionsGetDiscounts } options
	 * @returns {Promise<Stock[]>}
	 */
    getDiscounts(context, options) {
        /**
		 * @type QueryGetDiscounts
		 */
        let params = {
            limit: options.limit || 10,
            category: options.categoryId,
            source_id: options.sourceId,
            company_id: options.companyId,
            brand_id: options.brandId,
            place_id: options.placeId,
            type_id: options.typeId,
            time: options.time,
            is_bookmarked: options.isBookmarked,
            work_now: options.workNow,
            only_new: options.onlyNew,
            only_friends: options.onlyFriends,
            with_checkins: options.withCheckins,
            search: options.search,
            sort_by: options.sortBy,
            sort_direction: options.sortDirection,
            discount_card_id: options.discountCardId,
            pagination: options.paginate,
            page: options.page,
            all_place: options.allPlace,
            newsfeed_event: options.newsfeedEvent,
            city: options.city,
        };
        if (options.paginate) {
            params.paginate = options.paginate;
            params.page = options.page;
        } else {
            params.offset = options.offset || 0;
        }
        if (options.position) {
            params.lat = options.position[0];
            params.lng = options.position[1];
        }
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get('discounts', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    /**
	 *
	 * @param { Vue } context
	 * @param params
	 * @returns {Promise<Place[]>}
	 */
    getDiscountPlaces(context, params) {
        return new Promise((resolve, reject) => {
            try {
                context.$http.get('discounts/places', { params })
                    .then(data => resolve(data.body), reject)
                    .catch(reject);
            } catch (error) {
                reject(error);
            }
        });
    },
    getDiscountCategories(context) {
        return new Promise((resolve, reject) => context.$http.get('place-categories/tree')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPopularBanks(context) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/popular/bank-items`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    finishCompanyStock(context, stockId) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/stocks/' + stockId + '/finish')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getSingleDiscount(context, discountId) {
        return new Promise((resolve, reject) => context.$http.get('discounts/' + discountId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createCompanyStock(context, options) {
        const dateStart = options.isPeriodActive ? options.stock.date_start.format('YYYY-MM-DD') : null;
        const dateEnd = options.isPeriodActive ? options.stock.date_end.format('YYYY-MM-DD') : null;
        const discount = {
            name: options.stock.name,
            description: options.stock.description,
            date_start: dateStart,
            date_end: dateEnd,
            small_text: options.stock.small_text,
            max_discount: options.stock.max_discount >= 1 ? Number(options.stock.max_discount) / 100 : Number(options.stock.max_discount),
            all_place: options.stock.all_place || false,
            places: options.placesId,
        };
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + options.companyId + '/stocks', JSON.stringify(discount))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createCompanyPersonalStock(context, body) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/personal-discount-campaign', JSON.stringify(body))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    addCodesCompanyPersonalStock(context, campaign, codes) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + `/personal-discount-campaign/${campaign}/codes`, JSON.stringify(codes))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    editCompanyPersonalStock(context, body, personal_discount_campaign_id) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/personal-discount-campaign/' + personal_discount_campaign_id, JSON.stringify(body))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadImageCompanyPersonalStock(context, campaign, image) {
        const formData = new FormData();
        formData.append('image', image);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + `/personal-discount-campaign/${campaign}/image`, formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    editCompanyStock(context, options) {
        const dateStart = options.isPeriodActive ? options.stock.date_start.format('YYYY-MM-DD') : null;
        const dateEnd = options.isPeriodActive ? options.stock.date_end.format('YYYY-MM-DD') : null;
        const discount = {
            name: options.stock.name,
            description: options.stock.description,
            date_start: dateStart,
            date_end: dateEnd,
            small_text: options.stock.small_text,
            max_discount: options.stock.max_discount >= 1 ? Number(options.stock.max_discount) / 100 : Number(options.stock.max_discount),
            all_place: options.stock.all_place || false,
            places: options.placesId,
        };
        if (!options.placesId) {
            delete discount.places;
        }
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/stocks/' + options.stock.id, JSON.stringify(discount))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadStockImage(context, stockImage, stockId) {
        const formData = new FormData();
        formData.append('image', stockImage);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/stocks/set-image/' + stockId, formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    attachImageToDiscount(context, options = {}) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/images/' + options.imageId + '/discounts/' + options.discountId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteDiscount(context, discountId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/discounts/' + discountId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteStock(context, stockId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/stocks/' + stockId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceCoupons(context, placeId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/places/' + placeId + '/coupons')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createCompanyCoupon(context, companyId, coupon, placesId) {
        const dateStartYear = coupon.dateStart.get('year');
        const dateStartMonth = coupon.dateStart.get('month') + 1;
        const dateStartDate = coupon.dateStart.get('date');
        const dateStart = dateStartYear + '-' + dateStartMonth + '-' + dateStartDate;
        const dateEndYear = coupon.dateStart.get('year');
        const dateEndMonth = coupon.dateStart.get('month') + 1;
        const dateEndDate = coupon.dateStart.get('date');
        const dateEnd = dateEndYear + '-' + dateEndMonth + '-' + dateEndDate;
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/coupons', JSON.stringify({
            name: coupon.name,
            description: coupon.description,
            date_start: dateStart,
            date_end: dateEnd,
            all_place: coupon.allPlaces,
            places: placesId,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadCouponImage(context, couponImage, couponId) {
        const formData = new FormData();
        formData.append('image', couponImage);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/coupons/set-image/' + couponId, formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteCoupon(context, couponId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/coupons/' + couponId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getEvents(context, options = {}) {
        return new Promise((resolve, reject) => {
            let params = {
                past: options.past || null,
                active: options.active || null,
                limit: options.limit || 10,
                offset: options.offset || 0,
                brand_id: options.brand_id || null,
            };
            params = providerHelper.getObjectWithFields(params);

            return context.$http.get(consts.appUri + '/events', { params })
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    getSingleEvent(context, eventId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/events/' + eventId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createEvent(context, options = {}) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/events', JSON.stringify({
            age_limit: options.age_limit || 0,
            name: options.name || '',
            description: options.description || '',
            date_start: options.date_start || '',
            date_end: options.date_end || '',
            brand_id: options.brand_id,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadEventImage(context, eventImage, eventId) {
        const formData = new FormData();
        formData.append('file', eventImage);
        formData.append('profile_image', true);
        formData.append('event_id', eventId);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/images', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadStockMultiImage(context, image, id) {
        const formData = new FormData();
        formData.append('file', image);
        formData.append('discount_id', id);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/images', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadStockMultiVideo(context, video, id) {
        const formData = new FormData();
        formData.append('file', video);
        formData.append('discount_id', id);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/videos', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadBrandCashbackMultiImage(context, image, id) {
        const formData = new FormData();
        formData.append('file', image);
        formData.append('cashback_campaign_id', id);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/images', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadBrandCashbackMultiVideo(context, video, id) {
        const formData = new FormData();
        formData.append('file', video);
        formData.append('cashback_campaign_id', id);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/videos', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    terminateCompanyEvent(context, eventId) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/events/${eventId}/terminate`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    duplicateCompanyEvent(context, eventId) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/events/duplicate/' + eventId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyGiftSertificate(context, placeId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/places/' + placeId + '/gift-certificates')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createCompanyGiftSertificate(context, companyId, giftSertificate, placesId) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/gift-certificates', JSON.stringify({
            name: giftSertificate.name,
            description: giftSertificate.description,
            price: giftSertificate.price,
            all_place: giftSertificate.allPlaces,
            places: placesId,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadGiftSertificateImage(context, giftSertificateImage, giftSertificateId) {
        const formData = new FormData();
        formData.append('image', giftSertificateImage);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/gift-certificates/set-image/' + giftSertificateId, formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteGiftSertificate(context, giftSertificateId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/gift-certificates/' + giftSertificateId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    replicateGiftSertificate(context, giftSertificateId) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/gift-certificates/' + giftSertificateId + '/replicate')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteCompanyEvent(context, eventId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/events/' + eventId, {})
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    duplicateCompanyEvents(context, eventId) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/events/duplicate/' + eventId, {})
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceCashbackSettings(context, placeId) {
        return new Promise((resolve, reject) => context.$http.get(`companies/${placeId}/cashback-settings`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getAvailableBankCards(context, { placeId }) {
        return new Promise((resolve, reject) => context.$http.get(`places/${placeId}/available-bank-cards`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceAvailableBankCards(context, placeId) {
        return new Promise((resolve, reject) => context.$http.get(`places/${placeId}/available-bank-cards`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceUserInformation(context, placeId) {
        return new Promise((resolve, reject) => context.$http.get(`/places/${placeId}/user-information`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceInfo(context, placeId, options = {}) {
        let params = {
            presenter: options.presenter,
            scope: options.scope,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(`places/${placeId}`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceShortInfo(context, placeId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/places/${placeId}/short`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getReviews(context, options = {}) {
        let query = `?limit=${options.limit || 10}&offset=${options.offset || 0}`;
        if (options.onlyFriends) {
            query += `&only_friends=${options.onlyFriends}`;
        }
        if (options.orderBy) {
            query += `&order_by=${options.orderBy}`;
        }
        if (options.reviewableType) {
            query += `&reviewable_type=${options.reviewableType}`;
        }
        if (options.reviewableId) {
            query += `&reviewable_id=${options.reviewableId}`;
        }
        if (options.userId) {
            query += `&user_id=${options.userId}`;
        }
        if (options.query) {
            query += `&query=${options.query}`;
        }
        if (options.rating) {
            query += `&rating=${options.rating}`;
        }
        if (options.companyId) {
            query += `&company_id=${options.companyId}`;
        }
        return new Promise((resolve, reject) => context.$http.get(`reviews${query}`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyVisitorsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy,
            unique: options.unique || false,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => {
            context.$http.get(consts.appUri + '/statistics/visitors', { params })
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    getCheckinsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => {
            context.$http.get(consts.appUri + '/statistics/checkins', { params })
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    getTopVisitorsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
            offset: options.offset || 0,
            limit: options.limit || 5,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/topVisitors', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getDevicesStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/devices', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getAgeGenderVisitorsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => {
            context.$http.get(consts.appUri + '/statistics/visitorsAgeGroups', { params })
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    getGenderVisitorsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => {
            context.$http.get(consts.appUri + '/statistics/visitorsGender', { params })
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    getReviewsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/reviews', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPushNowStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            place_id: options.placeId || null,
            company_id: options.companyId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/pushNow', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPushNearStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/pushNear', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPushVisitorsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/pushVisitors', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceLoyalityCardsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/discountCardsCreation', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceAuditoryStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/dependsVisitors', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getRewardsCountStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/rewardsUsers', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getRewardsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
            reason_id: options.reasonId || null,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/rewards', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCashbackStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/cashback', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCashbackCountStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/cashbackUsers', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getGeographyCountriesStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => {
            context.$http.get(consts.appUri + '/statistics/countries', { params })
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    getGeographyCitiesStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistics/cities', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getGeographyRegionsStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => {
            context.$http.get(consts.appUri + '/statistics/regions', { params })
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    getCompanyRedirectSourcesStats(context, options = {}) {
        let params = {
            date_from: options.date_from,
            date_to: options.date_to,
            company_id: options.companyId || null,
            place_id: options.placeId || null,
            group: options.groupBy || 'month',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => {
            context.$http.get(consts.appUri + '/statistics/redirectSources', { params })
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    getStatsisticEventTypes(context) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/statistic/event-types')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getStatsisticReportParameters(context) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/report-parameters')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getReportParameterValue(context, options = {}) {
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        options.search = options.search || '';
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/report-parameters/' + options.reportParameterId + '/values' +
				'?limit=' + options.limit +
				'&offset=' + options.offset +
				'&search=' + options.search)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceReports(context, options = {}) {
        options.placeId = options.placeId || '';
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/places/' + options.placeId + '/reports' +
				'?limit=' + options.limit +
				'&offset=' + options.offset)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createPlaceReport(context, options = {}) {
        options.placeId = options.placeId || '';
        options.name = options.name || '';
        options.dateFrom = options.dateFrom || '';
        options.dateTo = options.dateTo || '';
        options.content = options.content || [];
        options.group = options.group || 'month';
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + options.placeId + '/reports', JSON.stringify({
            name: options.name,
            date_from: options.dateFrom,
            date_to: options.dateTo,
            group: options.group,
            content: options.content,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceReportChart(context, options = {}) {
        options.placeId = options.placeId || '';
        options.name = options.name || '';
        options.dateFrom = options.dateFrom || '';
        options.dateTo = options.dateTo || '';
        options.content = options.content || [];
        options.group = options.group || 'day';
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + options.placeId + '/reports/charts', JSON.stringify({
            name: options.name,
            date_from: options.dateFrom,
            date_to: options.dateTo,
            group: options.group,
            content: options.content,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceReportXlsx(context, options = {}) {
        options.placeId = options.placeId || '';
        options.name = options.name || '';
        options.dateFrom = options.dateFrom || '';
        options.dateTo = options.dateTo || '';
        options.content = options.content || [];
        options.group = options.group || 'day';
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + options.placeId + '/reports/xlsx', JSON.stringify({
            name: options.name,
            date_from: options.dateFrom,
            date_to: options.dateTo,
            group: options.group,
            content: options.content,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceReportById(context, reportId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/reports/' + reportId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceReportChartById(context, reportId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/reports/' + reportId + '/charts')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceReportXlsxById(context, reportId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/reports/' + reportId + '/xlsx')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deletePlaceReportById(context, reportId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/reports/' + reportId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadMainPlacePhoto(context, placeId, image) {
        const formData = new FormData();
        formData.append('image', image);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + placeId + '/images/profile', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadPlacePhoto(context, placeId, image, options) {
        let query = '';
        if (options.pushInNewsfeed) {
            query += '?push_in_newsfeed=true';
        }
        const formData = new FormData();
        formData.append('image', image);
        return new Promise((resolve, reject) => context.$http.post(`places/${placeId}/image${query}`, formData, {
            progress(e) {
                if (e.lengthComputable) {
                    if (context.calculateLoading) {
                        context.calculateLoading(e);
                    }
                }
            },
        })
            .then(data => {
                if (context.resetLoadingProgress) {
                    context.resetLoadingProgress();
                }
                resolve(data.body);
            })
            .catch(data => {
                if (context.resetLoadingProgress) {
                    context.resetLoadingProgress();
                }

                reject(data);
            }));
    },
    uploadCompanyPhoto(context, companyId, image) {
        const formData = new FormData();
        formData.append('image', image);
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/companies/${companyId}/image`, formData, {
            progress(e) {
                if (e.lengthComputable) {
                    if (context.calculateLoading) {
                        context.calculateLoading(e);
                    }
                }
            },
        })
            .then(data => {
                if (context.resetLoadingProgress) {
                    context.resetLoadingProgress();
                }
                resolve(data.body);
            })
            .catch(data => {
                if (context.resetLoadingProgress) {
                    context.resetLoadingProgress();
                }

                reject(data);
            }));
    },
    setImageProfileStatus: function(context, options = {}) {
        options.isProfileImage = options.isProfileImage || false;
        options.image = options.image || {};
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/images/' + options.image.id, { profile_image: options.isProfileImage })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceOwnerMedia(context, options = {}) {
        let params = {
            limit: options.limit || 10,
            offset: options.offset || 0,
            type: options.type || '',
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/places/${options.placeId}/media/from-owner`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceUserMedia(context, options = {}) {
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        options.type = options.type || '';
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/places/' + options.placeId + '/media/from-users', {
            params: options,
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyMedia(context, options = {}) {
        const query = {
            limit: options.limit || 10,
            offset: options.offset || 0,
            type: options.type || '',
            common: options.common,
            source: options.source,
        };
        return new Promise((resolve, reject) => context.$http.get(`companies/${options.companyId}/media`, { params: query })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyOwnerMedia(context, options = {}) {
        const query = {
            limit: options.limit || 10,
            offset: options.offset || 0,
            type: options.type || '',
        };
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${options.companyId}/media/from-owner`, { params: query })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyUserMedia(context, options = {}) {
        const query = {
            common: options.common,
            accept_types: options.acceptTypes,
            limit: options.limit || 10,
            offset: options.offset || 0,
            type: options.type || '',
        };
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${options.companyId}/media/from-users`, { params: query })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    /**
	 * @param { Vue } context
	 * @param { QueryGetPlaceMedia } options
	 * @returns {Promise<unknown>}
	 */
    getPlaceMedia(context, options = {}) {
        const params = {
            limit: 10,
            offset: 0,
            type: '',
            accept_types: '',
            from_owner: null,
            ...options,
        };
        return new Promise((resolve, reject) => context.$http.get('places/' + options.place + '/media', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deletePlacePhoto(context, placeId, imageId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/places/remove-image/' + placeId + '/' + imageId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadCompanyVideo(context, companyId, video) {
        const formData = new FormData();
        formData.append('video', video);
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/companies/${companyId}/video`, formData, {
            progress(e) {
                if (e.lengthComputable) {
                    if (context.calculateLoading) {
                        context.calculateLoading(e);
                    }
                }
            },
        })
            .then(data => {
                if (context.resetLoadingProgress) {
                    context.resetLoadingProgress();
                }

                resolve(data.body);
            })
            .catch(data => {
                if (context.resetLoadingProgress) {
                    context.resetLoadingProgress();
                }

                reject(data);
            }));
    },
    uploadPlaceVideo(context, placeId, video, options) {
        let query = '';
        if (options.pushInNewsfeed) {
            query += '?push_in_newsfeed=true';
        }
        const formData = new FormData();
        formData.append('video', video);
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/places/${placeId}/video${query}`, formData, {
            progress(e) {
                if (e.lengthComputable) {
                    if (context.calculateLoading) {
                        context.calculateLoading(e);
                    }
                }
            },
        })
            .then(data => {
                if (context.resetLoadingProgress) {
                    context.resetLoadingProgress();
                }

                resolve(data.body);
            })
            .catch(data => {
                if (context.resetLoadingProgress) {
                    context.resetLoadingProgress();
                }
                reject(data);
            }));
    },
    deletePlaceVideo(context, placeId, videoId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/places/' + placeId + '/video/' + videoId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanies(context, options = {}) {
        options.search = options.search ? options.search : '';
        options.limit = options.limit ? options.limit : '';
        options.cityId = options.cityId ? options.cityId : '';
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies?search=' + options.search +
				'&limit=' + options.limit +
				'&city_id=' + options.cityId, {
            before(request) {
                if (this.previousRequest) {
                    this.previousRequest.abort();
                }

                this.previousRequest = request;
            },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompany(context, id) {
        return new Promise((resolve, reject) => context.$http.get('companies/' + id)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createCompanyRequisite(context, companyId, requisite) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/requisite', JSON.stringify({
            isIndividualBiz: requisite.isIndividualBiz,
            company_name: requisite.company_name,
            legal_address: requisite.legal_address,
            real_address: requisite.real_address,
            INN: requisite.INN,
            OGRN: requisite.OGRN,
            KPP: requisite.KPP,
            signatory_full_name: requisite.signatory_full_name,
            signatory_base_id: requisite.signatory_base_id,
            signatory_position: requisite.signatory_position,
            full_name: requisite.full_name,
            register_address: requisite.register_address,
            OGRNIP: requisite.OGRNIP,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    sendCompanyRequisiteFiles(context, file, companyId) {
        const formData = new FormData();
        formData.append('file', file.file);
        formData.append('type', file.type);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/requisite/file', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    changeCompanyRequisite(context, companyId, requisite) {
        return new Promise((resolve, reject) => {
            const {
                isIndividualBiz,
                company_name,
                legal_address,
                real_address,
                signatory_full_name,
                signatory_base_id,
                signatory_position,
                full_name,
                register_address,
                OGRNIP,
                INN,
                OGRN,
                KPP,
            } = requisite;

            return context.$http.put(consts.appUri + '/companies/' + companyId + '/requisite', JSON.stringify({
                isIndividualBiz,
                company_name,
                legal_address,
                real_address,
                INN,
                OGRN,
                KPP,
                signatory_full_name,
                signatory_base_id,
                signatory_position,
                full_name,
                register_address,
                OGRNIP,
            }))
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    getCompanyRequisite(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/requisite')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getRequisteCompanyByINN(context, inn) {
        return context.$http.get(consts.appUri + `/suggestions/requisites?inn=${inn}`)
            .then(data => data.body)
            .catch(error => {
                throw error;
            });
    },
    getCompanyBankRequisite(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/bank-requisites')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    changeCompanyBankRequisite(context, requisite) {
        return new Promise((resolve, reject) => {
            const { bank_name, BIK, corrAccount, calcAccount } = requisite;
            return context.$http.put(consts.appUri + '/companies/bank-requisite/' + requisite.id, JSON.stringify({
                bank_name, BIK, corrAccount, calcAccount,
            }))
                .then(data => {
                    resolve(data.body);
                })
                .catch(data => {
                    reject(data);
                });
        });
    },
    createCompanyBankRequisite(context, companyId, requisite) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/bank-requisite', JSON.stringify({
            bank_name: requisite.bank_name,
            BIK: requisite.BIK,
            corrAccount: requisite.corrAccount,
            calcAccount: requisite.calcAccount,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createLoyaltyProgram(context, companyId, program, force_create = true) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/loyality-programs', JSON.stringify({
            needs_promo_code: program.needs_promo_code,
            description: program.description,
            url: program.url,
            confirmation_type: program.confirmation_type || [],
            force_create,
            coalition: program.coalition,
            issueable: program.issueable,
            availability: program.availability,
            name: program.name,
            card_confirmation_required: program.card_confirmation_required,
            share_count: program.shareCount,
            base_discount: program.discountValue ? program.discountValue / 100 : program.discountValue,
            base_points: program.bonusValue,
            show_barcode: program.showBarcode,
            available_in_benefitty_app: program.availableInBenefittyApp,
            color: program.color ? program.color.toUpperCase() : '#FFFFFF',
            rgb: program.rgb ? program.rgb.toUpperCase() : '#000000',
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyLoyaltyPrograms(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/loyality-programs')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getLoyaltyProgram(context, programId) {
        return new Promise((resolve, reject) => context.$http.get('loyality-programs/' + programId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getLoyaltyProgramPlaces(context, params) {
        console.log({ params });
        return new Promise((resolve, reject) => context.$http.get('loyality-programs/' + params.id + '/places', { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateLoyaltyProgram(context, companyId, program) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/companies/' + companyId + '/loyality-programs/' + program.id, JSON.stringify({
            needs_promo_code: program.needs_promo_code,
            description: program.description,
            url: program.url,
            confirmation_type: program.confirmation_type || [],
            base_discount: program.discountValue ? program.discountValue / 100 : program.discountValue,
            base_points: program.bonusValue,
            coalition: program.coalition,
            issueable: program.issueable,
            availability: program.availability,
            name: program.name,
            card_confirmation_required: program.card_confirmation_required,
            share_count: program.shareCount,
            show_barcode: program.showBarcode,
            available_in_benefitty_app: program.availableInBenefittyApp,
            color: program.color ? program.color.toUpperCase() : '#FFFFFF',
            rgb: program.rgb ? program.rgb.toUpperCase() : '#000000',
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteLoyaltyProgram(context, companyId, program) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/companies/' + companyId + '/loyality-programs/' + program.id)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadLoyaltyProgramFrontImage(context, programId, programImage) {
        const formData = new FormData();
        formData.append('image', programImage);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/loyality-programs/' + programId + '/images/front-side', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadLoyaltyProgramBackImage(context, programId, programImage) {
        const formData = new FormData();
        formData.append('image', programImage);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/loyality-programs/' + programId + '/images/back-side', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateLoyaltyProgramCardNumbers(context, options) {
        let cardNumbers = {};

        if (options.program.showBarcode) {
            cardNumbers = {
                number_min_value: options.program.firstReservedCardNumber,
                number_max_value: options.program.lastReservedCardNumber,
                bc_min_value: options.program.firstReservedBarCodeNumber,
                bc_max_value: options.program.lastReservedBarCodeNumber,
                prefix: '',
            };
        } else {
            cardNumbers = {
                number_min_value: options.program.firstReservedCardNumber,
                number_max_value: options.program.lastReservedCardNumber,
                prefix: '',
            };
        }

        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/loyality-programs/' + options.program.id + '/card-numbers', JSON.stringify(cardNumbers))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteLoyaltyProgramPartner(context, options = {}) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/loyality-programs/partners/' + options.partnerId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    /**
	 * @param { Vue } context
	 * @param { QueryGetLoyaltyPrograms } options
	 * @returns {Promise<LoyaltyProgram[]>}
	 */
    getLoyaltyPrograms: function(context, options = {}) {
        let query = {
            limit: options.limit || 10,
            company_id: options.company_id || null,
            place_id: options.place_id || null,
            coalition: options.coalition || '',
            search: options.search || '',
            offset: options.offset || null,
            sort: options.sort || null,
            lat: options.lat || null,
            lng: options.lng || null,
            cards_available: options.cards_available || null,
            include_available_card: options.include_available_card || null,
            page: options.page || null,
            paginate: options.paginate || null,
            type: options.type || null,
        };
        if (options.paginate) {
            query.paginate = options.paginate;
            query.page = options.page;
        } else {
            query.offset = options.offset || 0;
        }
        query = providerHelper.getObjectWithFields(query);
        return new Promise((resolve, reject) => context.$http.get('loyality-programs', { params: query })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    /**
	 * @param { Vue } context
	 * @param { QueryGetLoyaltyProgramsPartner } options
	 * @returns {Promise<LoyaltyProgramPartner[]>}
	 */
    getLoyaltyProgramsPartners: function(context, options = {}) {
        options.limit = options.limit || 10;
        options.offset = options.offset || 0;
        options.expired = options.expired || null;
        options.active = options.active || null;
        options.company_id = options.company_id || '';
        options.owner_company_id = options.owner_company_id || '';
        options.search = options.search || '';
        options.grouped = Boolean(options.grouped);
        options = providerHelper.getObjectWithFields(options);
        return new Promise((resolve, reject) => context.$http.get('loyality-programs/partners', { params: options })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    /**
	 * @param { Vue } context
	 * @param { QueryGetLoyaltyPrograms } options
	 * @returns {Promise<LoyaltyProgram[]>}
	 */
    getLoyaltyProgramsById: function(context, options = {}) {
        let query = {
            limit: options.limit || 10,
            company_id: options.company_id || null,
            place_id: options.place_id || null,
            coalition: options.coalition || '',
            offset: options.offset || null,
            sort: options.sort || null,
            lat: options.lat || null,
            lng: options.lng || null,
            cards_available: options.cards_available || null,
            include_available_card: options.include_available_card || null,
            page: options.page || null,
            paginate: options.paginate || null,
            type: options.type || null,
        };
        if (options.paginate) {
            query.paginate = options.paginate;
            query.page = options.page;
        } else {
            query.offset = options.offset || 0;
        }
        query = providerHelper.getObjectWithFields(query);
        return new Promise((resolve, reject) => context.$http.get(`loyality-programs/${options.id}`, { params: query })
            .then(data => {
                resolve([data.body]);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createLoyaltyProgramPartners(context, options = {}) {
        const params = {
            places: options.placesToAdd,
            discount_type: options.discountType,
            bonus_type: options.bonusType,
            write_off_points_ability: options.writeOffPointsAbility,
            short_description: options.shortDescription,
            description: options.description,
            without_duration: options.withoutDuration,
            duration: {
                from: options.duration && options.duration.from ? options.duration.from.format('YYYY-MM-DD') : null,
                to: options.duration && options.duration.to ? options.duration.to.format('YYYY-MM-DD') : null,
            },
            url: options.url,
            discount: options.discount / 100,
            company_id: options.companyId,
            all_place: options.allPlaces,
        };

        if (!options.allPlaces) {
            delete params.company_id;
        }

        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/loyality-programs/' + options.loyaltyProgram.id + '/partners', JSON.stringify(params))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateLoyaltyProgramPartner(context, options = {}) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/loyality-programs/partners/' + options.id, JSON.stringify({
            discount_type: options.discountType,
            bonus_type: options.bonusType,
            write_off_points_ability: options.writeOffPointsAbility,
            short_description: options.shortDescription,
            description: options.description,
            without_duration: options.withoutDuration,
            duration: {
                from: options.duration && options.duration.from ? options.duration.from.format('YYYY-MM-DD') : null,
                to: options.duration && options.duration.to ? options.duration.to.format('YYYY-MM-DD') : null,
            },
            url: options.url,
            discount: options.discount / 100,
            company_id: options.companyId,
            all_place: options.allPlaces,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyUsersDiscountCards: function(context, companyId, format = 'json') {
        let contentType = '';
        if (format === 'json') {
            contentType = 'application/json';
        } else if (format === 'csv') {
            contentType = 'text/csv';
        }
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/discount-cards/export-users', {
            headers: {
                'content-type': contentType,
            },
            params: { format },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getGroupLoyaltyProgramsPartners: function(context, groupKey) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/loyality-programs/partners/' + groupKey + '/group')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteGroupLoyaltyProgramsPartners(context, options) {
        return new Promise((resolve, reject) => context.$http.delete(`${consts.appUri}/loyality-programs/${options.loyalityProgramId}/partners/${options.groupKey}/group`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateGroupLoyaltyProgramsPartners: function(context, options, groupKey, loyalityProgramId) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/loyality-programs/' + loyalityProgramId + '/partners/' + groupKey + '/group', JSON.stringify({
            places: options.placesToAdd ? options.placesToAdd : [],
            discount_type: options.discountType,
            bonus_type: options.bonusType,
            write_off_points_ability: options.writeOffPointsAbility,
            short_description: options.shortDescription,
            description: options.description,
            without_duration: options.withoutDuration,
            duration: {
                from: options.duration && options.duration.from ? options.duration.from.format('YYYY-MM-DD') : null,
                to: options.duration && options.duration.to ? options.duration.to.format('YYYY-MM-DD') : null,
            },
            url: options.url,
            discount: options.discount / 100,
            company_id: options.companyId,
            all_place: options.allPlaces,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    uploadDiscountCardsCsv: function(context, options = {}) {
        const formData = new FormData();
        formData.append('file', options.file);
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/loyality-programs/' + options.loyalityProgramId
				+ '/discount-cards/upload', formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createDiscountCardComplain(context, cardId, description) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/discount-cards/' + cardId + '/complaints', JSON.stringify({
            message: description,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createDiscountComplain(context, discountId, description) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/discounts/' + discountId + '/complaints', JSON.stringify({
            message: description,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createReviewComplain(context, reviewId, description) {
        return new Promise((resolve, reject) => context.$http.post('reviews/' + reviewId + '/complaints', JSON.stringify({
            message: description,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createPhotoComplain(context, photoId, description) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/images/' + photoId + '/complaints', JSON.stringify({
            message: description,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createNewsfeedEventComplain(context, eventId, description) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/newsfeed/${eventId}/complaint`, JSON.stringify({
            message: description,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    setPlaceProperties(context, placeId, properties) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + placeId + '/properties', JSON.stringify(properties))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    moderateAddUserToCompanyByPlace(context, options = {}) {
        options.user = options.user || {};
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + options.placeId + '/users', JSON.stringify({
            first_name: options.user.name,
            last_name: options.user.lastName,
            email: options.user.workEmail,
            phone: options.user.workPhone,
            role: options.user.role,
            position: options.user.position,
            offer_accepted: options.userAgreementAccepted,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    moderateAttachPlaceToCompany(context, placeId, companyId) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/places/' + placeId + '/attach-company', JSON.stringify({
            company_id: companyId,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceBrands: function(context, placeId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/places/' + placeId + '/brands')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    addBrandToPlace: function(context, placeId, brandId) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + placeId + '/brands/' + brandId + '/attach')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteBrandFromPlace: function(context, placeId, brandsId) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + placeId + '/brands/detach', JSON.stringify({
            brands: brandsId,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    changeCompanyRewardsProgram: function(context, options) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + options.companyId + '/rewards/settings', JSON.stringify({
            make_review: options.makeReview,
            upload_image: options.uploadImage,
            share_link: options.shareLink,
            share_fb: options.shareFb,
            share_vk: options.shareVk,
            share_ok: options.shareOk,
            max_discount: options.maxDiscount,
            invitation: options.invitation,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    chargeRewardsToUsersFromCompany: function(context, companyId) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + companyId + '/invitations/rewards')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyInvitationsCount: function(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/invitations/count')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    rewardsBindLoyalityProgram(context, companyId, body) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + `/companies/${companyId}/rewards/bind-loyality-program`, body)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    rewardsUnbindLoyalityProgram(context, companyId) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + `/companies/${companyId}/rewards/unbind-loyality-program`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyRewardsProgram: function(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/rewards/settings')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyCashbackOfflineSettings: function(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/cashback/settings')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    changeCompanyCashbackOfflineSettings: function(context, options) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/companies/' + options.companyId + '/cashback/settings', JSON.stringify({
            cashback_rate: options.cashbackRate,
            cashback_delay: options.cashbackDelay,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteCompanyCashbackOfflineSettings: function(context, options) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/companies/' + options.companyId + '/cashback/settings')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyCashiers: function(context, options) {
        options.limit = options.limit || 10;
        options.page = options.page || 1;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + options.companyId + '/cashiers' +
				'?limit=' + options.limit +
				'&page=' + options.page)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyCashiersTrashed: function(context, options) {
        options.limit = options.limit || 10;
        options.page = options.page || 1;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + options.companyId + '/cashiers/trashed' +
				'?limit=' + options.limit +
				'&page=' + options.page)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createPlaceCashier: function(context, options) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + options.placeId + '/cashiers', JSON.stringify({
            user_id: options.userId,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deletePlaceCashier: function(context, options) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/places/' + options.placeId + '/cashiers', {
            body: {
                cashiers: options.cashierId,
            },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyAdministrators: function(context, options) {
        options.limit = options.limit || 10;
        options.page = options.page || 1;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + options.companyId + '/administrators' +
				'?limit=' + options.limit +
				'&page=' + options.page)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyAdministratorsTrashed: function(context, options) {
        options.limit = options.limit || 10;
        options.page = options.page || 1;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + options.companyId + '/administrators/trashed' +
				'?limit=' + options.limit +
				'&page=' + options.page)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createPlaceAdministrator: function(context, options) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + options.placeId + '/administrators', JSON.stringify({
            user_id: options.userId,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createPlaceAdministrators(context, companyId, options) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/companies/${companyId}/administrators`, options)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createSuperAdministrators(context, companyId, options) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/companies/${companyId}/super-admins`, options)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanySuperadministrators(context, options) {
        const params = {
            limit: options.limit || 10,
            page: options.page || 1,
            trashed: options.trashed,
        };
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${options.companyId}/super-admins`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteCompanySuperadministrator(context, options) {
        return new Promise((resolve, reject) => context.$http.delete(`${consts.appUri}/companies/${options.companyId}/super-admins/${options.userId}`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deletePlaceAdministrator: function(context, options) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/places/' + options.placeId + '/administrators', {
            body: {
                administrators: options.administratorsId,
            },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyCashMachines: function(context, options) {
        options.limit = options.limit || 10;
        options.page = options.page || 1;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + options.companyId + '/cash-machines' +
				'?limit=' + options.limit +
				'&page=' + options.page)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceCashMachine: function(context, options) {
        options.limit = options.limit || 10;
        options.page = options.page || 1;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/cash-machines/' +
				options.cashMachineId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createPlaceCashMachine: function(context, options) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places/' + options.placeId + '/cash-machines', JSON.stringify({
            id: options.registrationNumber,
            fiscal_drive_id: options.uniqueFiscalNumber,
            serial_number: options.fabricNumber,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updatePlaceCashMachine: function(context, options) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/cash-machines/' + options.cashMachineId, JSON.stringify({
            id: options.registrationNumber,
            fiscal_drive_id: options.uniqueFiscalNumber,
            serial_number: options.fabricNumber,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deletePlaceCashMachine: function(context, options) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/cash-machines/' + options.cashMachineId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlaceCashMachinesTrashed: function(context, options) {
        options.limit = options.limit || 10;
        options.page = options.page || 1;
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + options.companyId + '/cash-machines/trashed' +
				'?limit=' + options.limit +
				'&page=' + options.page)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createFiscalDriveForCashMachine: function(context, options) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/cash-machines/' + options.cashMachineId + '/fiscal-drives', JSON.stringify({
            id: options.uniqueFiscalNumber,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    requireDemonstrationTariff: function(context, options) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/leads', JSON.stringify(options))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    restoreFiscalDriveForCashMachine: function(context, options) {
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/cash-machines/' + options.cashMachineId +
				'/fiscal-drives/' + options.uniqueFiscalNumber +
				'/restore')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteCashMachineFiscalDrive: function(context, options) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/cash-machines/' + options.cashMachineId +
				'/fiscal-drives/' + options.uniqueFiscalNumber)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPaymentSystemPartner: function(context, partnerId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/payment-systems/partners/' + partnerId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPaymentSystemPartnerGroup: function(context, groupKey) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/payment-systems/partners/' + groupKey + '/group')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updatePaymentSystemPartnerGroup(context, partner) {
        const dateStart = partner.period && partner.period.from ? partner.period.from.format('YYYY-MM-DD') : null;
        const dateEnd = partner.period && partner.period.to ? partner.period.to.format('YYYY-MM-DD') : null;
        const anyBankProgram = partner.loyaltyProgramId == null;
        partner.loyaltyProgramId = partner.loyaltyProgramId == 0 ? null : partner.loyaltyProgramId;
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/payment-systems/partners/' + partner.groupKey + '/group', JSON.stringify({
            payment_system_id: partner.paymentSystemId,
            payment_system_levels: partner.paymentSystemCardTypes,
            description: partner.description,
            description_detail: partner.descriptionDetail,
            url: partner.url,
            duration: {
                from: dateStart,
                to: dateEnd,
            },
            card_types: partner.bankCardTypes,
            place_id: partner.placesToAdd,
            bank_id: partner.bankId,
            loyality_program_id: partner.loyaltyProgramId,
            any_bank_program: anyBankProgram,
            discount: Number(partner.discount) / 100,
            all_partners: partner.allPlaces,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPaymentSystemsPartners: function(context, options = {}) {
        let params = {
            expired: options.expired || null,
            active: options.active || null,
            bank_id: options.bankId || '',
            payment_system_id: options.paymentSystemId || '',
            search: options.search || '',
            limit: options.limit || 10,
            offset: options.offset || 0,
            company_id: options.companyId || '',
            grouped: Boolean(options.grouped),
        };

        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/payment-systems/partners', {
            before(request) {
                if (this.previousRequest) {
                    this.previousRequest.abort();
                }

                this.previousRequest = request;
            },
            params: params,
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createPaymentSystemPartner: function(context, options = {}) {
        const params = {
            place_ids: options.partner.placesToAdd,
            payment_system_id: options.partner.paymentSystemId,
            payment_system_levels: options.partner.paymentSystemCardTypes,
            description: options.partner.description,
            description_detail: options.partner.descriptionDetail,
            url: options.partner.url,
            discount: Number(options.partner.discount) / 100,
            without_duration: options.partner.withoutDuration,
            duration: {
                from: options.partner.period && options.partner.period.from ? options.partner.period.from.format('YYYY-MM-DD') : null,
                to: options.partner.period && options.partner.period.to ? options.partner.period.to.format('YYYY-MM-DD') : null,
            },
            any_level: options.partner.anyLevel,
            company_id: options.partner.currentCompanyId,
            all_places: options.partner.allPlaces,
        };

        if (!options.partner.allPlaces) {
            delete params.company_id;
        }

        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/payment-systems/partners', JSON.stringify(params))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updatePaymentSystemPartner: function(context, options = {}) {
        options = providerHelper.getObjectWithFields(options);
        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/payment-systems/partners/' + options.partnerId, JSON.stringify({
            payment_system_id: options.paymentSystemId,
            payment_system_levels: options.paymentSystemCardTypes,
            description: options.description,
            description_detail: options.descriptionDetail,
            url: options.url,
            discount: Number(options.discount) / 100,
            without_duration: options.withoutDuration,
            duration: {
                from: options.period && options.period.from ? options.period.from.format('YYYY-MM-DD') : null,
                to: options.period && options.period.to ? options.period.to.format('YYYY-MM-DD') : null,
            },
            any_level: options.anyLevel,
        }))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deletePaymentSystemPartner: function(context, partnerId) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/payment-systems/partners/' + partnerId)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deletePaymentSystemPartnerGroup: function(context, groupKey) {
        return new Promise((resolve, reject) => context.$http.delete(consts.appUri + '/payment-systems/partners/' + groupKey + '/group')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPlacesGeneralInformation: function(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/places-general-information')
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateShortPlacesGeneralInformation(context, options) {
        const formData = new FormData();
        const content = {
            company_id: options.companyId,
            name: options.place.name,
            latin_name: options.place.latinName,
            description: options.place.description,
            social_accounts: options.socialAccounts,
            applies: options.applies,
            phones: options.phones,
            websites: options.websites,
            emails: options.emails,
        };
        formData.append('contents', JSON.stringify(content));
        if (options.image) {
            formData.append('image', options.image);
        }
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places-general-information', formData)
            .then(data => {
                resolve(data);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updatePlacesGeneralInformation(context, options) {
        const formData = new FormData();
        const content = {
            appends_fields: options.appends_fields,
            company_id: options.companyId,
            name: options.place.name,
            latin_name: options.place.latinName,
            description: options.place.description,
            categories: options.categories,
            phones: options.phones,
            websites: options.websites,
            emails: options.emails,
            commercial_proposal_email: options.place.commercial_proposal_email,
            social_accounts: options.socialAccounts,
            tags: options.place.tags ? options.place.tags.split(',') : '',
            applies: options.applies,
        };
        if (options.workingTime) {
            content.working_hours = options.workingTime;
        }
        formData.append('contents', JSON.stringify(content));
        if (options.image) {
            formData.append('image', options.image);
        }
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/places-general-information', formData)
            .then(data => {
                resolve(data);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyCashbackOperations: function(context, companyId, options = {}) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/operations/cashback', {
            params: {
                offset: options.offset || 0,
                limit: options.limit || 10,
                cashiers: options.cashiers || '',
                cashier_id: options.cashierId || '',
                cash_machines: options.cashMachines || '',
                cash_machine_id: options.cashMachineId || '',
            },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    cancelCashbackEvent: function(context, options = {}) {
        const body = {
            reason: options.reason,
            note: options.note,
        };

        return new Promise((resolve, reject) => context.$http.put(consts.appUri + '/cashback/' + options.currentCashbackEventId + '/cancel', body)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyRewardsOperations: function(context, companyId, options = {}) {
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + companyId + '/operations/rewards', {
            params: {
                offset: options.offset || 0,
                limit: options.limit || 10,
                payroll: options.payroll || '',
                write_off: options.writeOff || '',
            },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyCategories: function(context, options = {}) {
        const params = {
            for_current_user: options.for_current_user || false,
        };
        return new Promise((resolve, reject) => context.$http.get(consts.appUri + '/companies/' + options.companyId + '/places/categories', {
            params,
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getAllBrands(context, params) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/brands`, {
            before(request) {
                if (this.previousRequest) {
                    this.previousRequest.abort();
                }
                this.previousRequest = request;
            },
            params,
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getBrandById(context, brandId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/brands/${brandId}`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getBrandMedia(context, options = {}) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/brands/${options.id}/media`, {
            params: {
                limit: options.limit || 10,
                offset: options.offset || 0,
            },
        })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getBrandReviews(context, brandId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/brands/${brandId}/reviews?limit=10&offset=0`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    invitePlaceToBenefitty(context, placeId) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/places/${placeId}/invitations`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    checkinInPlace(context, placeId) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/places/${placeId}/checkins`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getBenefittyLP(context) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/loyality-programs/configs`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createLPCondition(context, programId, params) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/loyality-programs/${programId}/conditions`, JSON.stringify(params))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateLPCondition(context, conditionId, params) {
        return new Promise((resolve, reject) => context.$http.put(`${consts.appUri}/loyality-programs/conditions/${conditionId}`, JSON.stringify(params))
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteLPCondition(context, conditionId) {
        return new Promise((resolve, reject) => context.$http.delete(`${consts.appUri}/loyality-programs/conditions/${conditionId}`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getLPConditions(context, programId, options) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/loyality-programs/${programId}/conditions`, { params: options })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getLPFreePlacesIds(context, programId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/loyality-programs/${programId}/free-place-ids`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    changeLPStatus(context, programId, params) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/loyality-programs/${programId}/status`, params)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    changeCompanyStatus(context, companyId, params) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/companies/${companyId}/status`, params)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getLPConditionPlaces(context, conditionId, params) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/loyality-programs/conditions/${conditionId}/places`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    detachLoyalityProgram(context, discountCardId) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/discount-cards/${discountCardId}/detach-loyality-program`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getReview(context, reviewId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/reviews/${reviewId}`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    makePlaceImageFromId(context, options) {
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/places/${options.placeId}/image/${options.imageId}/make-profile`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    makeCompanySmallLogo(context, file, companyId, type) {
        if (type === 'map') {
            type = 'map_logo';
        }
        if (type === 'profile') {
            type = 'profile_logo';
        }
        const formData = new FormData();
        formData.append('image', file);
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/companies/${companyId}/${type}`, formData)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyAllPlacesIds(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${companyId}/places/ids`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPromotionProgram(context, programId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/promotion-programs/${programId}`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPromotionPrograms(context, options) {
        let params = {
            limit: options.limit || 10,
            offset: options.offset || 0,
            company_id: options.companyId,
            user_id: options.userId,
            search: options.search,
            status: options.status,
            date_start: options.dateStart,
            date_end: options.dateEnd,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/promotion-programs`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    createPromotionProgram(context, options) {
        let params = {
            name: options.name,
            description: options.description,
            cashback_reward: options.cashbackReward ? options.cashbackReward / 100 : null,
            influencer_reward: options.influencerReward ? options.influencerReward / 100 : null,
            status: options.status,
            date_start: options.dateStart,
            date_end: options.dateEnd,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/company/${options.companyId}/promotion-programs`, params)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updatePromotionProgram(context, options) {
        let params = {
            name: options.name,
            description: options.description,
            cashback_reward: options.cashbackReward ? options.cashbackReward / 100 : null,
            influencer_reward: options.influencerReward ? options.influencerReward / 100 : null,
            status: options.status,
            date_start: options.dateStart,
            date_end: options.dateEnd,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.put(`${consts.appUri}/promotion-programs/${options.programId}`, params)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPromotionProgramsHistory(context, options) {
        let params = {
            limit: options.limit || 10,
            offset: options.offset || 0,
            user_id: options.userId,
            date_from: options.dateFrom,
            date_to: options.dateTo,
            time: options.time,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/promotion-program/company/${options.companyId}/history`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    changeStatusPromotionPrograms(context, options) {
        let params = {
            promotion_programs_ids: options.promotionProgramsIds,
            status: options.status,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.put(`${consts.appUri}/promotion-programs/status/bulk`, params)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getPromotionProgramsInfluencers(context, options) {
        const params = {
            limit: options.limit || 10,
            offset: options.offset || 0,
        };
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/company/${options.companyId}/promotion-programs/influencers`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyFollowers(context, options) {
        const params = {
            limit: options.limit || 10,
            offset: options.offset || 0,
        };
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${options.companyId}/followers`, { params })
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getCompanyBlockStatus(context, companyId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${companyId}/block-status`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getDiscountPlacesIds(context, discountId) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/discounts/${discountId}/places-ids`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    getOrganizationPromotionalMaterials(context, companyId, slug) {
        return new Promise((resolve, reject) => context.$http.get(`${consts.appUri}/companies/${companyId}/poster/${slug}`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    updateCompanyInstagram(context, options) {
        let params = {
            code: options.code,
            redirect_uri: options.redirect,
        };
        params = providerHelper.getObjectWithFields(params);
        return new Promise((resolve, reject) => context.$http.post(`${consts.appUri}/company/${options.companyId}/instagram`, params)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },
    deleteCompanyInstagram(context, options) {
        return new Promise((resolve, reject) => context.$http.delete(`${consts.appUri}/company/${options.companyId}/instagram`)
            .then(data => {
                resolve(data.body);
            })
            .catch(data => {
                reject(data);
            }));
    },

    getQRLinkLoyaltyProgram(context, program) {
        return new Promise((resolve, reject) => context.$http.post(consts.appUri + '/auth/simplified/' + program + '/registration-qr')
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            }));
    },

};
