import importPageFiles from './importPageFiles.js'
import RouterHelper from '@/router/routerHelper.js'

var routerHelper = new RouterHelper();

const OrganizationBrands = importPageFiles.OrganizationBrands;
const OrganizationSettings = importPageFiles.OrganizationSettings;
const OrganizationSettingsOld = importPageFiles.OrganizationSettingsOld;
const OrganizationTariffs = importPageFiles.OrganizationTariffs;
const OrganizationTariffsFlexible = importPageFiles.OrganizationTariffsFlexible;
const OrganizationListOfTariffs = importPageFiles.OrganizationListOfTariffs;
const OrganizationListOfTariffsEdit = importPageFiles.OrganizationListOfTariffsEdit;

const OrganizationCommon = importPageFiles.OrganizationCommon;
const OrganizationCommonMainInfo = importPageFiles.OrganizationCommonMainInfo;
const OrganizationStocks = importPageFiles.OrganizationStocks;
const OrganizationNewStock = importPageFiles.OrganizationNewStock;

const OrganizationPromotionsCommon = importPageFiles.OrganizationPromotionsCommon;
const OrganizationPromotionsList = importPageFiles.OrganizationPromotionsList;
const OrganizationPromotionsNew = importPageFiles.OrganizationPromotionsNew;
const OrganizationPromotionsReport = importPageFiles.OrganizationPromotionsReport;

const OrganizationSimpleLoyaltyProgram = importPageFiles.OrganizationSimpleLoyaltyProgram;
const OrganizationLoyaltyProgramsAddPartners = importPageFiles.OrganizationLoyaltyProgramsAddPartners;
const OwnerRegistrationWithId = importPageFiles.OwnerRegistrationWithId

const OrganizationRequisites = importPageFiles.OrganizationRequisites;
const OrganizationRequisitesEntity = importPageFiles.OrganizationRequisitesEntity;
const OrganizationRequisitesIndividualEntrepreneur = importPageFiles.OrganizationRequisitesIndividualEntrepreneur;

const PlaceSearch = importPageFiles.PlaceSearch;
const OwnerRegistration = importPageFiles.OwnerRegistration;
const AddPlace = importPageFiles.AddPlace;
const AttachPlaceToOrganization = importPageFiles.AttachPlaceToOrganization;

const OrganizationPlace = importPageFiles.OrganizationPlace;
const OrganizationPlaceMainInfo = importPageFiles.OrganizationPlaceMainInfo;
const OrganizationPlacePushNotice = importPageFiles.OrganizationPlacePushNotice;
const OrganizationPlacePhotosAndVideos = importPageFiles.OrganizationPlacePhotosAndVideos;
const OrganizationPlaceReviews = importPageFiles.OrganizationPlaceReviews;
const OrganizationPlaceDiscountReviews = importPageFiles.OrganizationPlaceDiscountReviews;
const OrganizationPlaceEvents = importPageFiles.OrganizationPlaceEvents;
const OrganizationPlaceAdvertisement = importPageFiles.OrganizationPlaceAdvertisement;
const OrganizationPlaceAdditionalInformation = importPageFiles.OrganizationPlaceAdditionalInformation;
const OrganizationPlacesAdmin = importPageFiles.OrganizationPlacesAdmin;
const OrganizationPlacesModeratorArchive = importPageFiles.OrganizationPlacesModeratorArchive;
const OrganizationPlacesCashier = importPageFiles.OrganizationPlacesCashier;
const OrganizationPlacesCashierArchive = importPageFiles.OrganizationPlacesCashierArchive;
const OrganizationPlacesCashierOperations = importPageFiles.OrganizationPlacesCashierOperations;
const OrganizationPlaceBrands = importPageFiles.OrganizationPlaceBrands;
const OrganizationPlaceMedias = importPageFiles.OrganizationPlaceMedias;
const OrganizationSteps = importPageFiles.OrganizationSteps;

export default [
	{
		path: '/organization/brands',
		name: 'organizationBrands',
		component: OrganizationBrands,
		beforeEnter: routerHelper.beforeOrganizationEnter
	},
	{
		path: '/organization/loyalty-program',
		name: 'organizationLoyaltyProgram',
		beforeEnter: routerHelper.beforeOrganizationEnter,
		children: [
			{
				path: '/organization/loyalty-program/promotion/:id',
				name: 'organizationPromotionsEdit',
				component: OrganizationPromotionsNew,
				props: true,
			},
			{
				path: '/organization/loyalty-program/promotions',
				name: 'organizationPromotions',
				component: OrganizationPromotionsCommon,
				children: [
					{
						path: '/organization/loyalty-program/promotions/list',
						name: 'organizationPromotionsList',
						component: OrganizationPromotionsList,
					},
					{
						path: '/organization/loyalty-program/promotions/report',
						name: 'organizationPromotionsReport',
						component: OrganizationPromotionsReport,
					},
				]
			},
			{
				path: '/organization/loyalty-program/simple-loyalty-program',
				name: 'organizationSimpleLoyaltyProgram',
				component: OrganizationSimpleLoyaltyProgram,
			},


			{
				path: '/organization/loyalty-program/loyalty-programs-add-partners',
				name: 'organizationLoyaltyProgramsAddPartners',
				component: OrganizationLoyaltyProgramsAddPartners,
			},
		]
	},
	{
		path: '/organization/settings',
		name: 'organizationSettings',
		component: OrganizationSettingsOld,
		beforeEnter: routerHelper.beforeOrganizationEnter
	},
	{
		path: '/organization/settings/requisites',
		name: 'organizationRequisites',
		component: OrganizationRequisites,
		beforeEnter: routerHelper.beforeOrganizationEnter,
		children: [
			{
				path: '/organization/settings/requisites/entity',
				name: 'organizationRequisitesEntity',
				component: OrganizationRequisitesEntity,
			},
			{
				path: '/organization/settings/requisites/individual-entrepreneur',
				name: 'organizationRequisitesIndividualEntrepreneur',
				component: OrganizationRequisitesIndividualEntrepreneur
			}]
	},
	{
		path: '/organization/tariffs',
		name: 'organizationTariffs',
		component: OrganizationTariffs,
		beforeEnter: routerHelper.beforeOrganizationEnter,
		children: [
			{
				path: '/organization/tariffs',
				name: 'organizationListOfTariffs',
				component: OrganizationListOfTariffs,
			},
			{
				path: '/organization/tariffs/edit',
				name: 'organizationListOfTariffsEdit',
				component: OrganizationListOfTariffsEdit,
			},
			{
				path: '/organization/tariffs/flexible',
				name: 'OrganizationTariffsFlexible',
				component: OrganizationTariffsFlexible
			}]
	},
	{
		path: '/organization/common',
		name: 'organizationCommon',
		component: OrganizationCommon,
		beforeEnter: routerHelper.beforeOrganizationEnter,
		children: [
			{
				path: '/organization/common/main-info',
				name: 'organizationCommonMainInfo',
				component: OrganizationCommonMainInfo,
			},
			{
				path: '/organization/common/discounts/stocks',
				name: 'organizationStocksCommon',
				component: OrganizationStocks,
				props: { type: 'organizationCommon' },
				beforeEnter: routerHelper.beforeOrganizationEnter
			},
			{
				path: '/organization/common/reviews',
				name: 'organizationReviewsCommon',
				component: OrganizationPlaceReviews,
				props: { type: 'organizationCommon' },
				beforeEnter: routerHelper.beforeOrganizationEnter
			},
			{
				path: '/organization/common/discount-reviews',
				name: 'organizationCommonDiscountReviews',
				component: OrganizationPlaceDiscountReviews,
				props: { type: 'organizationCommon' },
			},
			{
				path: '/organization/common/photos-and-videos',
				name: 'organizationCommonPhotosAndVideos',
				component: OrganizationPlacePhotosAndVideos,
				props: (route) => ({ id: route.params.id, type: 'organizationCommon' }),
			},
			{
				path: '/organization/common/discounts/stocks/create-stock',
				name: 'organizationNewStockCommon',
				component: OrganizationNewStock,
				props: { type: 'organizationCommon' },
				beforeEnter: routerHelper.beforeOrganizationEnter
			},
		]
	},
	{
		path: '/organization/place/:id',
		name: 'organizationPlace',
		component: OrganizationPlace,
		props: true,
		beforeEnter: routerHelper.beforeOrganizationEnter,
		children: [
			{
				path: '/organization/place/:id/main-info',
				name: 'organizationPlaceMainInfo',
				component: OrganizationPlaceMainInfo,
				props: (route) => ({ id: route.params.id, type: 'organization' })
			},
			{
				path: '/organization/place/:id/push-notice',
				name: 'organizationPlacePushNotice',
				component: OrganizationPlacePushNotice,
				props: true
			},
			{
				path: '/organization/place/:id/discounts/stocks',
				name: 'organizationPlaceStocks',
				component: OrganizationStocks,
				props: true,
			},
			// {
			// 	path:'/organization/place/:id/discounts/gift-sertificates',
			// 	name: 'organizationPlaceGiftSertificates',
			// 	component: OrganizationPlaceGiftSertificates,
			// 	props: true,
			// },
			// {
			// 	path:'/organization/place/:id/discounts/coupons',
			// 	name: 'organizationPlaceCoupons',
			// 	component: OrganizationPlaceCoupons,
			// 	props: true,
			// },
			{
				path: '/organization/place/:id/photos-and-videos',
				name: 'organizationPlacePhotosAndVideos',
				component: OrganizationPlacePhotosAndVideos,
				props: (route) => ({ id: route.params.id, type: 'organization' }),
			},
			{
				path: '/organization/place/:id/discount-reviews',
				name: 'organizationPlaceDiscountReviews',
				component: OrganizationPlaceDiscountReviews,
				props: true,
			},
			{
				path: '/organization/place/:id/reviews',
				name: 'organizationPlaceReviews',
				component: OrganizationPlaceReviews,
				props: true,
			},
			// {
			// 	path: '/organization/place/:id/events',
			// 	name:'organizationPlaceEvents',
			// 	component: OrganizationPlaceEvents,
			// 	props: true,
			// },
			// {
			// 	path: '/organization/place/:id/advertisement',
			// 	name:'organizationPlaceAdvertisement',
			// 	component: OrganizationPlaceAdvertisement,
			// 	props: true,
			// },
			{
				path: '/organization/place/:id/brands',
				name: 'organizationPlaceBrands',
				component: OrganizationPlaceBrands,
				props: true,
			},
			{
				path: '/organization/place/:id/additional-info',
				name: 'organizationPlaceAdditionalInformation',
				component: OrganizationPlaceAdditionalInformation,
				props: (route) => ({ id: route.params.id, type: 'organization' }),
			}]
	},
	{
		path: '/organization/place/:id/discounts/stocks/create-stock',
		name: 'organizationNewStock',
		component: OrganizationNewStock,
		beforeEnter: routerHelper.beforeOrganizationEnter,
		props: (route) => ({ id: route.params.id, type: 'organizationPlace' }),
	},
	{
		path: '/organization/place/:id/:mediaType/:user',
		name: 'OrganizationPlaceMedias',
		component: OrganizationPlaceMedias,
		beforeEnter: routerHelper.beforeOrganizationEnter,
		props: (route) => ({ id: route.params.id, user: route.params.user, mediaType: route.params.mediaType, type: 'organization' }),
	},
	{
		path: '/organization/common/photos-and-videos/:mediaType/:user',
		name: 'OrganizationCommonMedias',
		component: OrganizationPlaceMedias,
		beforeEnter: routerHelper.beforeOrganizationEnter,
		props: (route) => ({ id: route.params.id, user: route.params.user, mediaType: route.params.mediaType, type: 'organizationCommon' }),
	},
	{
		path: '/organization/place-search/:type',
		name: 'placeSearch',
		component: PlaceSearch,
		props: true,
		beforeEnter: routerHelper.beforeUserEnter
	},
	{
		path: '/organization/:id/owner-registration',
		name: 'OwnerRegistrationWithId',
		component: OwnerRegistrationWithId,
		props: true,
		beforeEnter: routerHelper.beforePersonEnterRegistration,
	},
	{
		path: '/organization/owner-registration',
		name: 'addPlaceOwnerRegistration',
		component: OwnerRegistration,
		beforeEnter: routerHelper.beforeUserEnter,
	},
	{
		path: '/organization/add-place',
		name: 'addPlace',
		component: AddPlace,
		beforeEnter: routerHelper.beforeUserEnter,
		props: { type: 'create' }
	},
	{
		path: '/organization/add-and-attach-place',
		name: 'addAndAttachPlace',
		component: AddPlace,
		beforeEnter: routerHelper.beforeUserEnter,
		props: { type: 'createAndAttach' }
	},
	{
		path: '/organization/place/:id/offer-place-changes',
		name: 'offerPlaceChanges',
		component: AddPlace,
		beforeEnter: routerHelper.beforeUserEnter,
		props: (route) => ({ id: route.params.id, type: 'update' })
	},
	{
		path: '/organization/:id/attach-place',
		name: 'attachPlaceToOrganization',
		component: AttachPlaceToOrganization,
		props: true,
		beforeEnter: routerHelper.beforeOrganizationEnter,
	},
	{
		path: '/organization/places-admin',
		name: 'organizationPlacesAdmin',
		component: OrganizationPlacesAdmin,
		beforeEnter: routerHelper.beforeOrganizationEnter,
	},
	{
		path: '/organization/places-admin/archive',
		name: 'organizationPlacesAdminArchive',
		component: OrganizationPlacesModeratorArchive,
		beforeEnter: routerHelper.beforeOrganizationEnter,
	},
	{
		path: '/organization/steps',
		name: 'organizationSteps',
		component: OrganizationSteps,
		beforeEnter: routerHelper.beforeOrganizationEnter,
	},
	{
		path: '/organization/places-cashier',
		name: 'organizationPlacesCashier',
		component: OrganizationPlacesCashier,
		beforeEnter: routerHelper.beforeOrganizationEnter,
	},
	{
		path: '/organization/places-cashier/archive',
		name: 'organizationPlacesCashierArchive',
		component: OrganizationPlacesCashierArchive,
		beforeEnter: routerHelper.beforeOrganizationEnter,
	},
	{
		path: '/organization/places-cashier/:id/operations',
		name: 'organizationPlacesCashierOperations',
		props: true,
		component: OrganizationPlacesCashierOperations,
		beforeEnter: routerHelper.beforeOrganizationEnter,
	},
]