export default {
	/**
	 * @returns {Promise<Partner>}
	 */
	getPartnerSettings() {
		return new Promise((resolve) => {
			resolve({
				links: {
					target: ''
				},
				style: {
					header: false
				},
				hiddenBlocks: {
					loyalty_program: [
						// 'exchange-points',
						// 'shared',
						// 'loyalty_program:description',
						// 'loyalty_program:places',
						// 'loyalty_program:shared',
						// 'loyalty_program:discounts'
					],
					place: [
						// "place:images",
						// "place:rating",
						// "place:location",
						// "place:discounts",
						// "place:shared-cards",
						//
						// "place:button-bookmark",
						// "place:button-location",
						// "place:button-loyalty",
						// "place:button-discount",
						// "place:button-scanner",
						"place:button-cashback",
						//
						// "place:modal-comment",
						// "place:modal-map",
						// "place:modal-media",
						// "place:modal-post",
						// "place:modal-checkin",
						"place:modal-invite",
						// "place:modal-scanner",
						// "place:modal-lightbox",
						"place:modal-quick_actions",
						//
						// "place:tab-discounts",
						// "place:tab-overview",
						// "place:tab-cards",
						"place:tab-exchange_points",
						// "place:tab-reviews",
						// "place:tab-cheques",
						// "place:tab-media"
					],
					loyalty_card: [
						// 'loyalty_card:button-share',
						'exchange-points',
						'shared'
						// 'loyalty_card:tab-cards',
						// 'loyalty_card:sharing'
					],
					search: {
						filter: [
							// 'search:categories',
							'search:bank_discounts',
							// 'search:payment_system_discounts',
							// 'search:mark',
							// 'search:average_price',

							// 'search:personal',
							'search:personal:friends_recommended',
							'search:personal:is_new',
							// 'search:personal:bookmarked',
							// 'search:personal:work_now',

							'search:in_programs'
							// 'search:properties',
						]
					}
				},
				hiddenRefs: {
					place: [
						'ref:place:logo'
					]
				},
			})
		})
	},
}