import Vue from 'vue'

const state = () => ({
	modal: {
		active: false,
		render: () => {}
	}
})

const getters = {
	modal: state => state.modal,
	modalComponent: state => state.modal.component
}

const mutations = {
	SET_GLOBAL_MODAL (state, options) {
		const modal = {
			...state.modal,
			...options,
			active: true
		}
		Vue.set(state, 'modal', modal)
	},
	CHANGE_GLOBAL_MODAL_ACTIVATION (state, active) {
		Vue.set(state.modal, 'active', active)
	},
	TOGGLE_GLOBAL_MODAL (state) {
		state.modal.active = !state.modal.active
	}
}

export default {
	namespaced: true,
	state, getters, mutations
}