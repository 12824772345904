import {callQueue} from "@/utils/helpers/common";

export default {
	computed: {
		element () {
			const modalId = this.elementId || this.modalId
			if (modalId) {
				return $(`#${modalId}`)
			} else {
				console.error('Modal element is not found')
				return null
			}
		}
	},
	methods: {
		getListeners (modalListeners) {
			function bindContext (functionArray) {
				return functionArray.map(func => func.bind(this))
			}
			const listeners = {
				hidden: [
					...(modalListeners.hidden || []),
				],
				hide: [
					...(modalListeners.hide || []),
				],
				shown: [
					...(modalListeners.shown || []),
				],
				show: [
					...(modalListeners.show || []),
				],
			}
			return {
				hidden: bindContext.call(this, listeners.hidden),
				hide: bindContext.call(this, listeners.hide),
				shown: bindContext.call(this, listeners.shown),
				show: bindContext.call(this, listeners.show)
			}
		},
		initListeners (defaultListeners = {}) {
			const listeners = this.getListeners(defaultListeners)
			if (this.element) {
				const events = {
					hide: [
						...(listeners.hide || [])
					],
					hidden: [
						...(listeners.hidden || []),
						() => {
							this.isActive = false
						}
					],
					show: [
						...(listeners.show || [])
					],
					shown: [
						...(listeners.shown || []),
						() => {
							this.isActive = true
						}
					]
				}
				for (let event in events) {
					const modalEvent = `${event}.bs.modal`
					this.element.on(modalEvent, (e) => {
						const queue = [
							...events[event],
							() => this.$emit(event, e)
						]
						callQueue(queue)
					})
				}
			}
		},
		show () {
			this.element && this.element.modal('show')
		},
		hide () {
			this.element && this.element.modal('hide')
		}
	}
}