<template>
    <router-link :to="link" class="single-item">
        <div class="single-item__title">{{ title }}</div>
    </router-link>
</template>

<script>
    export default {
        name: 'SingleItem',

        props: {
            link: {
                type: String,
                default: '',
            },

            title: {
                type: String,
                default: '',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .single-item {
        display: block;
        border-bottom: 1px solid #d9e5f6;
        padding: 20px;

        &__title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            color: $dark;
        }
    }
</style>