<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 11.1667L5.91671 15.25C5.76393 15.4028 5.56949 15.4792 5.33337 15.4792C5.09726 15.4792 4.90282 15.4028 4.75004 15.25C4.59726 15.0972 4.52087 14.9028 4.52087 14.6667C4.52087 14.4306 4.59726 14.2361 4.75004 14.0833L8.83337 10L4.75004 5.91668C4.59726 5.7639 4.52087 5.56945 4.52087 5.33334C4.52087 5.09723 4.59726 4.90279 4.75004 4.75001C4.90282 4.59723 5.09726 4.52084 5.33337 4.52084C5.56949 4.52084 5.76393 4.59723 5.91671 4.75001L10 8.83334L14.0834 4.75001C14.2362 4.59723 14.4306 4.52084 14.6667 4.52084C14.9028 4.52084 15.0973 4.59723 15.25 4.75001C15.4028 4.90279 15.4792 5.09723 15.4792 5.33334C15.4792 5.56945 15.4028 5.7639 15.25 5.91668L11.1667 10L15.25 14.0833C15.4028 14.2361 15.4792 14.4306 15.4792 14.6667C15.4792 14.9028 15.4028 15.0972 15.25 15.25C15.0973 15.4028 14.9028 15.4792 14.6667 15.4792C14.4306 15.4792 14.2362 15.4028 14.0834 15.25L10 11.1667Z" fill="#1E293B"/>
    </svg>        
</template>

<script>
    export default {
        name: 'CloseIcon',
    };
</script>