module.exports = {
	polyfill: () => {
		if (!Promise.allSettled) {
			const reflect = promise => {
				return promise
					.then((value) => {
						return {
							status: 'fullfilled',
							value
						}
					}, (reason) => {
						return {
							status: 'rejected',
							reason
						}
					})
			}
			Promise.allSettled = promises => Promise.all(promises.map(reflect))
		}
	}
}