class ProviderHelper {
	constructor(){

	}

	getObjectWithFields(obj){
		let newObj = {};
		for(let o in obj){
			if(obj[o] !== null && obj[o] !== undefined && obj[o] !== ''){
				newObj[o] = obj[o];
			}
		}

		return newObj;
	}
}

export default ProviderHelper;