<template>
    <div class="mobile-sidebar">
        <Transition name="fade">
            <div v-if="sidebarOpen" class="mobile-sidebar__mask"></div>
        </Transition>

        <div class="mobile-sidebar__content" :class="{'_open': sidebarOpen}">
            <SidebarHeader @close="closeSidebar"/>

            <div v-if="enterFrom === 'qr_service'" class="mobile-sidebar__back-button" @click="tryBack">
                Вернуться в QRManager
            </div>

            <div class="mobile-sidebar__items">
                <SidebarDropdownItem
                    v-for="item in content"
                    :content="item"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import SidebarDropdownItem from './components/mobile/SidebarDropdownItem.vue';
    import SidebarHeader from './components/mobile/SidebarHeader.vue';

    export default {
        name: 'MobileSidebar',

        components: {
            SidebarHeader,
            SidebarDropdownItem,
        },

        data() {
            return {
                companyCategories: [],

                content: [
                    {
                        title: 'Мои торговые точки',
                        link: '/organization-admin/places',
                        single: true,
                        items: []
                    },

                    {
                        title: 'Инфо-материалы',
                        single: false,
                        items: [
                            { title: 'Обучите сотрудников', icon: 'TeachIcon', link: '/organization-admin/promote-loyalty/teach' },
                            { title: 'Материалы в торговую точку', icon: 'MaterialsIcon', link: '/organization-admin/promote-loyalty/materials' },
                        ]
                    },

                    {
                        title: 'Лояльность',
                        single: false,
                        items: [
                            { title: 'Показатели лояльности', link: '/organization-admin/loyalty-program', icon: 'StatsIcon' },
                            { title: 'Настройки лояльности', link: '/organization-admin/loyalty-program/settings', icon: 'LoyaltySettingsIcon' },
                        ]
                    },

                    {
                        title: 'Тарифы',
                        single: true,
                        link: '/organization-admin/tariff'
                    },

                    {
                        title: 'Настройки аккаунта',
                        single: false,
                        items: [
                            { title: 'Общие настройки', link: '/organization-admin/settings', icon: 'SettingsIcon', subitems: [] },
                            { title: 'Администраторы', link: '/organization-admin/administrators', icon: 'AdminsIcon', subitems: [] },
                            { title: 'Кассиры', link: '/organization-admin/cashier', icon: 'CashierIcon', subitems: [] },
                            { title: 'Кассовые аппараты', link: '/organization-admin/cash-machines/variants', icon: 'MachinesIcon', subitems: [] },
                            { title: 'Платежный кабинет', link: '/organization-admin/payment-account/balance', icon: 'CabinetIcon', subitems: [] },
                            { title: 'Реквизиты компании', link: '/organization-admin/settings/requisites', icon: 'RequisitesIcon', subitems: [] },
                        ]
                    },

                    {
                        title: 'Сервисы BenefittY',
                        single: false,
                        items: [
                            { title: 'Общие Фото и видео', icon: 'PhotoIcon', link: '/organization-admin/organizations-media', subitems: [] },
                            { title: 'Общие Отзывы', icon: 'ReviewsIcon', link: '', subitems: [ { title: 'Отзывы о торговых точках', link: '/organization-admin/reviews-list' }, { title: 'Отзывы об акциях', link: '/organization-admin/discount/reviews-list' } ] },
                            { title: 'Общие Пуш-уведомления', icon: 'NoticeIcon', link: '/organization-admin/communicate-customers', subitems: [] },
                            { title: 'Общие Скидки/акции', icon: 'DiscountIcon', link: '/organization-admin/promotions-and-discounts', subitems: [] },
                        ]
                    },

                    {
                        title: 'Поддержка',
                        single: true,
                        link: '/organization-admin/support'
                    },
                ],
            };
        },

        watch: {
            sidebarOpen(newVal) {
                document.body.style.overflow = newVal ? 'hidden' : '';
            },
            '$route.path'() {
                this.setSidebarOpen(false);
            },
        },

        computed: {
            ...mapGetters({
                sidebarOpen: 'sidebarOpen',
                currentCompanyId: 'currentCompanyId',
                enterFrom: 'enterFrom',
            }),
        },

        methods: {
            ...mapActions({
                setSidebarOpen: 'setSidebarOpen',
            }),

            closeSidebar() {
                this.setSidebarOpen(false);
            },

            tryBack() {
                const os = navigator.userAgent || navigator.vendor || window.opera;

                if (/android/i.test(os)) {
                    const url = 'intent://qrmanager.ru/#Intent;scheme=https;package=ru.qrs_qr_manager.qrm;end';
                    // window.open(url);
                    window.location.replace(url);
                }

                if (/iPad|iPhone|iPod/.test(os) && !window.MSStream) {
                    window.location.replace("qrmanager://");
                    // const url = 'itms-apps://itunes.apple.com/app/qrmanager/id1599269491';
                    setTimeout(() => {
                        window.location.replace('https://apps.apple.com/us/app/qrmanager/id1599269491');
                    }, 10000);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .mobile-sidebar {
        overflow-y: scroll;

        &__mask {
            z-index: 1000;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(30, 41, 59, 0.4);
        }

        &__content {
            z-index: 1001;
            position: fixed;
            left: 0;
            top: 0;
            width: calc(100% - 20px);
            height: fit-content;
            min-height: 100vh;
            max-height: 100vh;
            overflow-y: scroll;
            background: #fff;
            transform: translate(-100%);
            transition: $default-transition;
            
            &._open {
                transform: translate(0);
            }
        }

        &__items {
            padding: 0 20px;
        }

        &__back-button {
            display: flex;
            margin: 0 auto;
            width: calc(100% - 40px);
            align-items: center;
            justify-content: center;
            color: #9bbe42;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            border-radius: 20px;
            background: transparent;
            border: 1px solid #9bbe42;
            padding: 12px 0;
            transition: $default-transition;

            &:hover {
                background: #9bbe42;
                color: $white;
            }
        }
    }
</style>