<template>
	<svg
		width="186"
		height="33"
		viewBox="0 0 186 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_7678_31891)">
			<path
				d="M27.6552 10.3588C27.9652 10.8183 27.9081 11.4278 27.4839 12.1872C27.0194 13.0263 26.3316 13.7209 25.4967 14.1941C24.6298 14.6779 23.6506 14.925 22.6577 14.9103H15.2422L18.179 9.67188H25.5921C26.6592 9.67188 27.342 9.90165 27.6552 10.3588Z"
				fill="url(#paint0_linear_7678_31891)"
			/>
			<path
				d="M35.5975 3.60067L32.3841 9.196C32.3465 8.73827 32.1896 8.29834 31.9289 7.92C31.2339 6.8917 29.7989 6.37756 27.624 6.37756H16.256L11.4542 14.9111H2.44791C2.02594 14.9117 1.61126 14.8013 1.24547 14.5912C0.879688 14.3811 0.575664 14.0786 0.363913 13.7141C0.152162 13.3495 0.0401335 12.9357 0.0390701 12.5143C0.0380067 12.0928 0.147946 11.6785 0.357854 11.3129L6.16301 1.20756C6.37431 0.841024 6.67845 0.536481 7.04489 0.324517C7.41133 0.112554 7.82716 0.000634118 8.25062 1.39554e-08H33.5075C33.9295 -4.53868e-05 34.3441 0.110686 34.7098 0.3211C35.0755 0.531514 35.3794 0.834221 35.5911 1.19889C35.8028 1.56357 35.9148 1.9774 35.9159 2.39892C35.917 2.82045 35.8072 3.23487 35.5975 3.60067Z"
				fill="url(#paint1_linear_7678_31891)"
			/>
			<path
				d="M17.3546 24.0445H10.1055L13.4608 18.0898H21.5371C23.7316 18.274 24.3255 19.2607 23.3188 21.0501C22.1995 23.0447 20.2115 24.0429 17.3546 24.0445Z"
				fill="url(#paint2_linear_7678_31891)"
			/>
			<path
				d="M38.535 21.6886L32.7323 31.7915C32.5218 32.1587 32.2178 32.4638 31.8512 32.6758C31.4846 32.8879 31.0683 32.9994 30.6447 32.999H5.38296C4.9612 32.9991 4.54683 32.8885 4.18138 32.6782C3.81594 32.4679 3.51226 32.1654 3.3008 31.8009C3.08934 31.4364 2.97752 31.0228 2.97657 30.6016C2.97561 30.1803 3.08555 29.7662 3.29535 29.4008L4.47988 27.3352H15.6619C18.0671 27.3739 20.4425 26.7993 22.5635 25.6657C24.5948 24.5559 26.1097 23.1137 27.1082 21.339C27.8425 20.0215 28.1239 18.9312 27.9257 18.0781H36.4474C36.871 18.0768 37.2874 18.1871 37.6545 18.3981C38.0217 18.609 38.3266 18.913 38.5384 19.2793C38.7502 19.6456 38.8615 20.0614 38.8609 20.4844C38.8603 20.9074 38.7479 21.3228 38.535 21.6886Z"
				fill="url(#paint3_linear_7678_31891)"
			/>
			<path
				d="M53.3516 27.5004V6.38281H63.9046C65.9245 6.38281 67.5259 6.90103 68.7088 7.93748C69.2752 8.41074 69.7284 9.00448 70.0354 9.67527C70.3424 10.3461 70.4954 11.0769 70.4831 11.8144C70.4831 13.3609 69.9423 14.5473 68.8605 15.3735C68.3799 15.7323 67.8421 16.0076 67.2697 16.1875V16.339C67.9787 16.4819 68.6539 16.7581 69.2595 17.153C70.5843 18.0998 71.2467 19.538 71.2467 21.4675C71.272 22.2946 71.1089 23.1167 70.7697 23.8717C70.4306 24.6266 69.9242 25.2949 69.2888 25.8259C67.9836 26.9422 66.1382 27.5004 63.7529 27.5004H53.3516ZM56.8709 14.9824H63.7529C64.7318 14.9824 65.4864 14.7379 66.0167 14.249C66.2856 13.99 66.4958 13.6764 66.633 13.3294C66.7702 12.9823 66.8313 12.6099 66.8121 12.2373C66.8252 11.8906 66.7606 11.5455 66.623 11.227C66.4854 10.9085 66.2782 10.6248 66.0167 10.3966C65.4864 9.93377 64.7318 9.70237 63.7529 9.70237H56.8782L56.8709 14.9824ZM56.8709 24.1833H63.606C66.259 24.1833 67.5846 23.1778 67.583 21.1668C67.5814 19.1558 66.2557 18.1504 63.606 18.1504H56.8782L56.8709 24.1833Z"
				fill="#18D6C5"
			/>
			<path
				d="M76.0118 25.4955C75.2571 24.7544 74.6627 23.8666 74.2653 22.887C73.868 21.9073 73.676 20.8566 73.7015 19.7999C73.7015 17.6929 74.5395 15.6722 76.0311 14.1823C77.5228 12.6925 79.5459 11.8555 81.6554 11.8555C83.7649 11.8555 85.788 12.6925 87.2797 14.1823C88.7713 15.6722 89.6093 17.6929 89.6093 19.7999V20.8559H76.9124C77.0979 22.0207 77.6953 23.0805 78.5962 23.843C79.4523 24.5601 80.5382 24.9454 81.6554 24.9284C83.083 24.9284 84.2251 24.5764 85.0817 23.8724C85.477 23.5819 85.8182 23.2245 86.09 22.8164H89.4552C89.0673 23.741 88.5179 24.5894 87.8326 25.3219C86.1602 26.9727 84.1011 27.7973 81.6554 27.7957C80.6068 27.8166 79.5649 27.6227 78.5943 27.2258C77.6236 26.8289 76.7447 26.2375 76.0118 25.4881M77.0666 18.2917H86.2442C86.0653 17.2968 85.5526 16.3924 84.7905 15.7275C84.0057 15.0235 82.9607 14.6715 81.6554 14.6715C80.5942 14.6579 79.5594 15.0024 78.7186 15.6492C77.8642 16.2988 77.2767 17.2376 77.0666 18.2892"
				fill="#18D6C5"
			/>
			<path
				d="M92.3594 27.4991V12.1137H95.5703V14.2257H95.7245C96.0276 13.7609 96.4104 13.3529 96.8552 13.0206C97.9444 12.2187 99.2663 11.7947 100.619 11.8131C102.556 11.8131 104.059 12.3663 105.13 13.4729C106.2 14.5794 106.736 16.1878 106.738 18.2982V27.4991H103.522V18.6013C103.522 17.2552 103.221 16.2644 102.619 15.6289C102.019 14.9957 101.094 14.678 99.8508 14.678C99.2793 14.6604 98.7104 14.7626 98.181 14.9782C97.6515 15.1938 97.1732 15.5179 96.7769 15.9295C95.9725 16.7655 95.5695 17.8574 95.5679 19.2051V27.4991H92.3594Z"
				fill="#18D6C5"
			/>
			<path
				d="M111.648 25.4955C110.894 24.7544 110.299 23.8666 109.902 22.887C109.505 21.9073 109.313 20.8566 109.338 19.7999C109.338 17.6929 110.176 15.6722 111.668 14.1823C113.159 12.6925 115.183 11.8555 117.292 11.8555C119.402 11.8555 121.425 12.6925 122.916 14.1823C124.408 15.6722 125.246 17.6929 125.246 19.7999V20.8559H112.549C112.735 22.0207 113.332 23.0805 114.233 23.843C115.089 24.5601 116.175 24.9454 117.292 24.9284C118.72 24.9284 119.862 24.5764 120.718 23.8724C121.115 23.5827 121.457 23.2252 121.729 22.8164H125.094C124.706 23.741 124.157 24.5894 123.472 25.3219C121.799 26.9727 119.74 27.7973 117.295 27.7957C116.246 27.8166 115.204 27.6227 114.233 27.2258C113.263 26.8289 112.384 26.2375 111.651 25.4881M112.706 18.2917H121.878C121.7 17.2971 121.189 16.3927 120.427 15.7275C119.642 15.0235 118.597 14.6715 117.292 14.6715C116.231 14.6579 115.196 15.0024 114.355 15.6492C113.501 16.2988 112.913 17.2376 112.703 18.2892"
				fill="#18D6C5"
			/>
			<path
				d="M126.773 14.9824V12.115H128.609V11.5113C128.609 9.86371 129.037 8.59667 129.894 7.71015C130.75 6.82363 131.954 6.38119 133.504 6.38282H139.01V9.25015H133.81C133.115 9.25015 132.61 9.42126 132.295 9.76348C131.98 10.1057 131.822 10.6883 131.82 11.5113V12.115H141.152V27.515H137.938V14.9824H131.82V27.5028H128.609V14.9824H126.773Z"
				fill="#18D6C5"
			/>
			<path
				d="M143.598 14.9828V12.1154H145.433V8.80078H148.647V12.1154H153.387V14.9828H148.647V22.3723C148.647 23.1986 148.803 23.7803 149.119 24.1226C149.435 24.4648 149.941 24.6359 150.634 24.6359H153.541V27.5032H150.328C148.778 27.5032 147.575 27.06 146.718 26.1734C145.861 25.2869 145.433 24.0199 145.433 22.3723V14.9828H143.598Z"
				fill="#18D6C5"
			/>
			<path
				d="M155.07 14.9828V12.1154H156.903V8.80078H160.117V12.1154H164.857V14.9828H160.117V22.3723C160.117 23.1986 160.273 23.7803 160.592 24.1226C160.91 24.4648 161.411 24.6359 162.104 24.6359H165.012V27.5032H161.798C160.248 27.5032 159.045 27.06 158.188 26.1734C157.332 25.2869 156.903 24.0199 156.903 22.3723V14.9828H155.07Z"
				fill="#18D6C5"
			/>
			<path
				d="M165.93 6.38281H170.058L175.717 15.7353H176.177L181.835 6.38281H185.964L177.706 19.6586V27.5004H174.187V19.6586L165.93 6.38281Z"
				fill="#18D6C5"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_7678_31891"
				x1="27.9587"
				y1="12.2923"
				x2="17.7923"
				y2="12.2923"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#18A7C5" />
				<stop offset="0.83" stop-color="#18D6C5" />
				<stop offset="1" stop-color="#18D6C5" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_7678_31891"
				x1="36.2142"
				y1="7.45555"
				x2="7.2937"
				y2="7.45555"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#18A7C5" />
				<stop offset="0.83" stop-color="#18D6C5" />
				<stop offset="1" stop-color="#18D6C5" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_7678_31891"
				x1="12.5993"
				y1="21.0672"
				x2="23.1426"
				y2="21.0672"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#18A7C5" />
				<stop offset="0.83" stop-color="#18D6C5" />
				<stop offset="1" stop-color="#18D6C5" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_7678_31891"
				x1="9.51167"
				y1="25.5435"
				x2="37.1351"
				y2="25.5435"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#18A7C5" />
				<stop offset="0.83" stop-color="#18D6C5" />
				<stop offset="1" stop-color="#18D6C5" />
			</linearGradient>
			<clipPath id="clip0_7678_31891">
				<rect width="186" height="33" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
import BaseIcon from "@/components/baseComponents/icons/BaseIcon";

export default {
	extends: BaseIcon,
};
</script>
