class AuthTokenHelper{
	constructor(){

	}

	checkIsTokenExpired(token, spareTimeUntilExpirationMs=0){
		if(!token){
			new Error('Token not provided');
		}

		let tokenInfo = this.getTokenInfo(token);
		if(new Date().valueOf() > (tokenInfo.exp * 1000 - spareTimeUntilExpirationMs)){
			return true;
		}else{
			return false;
		}
	}

	getTokenInfo(token){
		return JSON.parse(atob(token.split('.')[1]));
	}
}

export default AuthTokenHelper;