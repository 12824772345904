<template>
	<img
		class="base-image"
		@error="onError"
		@load="onLoad"
		:src="source"
		:alt="alt">
</template>

<script>
import lazyImages from "@/utils/lazyImages";

export default {
	props: {
		src: {
			required: false,
			type: String,
			default: lazyImages.places_defaultphoto
		},
		alt: {
			required: false,
			type: [String, Number],
			default: 'image'
		}
	},
	data () {
		return {
			lazyImages
		}
	},
	computed: {
		source () {
			return this.src || ''
		}
	},
	methods: {
		onLoad (e) {
			if (!e.target.src) {
				e.target.src = lazyImages.places_defaultphoto
			}
		},
		onError (e) {
			if (e.target.src !== lazyImages.places_defaultphoto) {
				e.target.src = lazyImages.places_defaultphoto
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.base-image {
	object-fit: cover;
}
</style>