require('@/assets/images/personPlace/checkin-icon.png');
require('@/assets/images/personPlace/photo-icon.png');
require('@/assets/images/personPlace/rating-icon.png');
require('@/assets/images/personPlace/shareinbenefitty-icon.png');

require('@/assets/images/logo-192.png');
require('@/assets/images/logo-512.png');

require('@/assets/images/cashback-icon.png');
require('@/assets/images/cashback-icon-blue.png');
require('@/assets/images/master-card-image.png');
require('@/assets/images/maestro-image.png');
require('@/assets/images/payments-scheme.png');
require('@/assets/images/chain-scheme.png');
require('@/assets/images/cirrus-image.png');
require('@/assets/images/mir-image.png');
require('@/assets/images/appstore-logo.png');
require('@/assets/images/androidstore-logo.png');
require('@/assets/images/gplus-logo-color.png');
require('@/assets/images/facebook-logo-color.png');
require('@/assets/images/youtube-logo-color.png');
require('@/assets/images/instagram-logo-color.png');
require('@/assets/images/ok-logo-color.png');
require('@/assets/images/twitter-logo-color.png');
require('@/assets/images/pinterest-logo-color.png');

require('@/assets/images/logo.png');

require('@/assets/images/play.png');

require('@/assets/images/place_has_delivery@3x.png');
require('@/assets/images/account/place_bankcard_3x.png');
require('@/assets/images/account/place_cashback_3x.png');
require('@/assets/images/account/place_certificat_3x.png');
require('@/assets/images/account/place_confirm_3x.png');
require('@/assets/images/account/place_coupon_3x.png');
require('@/assets/images/account/place_myrating_3x.png');
require('@/assets/images/account/place_friendsrating_3x.png');
require('@/assets/images/account/place_link_3x.png');
require('@/assets/images/account/place_loyaltycard_3x.png');
require('@/assets/images/account/place_paycheck_3x.png');
require('@/assets/images/account/chevron-left.png');
require('@/assets/images/account/non_register.png');
require('@/assets/images/account/user.png');
require('@/assets/images/account/profile-icon.png');
require('@/assets/images/account/lock.png');
require('@/assets/images/account/vk_logo.png');
require('@/assets/images/account/ok_logo.png');
require('@/assets/images/account/google_plus_logo.png');
require('@/assets/images/account/g-logo.png');
require('@/assets/images/account/facebook_logo.png');
require('@/assets/images/account/money_pig.png');
require('@/assets/images/account/monitor_info.png');
require('@/assets/images/account/card_share.png');
require('@/assets/images/account/pencil.png');
require('@/assets/images/account/benefitty_cashback.png');
require('@/assets/images/account/benefitty_rewards.png');
require('@/assets/images/account/bookmarks.png');
require('@/assets/images/account/friends.png');
require('@/assets/images/account/subscribers.png');
require('@/assets/images/account/subscribes.png');
require('@/assets/images/account/find_friends.png');
require('@/assets/images/account/bank_card.png');
require('@/assets/images/account/loyality_card.png');
require('@/assets/images/account/instagram.png');
require('@/assets/images/account/telegram.png');

require('@/assets/images/share/iphone_x_full.png');
require('@/assets/images/share/iphone_x_full_camera.png');
require('@/assets/images/share/quicktip_green.png');
require('@/assets/images/share/places_circle_detail.png');
require('@/assets/images/share/place_infoletter.png');
require('@/assets/images/share/places_bookmark_incircle.png');
require('@/assets/images/share/quicktip_cashback.png');
require('@/assets/images/share/barcode.png');
require('@/assets/images/share/logo.png');
require('@/assets/images/share/like.png');
require('@/assets/images/share/star.png');
require('@/assets/images/share/label_stock.png');
require('@/assets/images/share/arrow_light.png');
require('@/assets/images/share/slider_nav.png');
require('@/assets/images/share/share_icon.png');
require('@/assets/images/share/coin.png');

require('@/assets/images/landing/footer-f.png');
require('@/assets/images/landing/footer-inst.png');
require('@/assets/images/landing/footer-ok.png');
require('@/assets/images/landing/footer-vk.png');
require('@/assets/images/landing/footer-u.png');
require('@/assets/images/landing/footer-telegram.png');
require('@/assets/images/landing/howWork-bg.png');
require('@/assets/images/landing/howWork-download.png');
require('@/assets/images/landing/howWork-finger.png');
require('@/assets/images/landing/howWork-imac.png');
require('@/assets/images/landing/howWork-mouthpiece.png');
require('@/assets/images/landing/icon-google-play.png');
require('@/assets/images/landing/ikonka-zagruzki-appstore.png');
require('@/assets/images/landing/info-check.png');
require('@/assets/images/landing/info-slider-prev.png');
require('@/assets/images/landing/info-slider-next.png');
require('@/assets/images/landing/logoBen.png');
require('@/assets/images/landing/partners-1.png');
require('@/assets/images/landing/partners-2.png');
require('@/assets/images/landing/partners-3.png');
require('@/assets/images/landing/partners-4.png');
require('@/assets/images/landing/partners-5.png');
require('@/assets/images/landing/partners-6.png');
require('@/assets/images/landing/partners-7.png');
require('@/assets/images/landing/partners-8.png');
require('@/assets/images/landing/partners-9.png');
require('@/assets/images/landing/appStore-logo.png');
require('@/assets/images/landing/google-play-logo.png');
require('@/assets/images/landing/qrCodeGoogle.png');
require('@/assets/images/landing/qrCodeApp.png');

require('@/assets/images/landing/nike-logo.png');
require('@/assets/images/landing/braun-logo.png');
require('@/assets/images/landing/alfa-logo.png');
require('@/assets/images/landing/mvideo-logo.png');
require('@/assets/images/landing/icon-msg.png');
require('@/assets/images/landing/icon-grapf.png');
require('@/assets/images/landing/icon-peoples.png');
require('@/assets/images/landing/icon1.png');
require('@/assets/images/landing/icon2.png');
require('@/assets/images/landing/icon3.png');
require('@/assets/images/landing/audience-1.png');
require('@/assets/images/landing/audience-2.png');
require('@/assets/images/landing/audience-3.png');
require('@/assets/images/landing/audience-4.png');
require('@/assets/images/landing/audience-5.png');
require('@/assets/images/landing/audience-6.png');
require('@/assets/images/landing/profile-icon.png');
require('@/assets/images/landing/icon-cashback.png');
require('@/assets/images/landing/icon-position.png');
require('@/assets/images/landing/card-icon.png');

require('@/assets/images/landing/consideration-bg.png');
require('@/assets/images/landing/search-bg.png');

require('@/assets/images/review-star.png');
require('@/assets/images/review-star-active.png');
require('@/assets/images/review-photo.png');
require('@/assets/images/review-photo-cross.png');

require('@/assets/images/reviews/minus.png');
require('@/assets/images/reviews/plus.png');
require('@/assets/images/reviews/star.png');

require('@/assets/images/filters-icon.png');
require('@/assets/images/arrow-down.png');
require('@/assets/images/headerMain/icon-card.png');
require('@/assets/images/headerMain/icon-door.png');
require('@/assets/images/headerMain/icon-pack.png');
require('@/assets/images/headerMain/icon-setings.png');
require('@/assets/images/headerMain/icon-stone.png');
require('@/assets/images/headerMain/menu-cart.png');
require('@/assets/images/headerMain/menu-compas.png');
require('@/assets/images/headerMain/menu-i.png');
require('@/assets/images/headerMain/menu-icon-tiket.png');
require('@/assets/images/headerMain/menu-newspaper.png');
require('@/assets/images/headerMain/menu-benefittycard.png');
require('@/assets/images/headerMain/menu-mydiscount.png');

require('@/assets/images/button-up-page.png');

require('@/assets/images/exchangePoints.png');
require('@/assets/images/exchangeFAQ.png');
require('@/assets/images/message.png');
require('@/assets/images/bank-discount.png');

require('@/assets/images/personPlace/percent-icon.png');
require('@/assets/images/personPlace/percent-icon-orange.png');
require('@/assets/images/personPlace/plus-icon.png');
require('@/assets/images/phone-icon.png');
require('@/assets/images/link-icon.png');
require('@/assets/images/benefittyCard/benefitty-start.png');
require('@/assets/images/benefittyCard/benefitty-silver.png');
require('@/assets/images/benefittyCard/benefitty-gold.png');
require('@/assets/images/benefittyCard/benefitty-premium.png');
require('@/assets/images/chevron-right-black.png');
require('@/assets/images/account/circle-status-start.png');
require('@/assets/images/account/circle-status-silver.png');
require('@/assets/images/account/circle-status-gold.png');
require('@/assets/images/account/circle-status-premium.png');

require('@/assets/images/green-checkmark.png');
require('@/assets/images/red-cross.png');
require('@/assets/images/user-stock.png');

require('@/assets/images/badges/badge-green-long.png');
require('@/assets/images/badges/badge-green-short.png');
require('@/assets/images/badges/badge-red-long.png');
require('@/assets/images/badges/badge-blue-long.png');
require('@/assets/images/badges/badge-orange-long.png');
require('@/assets/images/badges/badge-orange-short.png');
require('@/assets/images/badges/badge-purple-long.png');
require('@/assets/images/badges/badge-pink-long.png');
require('@/assets/images/badges/badge-turquoise-long.png');
require('@/assets/images/bookmark/bookmark-active.png');
require('@/assets/images/bookmark/bookmark-inactive.png');
require('@/assets/images/shops/categories.png');
require('@/assets/images/shops/categories2.png');
require('@/assets/images/shops/categories3.png');
require('@/assets/images/shops/filters.png');
require('@/assets/images/shops/arrow.png');
require('@/assets/images/shops/filter-button.png');
require('@/assets/images/shops/filter-button2.png');
require('@/assets/images/shops/cashback.png');
require('@/assets/images/shops/rating.png');
require('@/assets/images/shops/collections.png');
require('@/assets/images/shops/search.png');
require('@/assets/images/cashBack-selling.jpg');
require('@/assets/images/cashBack-couple.jpg');
require('@/assets/images/robot.png');
require('@/assets/images/sidebarIcons/users.png');

require('@/assets/images/loyalityCard/bank-discount.png');
require('@/assets/images/loyalityCard/coin.png');
require('@/assets/images/loyalityCard/credit-card.png');
require('@/assets/images/loyalityCard/discount.png');
require('@/assets/images/loyalityCard/file.png');
require('@/assets/images/loyalityCard/payment-terminal.png');
require('@/assets/images/loyalityCard/question.png');
require('@/assets/images/loyalityCard/text-file.png');
require('@/assets/images/loyalityCard/pencil.png');
require('@/assets/images/bankCard/empty-placeholder.png');
require('@/assets/images/bankCard/stripe-start.png');
require('@/assets/images/bankCard/stripe-middle.png');
require('@/assets/images/bankCard/stripe-end.png');
require('@/assets/images/loyalityCard/empty-placeholder.png');
require('@/assets/images/loyalityCard/barcode-icon.png');
require('@/assets/images/loyalityCard/gallery-icon.png');
require('@/assets/images/loyalityCard/vk-white-logo.png');
require('@/assets/images/loyalityCard/vk-blue-logo.png');
require('@/assets/images/map-pin.png');
require('@/assets/images/map-marker.png');
require('@/assets/images/companies-benefitty3.png');
require('@/assets/images/companies-benefitty4.png');
require('@/assets/images/companies-benefitty6.png');

require('@/assets/images/homeScreen/info_status.png');
require('@/assets/images/homeScreen/up-status.png');
require('@/assets/images/homeScreen/promotion.png');
require('@/assets/images/homeScreen/benefit-cashback.png');
require('@/assets/images/homeScreen/benefit-friends.png');
require('@/assets/images/homeScreen/benefit-manual.png');
require('@/assets/images/homeScreen/benefit-rewards.png');
require('@/assets/images/cashback-cheque.png');
require('@/assets/images/quicktip_green@3x.png');
require('@/assets/images/imageCarousel/place_allphoto@3x.png');
require('@/assets/images/imageCarousel/profile_photo@3x.png');
require('@/assets/images/favicon.ico');
require('@/assets/images/place_calltoaction_back@3x.png');
require('@/assets/images/commom_info@3x.png');
require('@/assets/images/no_data_image.png');
require('@/assets/images/discounts_sortorder_createdat@3x.png');
require('@/assets/images/company_exchange_icon@3x.png');
require('@/assets/images/company_shop_icon@3x.png');
require('@/assets/images/company_places_icon@3x.png');
require('@/assets/images/company_events_icon@3x.png');
require('@/assets/images/company_discounts_icon@3x.png');
require('@/assets/images/company_cashback_online_icon@3x.png');
require('@/assets/images/company_cashback_offline_icon@3x.png');
require('@/assets/images/company_bank_discounts@3x.png');
require('@/assets/images/places_defaultphoto@3x.png');
require('@/assets/images/transparent_background.jpg');

require('@/assets/images/sidebarIcons/comment.png');
require('@/assets/images/sidebarIcons/document.png');
require('@/assets/images/sidebarIcons/eye.png');
require('@/assets/images/sidebarIcons/notebook.png');
require('@/assets/images/sidebarIcons/photo.png');
require('@/assets/images/sidebarIcons/settings.png');
require('@/assets/images/sidebarIcons/social.png');
require('@/assets/images/sidebarIcons/store.png');
require('@/assets/images/sidebarIcons/tag.png');
require('@/assets/images/sidebarIcons/user_shield.png');
require('@/assets/images/sidebarIcons/users.png');
require('@/assets/images/sidebarIcons/wallet.png');

require('@/assets/images/placeDefaultImage/place.png');
require('@/assets/images/pdf-file-image.png');

require('@/assets/images/file_placeholder.png');
require('@/assets/images/mock_img.png');
require('@/assets/images/mdi_warning-circle-outline.png');

require('@/assets/images/ion_gift-sharp.png');

require('@/assets/images/placeholder/upload/main-settings.png');
require('@/assets/images/loader-logo.png');
require('@/assets/images/user-mock.png');
require('@/assets/images/cshb-m.png');

export default {
    robot: '/src/assets/images/robot.png',
    geo: '/src/assets/images/sidebarIcons/users.png',
    loader_logo: '/src/assets/images/loader-logo.png',
    mdi_warning_circle_outline: '/src/assets/images/mdi_warning-circle-outline.png',

    mock: '/src/assets/images/mock_img.png',
    file_placeholder: '/src/assets/images/file_placeholder.png',

    transparent_background: '/src/assets/images/transparent_background.jpg',
    places_defaultphoto: '/src/assets/images/places_defaultphoto@3x.png',
    company_exchange_icon: '/src/assets/images/company_exchange_icon@3x.png',
    company_shop_icon: '/src/assets/images/company_shop_icon@3x.png',
    company_places_icon: '/src/assets/images/company_places_icon@3x.png',
    company_events_icon: '/src/assets/images/company_events_icon@3x.png',
    company_discounts_icon: '/src/assets/images/company_discounts_icon@3x.png',
    company_cashback_online_icon: '/src/assets/images/company_cashback_online_icon@3x.png',
    company_cashback_offline_icon: '/src/assets/images/company_cashback_offline_icon@3x.png',
    company_bank_discounts: '/src/assets/images/company_bank_discounts@3x.png',
    calendar: '/src/assets/images/discounts_sortorder_createdat@3x.png',
    common_info: '/src/assets/images/commom_info@3x.png',
    no_data_image: '/src/assets/images/no_data_image.png',

    place_calltoaction_back: '/src/assets/images/place_calltoaction_back@3x.png',
    places_in_benefitty: '/src/assets/images/places_in_benefitty.png',
    benefitty_logo: '/src/assets/images/favicon.ico',

    imageCarousel: {
        all_photos: '/src/assets/images/imageCarousel/place_allphoto@3x.png',
        profile_photo: '/src/assets/images/imageCarousel/profile_photo@3x.png',
    },

    rate_refresh: '/src/assets/images/rate-refresh.png',

    photo_icon: '/src/assets/images/personPlace/photo-icon.png',
    checkin_icon: '/src/assets/images/personPlace/checkin-icon.png',
    rating_icon: '/src/assets/images/personPlace/rating-icon.png',
    share_in_benefitty_icon: '/src/assets/images/personPlace/shareinbenefitty-icon.png',

    cashback_cheque: '/src/assets/images/cashback-cheque.png',

    info_status: '/src/assets/images/homeScreen/info_status.png',
    up_status: '/src/assets/images/homeScreen/up-status.png',
    promotion: '/src/assets/images/homeScreen/promotion.png',
    benefit_rewards: '/src/assets/images/homeScreen/benefit-rewards.png',
    benefit_manual: '/src/assets/images/homeScreen/benefit-manual.png',
    benefit_friends: '/src/assets/images/homeScreen/benefit-friends.png',
    benefit_cashback: '/src/assets/images/homeScreen/benefit-cashback.png',

    phone_icon_with_bg: '/src/assets/images/phone-icon-with-bg.png',
    place_has_delivery: '/src/assets/images/place_has_delivery@3x.png',

    barcode: '/src/assets/images/share/barcode.png',
    like: '/src/assets/images/share/like.png',
    star: '/src/assets/images/share/star.png',
    label_stock: '/src/assets/images/share/label_stock.png',
    arrow_light: '/src/assets/images/share/arrow_light.png',
    slider_nav: '/src/assets/images/share/slider_nav.png',
    share_icon: '/src/assets/images/share/share_icon.png',
    coin_share: '/src/assets/images/share/coin.png',

    share_quicktip_cashback: '/src/assets/images/share/quicktip_cashback.png',
    share_places_bookmark_incircle: '/src/assets/images/share/places_bookmark_incircle.png',
    share_place_infoletter: '/src/assets/images/share/place_infoletter.png',
    share_places_circle_detail: '/src/assets/images/share/places_circle_detail.png',
    share_quicktip_green: '/src/assets/images/share/quicktip_green.png',
    share_iphone_x_full: '/src/assets/images/share/iphone_x_full.png',
    share_iphone_x_full_camera: '/src/assets/images/share/iphone_x_full_camera.png',
    share_logo: '/src/assets/images/share/logo.png',

    logo: '/src/assets/images/logo.png',

    cashback_icon: '/src/assets/images/cashback-icon.png',
    cashback_icon_blue: '/src/assets/images/cashback-icon-blue.png',

    master_card_image: '/src/assets/images/master-card-image.png',
    maestro_image: '/src/assets/images/maestro-image.png',
    payments_scheme: '/src/assets/images/payments-scheme.png',
    chain_scheme: '/src/assets/images/chain-scheme.png',
    cirrus_image: '/src/assets/images/cirrus-image.png',
    mir_image: '/src/assets/images/mir-image.png',
    appstore_logo: '/src/assets/images/appstore-logo.png',
    androidstore_logo: '/src/assets/images/androidstore-logo.png',
    twitter_logo_color: '/src/assets/images/twitter-logo-color.png',
    ok_logo_color: '/src/assets/images/ok-logo-color.png',
    gplus_logo_color: '/src/assets/images/gplus-logo-color.png',
    facebook_logo_color: '/src/assets/images/facebook-logo-color.png',
    youtube_logo_color: '/src/assets/images/youtube-logo-color.png',
    instagram_logo_color: '/src/assets/images/instagram-logo-color.png',
    pinterest_logo_color: '/src/assets/images/pinterest-logo-color.png',

    place_bankcard_3x: '/src/assets/images/account/place_bankcard_3x.png',
    place_cashback_3x: '/src/assets/images/account/place_cashback_3x.png',
    place_certificat_3x: '/src/assets/images/account/place_certificat_3x.png',
    place_confirm_3x: '/src/assets/images/account/place_confirm_3x.png',
    place_coupon_3x: '/src/assets/images/account/place_coupon_3x.png',
    place_myrating_3x: '/src/assets/images/account/place_myrating_3x.png',
    place_friendsrating_3x: '/src/assets/images/account/place_friendsrating_3x.png',
    place_link_3x: '/src/assets/images/account/place_link_3x.png',
    place_loyaltycard_3x: '/src/assets/images/account/place_loyaltycard_3x.png',
    place_paycheck_3x: '/src/assets/images/account/place_paycheck_3x.png',
    chevron_left: '/src/assets/images/account/chevron-left.png',
    non_register: '/src/assets/images/account/non_register.png',
    account_user: '/src/assets/images/account/user.png',
    account_profile_icon: '/src/assets/images/account/profile-icon.png',
    account_find_friends: '/src/assets/images/account/find_friends.png',
    account_lock: '/src/assets/images/account/lock.png',
    account_vk_logo: '/src/assets/images/account/vk_logo.png',
    account_vkontakte_logo: '/src/assets/images/account/vk_logo.png',
    account_ok_logo: '/src/assets/images/account/ok_logo.png',
    account_telegram_logo: '/src/assets/images/account/telegram.png',
    account_instagram_logo: '/src/assets/images/account/instagram.png',
    account_google_plus_logo: '/src/assets/images/account/google_plus_logo.png',
    account_google_logo: '/src/assets/images/account/g-logo.png',
    account_facebook_logo: '/src/assets/images/account/facebook_logo.png',
    account_money_pig: '/src/assets/images/account/money_pig.png',
    account_monitor_info: '/src/assets/images/account/monitor_info.png',
    account_card_share: '/src/assets/images/account/card_share.png',
    account_pencil: '/src/assets/images/account/pencil.png',
    account_benefitty_cashback: '/src/assets/images/account/benefitty_cashback.png',
    account_benefitty_rewards: '/src/assets/images/account/benefitty_rewards.png',
    account_bookmarks: '/src/assets/images/account/bookmarks.png',
    account_friends: '/src/assets/images/account/friends.png',
    account_subscribers: '/src/assets/images/account/subscribers.png',
    account_subscribes: '/src/assets/images/account/subscribes.png',
    account_loyality_card: '/src/assets/images/account/loyality_card.png',
    account_bank_card: '/src/assets/images/account/bank_card.png',

    landing_footer_f: '/src/assets/images/landing/footer-f.png',
    landing_footer_inst: '/src/assets/images/landing/footer-inst.png',
    landing_footer_ok: '/src/assets/images/landing/footer-ok.png',
    landing_footer_vk: '/src/assets/images/landing/footer-vk.png',
    landing_footer_u: '/src/assets/images/landing/footer-u.png',
    landing_footer_telegram: '/src/assets/images/landing/footer-telegram.png',
    landing_howWork_bg: '/src/assets/images/landing/howWork-bg.png',
    landing_howWork_download: '/src/assets/images/landing/howWork-download.png',
    landing_howWork_finger: '/src/assets/images/landing/howWork-finger.png',
    landing_howWork_imac: '/src/assets/images/landing/howWork-imac.png',
    landing_howWork_mouthpiece: '/src/assets/images/landing/howWork-mouthpiece.png',
    landing_icon_google_play: '/src/assets/images/landing/icon-google-play.png',
    landing_ikonka_zagruzki_appstore: '/src/assets/images/landing/ikonka-zagruzki-appstore.png',
    landing_info_check: '/src/assets/images/landing/info-check.png',
    landing_info_slider_prev: '/src/assets/images/landing/info-slider-prev.png',
    landing_info_slider_next: '/src/assets/images/landing/info-slider-next.png',
    landing_logoBen: '/src/assets/images/landing/logoBen.png',
    landing_partners_1: '/src/assets/images/landing/partners-1.png',
    landing_partners_2: '/src/assets/images/landing/partners-2.png',
    landing_partners_3: '/src/assets/images/landing/partners-3.png',
    landing_partners_4: '/src/assets/images/landing/partners-4.png',
    landing_partners_5: '/src/assets/images/landing/partners-5.png',
    landing_partners_6: '/src/assets/images/landing/partners-6.png',
    landing_partners_7: '/src/assets/images/landing/partners-7.png',
    landing_partners_8: '/src/assets/images/landing/partners-8.png',
    landing_partners_9: '/src/assets/images/landing/partners-9.png',
    landing_appStore_logo: '/src/assets/images/landing/appStore-logo.png',
    landing_appStore_qr: '/src/assets/images/landing/qrCodeApp.png',
    landing_google_play_logo: '/src/assets/images/landing/google-play-logo.png',
    landing_google_play_qr: '/src/assets/images/landing/qrCodeGoogle.png',

    landing_nike_logo: '/src/assets/images/landing/nike-logo.png',
    landing_braun_logo: '/src/assets/images/landing/braun-logo.png',
    landing_alfa_logo: '/src/assets/images/landing/alfa-logo.png',
    landing_mvideo_logo: '/src/assets/images/landing/mvideo-logo.png',
    landing_icon_msg: '/src/assets/images/landing/icon-msg.png',
    landing_icon_grapf: '/src/assets/images/landing/icon-grapf.png',
    landing_icon_peoples: '/src/assets/images/landing/icon-peoples.png',
    landing_icon_1: '/src/assets/images/landing/icon1.png',
    landing_icon_2: '/src/assets/images/landing/icon2.png',
    landing_icon_3: '/src/assets/images/landing/icon3.png',
    landing_audience_1: '/src/assets/images/landing/audience-1.png',
    landing_audience_2: '/src/assets/images/landing/audience-2.png',
    landing_audience_3: '/src/assets/images/landing/audience-3.png',
    landing_audience_4: '/src/assets/images/landing/audience-4.png',
    landing_audience_5: '/src/assets/images/landing/audience-5.png',
    landing_audience_6: '/src/assets/images/landing/audience-6.png',
    landing_profile_icon: '/src/assets/images/landing/profile-icon.png',
    landing_icon_cashback: '/src/assets/images/landing/icon-cashback.png',
    landing_icon_position: '/src/assets/images/landing/icon-position.png',
    landing_icon_card: '/src/assets/images/landing/card-icon.png',

    review_star: '/src/assets/images/review-star.png',
    review_star_active: '/src/assets/images/review-star-active.png',
    review_photo: '/src/assets/images/review-photo.png',
    review_photo_cross: '/src/assets/images/review-photo-cross.png',

    common_play: '/src/assets/images/play.png',

    reviewMinus: '/src/assets/images/reviews/minus.png',
    reviewPlus: '/src/assets/images/reviews/plus.png',
    reviewStar: '/src/assets/images/reviews/star.png',

    filters_icon: '/src/assets/images/filters-icon.png',
    arrow_down_icon: '/src/assets/images/arrow-down.png',

    logo_192: '/src/assets/images/logo-192.png',
    logo_512: '/src/assets/images/logo-512.png',

    header_icon_card: '/src/assets/images/headerMain/icon-card.png',
    header_icon_door: '/src/assets/images/headerMain/icon-door.png',
    header_icon_pack: '/src/assets/images/headerMain/icon-pack.png',
    header_icon_setings: '/src/assets/images/headerMain/icon-setings.png',
    header_icon_stone: '/src/assets/images/headerMain/icon-stone.png',
    header_menu_cart: '/src/assets/images/headerMain/menu-cart.png',
    header_menu_compas: '/src/assets/images/headerMain/menu-compas.png',
    header_menu_i: '/src/assets/images/headerMain/menu-i.png',
    header_menu_icon_tiket: '/src/assets/images/headerMain/menu-icon-tiket.png',
    header_menu_newspaper: '/src/assets/images/headerMain/menu-newspaper.png',
    header_menu_benefittycard: '/src/assets/images/headerMain/menu-benefittycard.png',
    header_menu_mydiscount: '/src/assets/images/headerMain/menu-mydiscount.png',

    button_up: '/src/assets/images/button-up-page.png',

    percent_icon: '/src/assets/images/personPlace/percent-icon.png',
    percent_icon_orange: '/src/assets/images/personPlace/percent-icon-orange.png',
    plus_icon: '/src/assets/images/personPlace/plus-icon.png',
    phone_icon: '/src/assets/images/phone-icon.png',
    link_icon: '/src/assets/images/link-icon.png',
    benefitty_start: '/src/assets/images/benefittyCard/benefitty-start.png',
    benefitty_silver: '/src/assets/images/benefittyCard/benefitty-silver.png',
    benefitty_gold: '/src/assets/images/benefittyCard/benefitty-gold.png',
    benefitty_premium: '/src/assets/images/benefittyCard/benefitty-premium.png',
    chevron_right_black: '/src/assets/images/chevron-right-black.png',
    circle_status_0: '/src/assets/images/account/circle-status-start.png',
    circle_status_1: '/src/assets/images/account/circle-status-silver.png',
    circle_status_2: '/src/assets/images/account/circle-status-gold.png',
    circle_status_3: '/src/assets/images/account/circle-status-premium.png',
    user_stock: '/src/assets/images/user-stock.png',
    badge_green_long: '/src/assets/images/badges/badge-green-long.png',
    badge_green_short: '/src/assets/images/badges/badge-green-short.png',
    badge_red_long: '/src/assets/images/badges/badge-red-long.png',
    badge_blue_long: '/src/assets/images/badges/badge-blue-long.png',
    badge_orange_long: '/src/assets/images/badges/badge-orange-long.png',
    badge_orange_short: '/src/assets/images/badges/badge-orange-short.png',
    badge_purple_long: '/src/assets/images/badges/badge-purple-long.png',
    badge_pink_long: '/src/assets/images/badges/badge-pink-long.png',
    badge_turquoise_long: '/src/assets/images/badges/badge-turquoise-long.png',
    bookmark_active: '/src/assets/images/bookmark/bookmark-active.png',
    bookmark_inactive: '/src/assets/images/bookmark/bookmark-inactive.png',

    coin: '/src/assets/images/loyalityCard/coin.png',
    credit_card: '/src/assets/images/loyalityCard/credit-card.png',
    discount: '/src/assets/images/loyalityCard/discount.png',
    file: '/src/assets/images/loyalityCard/file.png',
    payment_terminal: '/src/assets/images/loyalityCard/payment-terminal.png',
    question: '/src/assets/images/loyalityCard/question.png',
    text_file: '/src/assets/images/loyalityCard/text-file.png',
    pencil: '/src/assets/images/loyalityCard/pencil.png',
    empty_placeholder: '/src/assets/images/bankCard/empty-placeholder.png',
    stripe_start: '/src/assets/images/bankCard/stripe-start.png',
    stripe_middle: '/src/assets/images/bankCard/stripe-middle.png',
    stripe_end: '/src/assets/images/bankCard/stripe-end.png',
    empty_placeholder_lc: '/src/assets/images/loyalityCard/empty-placeholder.png',
    barcode_icon: '/src/assets/images/loyalityCard/barcode-icon.png',
    gallery_icon: '/src/assets/images/loyalityCard/gallery-icon.png',
    map_pin: '/src/assets/images/map-pin.png',
    map_marker: '/src/assets/images/map-marker.png',
    shops_categories_icon: '/src/assets/images/shops/categories.png',
    shops_categories_icon_2: '/src/assets/images/shops/categories2.png',
    shops_filters_icon: '/src/assets/images/shops/filters.png',
    shops_arrow: '/src/assets/images/shops/arrow.png',
    button_categories_icon: '/src/assets/images/shops/categories3.png',
    button_filter_icon: '/src/assets/images/shops/filter-button.png',
    button_filter_icon2: '/src/assets/images/shops/filter-button2.png',
    shops_cashback_icon: '/src/assets/images/shops/cashback.png',
    shops_rating_icon: '/src/assets/images/shops/rating.png',
    shops_search_icon: '/src/assets/images/shops/search.png',
    collections_icon: '/src/assets/images/shops/collections.png',
    cashBack_selling: '/src/assets/images/cashBack-selling.jpg',
    cashBack_couple: '/src/assets/images/cashBack-couple.jpg',
    bankDiscount: '/src/assets/images/loyalityCard/bank-discount.png',
    vk_white_logo: '/src/assets/images/loyalityCard/vk-white-logo.png',
    vk_blue_logo: '/src/assets/images/loyalityCard/vk-blue-logo.png',

    exchangeImage: '/src/assets/images/exchangePoints.png',
    exchangeFAQ: '/src/assets/images/exchangeFAQ.png',
    message: '/src/assets/images/message.png',
    bank_discount: '/src/assets/images/bank-discount.png',
    cshb_m: '/src/assets/images/cshb-m.png',

    sidebar_comment: '/src/assets/images/sidebarIcons/comment.png',
    sidebar_document: '/src/assets/images/sidebarIcons/document.png',
    sidebar_eye: '/src/assets/images/sidebarIcons/eye.png',
    sidebar_notebook: '/src/assets/images/sidebarIcons/notebook.png',
    sidebar_photo: '/src/assets/images/sidebarIcons/photo.png',
    sidebar_settings: '/src/assets/images/sidebarIcons/settings.png',
    sidebar_social: '/src/assets/images/sidebarIcons/social.png',
    sidebar_store: '/src/assets/images/sidebarIcons/store.png',
    sidebar_tag: '/src/assets/images/sidebarIcons/tag.png',
    sidebar_user_shield: '/src/assets/images/sidebarIcons/user_shield.png',
    sidebar_users: '/src/assets/images/sidebarIcons/users.png',
    sidebar_wallet: '/src/assets/images/sidebarIcons/wallet.png',

    organization_default_place: '/src/assets/images/placeDefaultImage/place.png',
    pdf_file_image: '/src/assets/images/pdf-file-image.png',

    ion_gift_sharp: '/src/assets/images/ion_gift-sharp.png',
    main_settings: '/src/assets/images/placeholder/upload/main-settings.png',
    mock_user: '/src/assets/images/user-mock.png',
};
