import { render, staticRenderFns } from "./RequisitesAccessDeniedModal.vue?vue&type=template&id=5ac70b29&scoped=true"
import script from "./RequisitesAccessDeniedModal.vue?vue&type=script&lang=js"
export * from "./RequisitesAccessDeniedModal.vue?vue&type=script&lang=js"
import style0 from "./RequisitesAccessDeniedModal.vue?vue&type=style&index=0&id=5ac70b29&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ac70b29",
  null
  
)

export default component.exports