import Vue from 'vue'
import InstanceOptions from '@/utils/helpers/InstanceOptions';

const state = () => ({
	stocksCategories: new InstanceOptions()
})

const getters = {
	stocksCategories: state => state.stocksCategories
}

const mutations = {
	SET_STOCKS_CATEGORIES (state, stocks) {
		Vue.set(state, 'stocksCategories', stocks)
	}
}

export default {
	namespaced: true,
	state, getters, mutations
}