<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10.0142 7.60316C10.0142 7.46754 9.96033 7.33747 9.86443 7.24157C9.76853 7.14567 9.63846 7.0918 9.50284 7.0918C9.36722 7.0918 9.23715 7.14567 9.14125 7.24157C9.04535 7.33747 8.99148 7.46754 8.99148 7.60316V9.98952H6.60511C6.46949 9.98952 6.33942 10.0434 6.24352 10.1393C6.14763 10.2352 6.09375 10.3653 6.09375 10.5009C6.09375 10.6365 6.14763 10.7666 6.24352 10.8625C6.33942 10.9584 6.46949 11.0123 6.60511 11.0123H8.99148V13.3986C8.99148 13.5342 9.04535 13.6643 9.14125 13.7602C9.23715 13.8561 9.36722 13.91 9.50284 13.91C9.63846 13.91 9.76853 13.8561 9.86443 13.7602C9.96033 13.6643 10.0142 13.5342 10.0142 13.3986V11.0123H12.4006C12.5362 11.0123 12.6663 10.9584 12.7622 10.8625C12.8581 10.7666 12.9119 10.6365 12.9119 10.5009C12.9119 10.3653 12.8581 10.2352 12.7622 10.1393C12.6663 10.0434 12.5362 9.98952 12.4006 9.98952H10.0142V7.60316Z" fill="#475569"/>
        <path d="M9.5 3C13.642 3 17 6.35795 17 10.5C17 14.642 13.642 18 9.5 18C5.35795 18 2 14.642 2 10.5C2 6.35795 5.35795 3 9.5 3ZM3.02273 10.5C3.02273 12.2179 3.70515 13.8654 4.91988 15.0801C6.1346 16.2948 7.78212 16.9773 9.5 16.9773C11.2179 16.9773 12.8654 16.2948 14.0801 15.0801C15.2948 13.8654 15.9773 12.2179 15.9773 10.5C15.9773 8.78212 15.2948 7.1346 14.0801 5.91988C12.8654 4.70515 11.2179 4.02273 9.5 4.02273C7.78212 4.02273 6.1346 4.70515 4.91988 5.91988C3.70515 7.1346 3.02273 8.78212 3.02273 10.5Z" fill="#475569"/>
    </svg>
</template>

<script>
    export default {
        name: 'HideIcon',
    };
</script>