import Vue from 'vue'
export default {
	namespaced: true,
	state: () => {
		return {
			place: {},
			placeCompany: {}
		}
	},
	getters: {
		place: state => state.place,
		placeCompany: state => state.placeCompany
	},
	mutations: {
		SET_CURRENT_PLACE (state, place) {
			Vue.set(state, 'place', place )
		},
		SET_CURRENT_PLACE_COMPANY (state, company) {
			Vue.set(state, 'placeCompany', company )
		}
	}
}