import {Validator} from 'vee-validate';

Validator.extend('customUrl', {
	validate: value => {
		let regex = new RegExp(/^(https?:\/\/)?([\da-zа-яёЁ\.-]+)\.([a-zа-я\.])([^\s])*\/?[^\s]$/);
		// let regex = new RegExp(/(^|\s)((https?:\/\/)?[\d{1,10}\a-zA-Zа-яА-Я.\d{1,10}\-\_]+(\.[a-zA-Zа-яА-Я]+)+\.?(:\d+)?(\/\S*)?)/);
		return regex.test(value)
	}
});

Validator.extend('customEmail', {
	validate: value => {
		let regex = new RegExp(/^([A-Za-z0-9_-]+\.)*[A-Za-z0-9_-]+@[A-Za-z0-9_-А-Яа-я]+(\.[A-Za-z0-9_-]+)*\.([A-Za-z0-9_-А-Яа-я])*$/);
		return regex.test(value)
	}
});