<template>
	<div id="requisitesAccessDenied">
		<div class="modal fade" id="requisitesAccessDeniedModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Доступ ограничен</h4>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12 col-sm-12 col-12 center-text">
								<p>
									<strong>Внимание! Заполните реквизиты организации. Без заполненных реквизитов, управление личным кабинетом недоступно.</strong>
								</p>
							</div>
						</div>
					</div>
					<div class="modal-footer text-center">
						<router-link data-dismiss="modal" tag="button" class="btn btn-primary" to="/organization/settings/requisites/entity">
							Перейти к заполнению реквизитов
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		name: 'requisitesAccessDenied',
		data: () => ({
			userRole: '',
		}),
		mounted() {
			this.userRole = this.$store.getters.userRole;
		},
	}
</script>
<style scoped>
	.center-text {
		text-align: center;
	}
	.modal-backdrop {
		background-color: red !important;
	}
</style>