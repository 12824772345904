import Vue from 'vue';

export default {
	async created() {
		this.initGlobalInfoModal();
	},

	data() {
		return {
			modalParams: { typeModal: '', errorMessage: '', modalIsActive: false },

			resolvePromise: null,
			rejectPromise: null,
		};
	},
	methods: {
		initGlobalInfoModal() {
			const self = this;

			Vue.prototype.$messageModal = {
				error(message) {
					return self.setError(message);
				},

				alert(modalParams = {}) {
					return self.setAlert(modalParams);
				},

				toast(message, options = {}) {
					return self.setToast(message, options);
				},

				confirm(modalParams = {}, withoutCancelBtn) {
					self.setConfirm(modalParams, withoutCancelBtn);

					return new Promise((resolve, reject) => { 
						self.resolvePromise = resolve;
						self.rejectPromise = reject;
					});
				},
				// TODO выпилить, не используется
				showReasonPlaceDenied(params) {
					return self.setReasonPlaceDenied(params);
				},

				showWrongSegmentError(params) {
					return self.setWrongSegmentModal(params);
				},

				showModeratorDenied(params) {
					return self.setModeratorDenied(params);
				},

				close() {
					self.modalParams = { typeModal: '', errorMessage: '', modalIsActive: false };
				},

				geo() {
					self.showGeolocationModal = true;

					return new Promise((resolve, reject) => { 
						self.resolvePromise = resolve;
						self.rejectPromise = reject;
					});
				}
			};
		},

		setReasonPlaceDenied(params) {
			this.modalParams = {
				typeModal: 'place-denied',
				modalIsActive: true,
				params,
			};
		},

		setWrongSegmentModal(params) {
			this.modalParams = {
				typeModal: 'wrong-segment',
				modalIsActive: true,
				params,
			};
		},

		setModeratorDenied(params) {
			this.modalParams = {
				typeModal: 'moderator-denied',
				modalIsActive: true,
				params,
			};
		},

		setError(message = '') {
			this.modalParams = {
				typeModal: 'error',
				errorMessage: message,
				modalIsActive: true,
			};

			setTimeout(() => {
				this.closeModal();
			}, 3000);
		},

		setConfirm(modalParams, withoutCancelBtn) {
			this.modalParams = {
				typeModal: 'confirm',
				modalParams: {
					...modalParams,
					withoutCancelBtn: withoutCancelBtn,
				},
				modalIsActive: true,
			};
		},

		setAlert(modalParams) {
			this.modalParams = {
				typeModal: 'attention',
				modalParams,
				modalIsActive: true,
			};

			if (modalParams.timeout) {
				setTimeout(() => {
					this.closeModal();
				}, modalParams.timeout);
			}
		},

		setToast(message, opt) {
			this.$toast(message, {
				type: opt.type || 'default',
				timeout: opt.timeout || 2000
			})
		},

		closeModal() {
			this.modalParams = {
				typeModal: '',
				errorMessage: '',
				modalIsActive: false,
			};
		},
	},
};
