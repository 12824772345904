<template>
	<div id="tariffAccessDenied">
		<div class="modal fade" id="tariffAccessDeniedModal" tabindex="-1" role="dialog" aria-labelledby="tariffsPeriodLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{ textOfTariffModal.title }}</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12 col-sm-12 col-12 center-text">
								<p>
									<strong v-html="textOfTariffModal.text"></strong>
								</p>
							</div>
						</div>
					</div>
					<div class="modal-footer text-center">
						<router-link data-dismiss="modal" tag="button" class="btn btn-primary" :to="`/${userRole}/tariffs`">
							Перейти в тарифы
						</router-link>
						<button type="button" class="btn btn-light" data-dismiss="modal">
							Закрыть
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		name: 'tariffAccessDenied',
		computed: {
			userRole() {
				return this.$store.getters.userRole;
			},
			textOfTariffModal() {
				const route = this.$route.path;
				let text = {};
				if (route.includes('statistic')) {
					text.text = 'В некоторых отчетах нет данных из-за ограничений вашего тарифа.<br>Для доступа ко всем данным выберите другой тариф.';
					text.title = 'ВНИМАНИЕ!';
				} else {
					text.text = 'Для получения доступа к функционалу раздела подключите соответствующий тариф';
					text.title = 'Доступ ограничен';
				};
				return text;
			}
		}
	}
</script>
<style scoped>
	.center-text {
		text-align: center;
	}
	.modal-backdrop {
		background-color: red !important;
	}
</style>