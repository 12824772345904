<template>
	<div class="w-100 text-center loader" :class="{ 'loader-active': isLoading }">
		<i class="fa fa-spinner spinner"></i>
	</div>
</template>

<script>
export default {
	props: {
		isLoading: {
			type: Boolean,
			required: false,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
.loader {
	opacity: 0;
	transition: 0.2s opacity;
	height: 0;
	&-active {
		padding-top: 1rem;
		margin: 0.5rem 0;
		opacity: 1;
		height: auto;
	}
}
</style>