import VueMain from '@/main.js';
import Vue from 'vue';
import userProvider from "@/utils/providers/userProvider";

export const changeBackpaths = ({ commit }, backpaths) => {
	commit('changeBackpaths', backpaths);
};

export const changeAuthentificationStatus = ({ commit }, user) => {
	commit('changeAuthentificationStatus', user);
};

export const checkNewsfeed = ({ commit }, count) => {
	commit('checkNewsfeed', count);
};

export const changeUserResetPasswordState = ({ commit }, userResetPassword) => {
	commit('changeUserResetPasswordState', userResetPassword);
};

export const setUserRole = ({ commit }, userRole) => {
	commit('changeUserRole', userRole);
};

export const referralCode = ({ commit }, code) => {
	commit('referralCode', code);
};

export const pdcCode = ({ commit }, code) => {
	if (code)
		Vue.ls.set('pdcCode', code)
	else
		Vue.ls.remove('pdcCode')
	commit('pdcCode', code);
};

export const changeUserRole = ({ commit }, userRole) => {
	VueMain.$ls.set('userRole', userRole);

	commit('changeUserRole', userRole);
};

export const changeIsModerator = ({ commit }, { context, isModerator }) => {
	if (isModerator) {
		context.$ls.set('isModerator', isModerator);
	}

	commit('changeIsModerator', isModerator);
};

export const changeModeratorLink = ({ commit }, moderatorLink) => {
	commit('changeModeratorLink', moderatorLink);
};

export const googleMapsInitialized = ({ commit }) => {
	commit('googleMapsInitialized');
};

export const setCompanyAbilities = ({ commit }, companyAbilities) => {
	commit('setCompanyAbilities', companyAbilities);
};

export const setUserProfile = ({ commit }, userProfile) => {
	commit('setUserProfile', userProfile);
	if (userProfile) {
		Vue.ls.get('currentCompanyId') ? commit('setCurrentCompanyId', Vue.ls.get('currentCompanyId')) : userProfile.administrated_companies[0] ? commit('setCurrentCompanyId', userProfile.administrated_companies[0].id) : null;
	}
};

export const setEnterFrom = ({ commit }, enterFrom) => {
	commit('SET_ENTER_FROM', enterFrom);
	Vue.ls.set('enterFrom', enterFrom);
};

export const setContent = ({ commit }, content) => {
	commit('setContent', content);
};

export const setContentProperty = ({ commit }, { propertyName, contentProperty }) => {
	commit('setContentProperty', { propertyName, contentProperty });
};

export const deleteContentProperty = ({ commit }, propertyName) => {
	commit('deleteContentProperty', propertyName);
};

export const setAuthorizationModalSettings = ({ commit, state }, authorizationModalSettings) => {
	if ((authorizationModalSettings.isLoginForm || authorizationModalSettings.isRegistrationForm) && !state.user?.authenticated && !state.user?.token) {
		window.location.href = `/?from=app&params=${JSON.stringify(authorizationModalSettings)}`;
	}
	
	// commit('setAuthorizationModalSettings', authorizationModalSettings);
};

export const setCurrentCompanyId = ({ commit }, companyId) => {
	commit('setCurrentCompanyId', companyId);

	Vue.ls.set('currentCompanyId', companyId);
};

export const setCurrentBrandId = ({ commit }, brandId) => {
	commit('setCurrentBrandId', brandId);
};

export const setCompany = ({ commit }, company) => {
	commit('setCompany', company);
};

export const setCompanies = ({ commit }, companies) => {
	commit('setCompanies', companies);
};

export const setAdministratedCompanies = ({ commit }, administratedCompanies) => {
	commit('setAdministratedCompanies', administratedCompanies);
};

export const setIsPlaceAdministrator = ({ commit }, isPlaceAdministrator) => {
	commit('setIsPlaceAdministrator', isPlaceAdministrator);
};

export const setIsVKMiniApp = ({ commit }, isVKMiniApp) => {
	commit('setIsVKMiniApp', isVKMiniApp);
};

export const setIsShowBanner = ({ commit }, isShowBanner) => {
	commit('setIsShowBanner', isShowBanner);
};

export const setVKMiniAppPlatform = ({ commit }, platform) => {
	commit('setVKMiniAppPlatform', platform);
	commit('setIsVKMiniApp', true);
};

export const setHeaderRouteName = ({ commit }, headerRouteName) => {
	commit('setHeaderRouteName', headerRouteName);
};

export const setIsGeopositionAllowed = ({ commit }, isGeopositionAllowed) => {
	commit('setIsGeopositionAllowed', isGeopositionAllowed);
};

export const setGeoposition = ({ commit }, geoposition) => {
	commit('setGeoposition', geoposition);
};

export const setIsRouteLoading = ({ commit }, isRouteLoading) => {
	commit('setIsRouteLoading', isRouteLoading);
};

export const setPlacesCount = ({ commit }, placesCount) => {
	commit('setPlacesCount', placesCount);
};

export const setErrorText = ({ commit }, errorText) => {
	commit('setErrorText', errorText);
};

export const setVKUserInfo = ({ commit }, VKUserInfo) => {
	commit('setVKUserInfo', VKUserInfo);
};

export const setPersonStock = ({ commit }, personStock) => {
	commit('setPersonStock', personStock);
};

export const setVkLoginModalSettings = ({ commit }, vkLoginModalSettings) => {
	commit('setVkLoginModalSettings', vkLoginModalSettings);
};

export async function fetchUserDiscountCards({ commit }, context) {
	try {
		const params = {
			only_my: true
		}
		const data = await userProvider.getDiscountCards(context, params)
		commit('SET_USER_DISCOUNT_CARDS', data)
	} catch (error) {
		console.error(error)
	}
}

export const setMarkets = ({ commit }, markets) => {
	commit('setMarkets', markets);
};

export const setCountAttempts = ({ commit }) => {
	commit('SET_COUNT_ATTEMPTS');
};

export const setToken = ({ commit }, token) => {
	commit('SET_TOKEN', token);
};

export const setLoyaltyProgramCompany = ({ commit }, company) => {
	commit('SET_LOYALTY_COMPANY', company);
};

export const setLoyaltyProgramUser = ({ commit }, user) => {
	commit('SET_LOYALTY_USER', user);
};

export const setDoneSteps = ({ commit }, steps) => {
	if (steps.length)
		Vue.ls.set('doneSteps', steps.join(','))
	commit('SET_DONE_STEPS', steps);
};

export const setSidebarOpen = ({ commit }, payload) => {
	commit('SET_SIDEBAR_OPEN', payload);
};

export const setBenefittySteps = ({ commit }, steps) => {
	if (steps.length) {
		commit('SET_BENEFITTY_STEPS', steps);
	}
}

export const setMainInfoIsLoad = ({ commit }, payload) => {
	commit('SET_MAIN_INFO_IS_LOAD', payload);
}

export const setRefreshTokenException = ({ commit }, payload) => {
	commit('SET_REFRESH_TOKEN_EXCEPTION', payload);
}