import Popup from '@/utils/popup.js';

export default{
	methods: {
		initModerationRequestSentListener: function(){
			document.addEventListener('moderationRequestSent', this.onModerationRequestSent);
		},
		onModerationRequestSent: function(data){
			new Popup({
				id: 'successPopup', 
				classIconList: ['fa', 'fa-check'], 
				text: data.detail, 
				slide: 'rightBottom'
			});
		},
		dispatchModerationRequestSent: function(text){
			if(!this.$store.getters.isModerator){	
				var event = new CustomEvent('moderationRequestSent', { detail: text })
				document.dispatchEvent(event, text)
			}
		}
	}
}