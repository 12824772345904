import Vue from 'vue';
import Router from 'vue-router';
import bankRoutes from './bank.js';
import organizationRoutes from './organization.js';
import cashbackRoutes from './cashback.js';
import brandRoutes from './brand.js';
import personRoutes from './person.js';
import importPageFiles from './importPageFiles.js';
import RouterHelper from '@/router/routerHelper.js'

const routerHelper = new RouterHelper();

const LoginVk = importPageFiles.LoginVk;
const LoginOk = importPageFiles.LoginOk;

const Share = importPageFiles.Share;

const BlogFeed = importPageFiles.BlogFeed;
const BlogPost = importPageFiles.BlogPost;
const Lifehack = importPageFiles.Lifehack;
const Lifehacks = importPageFiles.Lifehacks;

import store from '@/store';

import consts from '@/utils/consts';
import authProvider from '@/utils/providers/authProvider';

Vue.use(Router);

var commonRoutes = [
	{
		path: '*',
		name: '404',
		beforeEnter: routerHelper.beforePersonEnter,
	},
	{
		path: '/404',
		name: '404-error',
		beforeEnter: routerHelper.beforePersonEnter,
	},
	{
		path: '/',
		name: 'personMain',
		meta: {
			isRootPage: true,
		},
		beforeEnter: routerHelper.beforePersonEnter,
	},
	{
		path: '/logInVk/',
		name: 'loginVk',
		component: LoginVk,
	},
	{
		path: '/logInOk/',
		name: 'logInOk',
		component: LoginOk,
	},
	{
		path: '/instagram-permission',
		redirect: to => {
			/** сюда будет редиректить инстаграм после успешной аутентификации, с параметрами @code и @state
			 *  code мы передадим, а state используем для определения страницы на которую редиректить и тоже передадим
			 *  state по замыслу - JSON
			 *   http://localhost:8080/instagram-permission?code=AQBJS-HvF9LwUl8gvrUredwBEeDkF6wXWT807185VHoM3uCiZZd1yBAzU2t-LefRmVY6-uiUcZ5iWeYjVLUeUOQOdkzRMGwdz2aRVBcL_2Z25R-WJ91J4dN544blLtQgLvjUPFLXcd7pi-MQo370eBdFS8FP6F3ArM0uowlu09KwH_djIszvU20C5qXvIR8vAS6ah4mWYX8TWUX0IVZEw1APDHMa2RQSGHk8o4X4_QK5QQ&state=%7B%22redirect%22%3A%22%2Fmoderator%2Forganizations%2F767142829%2Forganization%2Fplace%2F446942212%2Fphotos-and-videos%3Fcode%3DAQCLc40MasmV5DNa93cqc3fo6XyCRzOKZDPGgNY-RuvspmnrB8kPLI9lbrRUoxAVAiUs4-PF8WxvJ4frJm7R02wPy5MLkOOBhIeP0tGV470m_bzyXh0x7e_VRqM2ptQSisD5bgs_8JYvawnLS3_QH2qPGfHN90cg3EugMgE0NvFVOouw4vVzafNRmFC4sVwYLMbyUG6lNcISmF4vqp94ea0XZNVcE2t5TamTRJH8ZDTqeQ#_
			 */
			console.log(to.query)
			try {
				let options = JSON.parse(to.query.state)
				return { path: options.redirect, query: { ...options, instagramCode: to.query.code } }
			} catch (e) {
				return { path: '/', query: null }
			}
		}
	},
	{
		path: '/share/:shareName',
		name: 'share',
		component: Share,
		props: true
	},
	{
		path: '/share/user/:userId',
		name: 'shareUser',
		component: Share,
		props: true
	},
	{
		path: '/blog',
		name: 'blogFeed',
		component: BlogFeed,
	},
	{
		path: '/post/:id',
		name: 'blogPost',
		component: BlogPost,
		props: true
	},
	{
		path: '/lifehack/:id',
		name: 'lifehack',
		component: Lifehack,
		props: true
	},
	{
		path: '/lifehacks',
		name: 'lifehacks',
		component: Lifehacks,
		props: true
	}
];

let routes = commonRoutes.concat(bankRoutes, brandRoutes, organizationRoutes, cashbackRoutes, personRoutes);
// let commonRoutesNames = commonRoutes.map((item)=>item.name)

const router = new Router({
	mode: 'history',
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		// if(commonRoutesNames.includes(to.name))
		return { x: 0, y: 0 }
	}
});

/**
 *
 * @param { string } path
 */
function setAppUrlByPath(path) {
	if (process.env.NODE_ENV !== 'developmentProduction' && path.includes('web-view')) {
		Vue.http.options.root = consts.openApiAppUri
	} else {
		Vue.http.options.root = consts.appUri
	}
}

/**
 *
 * @param { Route } route
 * @returns { PartnerCredentials }
 */
function getRouteCredentials(route) {
	return {
		client_id: route.query.client_id || '',
		client_secret: route.query.client_secret || '',
		ext_user_token: route.query.ext_user_token || '',
		scope: route.query.scope,
		grant_type: route.query.grant_type || 'test_bank_token',
		bank_card_id: route.query.bank_card_id || route.params.bankCardId
	}
}

/**
 * @param { Route } route
 * @returns {Promise<void>}
 */
async function authorizePartner(route) {
	const credentials = getRouteCredentials(route)
	await authProvider.setUserPartner(credentials)
}

router.beforeEach(async (to, from, next) => {
	// if (!to.path.includes('/person/') && !['production', 'development'].includes(process.env.NODE_ENV) && !to.path.includes('vk_app_id')) {
	// 	window.location.href = to.path;
	// }

	let windowOpened = false

	try {
		setAppUrlByPath(to.path)
		const user = store.getters.user
		if (to.path.includes('web-view')) {
			if (!(user.type === 'partner' && user.authenticated)) {
				await authorizePartner(to)
			}
		} else if (store.getters.userProfile && !Boolean(store.getters.userProfile.id)) {
			const token = Vue.ls.get('token');
			const refreshToken = Vue.ls.get('refreshToken');
			let tokenForFetch = null;
			if (!token && refreshToken) {
				tokenForFetch = refreshToken;
			}
			if (token) {
				tokenForFetch = token;
			}
			await authProvider.setUserBasic(tokenForFetch)
		}

		store.commit('setMetaData', { field: 'title', value: null })

		if (store.getters['partner/partner']) {
			if (!to.path.includes('web-view')) {
				const partnerLinkTargetOption = store.getters['partner/partner'].links.target
				if (partnerLinkTargetOption === '_blank' && (from.fullPath !== '/' && from.path !== to.path)) {
					window.open(to.fullPath)
					windowOpened = true
				}
			}
		}
	} catch (error) {
		console.error(error)
	} finally {
		if (!windowOpened) {
			next()
		}
	}
})

export default router;