import organizationProvider from '@/utils/providers/organizationProvider.js';

export default {
	data: () => ({
		isBlockedByRequisites: false,
		allowedRoutes: ['organizationSteps', 'organizationRequisitesEntity', 'organizationRequisitesIndividualEntrepreneur', 'organization']
	}),
	watch: {
		'$route.path'() {
			this.checkRequisites();
		}
	},
	computed: {
		userRole() {
			return this.$store.getters.userRole;
		},
		currentCompanyId() {
			return this.$store.getters.currentCompanyId;
		}
	},
	methods: {
		checkRequisites() {			
			if (this.$store.getters.isModerator || this.userRole != 'organization') {
				return;
			}
			if (this.allowedRoutes.some(route => route === this.$route.name)) {
				return;
			}
			if (this.$route.path.split('/').some(el => el === 'organization')) {
				organizationProvider.getCompanyRequisite(this, this.currentCompanyId)
					.then((data) => {
						if (data.isIndividualBiz === null) {
							this.isBlockedByRequisites = true;
							$('#requisitesAccessDeniedModal').modal('show');
						}
					})
			}
		},
	}
}