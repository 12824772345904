import { debounce } from "lodash";
import consts from "@/utils/consts";
import store from "@/store";
import router from "@/router";
import { WorkTimeHelper } from './WorkTimeHelper'
import { getElementBrightness, isElementBright, getThemeStyles } from "@/utils/helpers/colorHelper";
import { getTheme } from "@/utils/themes";
import lazyImages from '@/utils/lazyImages.js'

const momentTimezone = require('moment-timezone')

export async function asyncForEach(array, callback) {
	for (let i = 0; i < array.length; i++) {
		await callback(array[i], i, array)
	}
}

/**
 *
 * @param { AuthorizationModalOptions } options
 * @param { Function|null } callback
 */
export function authorizedCall(callback = null, options = {}) {
	try {
		const currentRoute = router.currentRoute
		const params = {
			isLoginForm: true,
			isRegistrationForm: false,
			role: 'person',
			routeTo: currentRoute,
			callback,
			...options
		}

		if (!store.getters.userProfile || store.getters.userProfile.full_name === " ") {
			store.commit('setAuthorizationModalSettings', params);
		} else if (callback) {
			callback()
		}
	} catch (error) {
		console.error(error)
	}
}

export function optional(object, path, callback) {
	const parts = path.split('.')
	let result = null

	let currentValue = object
	let index = 0
	while (currentValue) {
		const key = parts[index]
		currentValue = currentValue[key]
		if (index === parts.length - 1) {
			result = currentValue
			break
		}
		index++
	}

	if (result && callback) {
		return callback(result)
	}

	return result
}

export function isBlockedService(service) {
	return consts.blockedServices.includes(service)
}
export function arrayUnique(array) {
	return Array.from(new Set(array))
}

export function isEmpty(value) {
	return value && Array.isArray(value) && value.length > 0
}

export function getWebViewUrl(path, options) {
	const webViewUrls = options.webViewUrls || consts.webViewRoutes
	const route = optional(webViewUrls, path)
	if (route) {
		route.params = { ...route.params, ...options.params }
		route.query = { ...route.query, ...options.query }
	}
	return route
}

/**
 * @param { URLSearchParams } params
 * @returns { Object }
 */
export function urlParamsToObject(params) {
	const entries = params.entries()
	const result = {}
	for (const [key, value] of entries) {
		result[key] = value;
	}
	return result
}

export const average = arr => arr.reduce((a, b) => Number(a) + Number(b), 0) / arr.length;

/**
 * @param { DiscountCard|LoyaltyProgram } card
 * @returns {Image}
 */
export function getCardImage(card) {
	if (card.primary_image) {
		return { url: card.primary_image }
	} else {
		return card.default_image || card.front_side || card.back_side || { url: card.primary_image } || { url: '' }
	}
}

/**
 * @param { DiscountCard|LoyaltyProgram } card
 * @returns {?string}
 */
export function getCardStyle(card) {
	const cardImage = getCardImage(card)
	if (cardImage) {
		if (cardImage.url) {
			return `background-image: url(${cardImage.url})`;
		}
	}
	return `background-image: url(${lazyImages.places_defaultphoto})`;
}


/**
 * @param { Array<Function> } queue
 */
export function callQueue(queue) {
	queue.forEach(fun => {
		if (typeof fun === 'function') {
			fun()
		}
	})
}

export function createId() {
	return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
}

export function arrayFillOrAdd(arr, elements) {
	if (Array.isArray(arr)) {
		arr.push(...elements)
	} else {
		arr = elements
	}

	return arr
}

export function toCurrentTimezone(datetime) {
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	console.log({ timezone })
	const val = momentTimezone.utc(datetime)
	return momentTimezone(val, 'YYYY-MM-DD HH:mm:SS').tz(timezone).format('YYYY.MM.DD HH:mm')
}

export default {
	createId,
	optional,
	debounce,
	arrayUnique,
	asyncForEach,
	average,
	authorizedCall,
	isBlockedService,
	isEmpty,
	getWebViewUrl,
	urlParamsToObject,
	getElementBrightness,
	getThemeStyles,
	isElementBright,
	getTheme,
	getCardStyle,
	getCardImage,
	toCurrentTimezone,
	workTimeHelper: new WorkTimeHelper()
}