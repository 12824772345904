import {authorizationModalSettings} from "@/store/getters";

export default {
	computed:{
		authOptions () {
			return this.$store.getters.authorizationModalSettings
		},
		isLoginForm: function(){
			return this.$store.getters.authorizationModalSettings.isLoginForm;
		},
		isRoleChoose: function(){
			return this.$store.getters.authorizationModalSettings.isRoleChoose;
		},
		isRegistrationForm: function(){
			return this.$store.getters.authorizationModalSettings.isRegistrationForm;
		},
		authorizationModalRole: function(){
			return this.$store.getters.authorizationModalSettings.role;
		},
		isClosable: function(){
			return this.$store.getters.authorizationModalSettings.isClosable;
		},
	},
	methods:{
		showModalLogIn: function(role='person'){
			this.$store.dispatch('setAuthorizationModalSettings',
			{
				isLoginForm: true,
				isRegistrationForm: false,
				role: role,
				routeTo: '',
				opacity: 0.8,
			});
		},
		showModalRegistration: function(role){
			this.$store.dispatch('setAuthorizationModalSettings',
			{
				isLoginForm: false,
				isRegistrationForm: true,
				role: role,
				routeTo: '',
				opacity: 0.8,
			});
		},
	}
}