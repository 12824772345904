import importPageFiles from './importPageFiles.js'
import RouterHelper from '@/router/routerHelper.js'

const routerHelper = new RouterHelper();

const Cashback = importPageFiles.Cashback;
const CashbackShops = importPageFiles.CashbackShops;
const CashbackShop = importPageFiles.CashbackShop;
const CashbackWallet = importPageFiles.CashbackWallet;
const CashbackShopRedirect = importPageFiles.CashbackShopRedirect;
const CashbackWalletHistory = importPageFiles.CashbackWalletHistory;
const CashbackRewardsHistory = importPageFiles.CashbackRewardsHistory;

export default [
{
  path: '/cashback',
  name: 'cashback',
  component: Cashback,
  beforeEnter: routerHelper.beforePersonEnter,
  children:[
  {
    path: '/cashback/shops',
    name: 'cashbackShops',
    component: CashbackShops,
  },
  {
    path: '/cashback/shops/category/:categoryId',
    name: 'cashbackShopsCategory',
    component: CashbackShops
  },
  {
    path: '/cashback/shop/:shopId/redirect',
    name: 'cashbackShopRedirect',
    component: CashbackShopRedirect
  },
  {
    path: '/cashback/shop/:shopId',
    name: 'cashbackShop',
    component: CashbackShop
  },
  {
    path: '/cashback/wallet',
    name: 'cashbackWallet',
    component: CashbackWallet
  },
  {
    path: '/cashback/wallet/filter/:filter/page/:page',
    name: 'cashbackWalletFilter',
    component: CashbackWallet
  },
  {
    path: '/cashback/wallet/page/:page',
    name: 'cashbackWalletPages',
    component: CashbackWallet
  },
  {
    path: '/cashback/wallet/page/:page/sort/:col/:dir',
    name: 'cashbackWalletSortable',
    component: CashbackWallet
  },
  {
    path: '/cashback/wallet/filter/:filter/page/:page/sort/:col/:dir',
    name: 'cashbackWalletFull',
    component: CashbackWallet
  },
  {
    path: '/cashback/wallet/history',
    name: 'cashbackWalletHistory',
    component: CashbackWalletHistory
  },
  {
    path: '/cashback/rewards-history',
    name: 'cashbackRewardsHistory',
    component: CashbackRewardsHistory,
  },
  ]
}
]
