<template>
	<div id="roleChoose">
		<div class="modal fade" id="roleChooseModal" tabindex="-1" role="dialog" aria-labelledby="roleChooseLabel" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Выберите бизнес аккаунт</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<div v-for="company in administratedCompanies" :key="`orgAdmRepresentative-${company.id}`">
								<div v-for="role in company.current_user_roles" class="form-group__element" @click="logInAsAdministrator(company.id)">
									<img :src="getImage(company)"/>
									<button v-if="administratedCompanies.length != 0">
										Войти как {{ role.role ? role.role.toLowerCase() : 'представитель' }} {{ company.name }}
									</button>
								</div>
							</div>
							<p class="form-group__element">
								<IconPersonAuth fill="white"/>
								<button @click="makeRequest">
									Подать заявку на администрирование другой
									<br> организации
								</button>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import userProvider from '@/utils/providers/userProvider.js';
	import consts from '@/utils/consts.js';
	import lazyImages from "@/utils/lazyImages";
	import IconPersonAuth from "@/components/baseComponents/icons/IconPersonAuth.vue";

	export default {
		name: 'roleChoose',
		components: { IconPersonAuth },
		data() {
			return {
				// companies: this.$ls.get('companies'),
				// administratedCompanies: this.$ls.get('administratedCompanies')
				lazyImages: lazyImages
			}
		},
		computed: {
			companies() {
				return this.$store.getters.companies;
			},
			administratedCompanies() {
				return this.$store.getters.administratedCompanies;
			}
		},
		// mounted(){
		// 	$('#roleChooseModal').on('show.bs.modal', async () => {
		// 		await userProvider.getCurrentUser(this);
		// 		this.companies = this.$ls.get('companies');
		// 	});
		// },
		methods: {
			checkExternal() {
				let isHas = false;
				this.$store.getters.companies.forEach(company => {
					if (company.external_source) {
						isHas = true;
					}
				});

				return isHas;
			},
			logInAsOrganization: function(companyId){
				userProvider.setUserRole(this, 'organization');
				this.$ls.set('currentCompanyId', companyId);
				this.$store.dispatch('setCurrentCompanyId', companyId);
				this.$emit('close');
				$('#roleChooseModal').modal('hide');
				this.$ls.set('isPlaceAdministrator', false);
				this.$store.dispatch('setIsPlaceAdministrator', false);

				if (this.checkExternal()) {
					return window.location.href = '/organization-admin';
				}

				if (!this.$ls.get('firstEntry')) {
					// this.$ls.set('firstEntry', true);
					this.$router.push('/organization');
				} else {
					this.$router.push('/organization');
				}
			},
			logInAsBank: function(companyId){
				userProvider.setUserRole(this, 'bank');
				this.$ls.set('currentCompanyId', companyId);
				this.$store.dispatch('setCurrentCompanyId', companyId);
				this.$ls.set('isPlaceAdministrator', false);
				this.$store.dispatch('setIsPlaceAdministrator', false);
				$('#roleChooseModal').modal('hide');

				this.$router.push('/bank');
			},
			logInAsBrand: function(companyId, brandId){
				userProvider.setUserRole(this, 'brand');
				this.$ls.set('currentCompanyId', companyId);
				this.$store.dispatch('setCurrentCompanyId', companyId);
				this.$ls.set('currentBrandId', brandId);
				this.$store.dispatch('setCurrentBrandId', brandId);
				this.$ls.set('isPlaceAdministrator', false);
				this.$store.dispatch('setIsPlaceAdministrator', false);
				$('#roleChooseModal').modal('hide');
				this.$router.push('/brand/statistic/business');
			},
			logInAsAdministrator: function(companyId){
				userProvider.setUserRole(this, 'organization');
				this.$ls.set('currentCompanyId', companyId);
				this.$store.dispatch('setCurrentCompanyId', companyId);
				this.$ls.set('isPlaceAdministrator', true);
				this.$store.dispatch('setIsPlaceAdministrator', true);
				$('#roleChooseModal').modal('hide');

				return window.location.href = '/organization-admin';
			},
			makeRequest: function(){
				userProvider.setUserRole(this, consts.userRole.person);
				$('#roleChooseModal').modal('hide');

				this.$router.push('/organization/place-search/owner-registration');
			},
			getImage(company) {
				if (company && company.logo && company.logo.url) {
					return company.logo.url;
				}

				return lazyImages.mock_user;
			},
		}
	}
</script>
<style scoped lang="scss">
.form-group {
	display: flex;
	flex-direction: column;

	&__element {
		padding: 12px;
		display: flex;
		align-items: center;
		gap: 16px;
		background-color: $light-blue;
		cursor: pointer;
		border-radius: 12px;
		margin-bottom: 12px;
		overflow: hidden;
		transition: box-shadow 0.3s linear;

		&:hover {
			box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
		}

		svg {
			width: 48px;
			height: 48px;
			background-color: $dark;
			border-radius: 50%;
			padding: 12px;
		}

		img {
			max-width: 48px;
			height: auto;
			border-radius: 50%;
		}

		button {
			font-size: 16px;
			line-height: 20px;
			font-weight: 500;
			color: $dark;
			border: none;
			outline: none;
			margin: 0;
			padding: 0;
			max-width: 220px;
			text-align: left;
			background: transparent;
		}
	}
}
.btn{
	white-space: normal;
}
</style>