function bind (el, binding) {
	el.classList.add('position-relative')
	componentUpdated(el, binding)
}

function findLoader (el) {
	return [ ...el.children ].find(child => child.id === 'loader')
}

function createLoader (el, binding) {
	const loader = document.createElement('div')
	loader.id = 'loader'

	const loaderClass = getLoaderClass(binding)
	loader.classList.add('w-100', 'text-center', loaderClass)

	const modifiersClasses = getModifiersClasses(binding)
	loader.classList.add(...modifiersClasses)

	const icon = document.createElement('i')
	icon.classList.add('fa', 'fa-spinner', 'spinner')
	loader.appendChild(icon)
	el.appendChild(loader)
	return loader
}

function getModifiersClasses (binding) {
	const classes = []
	if (binding.modifiers.transparent) {
		classes.push('list-loader_transparent')
	}
	return classes
}

function getLoaderClass (binding) {
	const classes = []
	if (binding.modifiers.center) {
		classes.push('list-loader_center')
	} else {
		classes.push('list-loader')
	}
	return classes.join(' ')
}

function componentUpdated (el, binding) {
	el.blur()
	const { value } = binding
	if (!el.classList.contains('position-relative')) {
		el.classList.add('position-relative')
	}

	const loaderClass = getLoaderClass(binding)

	const children = [ ...el.children ].filter(el => !el.classList.contains(loaderClass))
	const lastItem = children[children.length - 1]

	const isLoading = value
	if (isLoading) {
		if (findLoader(el)) {
			const loader = findLoader(el)
			loader.classList.add('list-loader_active')
		} else {
			const loader = createLoader(el, binding)
			loader.classList.add('list-loader_active')
		}
		if (lastItem && !lastItem.classList.contains('overflow-hidden')) {
			lastItem.classList.add('overflow-hidden')
		}
	} else {
		const loader = findLoader(el)
		if (loader) {
			loader.classList.remove('list-loader_active')
		} else {
			createLoader(el, binding)
		}
		if (lastItem && lastItem.classList.contains('overflow-hidden')) {
			lastItem.classList.remove('overflow-hidden')
		}
	}
}

function unbind (el) {
	el.classList.remove('position-relative')
}

export const loading = {
	bind,
	componentUpdated,
	unbind
}