class Popup{
	constructor(options={}){
		this.id = options.id || '';
		this.text = options.text || '';
		this.backgroundColor = options.backgroundColor || 'green';
		this.classIconList = options.classIconList || [];
		this.timeLife = options.timeLife || 5000;
		this._createPopup(options)
	}

	_createPopup(options){
		if(document.getElementById(this.id)){
			var el = document.getElementById(this.id);
			el.parentNode.removeChild(el);
		}

		var parentElement = document.createElement('div');
		parentElement.id = options.id;
		parentElement.style.position = 'fixed';
		parentElement.style.maxWidth = '85%';
		parentElement.style.borderRadius = '5px';
		parentElement.style.display = 'flex';
		parentElement.style.alignItems = 'center';
		parentElement.style.transitionDuration = '.5s';
		parentElement.style.backgroundColor = this.backgroundColor;
		parentElement.style.color = 'white';
		parentElement.style.paddingRight = '25px';
		parentElement.style.paddingLeft = '25px';
		parentElement.style.paddingTop = '20px';
		parentElement.style.paddingBottom = '20px';
		
		var buttonCloseElement = document.createElement('button');
		buttonCloseElement.innerHTML = '&times;';
		buttonCloseElement.classList.add('close');
		buttonCloseElement.style.position = 'absolute';
		buttonCloseElement.style.right = '5px';
		buttonCloseElement.style.top = '5px';
		buttonCloseElement.onclick = this.slideAwayRightBottom.bind(this);

		parentElement.appendChild(buttonCloseElement);

		var iconElement = document.createElement('i');
		for(var i = 0; i < options.classIconList.length; i++){
			iconElement.classList.add(options.classIconList[i]);
		}
		iconElement.style.paddingRight = '5px';
		parentElement.appendChild(iconElement);
		
		var spanElement = document.createElement('span');
		spanElement.innerText = options.text;
		parentElement.appendChild(spanElement);

		document.body.appendChild(parentElement);
		if(options.slide == 'rightBottom'){	
			this.slideRightBottom();
		}
	}

	slideRightBottom(){
		var el = document.getElementById(this.id);
		el.style.bottom = '30px';
		el.style.right = '-' + el.clientWidth + 'px';
		setTimeout(()=>{
			el.style.marginRight = el.clientWidth + 25 + 'px';
		}, 100)

		var timeout = setTimeout(()=>{
			var el = document.getElementById(this.id);
			if(!el){
				clearTimeout(timeout);
				return;
			}

			this.slideAwayRightBottom();
		}, this.timeLife)
	}

	slideAwayRightBottom(){
		var el = document.getElementById(this.id);
		el.style.marginRight = '-' + el.clientWidth + 'px';
		this.destroyPopup();
	}

	destroyPopup(){
		var el = document.getElementById(this.id);
		var timeout = setTimeout(()=>{
			if(!el || !el.parentNode){
				clearTimeout(timeout);
				return;
			}

			el.parentNode.removeChild(el);
		}, this.timeLife)
	}
}

export default Popup;