import {debounce} from 'lodash'

export default {
	data() {
		return {
			innerWidth: 0,
			deviceResolutions: {
				'mobile': 578,
				'tablet': 768,
				'desktop': 1290
			}
		}
	},

	mounted() {
		this.onResize()
		window.addEventListener('resize', this.onResize);
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		onResize: debounce(function() {
			this.innerWidth = window.innerWidth
			let type = ''
			if (this.innerWidth <= this.deviceResolutions.mobile) {
				type = 'mobile'
			} else if (this.innerWidth > this.deviceResolutions.mobile && this.innerWidth < this.deviceResolutions.tablet) {
				type = 'tablet'
			} else if (this.innerWidth >= this.deviceResolutions.desktop) {
				type = 'desktop'
			}

			this.$store.commit('device/SET_DEVICE_OPTIONS', { resolution: this.innerWidth, type })
		}, 100)
	}
}