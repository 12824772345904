import Vue from 'vue'
export default {
	namespaced: true,
	state: () => ({
		route: ''
	}),
	getters: {
		prevRoute: state => state.route,
	},
	mutations: {
		SET_PREV_ROUTE (state, options) {
			Vue.set(state, 'route', options)
		}
	},

	actions: {
        setPrevRoute({ commit }, data) {
            commit('SET_PREV_ROUTE', data); 
        },
 
    },
}