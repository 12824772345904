import importPageFiles from './importPageFiles.js'
import RouterHelper from '@/router/routerHelper.js'

var routerHelper = new RouterHelper();

const BrandSettings = importPageFiles.OrganizationSettings;
const BrandTariffs = importPageFiles.BrandTariffs;

const BrandMenu = importPageFiles.BrandMenu;
const BrandMainInfo = importPageFiles.OrganizationPlaceMainInfo;
const BrandStocks = importPageFiles.OrganizationStocks;
const BrandNewStock = importPageFiles.OrganizationNewStock;
const BrandMedia = importPageFiles.OrganizationPlaceMedias;
const BrandPhotosAndVideos = importPageFiles.OrganizationPlacePhotosAndVideos;
const BrandAdvertisement = importPageFiles.OrganizationPlaceAdvertisement;
const BrandReviews = importPageFiles.OrganizationPlaceReviews;
const BrandPushNotice = importPageFiles.BrandPushNotice;
const BrandEvents = importPageFiles.OrganizationPlaceEvents;

const BrandRequisites = importPageFiles.OrganizationRequisites;
const BrandRequisitesEntity = importPageFiles.OrganizationRequisitesEntity;
const BrandRequisitesIndividualEntrepreneur = importPageFiles.OrganizationRequisitesIndividualEntrepreneur;

const AddBrandMainInfo = importPageFiles.AddBrandMainInfo;
const BrandSearch = importPageFiles.BrandSearch;
const BrandOwnerRegistration = importPageFiles.BrandOwnerRegistration;

const BrandCashbackStocksCommon = importPageFiles.BrandCashbackStocksCommon;
const BrandCashbackStocks = importPageFiles.BrandCashbackStocks;
const BrandCashbackStockReport = importPageFiles.BrandCashbackStockReport;
const BrandCashbackStockNew = importPageFiles.BrandCashbackStockNew;

export default [
{
	path: '/brand',
	name: 'brand',
	props: true,
	component: BrandMenu,
	beforeEnter: routerHelper.beforeBrandEnter,
	children: [
		{
			path: '/brand/main-info',
			name: 'brandMainInfo',
			component: BrandMainInfo,
			props: (route) => ({ id: route.params.id, type: 'brand' })
		},
		{
			path: '/brand/discounts',
			name:'brandStocks',
			component: BrandStocks,
			props: { type: 'brand' }
		},
		{
			path: '/brand/discounts/stocks/create-stock',
			name:'brandNewStock',
			component: BrandNewStock,
			props: { type: 'brand' }
		},
		{
			path: '/brand/photos-and-videos',
			name:'brandPhotosAndVideos',
			component: BrandPhotosAndVideos,
			props: { type: 'brand' }
		},
		{
			path: '/brand/advertisement',
			name:'brandAdvertisement',
			component: BrandAdvertisement,
		},
		{
			path: '/brand/push-notice',
			name:'brandPushNotice',
			component: BrandPushNotice,
		},
		{
			path: '/brand/reviews',
			name:'brandReviews',
			component: BrandReviews,
			props: { type: 'brand' },
		},
		{
			path: '/brand/events',
			name:'brandEvents',
			component: BrandEvents,
		},
		{
			path: '/brand/cashback-stocks',
			name: 'brandCashbackStocks',
			component: BrandCashbackStocksCommon,
			children: [
				{
					path: '/brand/cashback-stocks/stocks',
					name: 'brandCashbackStocksList',
					component: BrandCashbackStocks,
				},
				{
					path: '/brand/cashback-stocks/new',
					name: 'brandCashbackStocksNew',
					component: BrandCashbackStockNew,
				},
				{
					path: '/brand/cashback-stocks/edit/:stockId',
					name: 'brandCashbackStocksEdit',
					component: BrandCashbackStockNew,
					props: true,
				},
				{
					path: '/brand/cashback-stocks/report',
					name: 'brandCashbackStocksReport',
					component: BrandCashbackStockReport,
					props: true,
				},
			]
		},
		{
			path: '/brand/cashback-stocks/epn',
			name: 'brandCashbackEpnStocks',
			component: BrandCashbackStocksCommon,
			props: { isEpn: true },
			children: [
				{
					path: '/brand/cashback-stocks/epn/stocks',
					name: 'brandCashbackEpnStocksList',
					component: BrandCashbackStocks,
					props: { isEpn: true },
				},
				{
					path: '/brand/cashback-stocks/epn/new',
					name: 'brandCashbackEpnStocksNew',
					component: BrandCashbackStockNew,
					props: { isEpn: true },
				},
				{
					path: '/brand/cashback-stocks/epn/edit/:stockId',
					name: 'brandCashbackEpnStocksEdit',
					component: BrandCashbackStockNew,
					props: (route) => ({ stockId: route.params.stockId, isEpn: true })
				},
				{
					path: '/brand/cashback-stocks/epn/report',
					name: 'brandCashbackEpnStocksReport',
					component: BrandCashbackStockReport,
					props: { isEpn: true },
				},
			]
		},
	]
},
{
	path: '/brand/settings',
	name: 'brandSettings',
	component: BrandSettings,
	beforeEnter: routerHelper.beforeBrandEnter
},
{
	path: '/brand/settings/requisites',
	name: 'brandRequisites',
	component: BrandRequisites,
	beforeEnter: routerHelper.beforeBrandEnter,
	children: [
	{
		path: '/brand/settings/requisites/entity',
		name:'brandRequisitesEntity',
		component: BrandRequisitesEntity,
	},
	{
		path: '/brand/settings/requisites/individual-entrepreneur',
		name:'brandRequisitesIndividualEntrepreneur',
		component: BrandRequisitesIndividualEntrepreneur
	}]
},
{
	path: '/brand/tariffs',
	name: 'brandTariffs',
	component: BrandTariffs,
	beforeEnter: routerHelper.beforeBrandEnter
},
{
	path: '/brand/add-brand-main-info',
	name: 'addBrandMainInfo',
	component: AddBrandMainInfo,
	beforeEnter: routerHelper.beforePersonEnter,
	props: (route) => ({ type: 'addBrand' }),
},
{
	path: '/brand/brand-search/:type',
	name: 'brandSearch',
	component: BrandSearch,
	props: true,
	beforeEnter: routerHelper.beforePersonEnter
},
{
	path: '/brand/:id/owner-registration',
	name: 'brandOwnerRegistration',
	component: BrandOwnerRegistration,
	beforeEnter: routerHelper.beforePersonEnter,
	props: true,
},
{
	path: '/brand/owner-registration',
	name: 'addBrandOwnerRegistration',
	component: BrandOwnerRegistration,
	beforeEnter: routerHelper.beforePersonEnter,
},
{
	path: '/brand/:mediaType/:user',
	name:'BrandMedia',
	component: BrandMedia,
	beforeEnter: routerHelper.beforeBrandEnter,
	props: (route) => ({ user: route.params.user, mediaType: route.params.mediaType, type: 'brand' }),
},]