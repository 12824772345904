<template>
    <div class="sidebar-dropdown">
        <div
            class="sidebar-dropdown__title"
            :class="{ '_open': isShowContent }"
            @click="toggleContent"
        >
            <span>
                {{ content.title }}
            </span>

            <DropdownIcon v-if="!content.single" :active="isShowContent"/>
        </div>

        <div
            v-if="!content.single"
            class="sidebar-dropdown__content"
            :class="{ '_open': isShowContent }"
        >
            <SidebarItem
                v-for="item in content.items"
                :title="item.title" 
                :icon="item.icon"
                :link="item.link"
                :subitems="item.subitems"
            />
        </div>
    </div>
</template>

<script>
    import DropdownIcon from '../icons/DropdownIcon.vue';
    import SidebarItem from '../SidebarItem.vue';

    export default {
        name: 'SidebarDropdownItem',

        data() {
            return {
                isShowContent: false,
                companyCategories: [],
            };
        },

        components: {
            DropdownIcon,
            SidebarItem,
        },

        props: {
            content: {
                type: Object,
                default: true,
            },
        },

        methods: {
            toggleContent() {
                if (this.content.single) {
                    return this.$router.push({ path: this.content.link });
                }
                this.isShowContent = !this.isShowContent;
            },

            getPlaceLink(id) {
                return `/organization-admin/places?currentCategory=${id}`
            },
        },

        computed: {
            currentCompanyId() {
                return this.$store.getters.currentCompanyId;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .sidebar-dropdown {
        padding: 15px 0;
        border-bottom: 1px solid rgba($color: $dark, $alpha: .1);

        &__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $dark;
            margin-bottom: 0;
            transition: $default-transition;

            &._open {
                margin-bottom: 15px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            max-height: 0;
            overflow: hidden;
            row-gap: 10px;
            //padding-top: 15px;
            transition: $default-transition;

            &._open {
                max-height: 1000px;
            }
        }
    }
</style>