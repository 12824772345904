import store from '@/store/index.js'
import consts from '../consts.js'
import ProviderHelper from './providerHelper.js';
import Vue from "vue";
import { authorizedCall } from "@/utils/helpers/common";
import { reject } from 'lodash';

let providerHelper = new ProviderHelper();
export default {
	toggleCardOwnership(card) {
		return new Promise((resolve, reject) => {
			authorizedCall(() => {
				try {
					if (card.is_co_owner) {
						resolve(this.leaveCoOwnersDiscountCard(Vue, card.id))
					} else {
						resolve(this.becomeCoOwnersDiscountCard(Vue, card.id))
					}
				} catch (error) {
					reject(error)
				}
			})
		})
	},

	getQuickCode(context, cardId) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + `/discount-card/${cardId}/update-security-code`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getChatWithUser(context, userId, limit, from) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + `/chats/with-user/${userId}` + `?limit=${limit}` + `&from=${from}`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	createChatWithUser(context, userId) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + `/chats/with-user/${userId}`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getDiscountsCompilationBySlug(context, slug, params) {
		return new Promise((resolve, reject) => {
			return context.$http.get('discounts/compilations/' + slug, params)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		})
	},
	getDiscountsCompilationsList(context) {
		return new Promise((resolve, reject) => {
			return context.$http.get('discounts/compilations/list')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		})
	},
	setUserRole(context, userRole = 'person') {
		store.dispatch('changeUserRole', userRole);
	},
	getUserRole(context) {
		return context.$ls.get('userRole');
	},
	getIsModerator(context) {
		return context.$ls.get('isModerator');
	},
	deleteUserRole(context) {
		context.$ls.remove('userRole');
	},
	getRecommendUsers(context) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/newsfeed/user-recommends')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		})
	},
	getUserId(context, search) {
		var self = this;
		search = search ? search : '';
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/users/search?query=' + search)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		})
	},
	getUser(context, userId) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/users/' + userId)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getAccrualsTotal(context) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/bonus-exchange/accruals-total')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getProposalCount(context, ...params) {
		return new Promise((resolve, reject) => {
			return context.$http.get('bonus-exchange/count' + (params ? `?${params.join("&")}` : ""))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getPersonalProposals(context, params) {
		return new Promise((resolve, reject) => {
			return context.$http.get('bonus-exchange', { params })
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getProposals(context, params) {
		return new Promise((resolve, reject) => {
			return context.$http.get('bonus-exchange' + (params ? `?${params.join("&")}` : ""))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getProposal(context, proposalId) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/bonus-exchange/' + proposalId)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	deleteProposal(context, proposalId) {
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + '/bonus-exchange/' + proposalId)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	sendProposal(context, body) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/bonus-exchange', JSON.stringify(body))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	issueCard(context, id, code) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + `/loyality-programs/${id}/discount-cards/issue`, JSON.stringify({ code }))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	changeProposal(context, body, proposalId) {
		return new Promise((resolve, reject) => {
			return context.$http.put(consts.appUri + '/bonus-exchange/' + proposalId, JSON.stringify(body))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	acceptProposal(context, exchangeId) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/bonus-exchange/' + exchangeId + '/accept')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getMessages(context, chatId, limit, from) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/chats/' + chatId + '/messages' + `?limit=${limit}` + `&from=${from}`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	sendMessage(context, chatId, message) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/chats/' + chatId + '/messages', JSON.stringify({ message }))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getChats(context, entity_id) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/chats/' + `?entity_id=${entity_id}` + '&entity_type=bonus_exchange')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getChat(context, chat) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/chats/' + chat)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	leaveFromChat(context, chat_id) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + `/chats/${chat_id}/leave`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	closeDeal(context, chat_id, body) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + `/chats/${chat_id}/accruals`, JSON.stringify(body))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	readMessages(context, messages_ids) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/chats/messages/read', JSON.stringify({ messages_ids }))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	acceptPersonalDiscount(context, personalDiscountId) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + `/personal-discount-campaign/${personalDiscountId}/issue`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	setReferralCode(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + "/users/current/register-referrer", JSON.stringify(options))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getUnreadNotificationsCount(context) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/newsfeed/notifications/unread-count')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	markAsLastReadNotification(context, body) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/newsfeed/notifications/last-read', body)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	markAsReadNotification(context, body) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/newsfeed/mark-as-read', body)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getNotifications(context, params) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/newsfeed/notifications', { params })
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	sendPost(context, options) {
		options.entity_id = options.entity_id || null;
		options.shareType = options.shareType || '';

		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/newsfeed/share', JSON.stringify({
				share_type: options.shareType,
				entity_id: options.entity_id,
				comment: options.comment,
				entity_type: options.entity_type
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	deletePost(context, eventId) {
		return new Promise((resolve, reject) => {
			return context.$http.delete(`${consts.appUri}/newsfeed/${eventId}/share`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	updateUserInfo(context, user) {
		let params = {
			about_me: user.about_me,
			promocode: user.promocode,
			name: user.name,
			last_name: user.lastName || user.last_name,
			middle_name: user.middle_name,
			gender: user.gender,
			id: user.id,
			birthday: user.birthday,
			phone: user.phone,
			email: user.email,
			city: user.city,
			password: user.password,
			password_confirmation: user.passwordConfirmation,
		}
		user = providerHelper.getObjectWithFields(user);
		return new Promise((resolve, reject) => {
			return context.$http.post(`${consts.appUri}/users/${user.id}`, JSON.stringify(params))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	updateUserCity(context, userId, cityId) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/users/${userId}/cities/${cityId}`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	unsubscribeFromUser(context, userId) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + "/users/" + userId + "/unsubscribe")
				.then(data => {
					resolve(data.body);
				})
				.catch(data => {
					reject(data);
				})
		});
	},
	unSubscribeFromCompany(context, companyId) {
		return new Promise((resolve, reject) => {
			return context.$http.post(`companies/${companyId}/unfollow`)
				.then(data => {
					resolve(data.body);
				})
				.catch(data => {
					reject(data);
				})
		});
	},
	subscribeToCompany(context, companyId) {
		return new Promise((resolve, reject) => {
			return context.$http.post(`companies/${companyId}/follow`)
				.then(data => {
					resolve(data.body);
				})
				.catch(data => {
					reject(data);
				})
		});
	},
	subscribeToUser(context, userId) {
		return new Promise((resolve, reject) => {
			return context.$http.post(`${consts.appUri}/users/${userId}/subscribe`)
				.then(data => {
					resolve(data.body);
				})
				.catch(data => {
					reject(data);
				})
		});
	},
	checkIfVkUserExists(context, vkId) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + `/user/vk/${vkId}/exists`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	updateVkAccount(context, { code, redirectURI }) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + "/users/current/attach-vk-account", JSON.stringify({
				code,
				redirect_uri: redirectURI
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	updateOkAccount(context, access_token, secret) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + "/users/current/attach-ok-account", JSON.stringify({
				access_token,
				secret
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	removeGoogleAccount(context) {
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + "/users/current/detach-google-account")
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	removeOkAccount(context) {
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + "/users/current/detach-ok-account")
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	removeVkAccount(context) {
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + "/users/current/detach-vk-account")
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	removeFbAccount(context) {
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + "/users/current/detach-fb-account")
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	updateGoogleAccount(context, access_token) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + "/users/current/attach-google-account", JSON.stringify({
				access_token
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	updateFacebookAccount(context, access_token) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + "/users/current/attach-fb-account", JSON.stringify({
				access_token
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	/**
	 *
	 * @param { Vue } context
	 * @return {Promise<User>}
	 */
	getCurrentUser(context, token, from) {
		const params = token ?
			{
				headers: { Authorization: `Bearer ${token}` }
			}
			:
			{};

		return new Promise((resolve, reject) => {
			try {
				return Vue.http.get('users/current', params)
					.then((data) => {
						if (!Vue.ls.get('isModerator')) {
							store.dispatch('setCompanies', data.body.companies);
							store.dispatch('setAdministratedCompanies', data.body.administrated_companies);
						}
						Vue.ls.set('lastName', data.body.last_name);
						Vue.ls.set('name', data.body.name);
						Vue.ls.set('id', data.body.id);
						Vue.ls.set('emailConfirmed', data.body.email_confirmed);
						Vue.ls.set('phoneConfirmed', data.body.phone_confirmed);
						Vue.ls.set('userProfile', data.body);
						store.dispatch('setUserProfile', data.body);

						if (data.body.administrated_companies && data.body.administrated_companies.length) {
							if (Vue.ls.get('currentCompanyId')) {
								const finded = data.body.administrated_companies.find(company => company.id === Vue.ls.get('currentCompanyId'))
								store.dispatch('setEnterFrom', finded && finded.external_source ? finded.external_source : 'benefitty');
							} else {
								store.dispatch('setEnterFrom', data.body.administrated_companies[0].external_source ? data.body.administrated_companies[0].external_source : 'benefitty');
							}
						}				
						resolve(data.body);
					})
					.catch((data) => {
						reject(data);
					})
			} catch (error) {
				console.error(error)
			}
		})
	},
	getCurrentUserRoles(context) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/users/current/roles')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		})
	},
	sendConfirmEmail(context) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/emails/confirm_mail', {})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	sendConfirmSms(context) {
		var self = this;
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/phones/confirm_sms', {})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	sendEmailConfirmationCode(context, code) {
		var self = this;
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/emails/confirm', {
				confirmation_code: code
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getOrganizationNewsFeed(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get('newsfeed/company/' + options.companyId,
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getNewsFeedCompany(context, companyId, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get('newsfeed/company/' + companyId,
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getRecommendedNews(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/newsfeed/recommended',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getNewsFeedComments(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get('comments',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	sendNewsFeedComments(context, body) {
		return new Promise((resolve, reject) => {
			return context.$http.post('comments',
				body)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	sendCommentReplies(context, body) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/replies',
				body)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getNewsFeedPost(context, id) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/newsfeed/' + id)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getNews(context, options = {}) {
		options.query = options.query || '';
		options.limit = options.limit || 10;
		options.offset = options.offset || 0;
		options.accept_classes = options.accept_classes || '';
		options = providerHelper.getObjectWithFields(options);


		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/newsfeed',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getNewsMy(context, options = {}) {
		options.query = options.query || '';
		options.limit = options.limit || 10;
		options.offset = options.offset || 0;
		options.accept_classes = options.accept_classes || '';
		options.all_near = options.all_near || null;
		options.only_discounts = options.only_discounts || null;
		options.discount_cards = options.discount_cards || null;
		options.from_friends = options.from_friends || null;
		options = providerHelper.getObjectWithFields(options);


		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/newsfeed/my',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getNewsfeedFromUser(context, options = {}, userId) {
		options.limit = options.limit || 10;
		options.offset = options.offset || 0;
		options = providerHelper.getObjectWithFields(options);

		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/users/' + userId + '/newsfeed',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	getUsers(context, options = {}) {
		var self = this;
		options.query = options.query || '';
		options.limit = options.limit || 10;
		options.offset = options.offset || 0;
		options.sort = options.sort || [];
		options = providerHelper.getObjectWithFields(options);

		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/users/search-multiple',
				{
					params: options,
					before(request) {
						if (this.previousRequest) {
							this.previousRequest.abort();
						}

						this.previousRequest = request;
					}
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				})
		});
	},
	sendPhoneConfirmationCode(context, code) {
		var self = this;
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/phones/confirm', {
				confirmation_code: code
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	sendNotificationToUser(context, options = {}) {
		var self = this;
		options.userId = options.userId || '';
		options.message = options.message || '';

		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/users/' + options.userId + '/notifications', {
				message: options.message
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	passwordResetCheck(context, user) {
		return context.$http.post(consts.appUri + '/password/reset/check', user);
	},
	passwordReset(context, userPasswords) {
		return context.$http.post(consts.appUri + '/password/reset', {
			password: userPasswords.password,
			password_confirmation: userPasswords.passwordConfirmation,
		})
	},
	setDeviceToken(context, deviceToken) {
		var self = this;

		return new Promise((resolve, reject) => {
			context.$http.put(consts.appUri + '/users/current/set-device-token', JSON.stringify({
				deviceToken
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addPlaceBookmark(context, placeId) {
		return new Promise((resolve, reject) => {
			context.$http.post('places/' + placeId + '/bookmarks')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	deletePlaceBookmark(context, placeId) {
		return new Promise((resolve, reject) => {
			context.$http.delete('places/' + placeId + '/bookmarks')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addDiscountBookmark(context, discountId) {
		return new Promise((resolve, reject) => {
			context.$http.post('stock/' + discountId + '/bookmarks')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	deleteDiscountBookmark(context, discountId) {
		return new Promise((resolve, reject) => {
			context.$http.delete(consts.appUri + '/stock/' + discountId + '/bookmarks')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addBrandBookmark(context, brandId) {
		var self = this;

		return new Promise((resolve, reject) => {
			context.$http.post(consts.appUri + '/brands/' + brandId + '/bookmarks')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	deleteBrandBookmark(context, brandId) {
		var self = this;

		return new Promise((resolve, reject) => {
			context.$http.delete(consts.appUri + '/brands/' + brandId + '/bookmarks')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	likeReview(context, reviewId) {
		var self = this;

		return new Promise((resolve, reject) => {
			context.$http.post(consts.appUri + '/reviews/' + reviewId + '/like')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	dislikeReview(context, reviewId) {
		var self = this;

		return new Promise((resolve, reject) => {
			context.$http.post(consts.appUri + '/reviews/' + reviewId + '/dislike')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addPlaceReview(context, options = {}) {
		const self = this;
		options.placeId = options.placeId || '';
		options.review = options.review || {};
		return new Promise((resolve, reject) => {
			context.$http.post('places/' + options.placeId + '/reviews', JSON.stringify({
				header: options.review.title,
				positive: options.review.advantages,
				negative: options.review.limitations,
				rating: options.review.rating,
				comment: options.review.comment,
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addDiscountReview(context, options = {}) {
		options.stockId = options.stockId || '';
		options.review = options.review || {};
		return new Promise((resolve, reject) => {
			context.$http.post(`discounts/${options.stockId}/review/self`, JSON.stringify({
				header: options.review.title,
				positive: options.review.advantages,
				negative: options.review.limitations,
				rating: options.review.rating,
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	uploadReviewImage(context, options = {}) {
		const self = this;
		const formData = new FormData();
		formData.append('image', options.image);

		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + "/reviews/" + options.id + "/images", formData)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	deleteImage(context, imageId) {
		const self = this;

		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + '/images/' + imageId)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	editReview(context, options = {}) {
		options.review = options.review || {};
		return new Promise((resolve, reject) => {
			context.$http.put(consts.appUri + "/reviews/" + options.review.id, JSON.stringify({
				header: options.review.title,
				positive: options.review.advantages,
				negative: options.review.limitations,
				rating: options.review.rating,
				comment: options.review.comment,
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	deleteReview(context, reviewId) {
		return new Promise((resolve, reject) => {
			context.$http.delete(consts.appUri + "/reviews/" + reviewId)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getPlaceReview(context, placeId) {
		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + "/places/" + placeId + "/reviews/self")
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getStockReview(context, stockId) {
		return new Promise((resolve, reject) => {
			context.$http.get(`${consts.appUri}/discounts/${stockId}/review/self`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getReviews(context, filters) {
		const params = {
			user_id: filters.userId,
			offset: filters.offset,
			limit: filters.limit,
			order_by: filters.orderBy
		};
		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + "/reviews", {
				params
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addDiscountBookmark(context, discountId) {
		var self = this;

		return new Promise((resolve, reject) => {
			context.$http.post('discounts/' + discountId + '/bookmark')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	deleteDiscountBookmark(context, discountId) {
		var self = this;

		return new Promise((resolve, reject) => {
			context.$http.delete('discounts/' + discountId + '/bookmark')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getUserPermissions(context, userId) {
		var self = this;

		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + '/permissions',
				{
					params: {
						user_id: userId
					}
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getUserRewardsTotal(context, options = {}) {
		var self = this;
		options.user_id = options.user_id || '';
		options = providerHelper.getObjectWithFields(options);

		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + '/rewards/total',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getUserCashbackTotal(context, options) {
		var self = this;
		options.user_id = options.user_id || '';
		options = providerHelper.getObjectWithFields(options);

		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + '/cashback/total',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	async getTotalBenefits(context) {
		const benefitTotal = {
			total: 0,
			cashback: 0,
			benefitFromFriends: 0,
			exchangePoints: 0,
			rewards: 0,
			addManually: 0,
			promotion: 0
		};

		benefitTotal.cashback += +await context.$http.get(consts.appUri + '/cashback/sum?types=cashiers_app, receipt, admitad, brand_cashback, epn, gift')
			.then(data => {
				return data.body;
			}).catch((data) => {
				return data;
			});

		benefitTotal.benefitFromFriends += +await context.$http.get(consts.appUri + '/cashback/sum?types=referrer_reward')
			.then(data => {
				return data.body;
			}).catch((data) => {
				return data;
			});

		benefitTotal.promotion += +await context.$http.get(consts.appUri + '/cashback/sum?types=influencer_reward')
			.then(data => {
				return data.body;
			}).catch((data) => {
				return data;
			});

		benefitTotal.exchangePoints += +await context.$http.get(consts.appUri + '/bonus-exchange/accruals-total')
			.then(data => {
				return data.body.total;
			}).catch((data) => {
				return data;
			});

		benefitTotal.addManually += +await context.$http.get(consts.appUri + '/benefits-log/total')
			.then(data => {
				return data.body;
			}).catch((data) => {
				return data;
			});

		benefitTotal.rewards += +await context.$http.get(consts.appUri + '/rewards/total')
			.then(data => {
				return data.body;
			}).catch((data) => {
				return data;
			});

		benefitTotal.total =
			benefitTotal.cashback +
			benefitTotal.benefitFromFriends +
			benefitTotal.exchangePoints +
			benefitTotal.rewards +
			benefitTotal.addManually +
			benefitTotal.promotion;

		benefitTotal.isLoaded = true;


		return benefitTotal;
	},
	getUserCashbackTable(context, options) {
		options.user_id = options.user_id || '';
		options.limit = options.limit || 10;
		options.offset = options.offset || 0;
		options = providerHelper.getObjectWithFields(options);

		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + '/cashback/table',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getUserRewardsLog(context, options) {
		var self = this;
		options.user_id = options.user_id || '';
		options.limit = options.limit || 10;
		options.offset = options.offset || 0;
		options = providerHelper.getObjectWithFields(options);

		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + '/rewards/log',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getUserRewardsTable(context, options) {
		options.user_id = options.user_id || '';
		options.limit = options.limit || 10;
		options.offset = options.offset || 0;
		options = providerHelper.getObjectWithFields(options);

		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + '/rewards/table',
				{
					params: options
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	userRegisterVisit(context) {
		return new Promise((resolve, reject) => {
			Vue.http.post('users/current/register-visit')
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	sendQrCode(context, body) {
		return new Promise((resolve, reject) => {
			context.$http.post('transactions', body)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getUserRegisterStats(context, options = {}) {
		options.platform = options.platform || null;
		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + '/users/register-stats', {
				params: options
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	/**
	 * @param { Vue } context
	 * @param { QueryGetTransactions } options
	 * @returns {Promise<Transaction[]>}
	 */
	getUserChecks(context, options) {
		return new Promise((resolve, reject) => {
			context.$http.get('users/current/transactions', {
				params: options
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getCheckins(context, options) {
		return new Promise((resolve, reject) => {
			context.$http.get(consts.appUri + '/checkins', {
				params: {
					offset: options.offset || 0,
					limit: options.limit || 10,
					lat: options.lat || null,
					lng: options.lng || null,
					user_id: options.userId || null,
				}
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	updateUserImage(context, image, userId) {
		var formData = new FormData();
		formData.append('image', image);
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + "/users/" + userId + "/images/profile", formData)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getDiscountCards(context, params) {
		params = providerHelper.getObjectWithFields(params);
		return new Promise((resolve, reject) => {
			context.$http.get('discount-cards', {
				params
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getPlacesCards(context, params) {
		return new Promise((resolve, reject) => {
			context.$http.get('places/loyality-programs', { params })
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	/**
	 *
	 * @param context
	 * @param params
	 * @returns {Promise<DiscountCard[]>}
	 */
	getSharedDiscountCards(context, params = {}) {
		return new Promise((resolve, reject) => {
			context.$http.get('discount-cards/shared', {
				params
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	deleteDiscountCard(context, discountCardId) {
		return new Promise((resolve, reject) => {
			context.$http.delete(`discount-cards/${discountCardId}`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	leaveCoOwnersDiscountCard(context, discountCardId) {
		return new Promise((resolve, reject) => {
			Vue.http.post(`${consts.appUri}/discount-cards/${discountCardId}/leave-co-owners`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	becomeCoOwnersDiscountCard(context, discountCardId) {
		return new Promise((resolve, reject) => {
			Vue.http.post(`${consts.appUri}/discount-cards/${discountCardId}/become-co-owner`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	/**
	 * @param { Vue } context
	 * @param  params
	 * @returns {Promise<unknown>}
	 */
	getPlacesOfDiscountCard(context, params) {
		return new Promise((resolve, reject) => {
			context.$http.get("discount-cards/" + params.card + "/places", {
				params
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getMostViewedDiscountCards(context, params) {
		return new Promise((resolve, reject) => {
			context.$http.get(`${consts.appUri}/discount-cards/most-viewed`, {
				params
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getNearbyDiscountsCard(context, params) {
		params = providerHelper.getObjectWithFields(params);
		return new Promise((resolve, reject) => {
			context.$http.get(`discount-cards/nearby`, { params })
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	createDiscountCard(context, card) {
		return new Promise((resolve, reject) => {
			context.$http.post('discount-cards', JSON.stringify(card))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	/**
	 * @param { Vue } context
	 * @param { DiscountCard } card
	 * @returns {Promise<unknown>}
	 */
	updateDiscountCard(context, card) {
		/**
		 * @type {{ notes: string, discount: number, points: number }}
		 */
		let params = {
			points: card.points,
			notes: card.notes,
			discount: card.discount,
		}
		params = providerHelper.getObjectWithFields(params);
		return new Promise((resolve, reject) => {
			context.$http.put(`discount-cards/${card.id}`, JSON.stringify(params))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	/**
	 * @param { Vue } context
	 * @param cardId
	 * @returns {Promise<DiscountCard>}
	 */
	getDiscountCard(context, cardId) {
		return new Promise((resolve, reject) => {
			context.$http.get('discount-cards/' + cardId)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	uploadDiscountCardDefaultImage(context, options = {}) {
		const self = this;
		const formData = new FormData();
		formData.append('image', options.image);

		return new Promise((resolve, reject) => {
			return context.$http.post('discount-cards/' + options.id + '/images/default', formData)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	uploadDiscountCardFrontImage(context, options = {}) {
		const self = this;
		const formData = new FormData();
		formData.append('image', options.image);

		return new Promise((resolve, reject) => {
			return context.$http.post('discount-cards/' + options.id + '/images/front-side', formData)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	uploadDiscountCardBackImage(context, options = {}) {
		const self = this;
		const formData = new FormData();
		formData.append('image', options.image);

		return new Promise((resolve, reject) => {
			return context.$http.post('discount-cards/' + options.id + '/images/back-side', formData)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	attachDiscountCardToPlace(context, options = { cardId, placeId }) {
		return new Promise((resolve, reject) => {
			context.$http.put(consts.appUri + '/discount-cards/' + options.cardId + '/attach-to-place',
				JSON.stringify({ place_id: options.placeId }))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getUserMedia(context, options = {}) {
		let query = `?limit=${options.limit || 10}&offset=${options.offset || 0}`
		if (options.type) query += `&type=${options.type}`;
		return new Promise((resolve, reject) => {
			return context.$http.get(`${consts.appUri}/users/${options.userId}/media${query}`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getCurrentUserMedia(context, options = {}) {
		let query = `?limit=${options.limit || 10}&offset=${options.offset || 0}`
		if (options.type) query += `&type=${options.type}`;
		return new Promise((resolve, reject) => {
			return context.$http.get(`${consts.appUri}/users/current/media${query}`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	updateUserProfilePrivacy(context, options = {}) {
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/users/current/profile-privacy',
				JSON.stringify({
					profile_privacy: options.profilePrivacy || 'public'
				}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getUserSettings(context) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/users/current/settings',
				{
					before(request) {
						if (this.previousRequest) {
							this.previousRequest.abort();
						}

						this.previousRequest = request;
					}
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	updateUserSettings(context, options = {}) {
		return new Promise((resolve, reject) => {
			return context.$http.put(consts.appUri + '/users/current/settings',
				JSON.stringify({
					items: options.settings
				}), {
				before(request) {
					if (this.previousRequest) {
						this.previousRequest.abort();
					}

					this.previousRequest = request;
				}
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	leads(context, options) {
		return new Promise((resolve, reject) => {
			context.$http.post(consts.appUri + '/leads', JSON.stringify(
				{
					name: options.name,
					person_name: options.person_name,
					email: options.email,
					phone: options.phone.replace(/[^0-9]/, ''),
					comment: options.comment,
					type: options.type,
				}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getFriends(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get(`${consts.appUri}/users/${options.userId}/friends`,
				{
					params: {
						limit: options.limit,
						offset: options.offset,
						search: options.search,
					},
					before(request) {
						if (this.previousRequest) {
							this.previousRequest.abort();
						}
						this.previousRequest = request;
					}
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getSearchFriends(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get(`${consts.appUri}/users`,
				{
					params: {
						limit: options.limit,
						offset: options.offset,
						query: options.search,
					},
					before(request) {
						if (this.previousRequest) {
							this.previousRequest.abort();
						}
						this.previousRequest = request;
					}
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getRecommendedFriends(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get(`${consts.appUri}/users/recommended-friends`,
				{
					params: {
						limit: options.limit,
						offset: options.offset,
						search: options.search,
					},
					before(request) {
						if (this.previousRequest) {
							this.previousRequest.abort();
						}
						this.previousRequest = request;
					}
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getSupposedFriends(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get(`${consts.appUri}/supposed-friends`,
				{
					params: {
						limit: options.limit,
						offset: options.offset,
						search: options.search,
					},
					before(request) {
						if (this.previousRequest) {
							this.previousRequest.abort();
						}
						this.previousRequest = request;
					}
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getSubscribers(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get(`${consts.appUri}/users/${options.userId}/subscribers`,
				{
					params: {
						limit: options.limit,
						offset: options.offset,
						query: options.search,
					},
					before(request) {
						if (this.previousRequest) {
							this.previousRequest.abort();
						}
						this.previousRequest = request;
					}
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getSubscribes(context, options) {
		return new Promise((resolve, reject) => {
			return context.$http.get(`${consts.appUri}/users/${options.userId}/subscribes`,
				{
					params: {
						limit: options.limit,
						offset: options.offset,
						query: options.search,
					},
					before(request) {
						if (this.previousRequest) {
							this.previousRequest.abort();
						}
						this.previousRequest = request;
					}
				})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getPosts(context, options = {}) {
		let params = {
			limit: options.limit || 50,
			offset: options.offset || 0,
			withInactive: options.withInactive || false,
			onlyInactive: options.onlyInactive || false,
			withTrashed: options.withTrashed || false,
			onlyTrashed: options.onlyTrashed || false
		};
		params = providerHelper.getObjectWithFields(params);
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/posts', {
				params
			})
				.then(data => {
					resolve(data.body);
				})
				.catch(data => {
					reject(data);
				})
		});
	},
	getPost(context, postID) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/posts/' + postID)
				.then(data => {
					resolve(data.body);
				})
				.catch(data => {
					reject(data);
				})
		});
	},
	getLifehack(context, lifehackID) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/lifehacks/' + lifehackID)
				.then(data => {
					resolve(data.body);
				})
				.catch(data => {
					reject(data);
				})
		});
	},
	getLifehacks(context, options = {}) {
		let params = {
			limit: options.limit || 10,
			offset: options.offset || 0,
			withInactive: options.withInactive || false,
			onlyInactive: options.onlyInactive || false,
			withTrashed: options.withTrashed || false,
			onlyTrashed: options.onlyTrashed || false
		};
		params = providerHelper.getObjectWithFields(params);
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/lifehacks', {
				params
			})
				.then(data => {
					resolve(data.body);
				})
				.catch(data => {
					reject(data);
				})
		});
	},
	addBrandBookmark(context, brandId) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/brands/${brandId}/bookmarks`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	deleteBrandBookmark(context, brandId) {
		return new Promise((resolve, reject) => {
			context.$http.delete(`${consts.appUri}/brands/${brandId}/bookmarks`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addDiscountCardBookmark(context, cardId) {
		return new Promise((resolve, reject) => {
			context.$http.post(`discount-cards/${cardId}/bookmark`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	deleteDiscountCardBookmark(context, cardId) {
		return new Promise((resolve, reject) => {
			context.$http.delete(`discount-cards/${cardId}/bookmark`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addBrandReview(context, options = {}) {
		options.brandId = options.brandId || '';
		options.review = options.review || {};
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/brands/${options.brandId}/reviews`, JSON.stringify({
				header: options.review.title,
				positive: options.review.advantages,
				negative: options.review.limitations,
				rating: options.review.rating,
				comment: options.review.comment,
			}))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},

	getBrandReview(context, brandId) {
		return new Promise((resolve, reject) => {
			context.$http.get(`${consts.appUri}/brands/${brandId}/reviews/self`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},

	getBenefittyCardLevels(context) {
		return new Promise((resolve, reject) => {
			context.$http.get(`${consts.appUri}/gamification/benefitty-card/levels`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},

	sendPromoCode(context, code) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/gamification/benefitty-card/levels/promo-code`, code)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},

	getBenefittyCardConfigs(context) {
		return new Promise((resolve, reject) => {
			context.$http.get(`${consts.appUri}/gamification/benefitty-card/configs`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},

	getBenefittyCardRewards(context, userId) {
		return new Promise((resolve, reject) => {
			context.$http.get(`${consts.appUri}/gamification/${userId}/benefitty-card/rewards`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},

	createDiscountFromUser(context, options) {
		const formData = new FormData();
		if (options.file) {
			formData.append('image', options.file);
		}
		let contents = {
			type_id: options.typeId,
			company_id: options.companyId,
			places: options.places,
			name: options.name,
			description: options.description,
			date_start: options.dateStart,
			date_end: options.dateEnd,
			share_type: options.shareType,
			users: options.shareUsers,
			all_place: options.allPlace,
		};
		if (options.maxDiscount) {
			contents.max_discount = options.maxDiscount / 100;
		}
		contents = providerHelper.getObjectWithFields(contents);
		console.log({ contents })
		formData.append('contents', JSON.stringify(contents));
		return new Promise((resolve, reject) => {
			return context.$http.post(`discounts/from-user`, formData)
				.then((data) => {
					resolve(data);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},

	updateDiscountFromUser(context, options) {
		const formData = new FormData();
		if (options.file) {
			formData.append('image', options.file);
		}
		let contents = {
			type_id: options.typeId,
			company_id: options.companyId,
			name: options.name,
			description: options.description,
			date_start: options.dateStart,
			date_end: options.dateEnd,
			share_type: options.shareType,
			users: options.shareUsers,
			all_place: options.allPlace,
		};
		if (options.maxDiscount) {
			contents.max_discount = options.maxDiscount / 100;
		}
		contents = providerHelper.getObjectWithFields(contents);
		formData.append('_method', 'PUT');
		formData.append('contents', JSON.stringify(contents));
		return new Promise((resolve, reject) => {
			return context.$http.post(`${consts.appUri}/discounts/${options.id}/from-user`, formData)
				.then((data) => {
					resolve(data);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},

	deleteDiscountFromUser(context, stockId) {
		return new Promise((resolve, reject) => {
			return context.$http.delete(`${consts.appUri}/discounts/${stockId}/from-user`)
				.then((data) => {
					resolve(data);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},

	getUserDiscounts(context, options = {}) {
		let params = {
			limit: options.limit || 10,
			offset: options.offset || 0,
			time: options.time,
			user_id: options.userId,
		}
		params = providerHelper.getObjectWithFields(params);
		return new Promise((resolve, reject) => {
			return context.$http.get(`${consts.appUri}/discounts`, { params })
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getUserDiscountsDetails(context, discountId) {
		return new Promise((resolve, reject) => {
			return context.$http.get(`discounts/${discountId}/cashback-details`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},
	getRecommendedCards(context) {
		return new Promise((resolve, reject) => {
			return context.$http.get(`popular/lps`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data);
				});
		});
	},

	createLikeRequest(context, options) {
		const params = {
			likeable_type: options.likeableType,
			likeable_id: options.likeableId,
			like_type: options.likeType,
		}
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/likes`, params)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},

	newsfeedShareRequest(context, options) {
		const params = {
			entity_type: options.entityType,
			entity_id: options.entityId,
			share_type: options.shareType,
		}
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/newsfeed/share`, params)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addImageToPost(context, postId, image) {

		const formData = new FormData();
		formData.append('image', image);

		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/newsfeed/media-group/${postId}/image`, formData)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getCardShareLink(context, cardId) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/discount-cards/${cardId}/share`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getSomeShareLink(context, type, id) {
		const typesList = ['discount', 'place', 'brand', 'card', 'lp', 'review', 'company', 'newsfeed', 'bonus-exchange', 'user'];
		if (typesList.includes(type)) {
			return new Promise((resolve, reject) => {
				context.$http.get(`${consts.appUri}/share/${type}/${id}`)
					.then(data => {
						resolve(data.body);
					})
					.catch((data) => {
						reject(data);
					});
			})
		} s
		return false;
	},
	setUserCoords(context, options) {
		const params = {
			latitude: options.latitude,
			longitude: options.longitude,
		}
		return new Promise((resolve, reject) => {
			context.$http.put(`${consts.appUri}/users/current/set-coords`, JSON.stringify(params))
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	followTheCompany(context, companyId) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/companies/${companyId}/follow`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	unfollowTheCompany(context, companyId) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/companies/${companyId}/unfollow`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	addToFamily(context, userId) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/users/${userId}/add-to-family`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	removeFromFamily(context, userId) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/users/${userId}/remove-from-family`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	getFamily(context, options) {
		return new Promise((resolve, reject) => {
			context.$http.get(`${consts.appUri}/users/current/family`, {
				params: {
					limit: options.limit,
					offset: options.offset,
					query: options.search,
					is_subscribed_as_family: options.isSubscribedAsFamily,
					is_family: options.isFamily
				}
			})
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	},
	declineFamilyRequest(context, userId) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/users/${userId}/decline-family-request`)
				.then((data) => {
					resolve(data.body);
				})
				.catch((data) => {
					reject(data)
				});
		});
	}
}