import consts from '../consts.js';
import ProviderHelper from './providerHelper.js';
import Vue from "vue";

let providerHelper = new ProviderHelper();

export default{
	getBanks(context){
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/banks')
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	createBankCardType(context, bankId, cardType){
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/banks/' + bankId + '/card-types', {
				name: cardType.name,
				credit: cardType.credit,
				description: cardType.description,
				url: cardType.url,
				payment_system_id: cardType.paymentSystemId,
				payment_system_level_id: cardType.paymentSystemLevelId,
			})
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	updateBankCardType(context, cardTypeId, cardType){
		return new Promise((resolve, reject) => {
			return context.$http.put(consts.appUri + '/card-types/' + cardTypeId, {
				name: cardType.name,
				credit: cardType.credit,
				description: cardType.description,
				url: cardType.url,
				payment_system_id: cardType.paymentSystemId,
				payment_system_level_id: cardType.paymentSystemLevelId,
			})
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	deleteBankCardType(context, cardTypeId){
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + '/card-types/' + cardTypeId)
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	getBankCardType(context, cardTypeId){
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/card-types/' + cardTypeId)
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	getBankCardTypes(context, options){
		var params = {
			limit: options.limit || 1000,
			offset: options.offset || 0,
			name: options.name || '',
			payment_system_id: options.paymentSystemId || null,
			payment_system_level_id: options.paymentSystemLevelId || null,
		};

		params = providerHelper.getObjectWithFields(params);

		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/banks/' + options.bankId + '/card-types', {
				before(request) {
					if (this.previousRequest) {
						this.previousRequest.abort();
					}

					this.previousRequest = request;
				},
				params
			})
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	uploadCardTypePhoto(context, cardTypeId, image){
		var formData = new FormData();
		formData.append('image', image);
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/card-types/' + cardTypeId + '/images/profile', formData)
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	addBankPartner(context, bankId, partner){
		var dateStart = partner.period && partner.period.from ? partner.period.from.format('YYYY-MM-DD') : null;
		var dateEnd = partner.period && partner.period.to ? partner.period.to.format('YYYY-MM-DD') : null;
		var anyBankProgram = partner.loyaltyProgramId == null ? true : false;
		partner.loyaltyProgramId = partner.loyaltyProgramId == 0 ? null : partner.loyaltyProgramId;
		var params = {
			description: partner.description,
			description_detail: partner.descriptionDetail,
			url: partner.url,
			without_duration: partner.withoutDuration,
			duration: {
				from: dateStart,
				to: dateEnd
			},
			card_types: partner.bankCardTypes,
			bank_loyality_program_id: partner.loyaltyProgramId,
			any_bank_program: anyBankProgram,
			bank_id: bankId,
			discount: Number(partner.discount) / 100,
			company_id: partner.companyId,
			all_places: partner.allPlaces,
		}
		if (partner.placesToAdd.length !== 0) {
			params.place_ids = partner.placesToAdd;
		}

		if(!partner.allPlaces){
			delete params.company_id;
		}

		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/banks/partners', JSON.stringify(params))
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	updateBankPartner(context, partner){
		let dateStart = partner.period && partner.period.from ? partner.period.from.format('YYYY-MM-DD') : null;
		let dateEnd = partner.period && partner.period.to ? partner.period.to.format('YYYY-MM-DD') : null;
		let anyBankProgram = partner.loyaltyProgramId == null ? true : false;
		partner.loyaltyProgramId = partner.loyaltyProgramId == 0 ? null : partner.loyaltyProgramId;

		return new Promise((resolve, reject) => {
			return context.$http.put(consts.appUri + '/banks/partners/' + partner.partnerId, JSON.stringify({
				description: partner.description,
				description_detail: partner.descriptionDetail,
				url: partner.url,
				without_duration: partner.withoutDuration,
				duration: {
					from: dateStart,
					to: dateEnd
				},
				card_types: partner.bankCardTypes,
				// place_id: partner.placeId,
				bank_id: partner.bankId,
				bank_loyality_program_id: partner.loyaltyProgramId,
				any_bank_program: anyBankProgram,
				discount: Number(partner.discount) / 100,
				all_places: partner.allPlaces,
				company_id: partner.companyId,
			}))
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	getBankPartner(context, partnerId){
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/banks/partners/' + partnerId)
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	deleteBankPartner(context, partnerId){
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + '/banks/partners/' + partnerId)
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	getBanksPartners(context, options={}){
		let params = {
			bank_id: options.bankId || '',
			expired: options.expired || null,
			active: options.active || null,
			search: options.search || '',
			limit: options.limit || 10,
			offset: options.offset || 0,
			place_category_id: options.place_category_id || null,
			company_id: options.companyId || '',
			place_id: options.placeId || '',
			grouped: options.grouped ? true : false
		}

		params = providerHelper.getObjectWithFields(params);

		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/banks/partners',
				{
					before(request) {
						if (this.previousRequest) {
							this.previousRequest.abort();
						}

						this.previousRequest = request;
					},
					params: params
				})
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	getBankLoyaltyPrograms(context, bankId){
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/banks/' + bankId + '/loyality-programs')
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	getBankLoyaltyProgram(context, programId){
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/banks/loyality-programs/' + programId)
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	createBankLoyaltyProgram(context, bankId, name){
		return new Promise((resolve, reject) => {
			return context.$http.post(consts.appUri + '/banks/' + bankId + '/loyality-programs', JSON.stringify({
				name: name
			}))
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	updateBankLoyaltyProgram(context, programId, name){
		return new Promise((resolve, reject) => {
			return context.$http.put(consts.appUri + '/banks/loyality-programs/' + programId, JSON.stringify({
				name: name
			}))
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	deleteBankLoyaltyProgram(context, programId){
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + '/banks/loyality-programs/' + programId)
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	getRecognizedCardInfo (context, prefix) {
		const params = {
			prefix
		};
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + "/banks/recognize", { params })
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	createBankCard (context, card) {
		return new Promise((resolve, reject) => {
			return context.$http.post("banks/cards", JSON.stringify({
				name: card.name,
				credit: card.credit,
				card_type_id: card.cardType.id,
				bank: card.bank,
				bank_name: card.bank.name,
				bank_id: card.bank.id || null,
				color: card.color,
				last_four_digits: card.lastFourDigits || null,
				payment_system_id: card.payment_system_id || null,
				payment_system_level_id: card.level || null
			}))
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	updateBankCard (context, card, cardId) {
		return new Promise((resolve, reject) => {
			return context.$http.put(consts.appUri + "/banks/cards/" + cardId, JSON.stringify({
				name: card.name,
				credit: card.credit,
				card_type_id: card.cardType.id,
				bank: card.bank,
				bank_name: card.bank.name,
				bank_id: card.bank.id || null,
				color: card.color,
				last_four_digits: card.lastFourDigits || null,
				payment_system_id: card.payment_system_id || null,
				payment_system_level_id: card.level || null
			}))
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	deleteCard (context, cardId) {
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + "/banks/cards/" + cardId)
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	getBankCard (context, cardId) {
		return new Promise((resolve, reject) => {
			return Vue.http.get("banks/cards/" + cardId)
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				});
		});
	},
	getBankCards (context) {
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + "/banks/cards")
				.then(data => {
					resolve(data.body);
				})
				.catch(data => {
					reject(data);
				});
		});
	},
	getBankPartnerGroup(context, groupKey){
		return new Promise((resolve, reject) => {
			return context.$http.get(consts.appUri + '/banks/partners/' + groupKey + '/group')
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	updateBankPartnersGroup(context, partner){
		let dateStart = partner.period && partner.period.from ? partner.period.from.format('YYYY-MM-DD') : null;
		let dateEnd = partner.period && partner.period.to ? partner.period.to.format('YYYY-MM-DD') : null;
		let anyBankProgram = partner.loyaltyProgramId == null ? true : false;
		partner.loyaltyProgramId = partner.loyaltyProgramId == 0 ? null : partner.loyaltyProgramId;

		return new Promise((resolve, reject) => {
			return context.$http.put(consts.appUri + '/banks/partners/' + partner.groupKey + '/group', JSON.stringify({
				description: partner.description,
				description_detail: partner.descriptionDetail,
				url: partner.url,
				duration: {
					from: dateStart,
					to: dateEnd
				},
				without_duration: partner.withoutDuration,
				card_types: partner.bankCardTypes,
				place_id: partner.placesToAdd,
				bank_id: partner.bankId,
				loyality_program_id: partner.loyaltyProgramId,
				any_bank_program: anyBankProgram,
				discount: Number(partner.discount) / 100,
				all_partners: partner.allPlaces,
			}))
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},
	deleteBankPartnersGroup(context, groupKey){
		return new Promise((resolve, reject) => {
			return context.$http.delete(consts.appUri + '/banks/partners/' + groupKey + '/group')
				.then((data)=>{
					resolve(data.body);
				})
				.catch((data)=>{
					reject(data);
				})
		});
	},}