<script>
import lazyImages from "@/utils/lazyImages";
export default {
	data () {
		return {
			lazyImages
		}
	},
	props: {
		fill: {
			type: String,
			required: false,
			default: 'black'
		},
		stroke: {
			type: String,
			required: false,
			default: 'black'
		},
		strokeWidth: {
			type: [String, Number],
			required: false,
			default: '1'
		},
		width: {
			type: [String, Number],
			required: false,
			default: '10'
		},
		height: {
			type: [String, Number],
			required: false,
			default: '10'
		},
		isActive: {
			type: Boolean,
			required: false,
			default: false
		}
	}
}
</script>