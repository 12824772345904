import router from '@/router'
function resolveRouteByName(name) {
	try {
		console.log({ router })
		return router.resolve({ name })
	} catch (error) {
		console.error(error)
		return ''
	}
}


export const ROUTER_HELPER_ROUTES = {
	LOYALTY_CARD_UPDATE: 'loyaltyCardUpdate',
	LOYALTY_CARD_OPEN: 'loyaltyCardOpen',
	LOYALTY_CARD_CREATE: 'loyaltyCardCreate',
	LOYALTY_CARD_ISSUE: 'loyaltyCardIssue',
	LOYALTY_CARD_DESCRIPTION: 'loyaltyCardDescription',


	LOYALTY_CARDS_SHARED_OPEN: 'personSharedLoyaltyCards',
	LOYALTY_CARDS_VIEW: 'loyaltyCardsView',

	COMPANY_LOYALTY_PROGRAM: 'companyLoyaltyProgram',
	LOYALTY_PROGRAMS_PAGE: 'loyaltyProgramsPage',

	PERSONAL_PLACE_MEDIA_OPEN: 'personalPlaceMediaOpen',
	PERSONAL_STOCKS_OPEN: 'personStocks',
	PERSONAL_STOCK_ADD: 'stockCreate',
	PERSONAL_COMPANY_DISCOUNTS: 'companyDiscounts',
	PERSONAL_COMPANY_BONUS_EXCHANGES: 'companyBonusExchanges',
	PERSONAL_COMPANY_PLACES: 'companyPlacesPage',
	PERSONAL_COMPANY_MEDIA: 'companyMedia',

	PERSON_PAGE: 'personPage',

	CHEQUE_PAGE: 'chequePage',

	PERSONAL_PLACE_PAGE: 'personalPlacePage',
	PERSONAL_PLACES_LIST: 'personalPlacesList',
	PERSONAL_STOCKS_COMPILATIONS: 'personalStocksCompilations',
	PERSONAL_STOCKS_DISCOUNTS: 'personStocksDiscounts',
	PERSONAL_BANK_DISCOUNTS: 'personPlaceDiscountsByBankCard',

	TRANSACTION: 'transaction',

	PERSONAL_STOCK_PAGE: 'personStock'
}


/**
 * @type RouterHelperRoutes
 */
export const routes = {
	loyaltyCardDescription: {
		basic: 'PersonDescriptionLoyaltyCard',
		webview: ''
	},
	loyaltyCardIssue: {
		basic: 'personIssueCard',
		webview: '' // TODO добавить страницу в webview
	},
	loyaltyCardCreate: {
		basic: 'personAddCard',
		webview: 'webViewAddCardLoyalty'
		/** query: { programId: <number> } */
	},
	loyaltyCardUpdate: {
		basic: 'personEditCard',
		webview: 'webViewCardLoyaltyItem'
	},
	loyaltyCardOpen: {
		basic: 'personLoyaltyCard',
		webview: 'webViewCardLoyaltyItem'
		/** params: { loyaltyCardId: <number>, cardId: <number>  } **/
	},
	loyaltyCardsView: {
		basic: 'personLoyaltyCards',
		webview: 'webViewCardsLoyalty'
	},
	personSharedLoyaltyCards: {
		basic: 'personSharedLoyaltyCards',
		webview: 'webViewPersonSharedLoyaltyCards' // TODO Сделать страницу с shared картами под webview
	},
	personalPlaceMediaOpen: {
		basic: 'personPlaceMedia',
		webview: 'personPlaceMedia'
		// TODO сделать страницу с медиа ресурсами места
	},
	personalStocksOpen: {
		basic: 'personalStocksOpen',
		webview: 'webViewStocks'
	},
	personPage: {
		basic: 'personProfile',
		webview: 'personProfile' // TODO Сделать страницу пользователя webview
	},
	personalPlacePage: {
		basic: 'personPlace',
		webview: 'webViewPlace'
	},
	companyLoyaltyProgram: {
		basic: 'PersonDescriptionLoyaltyCard',
		webview: 'webViewCompanyLoyaltyProgramItem'
		// params: { companyId, programId }
	},
	personalStocksCompilations: {
		basic: 'personStockCompilations',
		webview: 'webViewStocksCompilations'
	},
	personStocksDiscounts: {
		basic: 'personStockDiscounts',
		webview: 'webViewStocksDiscounts'
	},
	loyaltyProgramsPage: {
		basic: 'personLoyaltyPrograms',
		webview: 'webViewLoyaltyPrograms'
	},
	stockCreate: {
		basic: 'personAddStock',
		webview: 'webViewStockItem'
	},
	personPlaceDiscountsByBankCard: {
		basic: 'personPlaceDiscountsByBankCard',
		webview: 'webViewPlaceBankDiscounts'
		// { bankDiscount: true }
	},
	personalPlacesList: {
		basic: 'personPlaces',
		webview: 'webViewBankPlaces',
		// { query: { bookmarked: <boolean>, company_id: <number> }  }
	},
	companyDiscounts: {
		basic: 'companyDiscounts',
		webview: 'webViewCompanyDiscounts'
	},
	// query.type_id: <number|string>  query.has_bank_discounts: <boolean>  params.companyId: <number>
	// query.company: <JSON.stringify>
	companyBonusExchanges: {
		basic: 'companyBonusExchanges',
		webview: 'webViewCompanyExchangePoints'
	},
	// query.company: <JSON.stringify>  params.companyId: <number>
	companyPlacesPage: {
		basic: 'companyPlacesPage',
		webview: 'webViewCompanyPlaces'
	},
	// query.company: <JSON.stringify>  params.companyId: <number> query.bookmarked: <boolean>
	companyMedia: {
		basic: 'companyMedia',
		webview: 'webViewCompanyMedia'
	},
	transaction: {
		basic: 'checkView',
		webview: 'webViewTransaction'
	},
	// params.transactionId: <number>; query.id: <number>
	personStock: {
		basic: 'personStock',
		webview: 'webViewPlaceStock'
	}
	// params.stockId: <number>
}


