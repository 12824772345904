import initIndexedDB from "@/utils/helpers/initIndexedDB";

export default class indexedDBProvider {
	constructor () {
		this.install()
	}

	install () {
		try {
			const request = initIndexedDB()
			request.onsuccess = () => {
				this.db = request.result
			}
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * @param { string } key
	 * @param { function } onSuccess
	 * @param { function }  onFailure
	 */
	getAll (key, onSuccess = () => { console.log('Indexed DB success') }, onFailure = () => { console.error('Indexed DB failed') }) {
		let transaction = this.db.transaction(key, "readonly");
		let request = transaction.objectStore(key).getAll();

		request.onsuccess = () => onSuccess(request.result);
		request.onerror = () => onFailure(request.result)
	}

	/**
	 *
	 * @param { string } key
	 * @param { array } items
	 */
	rewrite (key, items) {
		let db = this.db;
		let WriteTransaction = db.transaction(key, "readwrite");
		let records = WriteTransaction.objectStore(key);
		records.clear();

		for (let i = 0; i < items.length; i++) {
			let WriteTransaction = db.transaction(key, "readwrite");
			let cards = WriteTransaction.objectStore(key);
			cards.put(items[i]);
		}
	}
}