import {average} from "@/utils/helpers/common";

const brightnessBorder = 150

/**
 * @param { HTMLElement } el
 * @param { string } field
 */
export function getElementBrightness (el, field) {
	const color = window.getComputedStyle(el, null).getPropertyValue(field)
	const rgb = color.replace('rgb(', '').replace(')', '').split(', ')
	return average(rgb)
}

export function isElementBright (el, field) {
	const brightness = getElementBrightness(el, field)
	return brightness > brightnessBorder
}

export function getThemeStyles (logoStyle) {
	if (logoStyle === 'white') {
		return {
			filter: 'grayscale(100%) brightness(0%) invert(100%)'
		}
	} else {
		return {
			filter: 'grayscale(100%) brightness(0%)'
		}
	}
}