import importPageFiles from './importPageFiles.js'
import RouterHelper from '@/router/routerHelper.js'

var routerHelper = new RouterHelper();

const BankSettings = importPageFiles.OrganizationSettings;
const BankTariffs = importPageFiles.BankTariffs;

const PlaceSearch = importPageFiles.PlaceSearch;
const OwnerRegistration = importPageFiles.OwnerRegistration;
const AddPlace = importPageFiles.AddPlace;
const AttachPlaceToOrganization = importPageFiles.AttachPlaceToOrganization;

const BankCommon = importPageFiles.OrganizationCommon;
const BankCommonMainInfo = importPageFiles.OrganizationCommonMainInfo;
const BankStocks = importPageFiles.OrganizationStocks;
const BankNewStock = importPageFiles.OrganizationNewStock;

const BankLoyaltyProgram = importPageFiles.BankLoyaltyProgram;
const BankLoyaltyProgramBankCards = importPageFiles.BankLoyaltyProgramBankCards;
const BankLoyaltyProgramPartners = importPageFiles.BankLoyaltyProgramPartners;
const BankLoyaltyProgramAddOrganization = importPageFiles.BankLoyaltyProgramAddOrganization;
const BankLoyaltyProgramRewards = importPageFiles.BankLoyaltyProgramRewards;
const BankLoyaltyProgramPartnersHandbook = importPageFiles.BankLoyaltyProgramPartnersHandbook;

const BankRequisites = importPageFiles.OrganizationRequisites;
const BankRequisitesEntity = importPageFiles.OrganizationRequisitesEntity;

const BankPlace = importPageFiles.OrganizationPlace;
const BankPlaceMainInfo = importPageFiles.OrganizationPlaceMainInfo;
const BankPlacePushNotice = importPageFiles.OrganizationPlacePushNotice;
const BankPlacePhotosAndVideos = importPageFiles.OrganizationPlacePhotosAndVideos;
const BankPlaceDiscountReviews = importPageFiles.OrganizationPlaceDiscountReviews;
const BankPlaceReviews = importPageFiles.OrganizationPlaceReviews;
const BankPlaceEvents = importPageFiles.OrganizationPlaceEvents;
const BankPlaceAdvertisement = importPageFiles.OrganizationPlaceAdvertisement;
const BankPlaceAdditionalInformation = importPageFiles.OrganizationPlaceAdditionalInformation;
const BankPlacesAdmin = importPageFiles.OrganizationPlacesAdmin;
const BankPlacesAdminArchive = importPageFiles.OrganizationPlacesModeratorArchive;
const BankPlacesCashier = importPageFiles.OrganizationPlacesCashier;
const BankPlacesCashierArchive = importPageFiles.OrganizationPlacesCashierArchive;
const BankPlacesCashierOperations = importPageFiles.OrganizationPlacesCashierOperations;

const BankPlaceGiftSertificates = importPageFiles.OrganizationPlaceGiftSertificates;
const BankPlaceCoupons = importPageFiles.OrganizationPlaceCoupons;
const BankPlaceMedia = importPageFiles.OrganizationPlaceMedias;

export default [
{
	path: '/bank/loyalty-program',
	name: 'bankLoyaltyProgram',
	component: BankLoyaltyProgram,
	beforeEnter: routerHelper.beforeBankEnter,
	children:[
	{
		path: '/bank/loyalty-program/bank-cards',
		name: 'bankLoyaltyProgramBankCards',
		component: BankLoyaltyProgramBankCards,
	},
	{
		path: '/bank/loyalty-program/partners',
		name: 'bankLoyaltyProgramPartners',
		component: BankLoyaltyProgramPartners,
	},
	{
		path: '/bank/loyalty-program/add-organization',
		name: 'bankLoyaltyProgramAddOrganization',
		component: BankLoyaltyProgramAddOrganization,
	},
	{
		path: '/bank/loyalty-program/rewards',
		name: 'bankLoyaltyProgramRewards',
		component: BankLoyaltyProgramRewards,
	},
	// {
	// 	path: '/bank/loyalty-program/partners-handbook',
	// 	name: 'bankLoyaltyProgramPartnersHandbook',
	// 	component: BankLoyaltyProgramPartnersHandbook,
	// },
	]
},
{
	path: '/bank/common',
	name: 'bankCommon',
	component: BankCommon,
	beforeEnter: routerHelper.beforeBankEnter,
	children: [
	{
		path: '/bank/common/main-info',
		name:'bankCommonMainInfo',
		component: BankCommonMainInfo,
	},
	{
		path: '/bank/common/discounts/stocks',
		name: 'bankStocksCommon',
		component: BankStocks,
		props: { type: 'organizationCommon' },
		beforeEnter: routerHelper.beforeBankEnter
	},
	{
		path: '/bank/common/reviews',
		name: 'bankReviewsCommon',
		component: BankPlaceReviews,
		props: { type: 'organizationCommon' },
		beforeEnter: routerHelper.beforeBankEnter
	},
	{
		path: '/bank/common/discount-reviews',
		name:'bankCommonDiscountReviews',
		component: BankPlaceDiscountReviews,
		props: { type: 'organizationCommon' },
	},
	{
		path: '/bank/common/photos-and-videos',
		name:'bankCommonPhotosAndVideos',
		component: BankPlacePhotosAndVideos,
		props: (route) => ({ id: route.params.id, type: 'organizationCommon' }),
	},
	{
		path: '/bank/common/discounts/stocks/create-stock',
		name: 'bankNewStockCommon',
		component: BankNewStock,
		props: { type: 'organizationCommon' },
		beforeEnter: routerHelper.beforeBankEnter
	},
	]
},
{
	path: '/bank/settings',
	name: 'bankSettings',
	component: BankSettings,
	beforeEnter: routerHelper.beforeBankEnter
},
{
	path: '/bank/settings/requisites',
	name: 'bankRequisites',
	component: BankRequisites,
	beforeEnter: routerHelper.beforeBankEnter,
	children: [
	{
		path: '/bank/settings/requisites/entity',
		name:'bankRequisitesEntity',
		component: BankRequisitesEntity,
	}]
},
{
	path: '/bank/tariffs',
	name: 'bankTariffs',
	component: BankTariffs,
	beforeEnter: routerHelper.beforeBankEnter
},
{
	path: '/bank/place/:id',
	name: 'bankPlace',
	component: BankPlace,
	props: true,
	beforeEnter: routerHelper.beforeBankEnter,
	children: [
	{
		path: '/bank/place/:id/main-info',
		name:'bankPlaceMainInfo',
		component: BankPlaceMainInfo,
		props: (route) => ({ id: route.params.id, type: 'organization' })
	},
	{
		path: '/bank/place/:id/push-notice',
		name:'bankPlacePushNotice',
		component: BankPlacePushNotice,
		props: true
	},
	{
		path:'/bank/place/:id/discounts/stocks',
		name: 'bankPlaceCurrentStocks',
		component: BankStocks,
		props: (route) => ({ id: route.params.id, type: 'organizationPlace' }),
	},
	{
		path: '/bank/place/:id/discounts/stocks/create-stock',
		name: 'bankNewStock',
		component: BankNewStock,
		beforeEnter: routerHelper.beforeBankEnter,
		props: (route) => ({ id: route.params.id, type: 'organizationPlace' }),
	},
	// {
	// 	path:'/bank/place/:id/discounts/gift-sertificates',
	// 	name: 'bankPlaceGiftSertificates',
	// 	component: BankPlaceGiftSertificates,
	// 	props: true,
	// },
	// {
	// 	path:'/bank/place/:id/discounts/coupons',
	// 	name: 'bankPlaceCoupons',
	// 	component: BankPlaceCoupons,
	// 	props: true,
	// },
	{
		path: '/bank/place/:id/photos-and-videos',
		name:'bankPlacePhotosAndVideos',
		component: BankPlacePhotosAndVideos,
		props: (route) => ({ id: route.params.id, type: 'organization' }),
	},
	{
		path: '/bank/place/:id/discount-reviews',
		name:'bankPlaceDiscountReviews',
		component: BankPlaceDiscountReviews,
		props: true,
	},
	{
		path: '/bank/place/:id/reviews',
		name:'bankPlaceReviews',
		component: BankPlaceReviews,
		props: true,
	},
	// {
	// 	path: '/bank/place/:id/events',
	// 	name:'bankPlaceEvents',
	// 	component: BankPlaceEvents,
	// 	props: true,
	// },
	// {
	// 	path: '/bank/place/:id/advertisement',
	// 	name:'bankPlaceAdvertisement',
	// 	component: BankPlaceAdvertisement,
	// 	props: true,
	// },
	{
		path: '/bank/place/:id/additional-info',
		name:'bankPlaceAdditionalInformation',
		component: BankPlaceAdditionalInformation,
		props: (route) => ({ id: route.params.id, type: 'organization' }),
	},]
},
{
	path: '/bank/place/:id/:mediaType/:user',
	name: 'BankPlaceMedia',
	component: BankPlaceMedia,
	beforeEnter: routerHelper.beforeBankEnter,
	props: (route) => ({ id: route.params.id, user: route.params.user, mediaType: route.params.mediaType, type: 'organization' }),
},
{
	path: '/bank/:id/owner-registration',
	name: 'bankOwnerRegistration',
	component: OwnerRegistration,
	beforeEnter: routerHelper.beforeUserEnter,
	props: true,
},
{
	path: '/bank/places-admin',
	name: 'bankPlacesAdmin',
	component: BankPlacesAdmin,
	beforeEnter: routerHelper.beforeBankEnter,
},
{
	path: '/bank/places-cashier',
	name: 'bankPlacesCashier',
	component: BankPlacesCashier,
	beforeEnter: routerHelper.beforeBankEnter,
},
{
	path: '/bank/places-cashier/archive',
	name: 'bankPlacesCashierArchive',
	component: BankPlacesCashierArchive,
	beforeEnter: routerHelper.beforeBankEnter,
},
{
	path: '/bank/places-cashier/:id/operations',
	name: 'bankPlacesCashierOperations',
	component: BankPlacesCashierOperations,
	beforeEnter: routerHelper.beforeBankEnter,
},
{
	path: '/bank/places-admin/archive',
	name: 'bankPlacesAdminArchive',
	component: BankPlacesAdminArchive,
	beforeEnter: routerHelper.beforeBankEnter,
},
{
	path: '/bank/add-place',
	name: 'addPlaceToBank',
	component: AddPlace,
	beforeEnter: routerHelper.beforeUserEnter,
	props: { type: 'create' }
},
{
	path: '/bank/add-and-attach-place',
	name: 'addAndAttachPlaceToBank',
	component: AddPlace,
	beforeEnter: routerHelper.beforeUserEnter,
	props: { type: 'createAndAttach' }
},
{
	path: '/bank/place/:id/offer-place-changes',
	name: 'offerPlaceBankChanges',
	component: AddPlace,
	beforeEnter: routerHelper.beforeUserEnter,
	props: (route) => ({ id: route.params.id, type: 'update' })
},
{
	path: '/bank/place-search/:type',
	name: 'placeSearchBank',
	component: PlaceSearch,
	props: true,
	beforeEnter: routerHelper.beforeUserEnter
},
{
	path: '/bank/:id/attach-place',
	name: 'attachPlaceToBank',
	component: AttachPlaceToOrganization,
	props: true,
	beforeEnter: routerHelper.beforeOrganizationEnter,
},]