<template>
    <router-link :to="'/organization-admin'">
        <div class="sidebar-logo">
            <IconLogoBenefitty />
        </div>
    </router-link>
</template>

<script>
    import IconLogoBenefitty from '@/components/baseComponents/icons/IconLogoBenefitty.vue';

    export default {
        name: 'SidebarLogo',

        components: {
            IconLogoBenefitty,
        },
    }
</script>

<style lang="scss" scoped>
    .sidebar-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 266px;
        height: 100px;
        padding: 12px 14px;

        &__text {
            margin: 0;
            font-size: 41px;
            font-weight: 700;
            background: linear-gradient(135deg, #18d6c5 0%, #18a7c5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
</style>