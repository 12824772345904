import Vue from 'vue'

export const changeBackpaths = (state, backpaths) => {
	state.backpaths = backpaths;
};

export const changeAuthentificationStatus = (state, user) => {
	state.user = user;
};

export const checkNewsfeed = (state, count) => {
	state.unreadNotifications = count;
};

export const changeUserResetPasswordState = (state, userResetPassword) => {
	state.userResetPassword = userResetPassword;
};

export const changeUserRole = (state, userRole) => {
	state.userRole = userRole;
};

export const changeIsModerator = (state, isModerator) => {
	state.isModerator = isModerator;
};

export const changeModeratorLink = (state, moderatorLink) => {
	state.moderatorLink = moderatorLink;
};

export const googleMapsInitialized = (state) => {
	state.isGoogleMapsInitialized = true;
};

export const setCompanyAbilities = (state, companyAbilities) => {
	state.companyAbilities = companyAbilities;
};

export const setUserProfile = (state, userProfile) => {
	state.userProfile = userProfile;
};

export const setContent = (state, content) => {
	state.content = content;
};

export const setContentProperty = (state, { propertyName, contentProperty }) => {
	state.content[propertyName] = contentProperty;
};

export const deleteContentProperty = (state, propertyName) => {
	delete state.content[propertyName];
};

/**
 *
 * @param state
 * @param { AuthorizationModalOptions } authorizationModalSettings
 */
export const setAuthorizationModalSettings = (state, authorizationModalSettings) => {
	state.authorizationModalSettings.isRoleChoose = authorizationModalSettings.isRoleChoose || false;
	state.authorizationModalSettings.isLoginForm = authorizationModalSettings.isLoginForm || false;
	state.authorizationModalSettings.isRegistrationForm = authorizationModalSettings.isRegistrationForm || false;
	state.authorizationModalSettings.role = authorizationModalSettings.role || 'person';
	state.authorizationModalSettings.routeTo = authorizationModalSettings.routeTo || '';
	state.authorizationModalSettings.opacity = authorizationModalSettings.opacity || 0.6;
	state.authorizationModalSettings.isClosable = authorizationModalSettings.isClosable || true;
};

export const referralCode = (state, request) => {
	state.referralCode = request;
};

export const pdcCode = (state, request) => {
	state.pdcCode = request;
};

export const clearImplicitRequestsList = (state) => {
	state.implicitRequestsList = [];
};

export const pushRefreshTokenRequestsList = (state, request) => {
	state.refreshTokenRequestsList.push(request);
};

export const clearRefreshTokenRequestsList = (state) => {
	state.refreshTokenRequestsList = [];
};

export const setCurrentCompanyId = (state, companyId) => {
	state.currentCompanyId = companyId;
};

export const setCurrentBrandId = (state, brandId) => {
	state.currentBrandId = brandId;
};

export const setCompanies = (state, companies) => {
	state.companies = companies;
};

export const setCompany = (state, company) => {
	state.company = company;
};

export const setAdministratedCompanies = (state, administratedCompanies) => {
	state.administratedCompanies = administratedCompanies;
};

export const setIsPlaceAdministrator = (state, isPlaceAdministrator) => {
	state.isPlaceAdministrator = isPlaceAdministrator;
};

export const setIsVKMiniApp = (state, isVKMiniApp) => {
	state.isVKMiniApp = isVKMiniApp;
};

export const setIsShowBanner = (state, isShowBanner) => {
	state.isShowBanner = isShowBanner;
};

export const setVKMiniAppPlatform = (state, VKMiniAppPlatform) => {
	state.VKMiniAppPlatform = VKMiniAppPlatform;
};

export const setHeaderRouteName = (state, headerRouteName) => {
	state.headerRouteName = headerRouteName;
};

export const setIsGeopositionAllowed = (state, isGeopositionAllowed) => {
	state.isGeopositionAllowed = isGeopositionAllowed;
};

export const setGeoposition = (state, geoposition) => {
	state.geoposition = geoposition;
};

export const setIsRouteLoading = (state, isRouteLoading) => {
	state.isRouteLoading = isRouteLoading;
};

export const setPlacesCount = (state, placesCount) => {
	state.placesCount = placesCount;
};

export const setErrorText = (state, errorText) => {
	state.errorText = errorText;
};

export const setVKUserInfo = (state, VKUserInfo) => {
	state.VKUserInfo = VKUserInfo;
};

export const setPersonStock = (state, personStock) => {
	state.personStock = personStock;
};

export const setVkLoginModalSettings = (state, vkLoginModalSettings) => {
	state.vkLoginModalSettings = vkLoginModalSettings
	state.data = vkLoginModalSettings.data
	state.callback = vkLoginModalSettings.callback
};

export const setMarkets = (state, markets) => {
	state.markets = markets;
}

export const SET_COUNT_ATTEMPTS = (state) => {
	state.countPaymentAttempts++;
}

export const setMetaData = (state, { field, value }) => {
	if (state.metaData) {
		Vue.set(state.metaData, field, value)
	} else {
		Vue.set(state, 'metaData', { [field]: value })
	}
}

export const setSettings = (state, settings) => {
	Vue.set(state, 'settings', { ...state.settings, ...settings })
}

export const SET_USER_DISCOUNT_CARDS = (state, cards) => {
	Vue.set(state, 'userDiscountCards', cards)
}

export const SET_TOKEN = (state, token) => {
	let user = {
		authenticated: true,
		token: token,
		refreshToken: '',
		type: ''
	};
	Vue.set(state, 'user', user);
}

export const SET_DONE_STEPS = (state, steps) => {
	Vue.set(state, 'doneSteps', steps)
}

export const SET_LOYALTY_COMPANY = (state, company) => {
	Vue.set(state, 'loyaltyProgramCompany', company);
}

export const SET_LOYALTY_USER = (state, user) => {
	Vue.set(state, 'loyaltyProgramUser', user);
}

export const SET_BENEFITTY_STEPS = (state, steps) => {
	Vue.set(state, 'benefittySteps', steps);
}

export const SET_SIDEBAR_OPEN = (state, payload) => {
	Vue.set(state, 'sidebarOpen', payload);
}

export const SET_MAIN_INFO_IS_LOAD = (state, payload) => {
	Vue.set(state, 'mainInfoIsLoad', payload);
}

export const SET_ENTER_FROM = (state, payload) => {
	Vue.set(state, 'enterFrom', payload);
}

export const SET_REFRESH_TOKEN_EXCEPTION = (state, payload) => {
	Vue.set(state, 'refreshTokenException', payload);
}