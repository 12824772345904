import consts from "@/utils/consts";
export default class GoogleProvider {
	constructor () {
		this.initService()
	}

	initService () {
		const body = document.getElementsByTagName('body')[0];
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://accounts.google.com/gsi/client';
		script.defer = true
		script.async = true

		script.onload = async () => {
			try {
				const config = consts.googlePLus;
				config.callback = (tokenResponse) => {
					if (tokenResponse && tokenResponse.access_token) {
						localStorage.setItem('google_auth_token', tokenResponse.access_token)
					}
				}
				this.client = google.accounts.oauth2.initTokenClient(config);
			} catch (error) {
				console.error(error)
			}
		};

		body.appendChild(script);
	}

	getToken () {
		return new Promise((resolve, reject) => {
			try {
				this.client.callback = ({ access_token }) => {
					resolve(access_token)
				}
				this.client.requestAccessToken()
			} catch (error) {
				reject(error)
			}
		})
	}
}