import Vue from 'vue'
export default {
	namespaced: true,
	state: () => ({
		device: {
			resolution: 0,
			type: ''
		}
	}),
	getters: {
		options: state => state.device,
		type: state => state.device.type,
		isType: state => value => state.device.type === value
	},
	mutations: {
		SET_DEVICE_OPTIONS (state, options) {
			Vue.set(state, 'device', { resolution: options.resolution, type: options.type })
		}
	}
}