<template>
    <li class="sidebar-item">
        <div class="sidebar-item__link" @click="routeTo">
            <div class="sidebar-item__head" @click.stop="clickHeader">
                <component :is="icon" class="sidebar-item__icon"></component>
    
                <span
                    class="sidebar-item__title"
                    :class="[{ '_no-icon': !icon }, { '_current': $route.path === link }]"
                    v-html="title"
                >
                </span>
            </div>

            <div
                v-if="subitems.length"
                class="sidebar-item__subitems-title"
                @click.stop="toggleSubitems"
            >
                <DropdownIcon :active="isShowSubItems"/>
            </div>
        </div>

        <div class="sidebar-item__content" :class="{ '_open': isShowSubItems }">
            <div
                class="sidebar-item__item"
                v-for="item in subitems"
                :key="`sidebar-item__${item.link}`"
                @click="routeToFromSubitem(item.link)"
            >
                <Component :is="item.icon" class="sidebar-item__icon" />

                <span
                    class="sidebar-item__title"
                    :class="[{ '_no-icon': !item.icon }, { '_current': $route.path === item.link }]"
                    v-html="item.title"
                >
                </span>
            </div>
        </div>

        <Transition name="fade">
            <div v-if="count" class="sidebar-item__count">
                {{ count }}
            </div>
        </Transition>
    </li>
</template>

<script>
    import DropdownIcon from './icons/DropdownIcon.vue';

    export default {
        name: 'SidebarItem',

        data() {
            return {
                isShowSubItems: false,
            };
        },

        props: {
            link: {
                type: String,
                default: '',
            },

            title: {
                type: String,
                default: '',
            },

            icon: {
                type: String,
                default: '',
            },

            subitems: {
                type: Array,
                default: () => ([]),
            },

            count: {
                type: Number,
                defailt: 0,
            },
        },

        components: {
            PhotoIcon: () => import('./icons/PhotoIcon.vue'),
            DiscountIcon: () => import('./icons/DiscountIcon.vue'),
            PlaceIcon: () => import('./icons/PlaceIcon.vue'),
            SettingsIcon: () => import('./icons/SettingsIcon.vue'),
            AdminsIcon: () => import('./icons/AdminsIcon.vue'),
            CashierIcon: () => import('./icons/CashierIcon.vue'),
            MachinesIcon: () => import('./icons/MachinesIcon.vue'),
            CabinetIcon: () => import('./icons/CabinetIcon.vue'),
            MaterialsIcon: () => import('./icons/MaterialsIcon.vue'),
            RequisitesIcon: () => import('./icons/RequisitesIcon.vue'),
            ReviewsIcon: () => import('./icons/ReviewsIcon.vue'),
            HideIcon: () => import('./icons/HideIcon.vue'),
            NoticeIcon: () => import('./icons/NoticeIcon.vue'),
            TeachIcon: () => import('./icons/TeachIcon.vue'),
            StatsIcon: () => import('./icons/StatsIcon.vue'),
            LoyaltySettingsIcon: () => import('./icons/LoyaltySettingsIcon.vue'),
            DropdownIcon,
        },

        methods: {
            toggleSubitems() {
                this.isShowSubItems = !this.isShowSubItems;
            },


            routeTo() {
                if (!this.subitems.length) {
                    this.$router.push({ path: this.link });
                }  
            },

            routeToFromSubitem(link) {
                this.$router.push({ path: link });
            },

            clickHeader() {
                if (this.subitems.length) {
                    this.toggleSubitems();
                } else {
                    this.routeTo();
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .sidebar-item {
        position: relative;
        display: flex;
        width: 100%;
        margin-top: 16px;
        cursor: pointer;
        flex-direction: column;

        @include respond-to(lTablet) {
            margin-top: 0;
        }

        &__count {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: calc(50% - 9px);
            right: 0;
            border-radius: 4px;
            background: #007bff;
            height: 18px;
            padding: 0px 8px;
            color: #fff;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;

            @include respond-to(lTablet) {
                font-size: 8px;
                font-weight: 400;
                line-height: 12px;
                height: 14px;
            }
        }

        &__head {
            display: flex;
            align-items: center;
        }

        &__link {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__item {
            margin-top: 10px;
            margin-left: 20px;
            display: flex;
        }

        &__content {
            display: flex;
            flex-direction: column;
            max-height: 0;
            overflow: hidden;
            transition: $default-transition;

            &._open {
                max-height: 1000px;
            }
        }
        
        &__icon {
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 9px;

            @include respond-to(lTablet) {
                width: 16px;
                height: 16px;
                margin-right: 6px;
            }
        }

        &__title, &__subitems-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #475569;
            white-space: nowrap;

            &._no-icon {
                margin-left: 29px;

                @include respond-to(lTablet) {
                    margin-left: 0;
                }
            }

            &._current {
                color: #007bff;
            }

            &.__active {
                color: #007bff;
            }

            @include respond-to(lTablet) {
                font-size: 12px;
                line-height: 18px;
            }
        }        
    }
    .dropdown-content__item {
        display: flex;
        align-items: center;
        margin-top: 16px;
        cursor: pointer;
        justify-content: space-between;

        .item-content__wrapper {
            display: flex;
            align-items: center;
            gap: 9px;
        }


        .item-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #475569;

            &.__active {
                color: #007BFF;
            }
        }

        .item-sum {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            width: 23px;
            height: 18px;
            background: #007BFF;
            border-radius: 4px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }
    }
</style>