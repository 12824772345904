export default class InstanceOptions {
	constructor(options = {}) {
		this.initialData = options
		this.setData(options)
	}

	setData (options) {
		this.limit = options.limit || 10
		this.offset = options.offset || 0
		this.isAllLoaded = options.isAllLoaded || false
		this.isLoading = options.isLoading || false
		this.paginate = options.paginate || false
		this.page = options.page || null
		this.items = options.items || []
	}

	setEmpty (flag) {
		this.isEmpty = flag
	}

	updateSilentPagination ({ offset = null, limit = null } = { offset: null, limit: null }) {
		this.offset = (offset === null) ? this.offset + this.limit : offset
		this.limit = (limit === null) ? this.limit : limit
	}

	updatePagination ({ page = null } = { page: null }) {
		this.page = page === null ? this.page + 1 : page
		this.paginate = true
	}

	toggleLoading (flag) {
		if (flag !== null) {
			this.isLoading = flag
		} else {
			this.isLoading = !this.isLoading
		}
	}

	toggleAllLoaded (flag) {
		if (flag !== null) {
			this.isAllLoaded = flag
		} else {
			this.isAllLoaded = !this.isAllLoaded
		}
	}

	addItems (items) {
		if (items.length === 0) {
			this.toggleAllLoaded(true)
		} else {
			this.items = [ ...this.items, ...items ].filter((value, index, self) =>
					index === self.findIndex((item) => (
						item.id === value.id
					))
			)
			this.toggleAllLoaded(this.limit > items.length)
		}
	}

	setItems (items) {
		this.items = items
	}

	prependItems (items) {
		this.items = [ ...items, ...this.items ]
	}

	getParams () {
		return {
			limit: this.limit,
			offset: this.offset,
			paginate: this.paginate,
			page: this.page,
		}
	}

	getAll () {
		return {
			limit: this.limit,
			offset: this.offset,
			isAllLoaded: this.isAllLoaded,
			isLoading: this.isLoading,
			paginate: this.paginate,
			page: this.page,
			items: this.items
		}
	}

	resetData () {
		const data = {
			...this.initialData,
			offset: 0,
			limit: 0,
			page: 0,
			allItemsLoaded: false
		}
		this.setData(data)
		this.setItems([])
	}


	/**
	 * @param callback
	 * @param params
	 * @param context
	 * @returns {Promise<unknown>}
	 */
	fetchItems (callback, params = {}, context) {
		return new Promise(async (resolve, reject) => {
			try {
				const options = this.getParams()
				if (this.isAllLoaded) {
					reject(new Error('All items loaded'))
				} else {
					this.setEmpty(false)
					this.toggleLoading(true)
					const mergedOptions = {
						...options,
						...params
					}
					let result = null
					if (context) {
						result = await callback(context, mergedOptions)
					} else {
						result = await callback(mergedOptions)
					}

					if (Array.isArray(result)) {
						this.addItems(result)
						resolve(result)
					} else if (typeof result === 'object') {
						this.addItems(result.data)
						resolve(result)
					}
				}
			} catch (error) {
				console.error(error)
				reject(error)
			} finally {
				this.toggleLoading(false)
				this.setEmpty(this.items.length === 0 && !this.isLoading)
			}
		})
	}
}