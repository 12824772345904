import Vue from "vue";
export default {
	namespaced: true,
	state: () => ({
		geo: false,
	}),
	getters: {
		geoInit: (state) => state.geo,
	},
	mutations: {
		SET_GEO(state, falg) {
			Vue.set(state, "geo", falg);
		},
	},

	actions: {
		setGeo({ commit }, data) {
			commit("SET_GEO", data);
		},
	},
};
