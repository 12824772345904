const state = {
  settings: {},
  metaData: null,
  user: {
    authenticated: false,
    token: '',
    refreshToken: '',
    type: ''
  },
  userProfile: {},
  userResetPassword: {
    username: '',
    token: ''
  },
  userRole: '',
  isModerator: false,
  moderatorLink: '',
  isGoogleMapsInitialized: false,
  companyAbilities: {},
  currentCompanyId: null,
  currentBrandId: null,
  content: {},
  authorizationModalSettings: {
    isLoginForm: false,
    isRegistrationForm: false,
    role: '',
    routeTo: '',
    isClosable: true
  },
  referralCode: null,
  pdcCode: null,
  companies: [],
  company: {},
  administratedCompanies: [],
  isPlaceAdministrator: false,
  isVKMiniApp: false,
  isShowBanner: true,
  VKMiniAppPlatform: '',
  headerRouteName: '',
  /**
   *  изначальное состояние, которое после, в зависимости от действий юзера, меняется
   *  на true или false (понадобилось, когда возникла необходимость знать, отклонил
   *  юзер геопозицию или ещё не разрешал)
   */
  isGeopositionAllowed: null,
  geoposition: {},
  isRouteLoading: false,
  placesCount: null,
  errorText: '',
  VKUserInfo: null,
  personStock: {},
  vkLoginModalSettings: {
    show: false,
    data: {},
    callback: null
  },
  backpaths: [],
  unreadNotifications: -1,

  userDiscountCards: [],

  markets: localStorage.getItem('markets') === null ? [] : JSON.parse(localStorage.getItem('markets')),
  loyaltyProgramCompany: {},
  loyaltyProgramUser: {},
  countPaymentAttempts: 0,

  enterFrom: '',
  doneSteps: [],

  sidebarOpen: true,
  benefittySteps: [],
  mainInfoIsLoad: false,
  refreshTokenException: false,
}

export default state