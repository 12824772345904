import Vue from 'vue'
import Vuex from 'vuex'
import state from './state.js'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

Vue.use(Vuex)

import partner from '@/store/modules/partner';
import device from '@/store/modules/device';
import place from '@/store/modules/place';
import media from '@/store/modules/media';
import global from '@/store/modules/global';
import stocks from '@/store/modules/stocks';
import organization from '@/store/modules/organization';
import route from '@/store/modules/route';
import geolocation from '@/store/modules/geolocation';

const store = new Vuex.Store({
	state,
	getters,
	actions,
	mutations,
	modules: {
		partner,
		device,
		place,
		media,
		global,
		stocks,
		organization,
		route,
		geolocation,
	}
})

export default store