<template>
	<div v-if="!isVKMiniApp && isHeaderShown && $route.matched.length > 0">
		<header
			class="header"
			:class="{ '_ios-padding': false }"
			id="headerMain"
		>
			<div class="header__wrapper" :class="{ 'header__wrapper_large': isModerator || isOrganizationHeader }">
				<div v-if='isModeratorHeader'></div>
				<button v-if="!isModeratorHeader" @click="changeMenu('menuOpen')" class='header__burger'
					:class="menuOpen ? 'header__burger-active' : ''">
					<div></div>
					<div></div>
					<div></div>
				</button>
				<div class="header__logo">
					<router-link to="/">
						<span class="header__logo_text-large">BenefittY</span>
						<span class="header__logo_text-small" v-if="userRole == 'person'">Beta</span>
					</router-link>
				</div>

				<div class="header__menu" v-if="!isModerator && !isOrganizationHeader && userRole == 'person'"
					:class='menuOpen ? "header__menu-active" : ""'>
					<router-link to="/person/newsfeed?by=recommended" :class="{ 'header__menu_item-active': isNewsFeedActive }"
						class="header__menu_item position-relative">
						<img :src="lazyImages.header_menu_newspaper" alt="icon">
						<span>Лента</span>
						<div v-if="$store.getters.unreadNotifications === -1 || $store.getters.unreadNotifications > 0"
							:class="{ 'header__menu__red-indicator_small': $store.getters.unreadNotifications === -1 }"
							class="header__menu__red-indicator">
							{{ $store.getters.unreadNotifications > 0 ? $store.getters.unreadNotifications : '' }}
						</div>
					</router-link>
					<router-link to="/person/stocks/discounts?type=5&categoryId=0&categoryName=Все%20категории&icon_3x"
						:class="{ 'header__menu_item-active': $route.fullPath.includes('/person/stocks/discounts?type=5&categoryId=0&categoryName=Все%20категории&icon_3x') }"
						class="header__menu_item">
						<img :src="lazyImages.header_menu_cart" alt="icon">
						Онлайн-магазины с кешбэком
					</router-link>
					<router-link to="/person/stocks/compilations?categoryId=-1&categoryName=Подборки&icon_3x"
						:class="{ 'header__menu_item-active': $route.fullPath.includes('/person/stocks/compilations?categoryId=-1&categoryName=Подборки&icon_3x') }"
						class="header__menu_item">
						<img :src="lazyImages.header_menu_icon_tiket" alt="icon">
						Каталог скидок
					</router-link>
					<router-link to="/person/places" :class="{ 'header__menu_item-active': isPlacesActive }"
						class="header__menu_item">
						<img :src="lazyImages.header_menu_compas" alt="icon">
						Что рядом
					</router-link>
					<router-link to="/person/cards/loyalty-cards" :class="{ 'header__menu_item-active': isCardsActive }"
						class="header__menu_item">
						<img :src="lazyImages.header_menu_compas" alt="icon">
						Мои карты
					</router-link>
				</div>

				<div v-if="isOrganizationHeader && userRole == 'organization' && !isModerator" class="header__menu"
					:class='menuOpen ? "header__menu-active" : ""'>
					<router-link v-if="placesCount > 1" to="/organization/common/photos-and-videos" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}CommonMainInfo` }">
						<img :src="lazyImages.header_icon_setings" alt="icon">
						Общая информация
					</router-link>
					<router-link to="/organization" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}` }">
						<img :src="lazyImages.header_menu_cart" alt="icon">
						Мои торговые точки
					</router-link>
					<router-link v-if="brandsExist"
						:to="$store.getters.isModerator ? `${$store.getters.moderatorLink}/${userRole}/brands` : `/organization/brands`"
						class="header__menu_item" :class="{ 'header__menu_item-active': $route.name == `${userRole}Brand` }">
						<img :src="lazyImages.header_menu_cart" alt="icon">
						Мои бренды
					</router-link>
					<router-link to="/organization/loyalty-program/individual" class="header__menu_item"
						:class="{ 'header__menu_item-active': [`${userRole}LoyaltyProgram`, `${userRole}LoyaltyProgramIndividual`, `${userRole}LoyaltyProgramCashBack`, `${userRole}LoyaltyProgramRewards`, `${userRole}LoyaltyProgramBankCardDiscount`, `${userRole}LoyaltyProgramBankCardPaymentSystemDiscount`, `${userRole}LoyaltyProgramPartners`, `${userRole}PartnerLoyaltyPrograms`, `${userRole}LoyaltyProgramsAddPartners`].includes($route.name) }">
						<img :src="lazyImages.header_menu_icon_tiket" alt="icon">
						Программа лояльности
					</router-link>
					<router-link to="/organization/tariffs" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}ListOfTariffs` }">
						<img :src="lazyImages.header_icon_pack" alt="icon">
						Тарифы
					</router-link>
					<router-link to="/organization/settings"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}Settings` }" class="header__menu_item">
						<img :src="lazyImages.header_icon_setings" alt="icon">
						Настройки аккаунта
					</router-link>
				</div>

				<div v-if="isOrganizationHeader && userRole == 'bank' && !isModerator" class="header__menu"
					:class='menuOpen ? "header__menu-active" : ""'>
					<router-link to="/bank/loyalty-program/bank-cards"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}LoyaltyProgram` }" class="header__menu_item">
						<img :src="lazyImages.header_menu_icon_tiket" alt="icon">
						Программа лояльности
					</router-link>
					<router-link to="/bank/tariffs" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}Tariffs` }">
						<img :src="lazyImages.header_icon_pack" alt="icon">
						Тарифы
					</router-link>
					<router-link to="/bank" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}` }">
						<img :src="lazyImages.header_menu_cart" alt="icon">
						Мои торговые точки
					</router-link>
					<router-link to="/bank/common/main-info" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}CommonMainInfo` }">
						<img :src="lazyImages.header_icon_setings" alt="icon">
						Общая информация
					</router-link>
					<router-link to="/bank/settings" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}Settings` }">
						<img :src="lazyImages.header_icon_setings" alt="icon">
						Настройки аккаунта
					</router-link>
				</div>

				<div v-if="isOrganizationHeader && userRole == 'brand' && !isModerator" class="header__menu"
					:class='menuOpen ? "header__menu-active" : ""'>
					<router-link to="/brand/tariffs" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}Tariffs` }">
						<img :src="lazyImages.header_icon_pack" alt="icon">
						Тарифы
					</router-link>
					<router-link to="/brand/main-info" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `brandMainInfo` }">
						<img :src="lazyImages.header_menu_i" alt="icon">
						Основные функции
					</router-link>
					<router-link to="/brand/settings" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}Settings` }">
						<img :src="lazyImages.header_icon_setings" alt="icon">
						Настройки аккаунта
					</router-link>
				</div>

				<div v-if="isModerator && !isModeratorHeader" class="header__menu"
					:class='menuOpen ? "header__menu-active" : ""'>
					<router-link :to="`${$store.getters.moderatorLink}/${userRole}/common/main-info`" v-if="userRole != 'brand'"
						class="header__menu_item" :class="{ 'header__menu_item-active': $route.name == `${userRole}CommonMainInfo` }">
						<img :src="lazyImages.header_icon_setings" alt="icon">
						Общая информация
					</router-link>
					<router-link :to="`${$store.getters.moderatorLink}/${userRole}/loyalty-program/individual`"
						v-if="userRole == 'organization'"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}LoyaltyProgram` }" class="header__menu_item">
						<img :src="lazyImages.header_menu_icon_tiket" alt="icon">
						Программа лояльности
					</router-link>
					<router-link :to="`${$store.getters.moderatorLink}/${userRole}/loyalty-program/bank-cards`"
						v-if="userRole == 'bank'" :class="{ 'header__menu_item-active': $route.name == `${userRole}LoyaltyProgram` }"
						class="header__menu_item">
						<img :src="lazyImages.header_menu_icon_tiket" alt="icon">
						Программа лояльности
					</router-link>
					<router-link :to="`${$store.getters.moderatorLink}/${userRole}/main-info`" v-if="userRole == 'brand'"
						class="header__menu_item" :class="{ 'header__menu_item-active': $route.name == `brandMainInfo` }">
						<img :src="lazyImages.header_menu_i" alt="icon">
						Основные функции
					</router-link>
					<router-link :to="`${$store.getters.moderatorLink}/${userRole}/tariffs`" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}Tariffs` }">
						<img :src="lazyImages.header_icon_pack" alt="icon">
						Тарифы
					</router-link>
					<router-link v-if="userRole == 'organization'"
						:to="`${$store.getters.moderatorLink}/${userRole}/tariffs/edit`" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}ListOfTariffs` }">
						<img :src="lazyImages.header_icon_pack" alt="icon">
						Настройки тарифа и гранты
					</router-link>
					<router-link v-if="userRole == 'organization'" :to="`${$store.getters.moderatorLink}/${userRole}/brands`"
						class="header__menu_item" :class="{ 'header__menu_item-active': $route.name == `${userRole}Brand` }">
						<img :src="lazyImages.header_menu_cart" alt="icon">
						Мои бренды
					</router-link>
					<router-link :to="`${$store.getters.moderatorLink}/${userRole}`" v-if="userRole != 'brand'"
						class="header__menu_item" :class="{ 'header__menu_item-active': $route.name == `${userRole}` }">
						<img :src="lazyImages.header_menu_cart" alt="icon">
						Мои торговые точки
					</router-link>
					<router-link :to="`${$store.getters.moderatorLink}/${userRole}/settings`" class="header__menu_item"
						:class="{ 'header__menu_item-active': $route.name == `${userRole}Settings` }">
						<img :src="lazyImages.header_icon_setings" alt="icon">
						Настройки аккаунта
					</router-link>
				</div>

				<div class="header__profile" v-if="authenticated && !isGuest">
					<div @click="changeMenu('profileMenuOpen')" class="header__profile_name"><img :src="userProfileImageUrl"
							class='header__profile-photo d-md-inline-block header__profile_name-img'
							:class='profileMenuOpen ? "d-none" : ""' alt="profile-photo"><button
							:class="!profileMenuOpen ? 'd-none' : ''">
							<div></div>
							<div></div>
						</button></div>

					<div v-if="isPersonHeader" class="header__profile_menu"
						:class="{ 'header__profile_menu-active': profileMenuOpen }">
						<button @click="changeMenu('profileMenuOpen')" class="header__profile_menu-close">
							<div></div>
							<div></div>
						</button>
						<div class="header__profile_menu-image">
							<router-link to="/person/profile">
								<img :src="userProfileImageUrl" class="header__profile-photo" alt="icon">
							</router-link>
						</div>
						<router-link to="/person/profile" class="header__profile_menu-profile">
							<span>{{ userName }}</span>
						</router-link>
						<div class="header__profile_menu-edit">
							<router-link to="/person/profile-settings">Редактировать профиль</router-link>
						</div>
						<div class="header__profile_menu-wrapper">
							<!-- <router-link to="/cashback/rewards-history"><img :src="lazyImages.header_icon_stone" alt="icon">BenefittY CashBack</router-link> -->
							<!-- <router-link :class="{'header__profile_menu-linkActive': isRewardsHistoryActive}" to="/cashback/rewards-history"><img :src="lazyImages.header_icon_stone" alt="icon">BenefittY Rewards</router-link> -->
							<router-link :class="{ 'header__profile_menu-linkActive': isWalletActive }" to="/cashback/wallet"><img
									:src="lazyImages.header_icon_stone" alt="icon">BenefittY CashBack</router-link>
							<router-link :class="{ 'header__profile_menu-linkActive': isBankCardActive }" to="/person/cards/bank-cards">
								<img :src="lazyImages.header_icon_card" alt="icon">Банковские карты</router-link>
							<router-link :class="{ 'header__profile_menu-linkActive': isLoyaltyCardActive }"
								to="/person/cards/loyalty-cards"><img :src="lazyImages.header_icon_card" alt="icon">Карты лояльности
							</router-link>
							<router-link :class="{ 'header__profile_menu-linkActive': isBenefittyCardActive }"
								to="/person/benefitty-card"><img :src="lazyImages.header_menu_benefittycard" alt="icon">Карта BenefittY
							</router-link>
							<router-link :class="{ 'header__profile_menu-linkActive': isUserStocksActive }" to="/person/user-stocks">
								<img :src="lazyImages.header_menu_mydiscount" alt="icon">Мои личные скидки</router-link>
							<router-link :class="{ 'header__profile_menu-linkActive': isCashbackActive }" to="/person/stocks/discounts?type=5&categoryId=0&categoryName=Все%20категории&icon_3x"><img
									:src="lazyImages.header_menu_cart" alt="icon">Online магазины с cash-back</router-link>
							<a data-toggle="modal" data-target="#roleChooseModal"><img :src="lazyImages.header_icon_pack"
									alt="icon">Перейти в бизнес-аккаунт</a>
							<a @click="logOut"><img :src="lazyImages.header_icon_door" alt="icon">Выйти</a>
						</div>
					</div>

					<div v-if="(isOrganizationHeader || isModeratorHeader) && !isModeratorHeader" class="header__profile_menu"
						:class="profileMenuOpen ? 'header__profile_menu-active' : ''">
						<button @click="changeMenu('profileMenuOpen')" class="header__profile_menu-close">
							<div></div>
							<div></div>
						</button>
						<div class="header__profile_menu-image">
							<a>
								<img :src="userProfileImageUrl" class="header__profile-photo" alt="icon">
							</a>
						</div>
						<a class="header__profile_menu-profile">
							<span>{{ userName }}</span>
						</a>
						<div class="header__profile_menu-wrapper mt-3 pt-4">
							<p @click="logInAsPerson"><img :src="lazyImages.header_icon_pack" alt="icon">Перейти в пользовательский
								аккаунт</p>
							<a @click="logOut"><img :src="lazyImages.header_icon_door" alt="icon">Выйти</a>
						</div>
					</div>

					<div v-if="isModeratorHeader" class="header__profile_menu"
						:class="profileMenuOpen ? 'header__profile_menu-active' : ''">
						<button @click="changeMenu('profileMenuOpen')" class="header__profile_menu-close">
							<div></div>
							<div></div>
						</button>
						<div class="header__profile_menu-image">
							<a>
								<img class="header__profile-photo" :src="userProfileImageUrl" alt="icon">
							</a>
						</div>
						<a class="header__profile_menu-profile">
							<span>{{ userName }}</span><br>
							<span class="text-info">Администратор</span>
						</a>
						<div class="header__profile_menu-wrapper mt-3 pt-4">
							<a @click="logOut"><img :src="lazyImages.header_icon_door" alt="icon">Выйти</a>
						</div>
					</div>

				</div>
				<router-link v-else to="/" class="btn header__auth-btn">
					Войти
				</router-link>
			</div>
		</header>
		<RoleChooseModal />
	</div>
</template>
<script>
import authProvider from '@/utils/providers/authProvider.js';
import windowMixin from '@/mixins/window.js';
import consts from '@/utils/consts.js';
import RoleChooseModal from '@/components/person/modal/roleChoose.vue';
import userProvider from '@/utils/providers/userProvider.js';
import lazyImages from '@/utils/lazyImages.js';

export default {
	name: 'HeaderMain',
	props: {
		integrated: {
			type: Boolean,
			default: false
		},
		strictShowType: {
			type: String,
			default: ''
		}
	},
	mixins: [windowMixin],
	components: {
		RoleChooseModal
	},
	data: () => ({
		brandsExist: false,
		placesCount: 0,
		lazyImages: lazyImages,
		userRoles: consts.userRole,
		isHeaderShown: false,
		name: '',
		lastName: '',
		profileMenuOpen: false,
		menuOpen: false,
		emailConfirmed: false
	}),
	computed: {
		routeNamesWithoutHeader() {
			return [
				...this.routes.webView,
				'DocumentTermsOfUse',
				'DocumentPrivacyPolicy',
				'questions',
				'rewards-rules',
				'DocumentCashbackRules',
				'DocumentCashbackAction',
				'DocumentOffer',
				'DocumentPresentation',
				'DocumentPresentationForBusiness',
				'DocumentPresentationForBanks',
				'DocumentPresentationForBrands',
				'DocumentPresentationForBrands2',
				'DocumentPresentationPricesForBrands',
				'DocumentPresentationPricesForBusiness',
				'DocumentPresentationPricesForRetail',
				'DocumentAgentOffer',
				'DocumentRewardsRules',
				'DocumentPartnerApplication',
				'personMain',
				'DocumentAgreementOfTransferPersonalData',
				'ContactsProvideInfo',
				'organizationMain',
				'brandMain',
				'bankMain',
				'investorMain',
				'ownerRegistration',
				'checkView',
				'document',
				'loginAdmin',
				'blogFeed',
				'blogPost',
				'lifehack',
				'notFound',
				'lifehacks',
				'share',
				"shareUser",
				'invoice']
		},
		routes() {
			let routes = [];
			let childrenRoutes = [];
			return {
				webView: ['webView', ...routes, ...childrenRoutes]
			}
		},
		isVKMiniApp() {
			return this.$store.getters.isVKMiniApp;
		},
		isModerator() {
			return this.$store.getters.isModerator
		},
		userRole() {
			return this.$store.getters.userRole || "person"
		},
		authenticated() {
			return this.$store.getters.user.authenticated
		},
		isGuest() {
			return !(this.emailConfirmed || this.name)
		},
		userName() {
			if (this.name && this.lastName) {
				return this.name + ' ' + this.lastName;
			};
		},
		userProfileImageUrl() {
			if (this.$store.getters.userProfile && this.$store.getters.userProfile.profile_image) {
				return this.$store.getters.userProfile.profile_image.url
			};
			return lazyImages.non_register;
		},
		isOnlineShopsActive() {
			return ['cashbackShops', 'cashbackShopsCategory', 'cashbackShopRedirect'].includes(this.$route.name);
		},
		isWalletActive() {
			return ['cashbackWallet',
				'cashbackWalletFilter',
				'cashbackWalletPages',
				'cashbackWalletSortable',
				'cashbackWalletFull',
				'cashbackWalletHistory']
				.includes(this.$route.name);
		},
		isBankCardActive() {
			return ['personBankCards']
				.includes(this.$route.name);
		},
		isLoyaltyCardActive() {
			return ['personLoyaltyCards']
				.includes(this.$route.name);
		},
		isBenefittyCardActive() {
			return ['personBenefittyCard']
				.includes(this.$route.name);
		},
		isUserStocksActive() {
			return ['personUserStocks']
				.includes(this.$route.name);
		},
		isUserSettingsActive() {
			return ['personProfileSettings']
				.includes(this.$route.name);
		},
		isHistoryActive() {
			return ['cashbackHistory', 'cashbackHistoryPages', 'cashbackHistorySortable'].includes(this.$route.name);
		},
		isDiscountActive() {
			return ['personStock', 'personStocks'].includes(this.$route.name);
		},
		isPlacesActive() {
			return ['personPlaces', 'personPlace'].includes(this.$route.name);
		},
		isCardsActive() {
			return ['personLoyaltyCards', 'personBankCards'].includes(this.$route.name);
		},
		isCashbackShopsActive() {
			return ['cashbackShop', 'cashbackShops'].includes(this.$route.name);
		},
		isNewsFeedActive() {
			return ['personNewsFeed'].includes(this.$route.name);
		},
		isCashbackActive() {
			return [
				'cashbackShops',
				'cashbackShopsCategory',
				'cashbackShopRedirect',
				'cashbackShop',
				'cashbackWalletFilter',
				'cashbackWalletPages',
				'cashbackWalletSortable',
				'cashbackWalletFull',
				'cashbackWalletHistory',
			]
				.includes(this.$route.name);
		},
		isRewardsHistoryActive() {
			return ['cashbackRewardsHistory'].includes(this.$route.name);
		},
		isOrganizationHeader() {
			return this.strictShowType == 'organization' && this.integrated ||
				this.userRole != 'person' && !this.isModerator && this.authenticated ||
				this.userRole != 'person' && this.integrated;
		},
		isPersonHeader() {
			return this.userRole == 'person' && !this.isModerator;
		},
		isModeratorHeader() {
			return (this.isModerator && !this.integrated) || this.strictShowType === 'moderator';
		},
		currentCompanyId() {
			return this.$store.getters.currentCompanyId;
		},
	},
	created() {
		this.fetchData();
	},
	watch: {
		'$route': 'fetchData',
		'$route.path'() {
			this.profileMenuOpen = false;
			this.menuOpen = false;
		},
	},
	methods: {
		logInAsPerson() {
			userProvider.setUserRole(this, consts.userRole.person);
			this.$router.push('/person/stocks/discounts?type=5&categoryId=0&categoryName=Все%20категории&icon_3x');
		},
		fetchData() {
			$('body').removeClass('modal-open');
			$('.modal-backdrop').remove();
			this.isHeaderShown = !this.routeNamesWithoutHeader.includes(this.$router.currentRoute.name);

			if (this.isHeaderShown) {
				this.name = this.$ls.get('name');
				this.lastName = this.$ls.get('lastName');
				this.emailConfirmed = this.$ls.get('emailConfirmed');
			};
		},
		logOut() {
			authProvider.logOut(this);
			this.$router.push('/person/home');
		},
		changeMenu(type) {
			if (type === 'menuOpen') {
				this.menuOpen = !this.menuOpen;
				this.profileMenuOpen = false;
			} else if (type === 'profileMenuOpen') {
				this.profileMenuOpen = !this.profileMenuOpen;
				this.menuOpen = false;
			};
		}
	}
}
</script>
<style scoped lang="scss">
.header {
	height: 76px;
	background: linear-gradient(270deg, #18a7c5, #18d7c5);

	&__profile_menu-profile {
		button {
			display: none;
		}
	}

	&__profile_name {
		button {
			display: none;
		}
	}

	&__profile_menu-image {
		display: none;
	}

	&__profile-photo {
		border-radius: 50%;
	}

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 205px;
		height: 100%;
	}

	&._ios-padding {
		height: 106px;

		.header__wrapper {
			align-items: flex-end;
			padding-bottom: 10px;
		}

		.header__profile_name {
			align-items: flex-end;
		}
	}

	&__wrapper_large {
		justify-content: space-between;
		padding: 0 100px;
	}
}

.header__logo a {
	font-family: 'roboto', sans-serif;
	font-weight: bold;
	color: #fff;
	font-size: 30px;
	text-decoration: none;
}

.header__logo_text-large {
	font-size: 30px;
	line-height: 37px;
}

.header__logo_text-small {
	font-size: 15px;
	line-height: 37px;
	vertical-align: top;
}

.header__menu {
	height: 100%;
	display: flex;
	z-index: 1; 

	&__red-indicator {
		display: flex;
		justify-content: center;
		align-items: center;
		right: 5px;
		top: 30%;
		position: absolute;
		width: 16px;
		height: 16px;
		border-radius: 8px;
		background: red;
		color: white;
		font-size: 7px;

		&_small {
			width: 10px;
			height: 10px;
			border-radius: 5px;
		}
	}
}

.header__burger {
	display: none;
}

a.header__menu_item {
	display: flex;
	align-items: center;
	padding: 0 15px;
	height: 100%;
	font-size: 21px;
	color: #fdfdfd;
	text-decoration: none;
	font-family: 'roboto', sans-serif;
	transition: 0.3s;
}

a.header__menu_item img {
	display: none;
}

a.header__menu_item:hover {
	background: rgba(255, 255, 255, 0.2);
	color: #fff;
}

.header__menu_item-active {
	background: rgba(255, 255, 255, 0.2) !important;
}

.header__auth-btn {
	border-radius: 10px;
	border: 1px solid white;
	color: white;
	cursor: pointer;
}

.header__profile {
	height: 100%;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.header__profile_name {
	color: #fff;
	height: 100%;
	font-size: 21px;
	font-family: 'roboto', sans-serif;
	display: flex;
	cursor: pointer;
	align-items: center;
}

.header__profile {
	position: relative;
}

.header__profile_name img {
	margin-left: 10px;
	width: 54px;
	height: 54px;
	object-fit: cover;
	object-position: 50% 0;
}

.header__profile_menu-close {
	height: 25px;
	padding: 0;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	right: 15px;
	top: 20px;
	position: absolute;
}

.header__profile_menu-close div {
	height: 2px;
	background: #000;
	width: 24px;
}

.header__profile_menu-close div:nth-child(1) {
	transform: rotate(45deg);
}

.header__profile_menu-close div:nth-child(2) {
	margin-top: -2px;
	transform: rotate(-45deg);
}

.header__profile_menu {
	z-index: 101;
	position: absolute;
	width: 315px;
	right: 0;
	top: 100%;
	padding: 17px 15px 17px 15px;
	padding-bottom: 0;
	background: #fff;
	box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
	transform: translateX(300%);
	transition: 0.5s;
	opacity: 0;
}

.header__profile_menu-active {
	transform: translateX(0);
	opacity: 1;
}

.header__profile_menu-profile {
	font-size: 21px;
	color: #000;
	text-decoration: none;
	font-family: 'roboto', sans-serif;
}

.header__profile_menu-profile:hover {
	color: #00c2be;
}

.header__profile_menu-edit {
	margin: 15px 0;
	font-size: 14px;
}

.header__profile_menu-edit a {
	text-decoration: none;
	font-size: 14px;
	font-family: 'roboto', sans-serif;
	color: #a2a2a2;
}

.header__profile_menu-edit a:hover {
	color: #00c2be;
}

.header__profile_menu-wrapper {
	border-top: 1px solid #a2a2a2;
}

.header__profile_menu-wrapper a,
.header__profile_menu-wrapper p {
	display: block;
	height: 42px;
	display: flex;
	font-size: 16px;
	text-decoration: none;
	font-family: 'roboto', sans-serif;
	color: #000;
	font-weight: bold;
	align-items: center;
	margin: 0 -15px;
	padding: 0 15px;
	white-space: inherit;
}

.header__profile_menu-wrapper a:hover,
.header__profile_menu-wrapper p:hover {
	background: #d1f4f4;
}

a.header__profile_menu-linkActive {
	background: #d1f4f4 !important;
}

.header__profile_menu-wrapper a img,
.header__profile_menu-wrapper p img {
	margin-right: 15px;
	width: 23px;
}

@media (max-width: 1600px) {
	.header__wrapper {
		padding: 0 10%;
	}

	.header__wrapper_large {
		padding: 0 3%;
	}

	.header__profile_menu {
		width: 290px;
	}

	a.header__menu_item {
		font-size: 15px;
	}

	.header__profile_name {
		font-size: 19px;
	}
}

@media (max-width: 1300px) {
	.header__wrapper {
		position: relative;
		justify-content: space-between;
		padding: 0 6%;
	}

	.header__profile_menu {
		width: 285px;
		left: auto;
		right: -15px;
	}

	a.header__menu_item {
		font-size: 17px;
		display: flex;
		align-items: center;
		height: 42px;
		color: #000;
		transition: 0;
		font-weight: bold;
		padding: 0 25px;
	}

	a.header__menu_item:hover {
		background: #d1f4f4;
		color: #000;
	}

	.header__menu_item-active {
		background: #d1f4f4 !important;
	}

	.header__menu {
		position: absolute;
		display: block;
		top: 100%;
		height: auto;
		box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
		background: #fff;
		transform: translateX(-200%);
		transition: 0.4s;
		opacity: 0;
		left: calc(6% - 25px);
	}

	.header__menu-active {
		transform: translateX(0);
		opacity: 1;
	}

	a.header__menu_item img {
		display: inline-block;
		margin-right: 10px;
	}

	.header__burger {
		display: inline-block;
		border: none;
		cursor: pointer;
		outline: none;
		background: none;
		height: 23px;
		width: 34px;
		padding: 0;
		margin-right: 150px;
	}

	.header__burger div {
		transition: 0.3s;
	}

	.header__burger-active div {
		transform: rotate(0);
	}

	.header__burger-active div:nth-child(1) {
		transform: rotate(45deg);
	}

	.header__burger-active div:nth-child(3) {
		transform: rotate(-45deg);
		margin-top: -2px;
	}

	.header__burger-active div:nth-child(2) {
		display: none;
	}

	.header__burger div {
		width: 100%;
		height: 2px;
		background: #fff;
	}

	.header__burger div:nth-child(2) {
		margin: 7px 0;
	}

	.header__logo {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
}

@media (max-width: 991px) {
	.header__wrapper_large .header__profile_menu-active {
		left: auto;
		right: -25px;
	}

	.header__wrapper {
		padding: 0 25px;
	}

	.header__profile_menu {
		width: 295px;
		right: -25px;
	}

	.header__menu {
		left: 0;
	}

	a.header__menu_item img {
		display: inline-block;
		margin-right: 10px;
	}

	.header__burger {
		display: inline-block;
		border: none;
		background: none;
		height: 23px;
		width: 34px;
		padding: 0;
		margin-right: 150px;
	}

	.header__burger div {
		width: 100%;
		height: 2px;
		background: #fff;
	}

	.header__burger div:nth-child(2) {
		margin: 7px 0;
	}
}

@media (max-width: 767px) {
	.header__wrapper_large .header__profile_menu-active {
		left: auto;
		right: -15px;
	}

	.header__logo {
		position: static;
		left: auto;
		transform: translateX(0);
	}

	.header__wrapper {
		padding: 0 15px;
	}

	header {
		height: 50px;
	}

	.header__logo a {
		font-size: 24px;
	}

	.header__profile_name button {
		display: inline-block;
		background: none;
		border: none;
		height: 19px;
		outline: none;
	}

	.header__profile_name button div {
		height: 2px;
		background: #fff;
		width: 24px;
	}

	.header__profile_name button div:nth-child(1) {
		transform: rotate(45deg);
	}

	.header__profile_name button div:nth-child(2) {
		margin-top: -2px;
		transform: rotate(-45deg);
	}

	.header__profile_menu {
		width: 75vw;
		right: -15px;
		max-width: 400px;
	}

	.header__menu a {
		padding: 0 13px;
		font-size: 15px;
	}

	.header__burger {
		width: 20px;
		margin: 0;
	}

	.header__burger div:nth-child(2) {
		margin: 6px 0;
	}

	.header__profile_menu-image {
		display: block;
	}

	.header__profile_menu-image img {
		width: 66px;
		height: 66px;
		margin-bottom: 10px;
		object-fit: cover;
		object-position: 50% 0;
	}

	.header__menu {
		width: 75vw;
		max-width: 400px;
	}

	.header__profile_menu-close {
		display: none;
	}

	.header__profile_name span {
		display: none;
	}

	.header__profile_name img {
		/* width: 22px;
	height: 22px; */
		width: 28px;
		height: 28px;
		display: inline-block;
		margin: 0 7px;
		object-fit: cover;
		object-position: 50% 0;
	}
}
</style>