export class WorkTimeHelper {
	constructor () {
		this.weekDays = {
			monday: 'ПН',
			tuesday: 'ВТ',
			wednesday: 'СР',
			thursday: 'ЧТ',
			friday: 'ПТ',
			saturday: 'СБ',
			sunday: 'ВС'
		}
	}

	getLocaleTimeByTimestamp (time) {
		if (time) {
			let hours = Math.floor(time / 3600);
			let minutes = Math.floor(time % 3600 / 60);
			if (hours < 10) {
				hours = `0${hours}`;
			}
			if (minutes < 10) {
				minutes = `0${minutes}`;
			}
			return {
				hours, minutes
			}
		} else {
			return {
				hours: '00',
				minutes: '00'
			}
		}
	}

	getTimeFromTimestamps ({ from, to }) {
		const parseTime = (time) => {
			if (time) {
				const { hours, minutes } = this.getLocaleTimeByTimestamp(time)
				return `${hours}:${minutes}`;
			} else {
				return '00:00'
			}
		}

		const timeFrom = parseTime(from)
		const timeTo = parseTime(to)

		if (timeFrom === timeTo) {
			return 'круглосуточно'
		} else {
			return `${parseTime(from)} - ${parseTime(to)}`
		}
	}

	orderedForEach (callback) {
		return Object.keys(this.weekDays)
			.forEach((item, index, array) => {
				callback(item, index, array)
			})
	}

	getWorkingDays (workingDays) {
		const days = {}
		this.orderedForEach(day => {
			const workingDay = workingDays[day]
			if (workingDay) {
				if (workingDay.closed) {
					days[day] = 'Выходной'
				} else {
					days[day] = this.getTimeFromTimestamps({
						from: workingDay.from,
						to: workingDay.to
					})
				}
			}
		})
		return days
	}

	getPeriodValue (firstDay, lastDay, dayValue) {
		if (lastDay) {
			const period = `${this.weekDays[firstDay]} - ${this.weekDays[lastDay]}`
			return { day: period, time: dayValue }
		} else {
			return { day: this.weekDays[firstDay], time: dayValue }
		}
	}

	simplifyWorkingTimes (workingTimes) {
		let periods = []
		const weekDays = Object.keys(this.weekDays)
		let dayIndex = 0
		while (dayIndex <= weekDays.length - 1) {
			const day = weekDays[dayIndex]
			const localRepeatedValues = {}
			const dayValue = workingTimes[day]

			const firstDay = day
			let lastDay = null

			localRepeatedValues[dayValue] = [ day ]
			for (let i = dayIndex + 1; i <= weekDays.length; i++) {
				const currentDayName = weekDays[i]
				const currentDayValue = workingTimes[currentDayName]
				if (currentDayValue === dayValue) {
					localRepeatedValues[dayValue].push(currentDayName)
					lastDay = currentDayName
					dayIndex = i;
				} else {
					dayIndex++
					break
				}
			}

			const periodValue = this.getPeriodValue(firstDay, lastDay, dayValue)
			periods.push(periodValue)
		}
		return periods
	}

	/**
	 *
	 * @param { WeekWorkingDays } options
	 */
	parseWeekDays (options) {
		const workingTimes = this.getWorkingDays(options)
		const simplifiedWorkingTimes = this.simplifyWorkingTimes(workingTimes)
		console.log({ workingTimes, simplifiedWorkingTimes })
		return simplifiedWorkingTimes
	}
}