import VueMain from "@/main";

export default class {
	constructor () {}

	request (method, url, data = {}, options = {}) {
		return new Promise ((resolve, reject) => {
			try {
				VueMain.$http[method](url, data, options)
					.then(response => resolve(response.data))
					.catch(error => reject(error))
			} catch (error) {
				reject(error)
			}
		})
	}

	/**
	 * @param url
	 * @param data
	 * @param options
	 * @return {Promise<unknown>}
	 */
	get (url, data = {}, options = {}) {
		return this.request('get', url, data, options)
	}

	/**
	 * @param url
	 * @param data
	 * @param options
	 * @return {Promise<unknown>}
	 */
	post (url, data = {}, options = {}) {
		return this.request('post', url, data, options)
	}

	/**
	 * @param url
	 * @param data
	 * @param options
	 * @return {Promise<unknown>}
	 */
	put (url, data = {}, options = {}) {
		return this.request('put', url, data, options)
	}

	/**
	 * @param url
	 * @param data
	 * @param options
	 * @return {Promise<unknown>}
	 */
	delete (url, data = {}, options = {}) {
		return this.request('delete', url, data, options)
	}

}