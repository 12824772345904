<template>
    <div class="sidebar-header">
        <router-link @click.native="$emit('close')" :to="{ path: '/organization-admin' }">
            <IconLogoBenefitty class="sidebar-header__logo"/>
        </router-link>

        <CloseIcon class="sidebar-header__icon" @click.native="$emit('close')"/>
    </div>
</template>

<script>
    import lazyImages from '@/utils/lazyImages';
    
    import IconLogoBenefitty from '@/components/baseComponents/icons/IconLogoBenefitty.vue';
    import CloseIcon from './CloseIcon.vue';

    export default {
        name: "SidebarHeader",

        components: {
            CloseIcon,
            IconLogoBenefitty,
        },
        
        computed: {
            logo() {
                return lazyImages.loader_logo;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .sidebar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        &__logo {
            width: 109.41px;
            height: 20px;
            opacity: 1;
            background-position: center;
            background-size: cover;
            transition: $default-transition;

            &:hover {
                opacity: .6;
            }
        }
    }
</style>