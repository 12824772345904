
import userProvider from '@/utils/providers/userProvider';
import authProvider from '@/utils/providers/authProvider';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
    data() {
        return {
            initDataIsLoading: false,
            inn: '',
        }
    },

    created() {
        if (process.env.NODE_ENV === 'development') {
            this.unlockPageContent();
            // поясняю, при хот релоаде страницы, контент не будет отображаться, если убрать это условие
        }
    },

    watch: {
        currentCompanyId(newVal, oldVal) {
            if (oldVal && newVal && oldVal !== newVal) {
                this.lockPageContent();
                this.initAppData();
            }
        },

        $route: {
            deep: true,
            handler(newVal, oldVal) {
                if (oldVal.fullPath === '/' && !oldVal.meta.isRootPage) {
                    this.startInitApp();
                }

                if (oldVal.meta.isNotAuth && !newVal.meta.isNotAuth) {
                    if (this.userRole === 'organization') {
                        this.lockPageContent();
                        this.initAppData();
                    }
                }
            },
        },

        refreshTokenException: {
            handler(val) {
                if (val) {
                    this.lockPageContent();
                } else {
                    this.initAppData();
                }
            },
        },
    },

    computed: {
        ...mapGetters({
            currentCompanyId: 'currentCompanyId',
            userRole: 'userRole',
            user: 'user',
            refreshTokenException: 'refreshTokenException',
        }),

        isOrganizationPages() {
            return window.location.href.includes('organization-admin');
        },
    },

    methods: {
        async startInitApp() {
            const token = Vue.ls.get('token');
            const refreshToken = Vue.ls.get('refreshToken');
    
            if (this.$route.meta.isNotAuth) {
                return this.initDataIsLoading = true;
            }
    
            this.lockPageContent();
    
            if (!token && !refreshToken) {
                return this.unlockPageContent();
            }
    
            try {
                this.authenticate(token, refreshToken);
            } catch (error) {
                return;
            }
    
            if (!this.isModerator) {
                if (this.$ls.get('currentCompanyId')) {
                    await this.$store.dispatch('setCurrentCompanyId', this.$ls.get('currentCompanyId'));
                }
                if (this.$ls.get('currentBrandId')) {
                    await this.$store.dispatch('setCurrentBrandId', this.$ls.get('currentBrandId'));
                }
            }
    
            if (this.userRole === 'organization') {
                this.initAppData();
            } else {
                this.unlockPageContent();
            }
        },

        async authenticate(token, refreshToken) {
			const userRes = await userProvider.getCurrentUser(this, token);
			await authProvider.authenticate(this, {...userRes, token: token, refresh_token: refreshToken});
        },

        unlockPageContent() {
            this.initDataIsLoading = true;

            const appLoadingBg = document.getElementById('loading-bg');

            if (appLoadingBg) {
                appLoadingBg.style.display = 'none';
            }
        },

        lockPageContent() {
            this.initDataIsLoading = false;

            const appLoadingBg = document.getElementById('loading-bg');

            if (appLoadingBg) {
                appLoadingBg.style.display = 'flex';
            }
        },

        async initAppData() {
            if (!this.isOrganizationPages) {
                this.initDataIsLoading = true;
            }

            if (!this.refreshTokenException) {
                this.unlockPageContent();
            }
        },
    },
}