export default {
	//Common
	LoginVk: () => import('../pages/authorizationPages/logInVk.vue'),
	LoginOk: () => import('../pages/authorizationPages/logInOk.vue'),

	Share: () => import('../pages/share.vue'),

	Invoice: () => import('../pages/invoice.vue'),

	//Organization
	OrganizationBrands: () => import('../pages/organizationPages/organizationBrands.vue'),
	OrganizationSettingsOld: () => import('../pages/organizationPages/OrganizationSettingsOld.vue'),
	OrganizationSteps: () => import('../pages/organizationPages/OrganizationSteps.vue'),

	OrganizationCommon: () => import('../pages/organizationPages/common/common.vue'),
	OrganizationCommonMainInfo: () => import('../pages/organizationPages/common/commonMainInfo.vue'),
	OrganizationStocks: () => import('../pages/organizationPages/common/discounts/stocks.vue'),
	OrganizationNewStock: () => import('../pages/organizationPages/common/newStock.vue'),

	OrganizationPromotionsCommon: () => import('../pages/organizationPages/loyaltyProgramsPages/promotions/PromotionsCommon.vue'),
	OrganizationPromotionsList: () => import('../pages/organizationPages/loyaltyProgramsPages/promotions/PromotionsList.vue'),
	OrganizationPromotionsReport: () => import('../pages/organizationPages/loyaltyProgramsPages/promotions/PromotionsReport.vue'),
	OrganizationPromotionsNew: () => import('../pages/organizationPages/loyaltyProgramsPages/promotions/PromotionsNew.vue'),

	OrganizationSimpleLoyaltyProgram: () => import('../pages/organizationPages/loyaltyProgramsPages/simpleLoyaltyProgram.vue'),
	OrganizationLoyaltyProgramsAddPartners: () => import('../pages/organizationPages/loyaltyProgramsPages/loyaltyProgramsAddPartners.vue'),

	PlaceSearch: () => import('../pages/organizationPages/registration/placeSearch.vue'),
	OwnerRegistration: () => import('../pages/organizationPages/registration/ownerRegistration.vue'),
	OwnerRegistrationWithId: () => import('../pages/organizationPages/registration/OwnerRegistrationWithId.vue'),
	AddPlace: () => import('../pages/organizationPages/registration/addPlace.vue'),

	AttachPlaceToOrganization: () => import('../pages/organizationPages/registration/attachPlaceToOrganization.vue'),

	OrganizationPlace: () => import('../pages/organizationPages/place/place.vue'),
	OrganizationPlaceMainInfo: () => import('../pages/organizationPages/place/mainInfo.vue'),
	OrganizationPlacePushNotice: () => import('../pages/organizationPages/place/pushNotice.vue'),
	OrganizationPlacePhotosAndVideos: () => import('../pages/organizationPages/place/photosAndVideos.vue'),
	OrganizationPlaceDiscountReviews: () => import('../pages/organizationPages/place/discounts/DiscountReviews.vue'),
	OrganizationPlaceEvents: () => import('../pages/organizationPages/place/events.vue'),
	OrganizationPlaceAdvertisement: () => import('../pages/organizationPages/place/advertisement.vue'),
	OrganizationPlaceAdditionalInformation: () => import('../pages/organizationPages/place/additionalInformation.vue'),
	OrganizationPlacesAdmin: () => import('../pages/organizationPages/place/organizationPlacesAdmin.vue'),
	OrganizationPlacesModeratorArchive: () => import('../pages/organizationPages/place/organizationPlacesAdminArchive.vue'),
	OrganizationPlacesCashier: () => import('../pages/organizationPages/place/cashier.vue'),
	OrganizationPlacesCashierArchive: () => import('../pages/organizationPages/place/cashierArchive.vue'),
	OrganizationPlacesCashierOperations: () => import('../pages/organizationPages/place/cashierOperations.vue'),
	OrganizationPlaceBrands: () => import('../pages/organizationPages/place/brands.vue'),

	OrganizationPlaceMedias: () => import('../pages/organizationPages/place/Medias.vue'),

	LoginModerator: () => import('../pages/authorizationPages/loginModerator.vue'),

	//Cashback
	Cashback: () => import('../pages/cashbackPages/Cashback.vue'),
	CashbackShops: () => import('../pages/personPages/stock/Stocks.vue'),
	CashbackWallet: () => import('../pages/cashbackPages/Wallet.vue'),
	CashbackShop: () => import('../pages/cashbackPages/CashbackShop.vue'),
	CashbackShopRedirect: () => import('../pages/cashbackPages/shop-redirect.vue'),
	CashbackWalletHistory: () => import('../pages/cashbackPages/cashbackWalletHistory.vue'),
	CashbackRewardsHistory: () => import('../pages/cashbackPages/rewardsHistory.vue'),

	//Person
	PersonPage: () => import('../pages/personPages/Person.vue'),
	PersonPlaces: () => import('../pages/personPages/place/Places.vue'),

	PersonPlace: () => import('@/pages/personPages/place/PersonPlacePage.vue'),
	// PersonPlace: () => import('../pages/personPages/place/Place.vue'),
	// TODO Исходная страница места
	PersonPlaceDiscountsByBankCard: () => import('../pages/personPages/place/DiscountsByBankCard.vue'),

	PersonUserStocks: () => import('../pages/personPages/stock/UserStocks.vue'),


	PersonStocks: () => import('../pages/personPages/stock/PersonalStocks.vue'),
	// TODO измененная страница
	// PersonStocks: () => import('../pages/personPages/stock/Stocks.vue'),

	PersonStock: () => import('../pages/personPages/stock/PersonStock.vue'),
	// TODO Измененная страница
	// PersonStock: () => import('../pages/personPages/stock/Stock.vue'),
	PersonAddStock: () => import('../pages/personPages/stock/AddStock.vue'),

	PersonExchange: () => import('../pages/personPages/exchangePoints/Exchange.vue'),
	PersonProposals: () => import('../pages/personPages/exchangePoints/Proposals.vue'),
	PersonCreateProposal: () => import('../pages/personPages/exchangePoints/CreateProposal.vue'),
	PersonChat: () => import('../pages/personPages/chat/Chat.vue'),
	PersonExchangeAllChats: () => import('../pages/personPages/exchangePoints/ExchangeAllChats.vue'),

	PersonBrand: () => import('../pages/personPages/brand/Brand.vue'),
	PersonBrands: () => import('../pages/personPages/brand/Brands.vue'),
	PersonBrandRepresentatives: () => import('../pages/personPages/brand/BrandRepresentatives.vue'),

	PersonReviewsList: () => import('../pages/personPages/review/ReviewsList.vue'),
	PersonReview: () => import('../pages/personPages/review/Review.vue'),

	PersonCardsCommon: () => import('../pages/personPages/cards/CardsCommon.vue'),

	PersonLoyaltyCards: () => import('../pages/personPages/cards/LoyaltyCards/PersonLoyaltyCards.vue'),
	// TODO Измененный компонент
	// PersonLoyaltyCards: () => import('../pages/personPages/cards/LoyaltyCards/LoyaltyCards.vue'),

	PersonSharedLoyaltyCards: () => import('../pages/personPages/cards/LoyaltyCards/SharedLoyaltyCards.vue'),


	PersonLoyaltyPrograms: () => import('../pages/personPages/cards/LoyaltyCards/PersonalLoyaltyPrograms.vue'),
	// TODO Измененный компонент
	// PersonLoyaltyPrograms: () => import('../pages/personPages/cards/LoyaltyCards/LoyaltyPrograms.vue'),
	PersonLoyaltyCard: () => import('../pages/personPages/cards/LoyaltyCards/LoyaltyCard.vue'),
	PersonAddLoyaltyCard: () => import('../pages/personPages/cards/LoyaltyCards/AddLoyaltyCard.vue'),
	PersonIssueableCard: () => import('../pages/personPages/cards/LoyaltyCards/IssueableCard.vue'),
	PersonDescriptionLoyaltyCard: () => import('../pages/personPages/cards/LoyaltyCards/DescriptionLoyaltyCard.vue'),

	PersonBankCards: () => import('../pages/personPages/cards/BankCards/BankCards.vue'),
	PersonAddBankCard: () => import('../pages/personPages/cards/BankCards/AddBankCard.vue'),

	PersonBenefittyCard: () => import('../pages/personPages/cards/BenefittyCard.vue'),

	PersonProfile: () => import('../pages/personPages/profile/Profile.vue'),
	PersonCompanyProfile: () => import('../pages/personPages/profile/CompanyProfile.vue'),
	PersonProfileSettings: () => import('../pages/personPages/profile/ProfileSettings.vue'),
	PersonChangePassword: () => import('../pages/personPages/profile/ChangePassword.vue'),
	PersonStatus: () => import('../pages/personPages/profile/Status.vue'),

	PersonSettings: () => import('../pages/personPages/Settings.vue'),
	PersonChecks: () => import('../pages/personPages/Checks.vue'),
	PersonMedia: () => import('../pages/personPages/Media.vue'),
	PersonBookmarks: () => import('../pages/personPages/Bookmarks.vue'),
	PersonNewsFeed: () => import('../pages/personPages/newsFeed/NewsFeed.vue'),
	PersonNewsFeedPost: () => import('../pages/personPages/newsFeed/NewsFeedPost.vue'),
	PersonFriends: () => import('../pages/personPages/friends/Friends.vue'),
	PersonCheckins: () => import('../pages/personPages/checkins/Checkins.vue'),
	PersonCashbackWallet: () => import('../pages/personPages/cashback/Wallet.vue'),
	PersonRewardsHistory: () => import('../pages/personPages/cashback/RewardsHistory.vue'),
	PersonHomeScreen: () => import("@/pages/personPages/homeScreen/HomeScreen.vue"),
	PersonSettingsQuickMenu: () => import("@/pages/personPages/homeScreen/SettingsQuickMenu.vue"),
	PersonBenefitTotal: () => import("@/pages/personPages/homeScreen/BenefitTotal.vue"),
	PersonCallFriends: () => import("@/pages/personPages/homeScreen/CallFriends.vue"),
	PersonStatusInfo: () => import("@/pages/personPages/homeScreen/StatusInfo.vue"),

	PersonDiscountsPage: () => import("@/pages/personPages/discountsPage/PersonDiscountPage.vue"),

	//Posts
	BlogFeed: () => import('../pages/personPages/blog/BlogFeed.vue'),
	BlogPost: () => import('../pages/personPages/blog/BlogPost.vue'),
	Lifehack: () => import('../pages/personPages/lifehacks/Lifehack.vue'),
	Lifehacks: () => import('../pages/personPages/lifehacks/Lifehacks.vue'),

	StocksCompilationsPage: () => import("../pages/personPages/stock/stocksPages/StocksCompilationsPage"),
	StockDiscountsPage: () => import("../pages/personPages/stock/stocksPages/StockDiscountsPage"),

	CompanyDiscountsPage: () => import('../pages/personPages/profile/CompanyDiscountsPage'),
	CompanyBonusExchangesPage: () => import('../pages/personPages/profile/CompanyBonusExchanges'),
	CompanyPlacesPage: () => import('../pages/personPages/profile/CompanyPlaces'),
	CompanyMediaPage: () => import('../pages/personPages/profile/CompanyMedia'),

	//Add loyalty
	RepaymentPage: () => import('../pages/personPages/Payment.vue'),

	// benefittySteps
	BenefittySteps: () => import('../pages/benefittySteps/steps.vue'),
}