import importPageFiles from './importPageFiles.js'
import RouterHelper from '@/router/routerHelper.js'
var routerHelper = new RouterHelper();


const PlacesSearch = () => import('@/pages/WebViewPages/PlacesSearch')
const PersonPage = importPageFiles.PersonPage;
const PersonPlaces = importPageFiles.PersonPlaces;
const PersonPlace = importPageFiles.PersonPlace;
const PersonPlaceDiscountsByBankCard = importPageFiles.PersonPlaceDiscountsByBankCard;
const PersonStocks = importPageFiles.PersonStocks;
const PersonUserStocks = importPageFiles.PersonUserStocks;
const PersonStock = importPageFiles.PersonStock;
const PersonAddStock = importPageFiles.PersonAddStock;
const PersonBrand = importPageFiles.PersonBrand;
const PersonBrands = importPageFiles.PersonBrands;
const PersonBrandRepresentatives = importPageFiles.PersonBrandRepresentatives;
const PersonReview = importPageFiles.PersonReview;
const PersonCardsCommon = importPageFiles.PersonCardsCommon;
const PersonAddLoyaltyCard = importPageFiles.PersonAddLoyaltyCard;
const PersonIssueableCard = importPageFiles.PersonIssueableCard;
const PersonLoyaltyCards = importPageFiles.PersonLoyaltyCards;
const PersonSharedLoyaltyCards = importPageFiles.PersonSharedLoyaltyCards;
const PersonLoyaltyPrograms = importPageFiles.PersonLoyaltyPrograms;
const PersonLoyaltyCard = importPageFiles.PersonLoyaltyCard;
const PersonBankCards = importPageFiles.PersonBankCards;
const PersonAddBankCard = importPageFiles.PersonAddBankCard;
const PersonDescriptionLoyaltyCard = importPageFiles.PersonDescriptionLoyaltyCard;
const PersonChecks = importPageFiles.PersonChecks;
const PersonReviewsList = importPageFiles.PersonReviewsList;
const PersonProfile = importPageFiles.PersonProfile;
const PersonCompanyProfile = importPageFiles.PersonCompanyProfile;
const PersonProfileSettings = importPageFiles.PersonProfileSettings;
const PersonChangePassword = importPageFiles.PersonChangePassword;
const PersonMedia = importPageFiles.PersonMedia;
const PersonBookmarks = importPageFiles.PersonBookmarks;
const PersonSettings = importPageFiles.PersonSettings;
const PersonNewsFeed = importPageFiles.PersonNewsFeed;
const PersonNewsFeedPost = importPageFiles.PersonNewsFeedPost;
const PersonFriends = importPageFiles.PersonFriends;
const PersonBenefittyCard = importPageFiles.PersonBenefittyCard;
const PersonCheckins = importPageFiles.PersonCheckins;
const PersonStatus = importPageFiles.PersonStatus;
const PersonCashbackWallet = importPageFiles.PersonCashbackWallet;
const PersonRewardsHistory = importPageFiles.PersonRewardsHistory;
const PersonSettingsQuickMenu = importPageFiles.PersonSettingsQuickMenu;
const PersonCallFriends = importPageFiles.PersonCallFriends;
const PersonHomeScreen = importPageFiles.PersonHomeScreen;
const PersonStatusInfo = importPageFiles.PersonStatusInfo;
const PersonBenefitTotal = importPageFiles.PersonBenefitTotal;
const PersonExchange = importPageFiles.PersonExchange;
const PersonProposals = importPageFiles.PersonProposals;
const PersonCreateProposal = importPageFiles.PersonCreateProposal;
const PersonChat = importPageFiles.PersonChat;
const PersonExchangeAllChats = importPageFiles.PersonExchangeAllChats;
const PersonDiscountsPage = importPageFiles.PersonDiscountsPage;

const StocksCompilationsPage = importPageFiles.StocksCompilationsPage
const StockDiscountsPage = importPageFiles.StockDiscountsPage

const CompanyDiscountsPage = importPageFiles.CompanyDiscountsPage
const CompanyBonusExchangesPage = importPageFiles.CompanyBonusExchangesPage
const CompanyPlacesPage = importPageFiles.CompanyPlacesPage
const CompanyMediaPage = importPageFiles.CompanyMediaPage
const BenefittySteps = importPageFiles.BenefittySteps;


export default [
	{
		path: '/person',
		name: 'person',
		component: PersonPage,
		beforeEnter: routerHelper.beforePersonEnter,
		children: [
			{
				path: '/person/home',
				name: 'personHome',
				component: PersonHomeScreen,
				beforeEnter: routerHelper.beforePersonEnter,
				props: true
			},
			{
				path: '/person/places',
				name: 'personPlaces',
				props: true,
				component: PlacesSearch,
				beforeEnter: routerHelper.beforePersonEnter,
			},
			{
				path: '/person/place/:placeId',
				name: 'personPlace',
				component: PersonPlace,
				props: true,
			},
			{
				path: '/person/stocks',
				name: 'personStocks',
				component: PersonStocks,
				children: [
					{
						path: 'compilations',
						name: 'personStockCompilations',
						component: StocksCompilationsPage
					},
					{
						path: 'discounts',
						name: 'personStockDiscounts',
						component: StockDiscountsPage
					}
				]
			},
			{
				path: '/person/user-stocks',
				name: 'personUserStocks',
				component: PersonUserStocks,
			},
			{
				path: '/person/stocks/add',
				name: 'personAddStock',
				component: PersonAddStock,
			},
			{
				path: '/person/stocks/edit/:stockId',
				name: 'personEditStock',
				component: PersonAddStock,
				props: true,
			},
			{
				path: '/person/stock/:stockId',
				name: 'personStock',
				component: PersonStock,
				props: true,
			},
			{
				path: '/person/exchange-points',
				name: 'exchangePoints',
				component: PersonExchange,
				props: true
			},
			{
				path: '/person/exchange-points/proposals/:type',
				name: 'exchangeProposals',
				component: PersonProposals,
			},
			{
				path: '/person/exchange-points/choose-card',
				name: 'chooseCard',
				component: PersonLoyaltyCards,
				meta: {
					sections: {
						cardsBonus: true
					}
				}
			},
			{
				path: '/person/exchange-points/create-proposal/:programId/:title',
				name: 'createProposal',
				component: PersonCreateProposal,
				props: true,
				meta: {
					sections: {
						cardsNearby: true,
						cardsLoyalty: true
					}
				}
			},
			{
				path: '/person/exchange-points/change-proposal/:proposalId',
				name: 'changeProposal',
				component: PersonCreateProposal,
				props: true,
				meta: {
					sections: {
						cardsNearby: true,
						cardsLoyalty: true
					}
				}
			},
			{
				path: '/person/exchange-points/chat/:chatId?',
				name: 'exchangeChat',
				component: PersonChat,
				props: true
			},
			{
				path: '/person/chat/:companionId',
				name: 'personChat',
				component: PersonChat,
				props: true
			},
			{
				path: '/person/exchange-points/all-chats',
				name: 'exchangeAllChats',
				component: PersonExchangeAllChats
			},
			{
				path: '/person/brands',
				name: 'personBrands',
				component: PersonBrands,
			},
			{
				path: '/person/brand/:brandId',
				name: 'personBrand',
				component: PersonBrand,
				props: true,
			},
			{
				path: '/person/brand/:brandId/representatives',
				name: 'personBrandepresentatives',
				component: PersonBrandRepresentatives,
				props: true,
			},
			{
				path: '/person/checks',
				name: 'personChecks',
				component: PersonChecks,
			},
			{
				path: '/person/place/:placeId/reviews',
				name: 'personPlaceReviews',
				component: PersonReviewsList,
				props: true,
			},
			{
				path: '/person/:userId/reviews',
				name: 'personUserReviews',
				component: PersonReviewsList,
				props: true,
			},
			{
				path: '/person/brand/:brandId/reviews',
				name: 'personBrandReviews',
				component: PersonReviewsList,
				props: true,
			},
			{
				path: '/person/stock/:discountId/reviews',
				name: 'personDiscountReviews',
				component: PersonReviewsList,
				props: true,
			},
			{
				path: '/person/place/:placeId/media',
				name: 'personPlaceMedia',
				component: PersonMedia,
				props: true,
			},
			{
				path: '/person/companies/:companyId/places',
				name: 'companyPlacesPage',
				component: CompanyPlacesPage
			},
			{
				path: '/person/companies/:companyId/bonus-exchanges',
				name: 'companyBonusExchanges',
				component: CompanyBonusExchangesPage
			},
			{
				path: '/person/companies/:companyId/discounts',
				name: 'companyDiscounts',
				component: CompanyDiscountsPage
			},
			{
				path: '/person/companies/:companyId/media',
				name: 'companyMedia',
				component: CompanyMediaPage
			},
			{
				path: '/person/company/:companyId/media',
				name: 'personCompanyMedia',
				component: PersonMedia,
				props: true,
			},
			{
				path: '/person/brand/:brandId/media',
				name: 'personBrandMedia',
				component: PersonMedia,
				props: true,
			},
			{
				path: '/person/profile',
				name: 'currentPersonProfile',
				props: true,
				component: PersonProfile,
			},
			{
				path: '/person/user/:userId',
				name: 'personProfile',
				component: PersonProfile,
				props: true,
			},
			{
				path: '/person/company/:companyId',
				name: 'personCompanyProfile',
				component: PersonCompanyProfile,
				props: true,
			},
			{
				path: '/person/profile-settings',
				name: 'personProfileSettings',
				component: PersonProfileSettings,
			},
			{
				path: '/person/profile-settings/change-password',
				name: 'personChangePassword',
				component: PersonChangePassword,
			},
			{
				path: '/person/place/:placeId/review',
				name: 'personPlaceReview',
				component: PersonReview,
				props: true,
			},
			{
				path: '/person/stock/:stockId/review',
				name: 'personStockReview',
				component: PersonReview,
				props: true,
			},
			{
				path: '/person/brand/:brandId/review',
				name: 'personBrandReview',
				component: PersonReview,
				props: true,
			},
			{
				path: '/person/review',
				name: 'personReview',
				component: PersonReview,
				props: true,
			},
			{
				path: '/person/discounts/:entityType/:id',
				name: 'personDiscountsPage',
				component: PersonDiscountsPage,
				props: true,
			},
			{
				path: '/person/cards',
				name: 'personCards',
				component: PersonCardsCommon,
				props: (route) => ({ vkAppId: route.query.vk_app_id }),
				beforeEnter: routerHelper.beforePersonEnter,
				children: [
					{
						path: '/person/cards/loyalty-program/:programId/description',
						name: 'PersonDescriptionLoyaltyCard',
						component: PersonDescriptionLoyaltyCard,
						beforeEnter: routerHelper.beforePersonEnter,
						props: true,
					},
					{
						path: '/person/cards/loyalty-cards',
						name: 'personLoyaltyCards',
						component: PersonLoyaltyCards,
						props: (route) => ({ search: route.query.search }),
						beforeEnter: routerHelper.beforePersonEnter,
					},
					{
						path: '/person/cards/shared-loyalty-cards',
						name: 'personSharedLoyaltyCards',
						component: PersonSharedLoyaltyCards,
						props: (route) => ({ programId: route.query.programId }),
						beforeEnter: routerHelper.beforePersonEnter,
					},
					{
						path: '/person/cards/loyalty-programs',
						name: 'personLoyaltyPrograms',
						component: PersonLoyaltyPrograms,
						beforeEnter: routerHelper.beforePersonEnter,
					},
					{
						path: '/person/cards/loyalty-cards/:cardId/edit',
						name: 'personEditCard',
						component: PersonAddLoyaltyCard,
						props: true,
						beforeEnter: routerHelper.beforePersonEnter,
					},
					{
						path: '/person/cards/loyalty-cards/add-card',
						name: 'personAddCard',
						component: PersonAddLoyaltyCard,
						props: (route) => ({ programId: route.query.programId }),
						beforeEnter: routerHelper.beforePersonEnter,
					},
					{
						path: '/person/cards/loyalty-cards/issueable/:programId',
						name: 'personIssueCard',
						component: PersonIssueableCard,
						beforeEnter: routerHelper.beforePersonEnter,
					},
					{
						path: '/person/cards/loyalty-cards/:cardId',
						name: 'personLoyaltyCard',
						component: PersonLoyaltyCard,
						props: true,
						beforeEnter: routerHelper.beforePersonEnter,
					},
					{
						path: '/person/cards/bank-cards',
						name: 'personBankCards',
						component: PersonBankCards,
						beforeEnter: routerHelper.beforePersonEnter,
					},
					{
						path: '/person/cards/bank-cards/add',
						name: 'personAddBankCard',
						component: PersonAddBankCard,
						beforeEnter: routerHelper.beforePersonEnter,
					},
					{
						path: '/person/cards/bank-cards/:cardId/edit',
						name: 'personEditBankCard',
						component: PersonAddBankCard,
						props: true,
						beforeEnter: routerHelper.beforePersonEnter,
					}
				]
			},
			{
				path: '/person/:personId/media',
				name: 'personMedia',
				component: PersonMedia,
				props: true,
			},
			{
				path: '/person/place/:placeId/bank-card-discounts',
				name: 'personPlaceDiscountsByBankCard',
				component: PersonPlaceDiscountsByBankCard,
				props: true,
			},
			{
				path: '/person/bookmarks',
				name: 'personBookmarks',
				component: PersonBookmarks,
				props: true,
			},
			{
				path: '/person/settings',
				name: 'personSettings',
				component: PersonSettings,
				props: true,
			},
			{
				path: '/person/newsfeed',
				name: 'personNewsFeed',
				component: PersonNewsFeed,
				props: true,
				beforeEnter: routerHelper.beforePersonEnter,
			},
			{
				path: '/person/newsfeed/:id',
				name: 'personNewsFeedPost',
				component: PersonNewsFeedPost,
				props: true,
				beforeEnter: routerHelper.beforePersonEnter,
			},
			{
				path: '/person/friends',
				name: 'personFriends',
				component: PersonFriends,
			},
			{
				path: '/person/:userId/friends',
				name: 'personUserFriends',
				component: PersonFriends,
				props: true,
			},
			{
				path: '/person/benefitty-card',
				name: 'personBenefittyCard',
				component: PersonBenefittyCard,
			},
			{
				path: '/person/checkins',
				name: 'personCheckins',
				component: PersonCheckins,
			},
			{
				path: '/person/:userId/checkins',
				name: 'personUserCheckins',
				component: PersonCheckins,
				props: true,
			},
			{
				path: '/person/profile/status',
				name: 'personStatus',
				component: PersonStatus,
			},
			{
				path: '/person/cashback-wallet',
				name: 'personCashbackWallet',
				component: PersonCashbackWallet,
			},
			{
				path: '/person/rewards-history',
				name: 'personRewardsHistory',
				component: PersonRewardsHistory,
			},
			{
				path: '/person/settings-quick-menu',
				name: 'personSettingsQuickMenu',
				component: PersonSettingsQuickMenu,
				props: true
			},
			{
				path: '/person/benefit-total',
				name: 'personBenefitTotal',
				component: PersonBenefitTotal,
				props: true
			},
			{
				path: '/person/status-info',
				name: 'PersonStatusInfo',
				component: PersonStatusInfo,
			},
			{
				path: '/person/call-friends',
				name: 'personCallFriends',
				component: PersonCallFriends
			},
			{
				path: '/person/steps',
				name: 'personBenefittySteps',
				component: BenefittySteps,
			},
		]
	}
]