import 'bootstrap-vue/dist/bootstrap-vue.css';
import Vue from 'vue';
import VueMq from 'vue-mq';
import VeeValidate from 'vee-validate';
import vClickOutside from 'v-click-outside';
import VueSocialSharing from 'vue-social-sharing';
import './customValidator';
import VueLocalStorage from 'vue-ls';
import store from './store/index.js';
import router from './router/index.js';
import App from './App.vue';
import auth from './utils/providers/authProvider.js';
import userProvider from './utils/providers/userProvider.js';
import VueResource from 'vue-resource';
import Croppa from 'vue-croppa';
import consts from './utils/consts.js';
import VueLazyLoad from 'vue-lazyload';
import AuthTokenHelper from '@/utils/helpers/authTokenHelper.js';
import uuidv4 from '@/utils/uuidv4.js';
import { firebase } from '@firebase/app';
import '../css/fonts.css';
import '../css/vue-slick-carousel.css';
import '../css/vue-slick-carousel-theme.css';
import '@firebase/messaging';
// import '@firebase/analytics';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css';
import '../css/benefitty.scss';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import '../css/font-awesome/css/font-awesome.min.css';
import '../fonts/roboto/roboto.css';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import 'vue-croppa/dist/vue-croppa.css';
import './assets/images/favicon.ico';
import './../apple-app-site-association';
import './../manifest.json';
import './../firebase-messaging-sw.js';
import './../robots.txt';
import './../sitemap.xml';
import './../appicon_128.png';
import './../appicon_50.png';
import Meta from 'vue-meta';
import bridge from '@vkontakte/vk-bridge';

bridge.send('VKWebAppInit');

import VueTheMask from 'vue-the-mask';
import { mask } from 'vue-the-mask';
import VScrollLock from 'v-scroll-lock';
import AsyncComputed from 'vue-async-computed';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import axios from 'axios';
import axiosRetry from 'axios-retry';
Vue.use(VeeValidate);

const client = axios.create({
    baseURL: consts.appUri,
});

axiosRetry(client, { retries: 2, retryDelay: count => count * 2000 });

Vue.prototype.$client = client;

global.$ = global.jQuery = require('jquery');
const toastOptions = {
    transition: 'fade',
    timeout: 2000,
    newestOnTop: true,
};
Vue.use(Toast, toastOptions);
Vue.use(AsyncComputed);
Vue.use(VScrollLock);
Vue.use(VueTheMask);
Vue.use(VueLazyLoad);
Vue.use(VueResource);
Vue.use(VeeValidate);
Vue.use(VueLocalStorage);
Vue.use(VueSocialSharing);
Vue.use(Croppa);
Vue.use(Meta);
Vue.use(vClickOutside);
Vue.http.options.emulateJSON = true;
Vue.http.options.emulateHTTP = true;
Vue.http.options.root = consts.appUri;

import { directives } from '@/directives';

Vue.directive('inputNumber', directives.inputNumber);
Vue.directive('mask', directives.mask);
Vue.directive('mask-phone', mask);
Vue.directive('scrollEnd', directives.scrollEnd);
Vue.directive('loading', directives.loading);
Vue.directive('fixed', directives.fixed);
Vue.directive('observe-last', directives.observeLast);

import ConditionalLink from '@/components/baseComponents/common/ConditionalLink';
import BaseImage from '@/components/baseComponents/BaseImage';
import VButton from '@/components/organizationAdmin/base/VButton.vue';
Vue.component('ConditionalLink', ConditionalLink);
Vue.component('BaseImage', BaseImage);
Vue.component('VButton', VButton);

import common from './utils/helpers/common';
// 
// @property { Helpers } $helpers
// 
Vue.prototype.$helpers = common;

import device from '@/utils/device.js';
Vue.prototype.$checkDevice = device;

import indexedDBProvider from '@/utils/providers/indexedDBProvider';
Vue.prototype.$db = new indexedDBProvider();

Vue.prototype.$OS = getOS();

function getOS() {
    if (window.navigator.appVersion.indexOf('Win') != -1) {
        return 'Windows';
    }

    if (window.navigator.appVersion.indexOf('Mac') != -1) {
        return 'MacOS';
    }

    if (window.navigator.appVersion.indexOf('Linux') != -1) {
        return 'Linux';
    }

    return 'Unknown';
}

import { ColorPicker, ColorPanel } from 'one-colorpicker';

Vue.use(ColorPanel);
Vue.use(ColorPicker);
Vue.use(VueMq, {
    breakpoints: { // default breakpoints - customize this
        sm: 376,
        tl: 1151,
        lg: Infinity,
    },
});

let messaging = null;

if (firebase.messaging.isSupported()) {
    firebase.initializeApp(consts.firebaseConfig);
    messaging = firebase.messaging();
}
import Loader from '@/components/Loader.vue';
import GoogleProvider from '@/utils/providers/googleProvider';
import Geolocation from '@/utils/Geolocation';

Vue.component('VLoader', Loader);


const VueMain = new Vue({
    el: '#app',
    $checkDevice: () => ({}),
    store,
    router,
    data() {
        return {
            authTokenHelper: null,
            isImplicitLoading: false,
            isAuthTokenRefreshing: false,
            refreshTokenRequestsList: [],
            implicitRequestsList: [],
            listRejectingRequest: [
                `${consts.appUri}/register/implicit`,
                `${consts.appUri}/login`,
                `${consts.appUri}/logout`,
                `${consts.appUri}/token`,
            ],
        };
    },

    async created() {
        this.authTokenHelper = new AuthTokenHelper();
        if (!this.getCookie('token')) {
            this.setInterceptors();
        }
        this.initVKBridge();
        this.setHttpHeaders();
        this.googleMapsInit();
        this.googlePlusInit();
        // this.facebookInit();
        // this.setDateTimePickerSetting();
        store.dispatch('setUserRole', userProvider.getUserRole(this));
        if (userProvider.getIsModerator(this)) {
            store.dispatch('changeIsModerator', { context: this, isModerator: userProvider.getIsModerator(this) });
        }
    },

    async mounted() {
        try {
            if (!this.$store.getters.isVKMiniApp) {
                Vue.prototype.$geolocation = new Geolocation();
            }

            const [access, date] = await Promise.all([
                this.checkGeopositionAccess(),
                this.checkQueryDateGeopositionAccess(),
            ]);
	
            if (access) {
                if (this.$store.getters.isVKMiniApp) {
                    Vue.prototype.$geolocation = new Geolocation(true);
					
                    this.$store.dispatch('geolocation/setGeo', true);
                }

                return;
            }
	
            const currentDate = new Date().getTime();
	
            if (!access && currentDate - Number(date) > 86400000 || !date) {
                this.$messageModal.geo().then(() => {
                    if (!Vue.prototype.$geolocation) {
                        Vue.prototype.$geolocation = new Geolocation(true);
                        this.$store.dispatch('geolocation/setGeo', true);
                    } else if (this.$store.getters.isVKMiniApp) {
                        Vue.prototype.$geolocation = new Geolocation(true);
							
                        this.$store.dispatch('geolocation/setGeo', true);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }

        document.dispatchEvent(new Event('render-event'));
    },

    methods: {
        checkQueryDateGeopositionAccess() {
            return bridge.send('VKWebAppStorageGet', {
                keys: [
                    'geolocation_access_date',
                ],
            })
                .then(data => {
                    if (data.keys?.length) {
                        return data.keys[0].value;
                    }

                    return null;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        checkGeopositionAccess() {
            return bridge.send('VKWebAppStorageGet', {
                keys: [
                    'geolocation_access',
                ],
            })
                .then(data => {
                    if (data.keys?.length) {
                        return data.keys[0].value;
                    }

                    return null;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        initVKBridge() {
            return bridge.send('VKWebAppGetClientVersion', {})
                .then(data => {
                    this.$store.dispatch('setVKMiniAppPlatform', data.platform);
                    // Vue.prototype.$geolocation = new Geolocation(true);
                });
        },

        setInterceptors() {
            Vue.http.interceptors.push((request, next) => {
                next(response => {
                    if (!store.getters.isVKMiniApp && this.$ls.get('authType') === 'user') {
                        this.silentRegister(request);
                        this.refreshToken(request, response);
                    }
                    if (response.status == 504 || response.status == 522 || response.status == 524) {
                        store.dispatch('setErrorText', 'Похоже, у Вас слабое интернет-соединение!');
                    } else if (response.status == 403 && !response.url.includes('event-potential') && !response.url.includes('event-no-invoice')) {
                        store.dispatch('setErrorText', 'Недостаточно прав для выполнения действия.');
                    }
                    // } else if (response.status == 0) {
                    // store.dispatch('setErrorText', 'Отсутствует доступ к интернету.');
                    // else if (response.status == 401) {
                    // 	let userRole = this.$store.getters.userRole;
                    // 	let path = this.$route.fullPath;
                    // 	if (path.indexOf('web-view') == -1 && path != '/') {
                    // 		store.dispatch('setAuthorizationModalSettings', {
                    // 			isLoginForm: true,
                    // 			isRegistrationForm: false,
                    // 			role: userRole ? userRole : 'person',
                    // 			routeTo: this.$route.path,
                    // 		})
                    // 	}
                    // } 
                    else if (response.status == 503) {
                        store.dispatch('setErrorText', 'На сервере проводятся технические работы. Пожалуйста, попробуйте чуть позже.');
                    } else if (response.status >= 500) {
                        // store.dispatch('setErrorText', 'Какие-то неполадки на сервере. Пожалуйста, попробуйте чуть позже.');
                    } else if (response.status == 429 && this.$ls.get('userRole') === 'moderator') {
                        alert('Слишком много запросов, попробуйте обновить страницу через минуту');
                    }
                });
            });
        },

        setHttpHeaders() {
            const timezoneOffset = new Date().getTimezoneOffset();
            if (timezoneOffset < 0) {
                Vue.http.headers.common['X-TZ-Hour'] = Number(timezoneOffset / -60).toFixed(1);
                Vue.http.headers.common['X-TZ-Abbreviation'] = `GMT+${Number(timezoneOffset / -60).toFixed(1)}`;

                this.$client.defaults.headers.common['X-TZ-Hour'] = Number(timezoneOffset / -60).toFixed(1);
                this.$client.defaults.headers.common['X-TZ-Abbreviation'] = `GMT+${Number(timezoneOffset / -60).toFixed(1)}`;
            } else {
                Vue.http.headers.common['X-TZ-Hour'] = Number(timezoneOffset / 60).toFixed(1);
                Vue.http.headers.common['X-TZ-Abbreviation'] = `GMT${Number(timezoneOffset / 60).toFixed(1)}`;

                this.$client.defaults.headers.common['X-TZ-Hour'] = Number(timezoneOffset / 60).toFixed(1);
                this.$client.defaults.headers.common['X-TZ-Abbreviation'] = `GMT${Number(timezoneOffset / 60).toFixed(1)}`;
            }

            Vue.http.headers.common['Authorization'] = auth.getAuthHeader(this);
            Vue.http.headers.common['X-TZ-Id'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
            Vue.http.headers.common['Accept'] = 'application-json';
            Vue.http.headers.common['accept-language'] = 'ru';
            Vue.http.headers.common['content-type'] = 'application/json';
            Vue.http.headers.common['X-Device-Id'] = this.getUUIDV4();
            Vue.http.headers.common['X-Device-Model'] = window.navigator.platform;

            this.$client.defaults.headers.common['Authorization'] = auth.getAuthHeader(this);
            this.$client.defaults.headers.common['X-TZ-Id'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.$client.defaults.headers.common['Accept'] = 'application-json';
            this.$client.defaults.headers.common['accept-language'] = 'ru';
            this.$client.defaults.headers.common['content-type'] = 'application/json';
            this.$client.defaults.headers.common['X-Device-Id'] = this.getUUIDV4();
            this.$client.defaults.headers.common['X-Device-Model'] = window.navigator.platform;

            const isUserAuthenticated = auth.checkAuth(this);
            if (isUserAuthenticated && firebase.messaging.isSupported()) {
                this.firebaseInit();
            }
        },

        getUUIDV4() {
            const deviceId = this.$ls.get('deviceId');
            if (deviceId) {
                return deviceId;
            } else {
                const uuidv4Result = uuidv4();
                this.$ls.set('deviceId', uuidv4Result);
                return uuidv4Result;
            }
        },

        googlePlusInit() {
            window.googleProvider = new GoogleProvider();
        },

        googleMapsInit() {
            const body = document.getElementsByTagName('body')[0];
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${consts.googleMaps.key}&libraries=places,map`;
            script.onload = () => {
                this.$store.dispatch('googleMapsInitialized');
            };
            body.appendChild(script);
        },

        facebookInit() {
            if (!this.$helpers.isBlockedService('facebook')) {
                window.fbAsyncInit = function() {
                    FB.init(consts.fb);
                    FB.AppEvents.logPageView();
                };

                (function(d, s, id) {
                    let js; const 
                        fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {
                        return; 
                    }
                    js = d.createElement(s); js.id = id;
                    js.src = '//connect.facebook.net/en_US/sdk.js';
                    fjs.parentNode.insertBefore(js, fjs);
                })(document, 'script', 'facebook-jssdk');
            }
        },

        getFirebaseToken() {
            messaging.getToken()
                .then(currentToken => {
                    if (currentToken) {
                        userProvider.setDeviceToken(this, currentToken);
                    }
                });
        },

        firebaseInit() {
            messaging.requestPermission()
                .then(() => {
                    this.getFirebaseToken();
                })
                .catch(error => {
                    console.error(error);
                    console.log('firebase request permission failed');
                });

            messaging.onTokenRefresh(() => {
                this.getFirebaseToken();
            });
        },

        getCookie(name) {
            const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        },

        silentRegister(request) {
            if (!auth.checkAuth(this) && !this.isImplicitLoading && !location.search.includes('client_id')) {
                this.isImplicitLoading = true;
                if (!this.listRejectingRequest.includes(request.url)) {
                    this.implicitRequestsList.push(request);
                }
                auth.register(this)
                    .then(() => {
                        for (request of this.implicitRequestsList) {
                            request.headers.set('Authorization', auth.getAuthHeader(this));
                            this.$http(request);
                        }
                        this.implicitRequestsList.length = 0;
                        this.isImplicitLoading = false;
                    })
                    .catch(() => {
                        this.isImplicitLoading = false;
                    });
            } else if (!auth.checkAuth(this) && this.isImplicitLoading) {
                if (!this.listRejectingRequest.includes(request.url)) {
                    this.implicitRequestsList.push(request);
                }
            }
        },

        refreshToken(request, response) {
            const companyId = store.getters.currentCompanyId;
            const initAppRequests = [
                `/companies/${companyId}/places`,
                `/companies/${companyId}/tariffs/advanced/pending`,
                `/companies/${companyId}/places/on-moderation`,
                `/companies/${companyId}/guides`,
                `/companies/${companyId}/tariffs/advanced/activated`,
            ];
            if (response.status === 401 && request.url !== `${consts.appUri}/token`) {
                store.dispatch('setRefreshTokenException', true);
                if (this.isAuthTokenRefreshing) {
                    if (!this.listRejectingRequest.includes(request.url) && !initAppRequests.some(req => request.url.includes(req))) {
                        this.refreshTokenRequestsList.push(request);
                    }
                    return;
                }
                this.isAuthTokenRefreshing = true;
                auth.refresh(this)
                    .then(() => {
                        for (request of this.refreshTokenRequestsList) {
                            request.headers.set('Authorization', auth.getAuthHeader(this));
                            this.$http(request);
                        }
                        this.refreshTokenRequestsList.length = 0;
                        this.isAuthTokenRefreshing = false;
                        store.dispatch('setRefreshTokenException', false);
                    })
                    .catch(() => {
                        this.isAuthTokenRefreshing = false;
                    });
            }
        },
    },

    render: h => h(App),
});
global.VueApp = VueMain;


// import smoothscroll from 'smoothscroll-polyfill'
// smoothscroll.polyfill()

import promiseAllSettledPolyfill from './utils/polifills/promise-all-settled';
promiseAllSettledPolyfill.polyfill();

export default VueMain;
