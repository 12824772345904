<template>
    <component
        :is="tag"
        v-bind="$attrs"
        class="VButton"
        :type="type"
        :class="[classList]"
        :disabled="disabled"
        @click="onClick"
    >
        <slot></slot>
    </component>
</template>

<script>
    export default {
        name: 'VButton',

        props: {
            active: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            type: {
                type: String,
                default: 'submit',
                validator: (value) => {
                    return [
                        'button',
                        'submit',
                    ].indexOf(value) >= 0;
                },
            },

            tag: {
                type: String,
                default: 'button',
                validator: (value) => {
                    return [
                        'button',
                        'a',
                        'input',
                        'router-link',
                    ].indexOf(value) >= 0;
                },
            },

            size: {
                type: String,
                default: 'medium',
                validator: (value) => {
                    return [
                        'small',
                        'medium',
                    ].indexOf(value) >= 0;
                },
            },

            color: {
                type: String,
                default: 'blue',
                validator: (value) => {
                    return [
                        'red',
                        'blue',
                        'sber',
                    ].indexOf(value) >= 0;
                },
            },

            outline: Boolean,
        },

        computed: {
            classList() {
                return [
                    this.size ? `_${this.size}` : '',
                    this.color ? `_${this.color}` : '',
                    this.outline ? '_outline' : '',
                    this.active ? '_active' : '',
                    this.disabled ? '_disabled' : '',
                ];
            },
        },

        methods: {
            onClick(e) {
                if (!this.disabled) {
                    this.$emit('click', e);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
button {
    border: none;
}

.VButton {
    display: inline-block;
    padding: 15px 30px;
    border-radius: 6px;
    -webkit-appearance: none;
    text-align: center;
    white-space: nowrap;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
    user-select: none;
    transition: $default-transition, line-height 0s ease;
    cursor: pointer;

    @include respond-to(lTablet) {
        padding: 10px;
    }

    &._disabled {
        user-select: none;
        cursor: default;
    }

    &._small {
        padding: 8px 18px;
        height: 32px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    &._medium {
        height: 56px;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;

        @include respond-to(lTablet) {
            height: 42px;
            font-size: 12px;
            line-height: 22px;
        }
    }

    &._blue {
        background-color: $accent-blue;
        color: white;

        &:hover {
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, .04), 0 2px 6px 0 rgba($accent-blue, 0.12), 0 10px 20px 0 rgba($accent-blue, .18);
        }

        &._disabled {
            background-color: rgba($color: $accent-blue, $alpha: .6);

            &:hover {
                box-shadow: none;
            }
        }

        &._outline {
            color: $accent-blue;
            background-color: transparent;
            border: 1px solid $accent-blue;

            &:hover {
                color: $white;
                background-color: $accent-blue;
            }
        }
    }

    &._red {
        background-color: $danger-red;
        color: white;

        &:hover {
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, .04), 0 2px 6px 0 rgba($danger-red, .12), 0 10px 20px 0 rgba($danger-red, .18);
        }

        &._disabled {
            &:hover {
                box-shadow: none;
            }
        }

        &._outline {
            color: $danger-red;
            background-color: transparent;
            border: 1px solid $danger-red;

            &:hover {
                color: $white;
                background-color: $danger-red;
            }
        }
    }

    &._sber {
        background-color: $sber-primary;
        color: $white;

        &:hover {
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, .04), 0 2px 6px 0 rgba($sber-primary, .12), 0 10px 20px 0 rgba($sber-primary, .18);
        }

        &._disabled {
            &:hover {
                box-shadow: none;
            }
        }

        &._outline {
            color: $sber-primary;
            background-color: transparent;
            border: 1px solid $sber-primary;

            &:hover {
                color: $white;
                background-color: $sber-primary;
            }
        }
    }
}
</style>