<template>
  <div id="modal">
    <div class="modal fade" :style="styleString" :id="modalId" tabindex="-1" role="dialog" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
      <div class="modal-dialog modal-lg" :class="{'modal-dialog-centered': isVerticallyCentered}" role="document">
        <div class="modal-content" :style="bodyStyle">
          <div v-if="isHeaderShown" class="modal-header">
            <slot name="header">
            </slot>
          </div>
          <div v-if="isBodyShown" class="modal-body" :style="contentStyle">
            <slot name="body">
            </slot>
          </div>
          <div v-if="isFooterShown" class="modal-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
	import modal from '@/mixins/modal'

  export default {
    name: 'modal',
    props:{
      modalId:{
				required: true,
        default: 'commonModal'
      },
      isHeaderShown: {
        default: true,
      },
      isBodyShown: {
        default: true,
      },
      isFooterShown: {
        default: true,
			},
			isVerticallyCentered: {
				default: false,
			},
      styleString: '',
			bodyStyle: {
				required: false,
				type: [Object, String],
				default: null
			},
			contentStyle: {
				required: false,
				type: [Object, String],
				default: null
			}
    },
		model: {
			event: 'toggle',
			prop: 'active'
		},
		mixins: [
			modal
		],
		data () {
			return {
				isActive: false
			}
		},
		mounted () {
			this.initListeners()
		}
  }
</script>
<style scoped>

</style>