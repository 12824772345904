import Vue from 'vue'
import partnerProvider from "@/utils/providers/partnerProvider";
export default {
	namespaced: true,
	state: () => ({
		partner: null
	}),
	getters: {
		/**
		 * @param state
		 * @returns { ?Partner }
		 */
		partner: state => state.partner
	},
	mutations: {
		setPartner (state, partner) {
			Vue.set(state, 'partner', partner)
		}
	},
	actions: {
		async getPartnerData ({ commit }) {
			return new Promise(async (resolve, reject) => {
				try {
					const partnerData = await partnerProvider.getPartnerSettings()
					const urlParams = new URLSearchParams(location.search)
					partnerData.links.target = urlParams.has('target') ? urlParams.get('target') : partnerData.links.target // '_blank' or ''
					partnerData.style.header = urlParams.has('header') ? Boolean(parseInt(urlParams.get('header'))) : partnerData.style.header // true or false
					commit('setPartner', partnerData)
					resolve(partnerData)
				} catch (error) {
					reject(error)
				}
			})
		}
	}
}