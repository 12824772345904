import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    // loyalty program steps
    completedSteps: [],
  }),
  getters: {
    completedSteps: state => state.completedSteps
  },
  mutations: {
    setSteps(state, steps) {
      Vue.set(state, 'completedSteps', steps)
    }
  },
  actions: {
    async setCurrentSteps({ commit }, steps) {
      return commit('setSteps', steps);
    }
  }
}