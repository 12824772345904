<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M9.16667 9.5V6.16667M13.3333 9.5V6.16667M17.2071 12.2929L14.4596 15.0404C14.272 15.228 14.0177 15.3333 13.7525 15.3333H10.4142C10.149 15.3333 9.89464 15.4387 9.70711 15.6262L8.33333 17C7.71829 17.615 6.66667 17.1794 6.66667 16.3096V16.3096C6.66667 15.7704 6.22956 15.3333 5.69036 15.3333H3.5C2.94772 15.3333 2.5 14.8856 2.5 14.3333V3C2.5 2.44772 2.94772 2 3.5 2H16.5C17.0523 2 17.5 2.44772 17.5 3V11.5858C17.5 11.851 17.3946 12.1054 17.2071 12.2929Z" stroke="#475569" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
    export default {
        name: 'ReviewsIcon',
    };
</script>