<template>
	<div class="lightbox">
		<VueGallery
			ref="gallery"
			:options="{
				closeOnSwipeUpOrDown: true,
			 	toggleControlsOnSlideClick: false,
			 	closeOnSlideClick: true
			}"
			:images="images"
			:index="media.index"
			@onclosed="onClosed"
		>
			<template #next>
				<div class="chevron-wrapper" @click.stop.prevent="onSlide(media.index + 1)">
					<span class="fa fa-chevron-right chevron chevron-right"></span>
				</div>
			</template>
			<template #prev>
				<div class="chevron-wrapper" @click.stop.prevent="onSlide(media.index - 1)">
					<span class="fa fa-chevron-left chevron chevron-left"></span>
				</div>
			</template>
			<template #close>
				<span style="color: white;">×</span>
			</template>
		</VueGallery>
	</div>
</template>

<script>
	import VueGallery from 'vue-gallery';
	import {mapGetters} from "vuex";
	import lazyImages from "@/utils/lazyImages";
	export default {
	name: 'Lightbox',
	components: {
		VueGallery
	},
	data () {
		return {
			lazyImages
		}
	},
	computed: {
		...mapGetters({
			media: 'media/media'
		}),
		images () {
			return this.media.items.map((item) => {
				let type = '';
				if (item.type === 'image') {
					type = 'image/jpeg';
				} else {
					type = 'video/mp4';
				}
				return { href: item.url, type: type, poster: item.thumbnail_url };
			})
		}
	},
	watch: {
		'media.active' (isActive) {
			if (isActive) {
				this.open()
			}
		}
	},
	methods: {
		onSlide (index) {
			let slideIndex = 0
			if (index < 0) {
				slideIndex = this.media.items.length - 1
			} else if (index > this.media.items.length - 1) {
				slideIndex = 0
			} else {
				slideIndex = index
			}
			this.$refs.gallery.instance.slide(slideIndex, null)
			this.$store.commit('media/SET_MEDIA', {
				...this.media,
				index: slideIndex
			})
		},
		onClosed () {
			this.$store.commit('media/SET_MEDIA', {
				items: [],
				index: -1,
				active: false
			})
		},
		close () {
			this.$refs.gallery.instance.close()
		},
		open () {
			this.$refs.gallery.open(this.media.index)
		}
	}
}
</script>

<style lang="scss">

.video-content:not(.video-loading) > a {
	background-image: url('/src/assets/images/play.png') !important;
	background-size: 60px 60px !important;
	transition: opacity 0.3s;
}
.gallery-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

</style>

<style lang="scss" scoped>

	.close {
		display: block !important;
	}

	.chevron {
		font-size: 18px;
		color: white;
		padding-bottom: 3px;

		&-left {
			padding-right: 3px;
			padding-bottom: 0;
		}

		&-right {
			padding-left: 3px;
			padding-bottom: 0;
		}

		&-wrapper {
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
		}
	}

	.next, .prev, .close {
		color: white !important;
		opacity: 0.5 !important;
		transition: opacity 0.3s;
		display: flex !important;
		justify-content: center!important;
		align-items: center!important;

		&:hover {
			opacity: 1 !important;
		}
	}
</style>