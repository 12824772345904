import store from '@/store/index.js';
import consts from '@/utils/consts.js';
import AuthTokenHelper from '@/utils/helpers/authTokenHelper.js';
import userProvider from "@/utils/providers/userProvider";
import Vue from 'vue'
import bankProvider from "@/utils/providers/bankProvider";
import { NODE_ENV_TYPES } from "@/utils/constants";
var authTokenHelper = new AuthTokenHelper();


function getAuthHeader(context) {
	let token = Vue.ls.get('token');
	if (!token) {
		return;
	}

	return 'Bearer ' + token;
};

export default {
	silentRegister(context) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await context.$http.post(consts.appUri + '/register/implicit')
				await this.authenticate(context, data.body)
				// if (window.location.pathname !== "/") {
				// 	console.log('reload location')
				// 	location.reload();
				// }
				await context.$store.dispatch('setAuthorizationModalSettings', {
					isLoginForm: true,
					isRegistrationForm: false,
					role: 'person',
					routeTo: context.$route.fullPath
				})
				resolve(data);
			} catch (error) {
				reject(error)
			}
		})
	},
	/**
	 *
	 * @param { Vue } context
	 * @param { AuthorizationData } data
	 * @returns {Promise<unknown>}
	 */
	authenticate(context, data) {
		return new Promise(async (resolve, reject) => {
			try {
				let authenticate = data;
				if (!authenticate.token) {
					reject(new Error("Token absent"))
				}

				let id = data.id ? data.id : Vue.ls.get('id');
				Vue.ls.set('id', id);
				Vue.ls.set('token', authenticate.token);
				Vue.ls.set('refreshToken', authenticate.refresh_token);
				Vue.ls.set('authType', authenticate.type || 'user');
				Vue.ls.set('ext_user_token', authenticate.ext_user_token || null)
				let user = {
					authenticated: true,
					token: authenticate.token,
					refreshToken: authenticate.refresh_token,
					type: authenticate.type,
					ext_user_token: authenticate.ext_user_token || null,
					id
				};

				Vue.http.headers.common['Authorization'] = `Bearer ${authenticate.token}`;
				await store.dispatch('changeAuthentificationStatus', user);
				resolve(authenticate)
			} catch (error) {
				console.error(error)
				reject(error)
			}
		})
	},
	login(context, username, password) {
		return new Promise((resolve, reject) => {
			username = username.toLowerCase();
			context.$http.post(consts.appUri + '/login', { username: username, password: password })
				.then(
					data => {
						this.authenticate(context, data.body).then(() => resolve(data));
					},
					error => {
						reject(new Error(error));
					});
		})
	},

	loginFacebook(context, accessToken) {
		return new Promise((resolve, reject) => {
			context.$http.post(consts.appUri + '/login/fb', { access_token: accessToken })
				.then(
					data => {
						this.authenticate(context, data.body).then(() => resolve(data));
					},
					error => {
						reject(new Error(error));
					});
		})
	},

	loginVk(context, code) {
		return new Promise((resolve, reject) => {
			context.$http.post(consts.appUri + '/login/vk', {
				code: code,
				redirect_uri: consts.vk.vkRedirectUri
			})
				.then(
					data => {
						this.authenticate(context, data.body).then(() => resolve(data));
					},
					error => {
						reject(new Error(error));
					});
		})
	},

	loginVkMiniapp(context, data) {
		return new Promise((resolve, reject) => {
			context.$http.post(`${consts.appUri}/login/vk/miniapp`, JSON.stringify(data))
				.then(
					data => {
						this.authenticate(context, data.body).then(() => resolve(data));
					},
					error => {
						reject(new Error(error));
					});
		})
	},

	loginOk(context, accessToken, secret) {
		return new Promise((resolve, reject) => {
			context.$http.post(consts.appUri + '/login/ok', { access_token: accessToken, secret: secret })
				.then(
					data => {
						this.authenticate(context, data.body).then(() => resolve(data));
					},
					error => {
						reject(new Error(error));
					});
		})
	},

	loginGoogle(context, accessToken) {
		return new Promise((resolve, reject) => {
			context.$http.post(consts.appUri + '/login/google', { access_token: accessToken })
				.then(
					data => {
						this.authenticate(context, data.body).then(() => resolve(data));
					},
					error => {
						reject(new Error(error));
					});
		})
	},

	/**
	 *
	 * @param { PartnerCredentials } credentials
	 * @returns {Promise<unknown>}
	 */
	loginPartner(credentials) {
		return new Promise(async (resolve, reject) => {
			try {
				const { grant_type, client_id, client_secret, ext_user_token, scope } = credentials
				const { promise } = Vue.http.post('auth/token', { grant_type, client_id, client_secret, ext_user_token, scope })
				const { data } = await promise
				resolve({
					...data,
					client_id,
					client_secret,
					ext_user_token,
					scope,
					grant_type
				})
			} catch (error) {
				reject(error)
			}
		})
	},

	register(context, user) {
		return new Promise((resolve, reject) => {
			if (user) {
				user.email = user.email.toLowerCase();
				context.$http.post(consts.appUri + '/register/explicit',
					{
						email: user.email,
						password: user.password,
						password_confirmation: user.passwordConfirmation,
						name: user.name,
						last_name: user.lastName,
					})
					.then((data) => {
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					})
			} else if (!user && this.checkAuth(context)) {
				resolve();
			} else {
				this.silentRegister(context).then((data) => {
					resolve(data);
				})
					.catch((data) => {
						reject(data)
					});
			}
		})
	},

	refresh(context) {
		return new Promise(async (resolve, reject) => {
			context.$http.post(consts.appUri + '/token',
				{
					refresh_token: context.$ls.get('refreshToken')
				})
				.then((data) => {
					this.authenticate(context, data.body).then(() => resolve(data));
				})
				.catch((error) => {
					if (error.status >= 400) {
						this.logOut(context);
					}

					reject(error);
				})
		})
	},

	logOut(context) {
		context.$http.post(consts.appUri + '/logout');
		this.silentLogOut(context)
		context.$router.push('/person/home');
	},

	/**
	 * Выйти из аккаунта без редиректов и всего такого
	 */
	silentLogOut(context) {
		return new Promise(async (resolve, reject) => {
			try {
				const deviceId = Vue.ls.get('deviceId');
				let user = { authenticated: false };
				Vue.ls.remove('username');
				Vue.ls.remove('token');
				Vue.ls.remove('refreshToken');
				Vue.ls.remove('id');
				Vue.ls.remove('lastName');
				Vue.ls.remove('name');
				Vue.ls.remove('companies');
				Vue.ls.remove('administratedCompanies');
				Vue.ls.remove('emailConfirmed');
				Vue.ls.remove('phoneConfirmed');
				Vue.ls.remove('userRole');
				Vue.ls.remove('userProfile');
				Vue.ls.remove('currentCompanyId');
				Vue.ls.remove('isModerator');
				Vue.ls.remove('isPlaceAdministrator');
				if (deviceId) {
					Vue.ls.set('deviceId', deviceId);
				};
				Vue.ls.clear(); // TODO сделал вывод выбора роли везде, посмотреть на наличие ошибок
				Vue.http.headers.common['Authorization'] = '';
				await store.dispatch('changeAuthentificationStatus', user);
				await store.dispatch('changeIsModerator', { context: context, isModerator: false });
				await store.dispatch('setUserRole', '');
				await store.dispatch('changeModeratorLink', '');
				resolve()
			} catch (error) {
				console.error(error)
				reject(error)
			}
		})
	},

	checkAuth(context) {
		var user = {
			authenticated: false,
			token: Vue.ls.get('token'),
			refreshToken: Vue.ls.get('refreshToken'),
		};

		if (user.token) {
			user.authenticated = true;
			store.dispatch('setUserProfile', Vue.ls.get('userProfile'));
		}

		Vue.http.headers.common['Authorization'] = getAuthHeader(context);
		store.dispatch('changeAuthentificationStatus', user);

		return user.authenticated;
	},

	sendCode(context, username) {
		return context.$http.post(consts.appUri + '/password/forgot',
			{
				username: username
			})
	},

	getAuthHeader: getAuthHeader,

	getCookie(name) {
		const matches = document.cookie.match(new RegExp(
			"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		))
		return matches ? decodeURIComponent(matches[1]) : undefined;
	},

	/**
	 * @param { PartnerCredentials } partnerData
	 * @returns {Promise<unknown>}
	 */
	authenticatePartner(partnerData) {
		return new Promise(async (resolve, reject) => {
			try {
				await this.silentLogOut()
				const data = await this.loginPartner(partnerData)
				/**
				 * @type AuthorizationData
				 */
				const authData = {
					...data,
					token: data.access_token,
					refresh_token: null,
					type: 'partner',
				}
				await this.authenticate(this, authData)
				resolve()
			} catch (error) {
				console.error(error)
				reject(error)
			}
		})
	},

	/**
	 *
	 * @param { PartnerCredentials } credentials
	 * @returns {Promise<unknown>}
	 */
	setUserPartner(credentials) {
		return new Promise(async (resolve, reject) => {
			try {
				if (!this.getCookie('token')) {
					if (credentials && credentials.client_id) {
						await this.authenticatePartner(credentials)
						const user = store.getters.user
						store.commit('changeAuthentificationStatus', { ...user, authenticated: true })
						await store.dispatch('partner/getPartnerData')

						// ВЫПИЛИВАЕМ bank_card из вебвью
						// bankProvider.getBankCard(this, credentials.bank_card_id)
						// 	.then((card) => {
						// 		store.commit('changeAuthentificationStatus', { ...user, authenticated: true, card })
						// 	})
						// 	.catch((error) => { console.error(error) })

						userProvider.getCurrentUser(this);
						// userProvider.userRegisterVisit(this);
					}
				}
				resolve()
			} catch (error) {
				console.error(error)
				reject(error)
			}
		})
	},

	setUserBasic() {
		return new Promise(async (resolve, reject) => {
			try {
				if (localStorage.token) {
					// await userProvider.getCurrentUser(this, localStorage.token ? JSON.parse(localStorage.token).value : null);
					// запрос падал из-за device id
					this.checkAuth(this);
				}
				resolve()
			} catch (error) {
				console.error(error)
				reject(error)
			}
		})
	},

	setAuthHeader(token) {
		console.log('Set header', token)
		Vue.http.headers.common = `Bearer ${token}`
	}
}