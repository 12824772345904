<script>
import {mapGetters} from "vuex";
export default {
	watch: {
		modal (modal) {
			this.$nextTick(() => {
				const component = this.getComponent()
				if (component) {
					if (modal.active) {
						component.show()
					} else {
						component.hide()
					}
				}
			})
		}
	},
	methods: {
		getComponent () {
			const component = this.$children && this.$children[0]
			return component || null
		}
	},
	computed: {
		...mapGetters({
			modal: 'global/modal'
		}),
		shouldRenderModal () {
			return this.modal.active
		}
	},
	render (createElement) {
		if (this.shouldRenderModal) {
			return this.modal.render(createElement)
		} else {
			return '<div></div>'
		}
	}
}
</script>