export default function initIndexedDB() {
	let openRequest = indexedDB.open("benefitty", 2);

	openRequest.onupgradeneeded = () => {
		let db = openRequest.result;
		if (!db.objectStoreNames.contains('all')) {
			db.createObjectStore("all", {keyPath: "id"});
		}
		if (!db.objectStoreNames.contains('recommended')) {
			db.createObjectStore("recommended", {keyPath: "id"});
		}
		if (!db.objectStoreNames.contains('friends')) {
			db.createObjectStore("friends", {keyPath: "id"});
		}
		if (!db.objectStoreNames.contains('discounts')) {
			db.createObjectStore("discounts", {keyPath: "id"});
		}
		if (!db.objectStoreNames.contains('cards')) {
			db.createObjectStore("cards", {autoIncrement: true});
		}
	};

	return openRequest;
}