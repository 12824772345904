import Vue from 'vue'

const state = () => {
	return {
		media: {
			items: [],
			index: -1,
			active: false
		}
	}
}

const getters = {
	media: state => state.media
}

const mutations = {
	/**
	 * @param state
	 * @param { MediaData } media
	 */
	SET_MEDIA (state, media) {
		Vue.set(state, 'media', {
			...state.media,
			...media
		})
	}
}

export default {
	namespaced: true,
	state, getters, mutations
}